import * as React from 'react';
import ReactFlow, { Background, Controls, MiniMap, Node as ReactFlowNode, Edge as ReactFlowEdge, Position, ReactFlowInstance, MarkerType, EdgeMarkerType, ConnectionLineType, BackgroundVariant } from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { useHistory } from 'react-router';
import { getUrlParameterByName } from '../../../components_v2/utils';
import { getLocalFormById, setLocalForm } from '../localForm';
import { Form } from '../create-from-template/type';
import { useFunctionState } from '../../../utils/customHooks';
import { assert } from '../../../utils/assert';
import { FlexDiv } from '../../products/style';
import { BlueSidely, GreySidely, LightBlueSidely, RedSidely } from '../../../styles/global/css/Utils';
import * as _ from 'lodash';
import Equal from 'deep-equal';
import { PaginationValue, Screen } from '../jsonValidator';
import { BoolEditor, EditNextScreens, PageNameEditor, Wrapper } from './componentsEditor';
import { postForm } from '../actions';
import { addFieldToPage, pageExists, pageValidator } from './formProcessingTools';
import { toast } from 'react-toastify';
import Input from '../../../components_v2/input/Input';
import { EditLayout } from './layoutEditor';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import Popup from '../../../components_v2/popup/Popup';
import { LeftModal } from '../FormTemplateCreator';
import { kebabCase } from 'lodash';
import { Field, getFields } from '../../../atoms/forms';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import Close from 'images/icons/orders/close.svg';
import { Translate, translateToNode } from '../../../styles/global/translate';
import { ComponentProps } from '../../globals/mainPage/mainPage';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { TitleAndChild } from '../../client-companies/popup/Detail';


function launchToastError(message: string) {
	toast.error(message, {
		position: 'top-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined
	});
}
const DefaultNodeStyle = { };

const WarningNodeStyle = { border: '2px solid #ff973280' };
const WarningSelectedNodeStyle = { background: '#F88158', border: '2px solid #ff973280' };

const ErrorNodeStyle = { border: '2px solid #FF1010' };
const ErrorSelectedNodeStyle = { background: '#F75D59', border: '2px solid #FF1010', color: 'white' };

const SelectedNodeStyle = {
	border: '2px solid ${BlueSidely}',
	background: LightBlueSidely
};

const InfoSelectedNodeStyle = { border: '1px solid  #f3f4bc ', background: '#feffd1' };
const InfoNodeStyle = { background: '#feffd1' };

function PanelEditor(props: { pageNumber: number, setPageEditing: (value: number | undefined) => void}) {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [newForm, setNewForm] = React.useState<Form>(form);
	const [addFieldPopup, setAddFieldPopup] = React.useState<boolean>(false);
	const pageName = form[props.pageNumber].name;
	const [fields, setFields] = React.useState<Field[]>();
	const alert = useAlert();

	React.useEffect(() => {
		getFields().then(setFields);
	}, []);


	if (fields) {
		return <>
			{pageName && addFieldPopup && <Popup
				popupStyle={{ noTransition: true }}
				isOpen={addFieldPopup} popupMode={PopupMode.Details} onClickOut={() => setAddFieldPopup(false)} content={
					<>
						<div style={{ width: '100%', height: 'calc(100vh - 120px)' }}>
							<LeftModal
								externalUse={true}
								allowEdit={false}
								onFieldClicked={(f) => {
									setForm(addFieldToPage(newForm, pageName, {
										name: kebabCase(f.name), field_id: f.id, metadata: {
											'catalogue': null,
											'product': null
										}
									}));
									setAddFieldPopup(false);
								}}
								errors={[]}
							/>
						</div>
					</>}
			/>}
			<div style={{
				cursor: 'pointer',
			}} onClick={c => props.setPageEditing(undefined)}>
				<img src={Close} alt='close'/>
			</div>
			
			<PageNameEditor path='name' pageNumber={props.pageNumber} />
			<DefaultButton
				buttonStyle={ButtonStyle.White}
				style={{
					position: 'absolute',
					top: '54px',
					right: '475px'
				}}
				onClick={() => {
					alert({
						title: 'Confirmation de suppression',
						// eslint-disable-next-line react/no-unescaped-entities
						content: <>Voulez-vous vraiment supprimer cette page: "{pageName}" ?</>,
						buttons: [
							{
								title: translateToNode('cancel'),
								res: AlertRes.Break,
								style: ButtonStyle.White
							},
							{
								title: translateToNode('yes'),
								res: AlertRes.Ok,
								style: ButtonStyle.Error
							}
						],
					}).then((res) => {
						if (res === AlertRes.Ok) {
							props.setPageEditing(undefined);
							newForm.splice(props.pageNumber, 1);
							setForm(newForm);
						}
					});
				}}
			>Supprimer cette page
			</DefaultButton>
			<TitleAndChild
				title={'Paramètres de la page'}>
				<div style={{ alignItems: 'center', width: '100%' }}>
					<Wrapper>
						<FlexDiv>
							Activer la progression <BoolEditor defaultIsEnabled path='progression' pageNumber={props.pageNumber} />
						</FlexDiv>
						{<EditNextScreens path={'next_screens'} pageNumber={props.pageNumber} component={form[props.pageNumber].next_screens} />}
					</Wrapper>
				</div>
			</TitleAndChild>
			<hr />
			<TitleAndChild defaultOpen={true} title={<Translate id='form_editor.display_order' />}>
				<div style={{ width: '100%' }}>
					<EditLayout fieldList={fields} path='layout' pageNumber={props.pageNumber} layout={form[props.pageNumber].layout} />
				</div>
			</TitleAndChild>
			<hr />
			<TitleAndChild title={'Voir le code'}>
				<Input spellcheck={false} type='text' textArea={true} inputStyle={{ color: 'black', height: '500px', width: '900px' }} name='page'
					value={JSON.stringify(form[props.pageNumber], null, 2)}
					onChange={(text) => {
						const newForm = _.cloneDeep(form);
						newForm[props.pageNumber] = JSON.parse(text);
						setNewForm(newForm);
					}}
				></Input>
			</TitleAndChild>
		</>;
	}
}

export const FormEditorContext = React.createContext<{
	form: Form
	setForm: React.Dispatch<React.SetStateAction<Form>>
		}>({ form: {} as Form, setForm: () => undefined });

function FormEditorProvider(props: {form: string, setForm: (value: string) => void, children: React.ReactNode }): JSX.Element {
	const [form, setForm] = useFunctionState<Form>(JSON.parse(props.form), React.useCallback(({ newValue }) => {
		props.setForm(JSON.stringify(newValue, null, 2));
		return newValue;
	}, []));

	return <FormEditorContext.Provider value={{ form, setForm }}>
		{props.children}
	</FormEditorContext.Provider>;
}

function calc_layouted_elements(form: Form, fields: Field[], pageEditing: number | undefined): {nodes: ReactFlowNode[], edges: ReactFlowEdge[] } {
	const initialEdges: ReactFlowEdge[] = [];
	const initialNodes: ReactFlowNode[] = [];

	form.forEach((screen, index) => {
		if (screen.name && fields) {
			let style = DefaultNodeStyle;

			if (index == pageEditing) {
				switch (pageValidator(form, screen.name, fields)) {
					case 'warning':
						style = WarningSelectedNodeStyle;
						break;
					case 'error':
						style = ErrorSelectedNodeStyle;
						break;
					case 'info':
						style = InfoSelectedNodeStyle;
						break;
					default:
						style = SelectedNodeStyle;
						break;
				}
			} else {
				switch (pageValidator(form, screen.name, fields)) {
					case 'warning':
						style = WarningNodeStyle;
						break;
					case 'error':
						style = ErrorNodeStyle;
						break;
					case 'info':
						style = InfoNodeStyle;
						break;
					default:
						break;
				}
			}

			initialNodes.push({
				id: screen.name,
				position: { x: 0, y: 0 },
				data: { label: screen.name, index: index },
				style: style
			});
		}
	});

	const UpStyle = { stroke: GreySidely };
	const DownStyle = { stroke: BlueSidely, strokeWidth: 3 };


	form.forEach((screen) => {

		screen.next_screens.forEach(nextScreen => {
			if (nextScreen && nextScreen.screen_name && screen.name)
				initialEdges.push({
					id: `${screen.name}-${nextScreen.screen_name}`,
					source: nextScreen.screen_name,
					target: screen.name,
					style: UpStyle,
				});
		});

		screen.components.forEach(component => {
			if (component.type == 'pagination' && typeof component.data == 'object' && !Array.isArray(component.data)) {
				const pagination = component.data;
				if (pagination && pagination['value']) {
					pagination['value'].forEach((value: PaginationValue) => {
						if (value.screen && value.screen.screen_name && screen.name) {
							if (pageExists(form, value.screen.screen_name)) {
								initialEdges.push({
									id: `${screen.name}-${value.screen.screen_name}`,
									target: value.screen.screen_name,
									source: screen.name,
									style: DownStyle,
									// markerEnd: ArrowDown,
								});
							} else {
								initialEdges.push({
									id: `${screen.name}-${value.screen.screen_name}-error`,
									target: `${value.screen.screen_name}-error`,
									source: screen.name,
									style: DownStyle,
									// markerEnd: ArrowDown,
								});
								initialNodes.push({
									id: `${value.screen.screen_name}-error`,
									position: { x: 0, y: 0 },
									data: { label: value.screen.screen_name, index: -1 },
									style: { background: RedSidely },
								});
							}
						}
					});
				}
			}
			else if (component.type === 'links' && Array.isArray(component.data)) {
				component.data.forEach((link: string) => {
					if (screen.name && link != '')
						if (pageExists(form, link)) {
							initialEdges.push({
								id: `${screen.name}-${link}`,
								target: link,
								source: screen.name,
								style: DownStyle,
							});
						}
						else {
							initialEdges.push({
								id: `${screen.name}-${link}-error`,
								target: `${link}-error`,
								source: screen.name,
								style: DownStyle,
								// markerEnd: ArrowDown,
							});
							initialNodes.push({
								id: `${link}-error`,
								position: { x: 0, y: 0 },
								data: { label: link, index: -1 },
								style: { background: RedSidely },
							});
						}
				});
			}
			else if (component.screen && screen.name) {
				if (component.screen.screen_name && pageExists(form, component.screen.screen_name)) {
					initialEdges.push({
						id: `${screen.name}-${component.screen.screen_name}`,
						target: component.screen.screen_name,
						source: screen.name,
						style: DownStyle,
					});
				} else {
					initialEdges.push({
						id: `${screen.name}-${component.screen.screen_name}-error`,
						target: `${component.screen.screen_name}-error`,
						source: screen.name,
						style: DownStyle,
					});
					initialNodes.push({
						id: `${component.screen.screen_name}-error`,
						position: { x: 0, y: 0 },
						data: { label: component.screen.screen_name, index: -1 },
						style: { background: RedSidely },
					});
				}
			}
		});
	});
	
	const dagreGraph = new dagre.graphlib.Graph();
	dagreGraph.setDefaultEdgeLabel(() => ({}));
	
	
	const nodeWidth = 172;
	const nodeHeight = 36;

	const getLayoutedElements = (nodes: ReactFlowNode[], edges: ReactFlowEdge[], direction = 'BR') => {
		const isHorizontal = direction === 'LR';

		dagreGraph.setGraph({ rankdir: direction });

		nodes.forEach((node) => {
			dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
		});

		edges.forEach((edge) => {
			dagreGraph.setEdge(edge.source, edge.target);
		});

		dagre.layout(dagreGraph);

		nodes.forEach((node) => {
			const nodeWithPosition = dagreGraph.node(node.id);
			node.targetPosition = isHorizontal ? Position.Left : Position.Top;
			node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
			node.position = {
				x: nodeWithPosition.x - nodeWidth / 2,
				y: nodeWithPosition.y - nodeHeight / 2,
			};
			return node;
		});
	
		return { nodes, edges };
	};

	return getLayoutedElements(
		initialNodes,
		initialEdges
	);
}


function _VisualFormEditor() {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [pageEditing, setPageEditing] = React.useState<number | undefined>(undefined);
	const [reactFlowInstance, setReactFlowInstance] = React.useState<ReactFlowInstance | undefined>(undefined);
	const [layoutedEdges, setLayoutedEdges] = React.useState<ReactFlowEdge[]>([]);
	const [layoutedNodes, setLayoutedNodes] = React.useState<ReactFlowNode[]>([]);
	const [fields, setFields] = React.useState<Field[]>();


	React.useEffect(() => {
		getFields().then(setFields);
	}, []);

	React.useEffect(() => {
		if (form && fields) {
			const { nodes, edges } = calc_layouted_elements(form, fields, pageEditing);
			setLayoutedEdges(edges);
			setLayoutedNodes(nodes);
		}
	}, [JSON.stringify(form), fields, pageEditing]);


	React.useEffect(() => {
		setTimeout(() => reactFlowInstance?.fitView(), 0);
	}, [pageEditing]);
	


	function nodeClickEvent(event: React.MouseEvent, node: ReactFlowNode) {
		if (!node.id.includes('-error')) {
			if (pageEditing != undefined) {
				setPageEditing(node.data.index);
			} else {
				setPageEditing(node.data.index);
				setTimeout(() => reactFlowInstance?.fitView(), 0);
			}
		} else {
			setPageEditing(undefined);
			launchToastError(node.id.replace('-error', '') + ' does not exist in this form.');
		}
	}	

	function onInit(instance: ReactFlowInstance) {
		setReactFlowInstance(instance);
	}
	return <>
		{/* Todo: add page with guided interface and different templates */}
		<DefaultButton
			style={{ marginRight: '10px', position: 'absolute',
				top: '54px',
				right: '350px' }}
			onClick={() => {
				const newPageName = prompt('Nom de la nouvelle page');
				if (newPageName && form) {
					const newForm = _.cloneDeep(form);
					newForm.push({ ...defaultPage, name: newPageName });
					setForm(newForm);
				}
			}}
		>
						Nouvelle page
		</DefaultButton>
		<FlexDiv
			style={
				{
					overflow: 'scroll',
				}	
			}
		>


			<div style={{ width: 'calc(' + (pageEditing != undefined ? 33 : 100) + 'vw - 60px)', height: 'calc(100vh - 120px)' }}>
				{layoutedNodes.length > 1 && <ReactFlow
					fitView={true}
					nodes={layoutedNodes}
					edges={layoutedEdges}
					onNodeClick={nodeClickEvent}
					onInit={onInit}
					proOptions={{ hideAttribution: true }}
				>
					<Controls />
					<MiniMap />
					<Background variant={BackgroundVariant.Dots} gap={12} size={1} />
				</ReactFlow>}
			</div>
			{pageEditing != undefined && <div style={{ height: 'calc(100vh - 120px)', width: 'calc(69vw - 60px)', backgroundColor: 'white', overflow: 'scroll' }}>
				<PanelEditor setPageEditing={setPageEditing} pageNumber={pageEditing} />
			</div>}
		</FlexDiv>
	</>;
}
const defaultPage: Screen = {
	components: [
		{
			'data': 'Texte',
			'name': 'product_text',
			'type': 'text'
		}
	],
	fields: [],
	layout: {
		children: [
			{
				'type': 'field',
				'name': 'product_text'
			}
		],
		type: 'column'
	},
	next_screens: [],
	name: 'default_page'
};



export function VisualFormEditor(
	props: ComponentProps
): JSX.Element {
	const history = useHistory();
	const formId = parseInt(getUrlParameterByName('id') ?? 'NaN');
	const formFromStorage = getLocalFormById(isNaN(formId) ? undefined : formId);
	const [savable, setSavable] = React.useState<boolean>(false);
	const [openEditFields, setOpenEditFields] = React.useState<boolean>(false);
	// const alert = useAlert();


	if (!formFromStorage) {
		history.replace({ pathname: 'form-template-list' });
		return <></>;
	}

	assert(formFromStorage.screens, 'The form is null');
	const [form, setForm] = React.useState<string>((formFromStorage.screens));



	React.useEffect(() => {
		if (formFromStorage.screens && !Equal(JSON.stringify(JSON.parse(form), null, 2), JSON.stringify(JSON.parse(formFromStorage.screens), null, 2))) {
			setSavable(true);
		} else {
			setSavable(false);
		}
	}, [JSON.stringify(form)]);

	React.useEffect(() => {
		if (form) {
			props.setToolBarState({
				title: 'Edition du formulaire ' + (formFromStorage ? formFromStorage.name : ''),
				bottomRightToolbarComponent: <>
					<DefaultButton onClick={() => setOpenEditFields(true)	}>
						Modifier les champs du compte
					</DefaultButton>
					<DefaultButton
						disabled={!savable}
						onClick={() => {
							const newForm = getLocalFormById(formId);
							if (newForm) {
								newForm.screens = JSON.parse(form);
								setLocalForm({ ...newForm, screens: JSON.stringify(newForm.screens) }, formId);
								postForm(newForm);
								setSavable(false);
							}
						}
						}
					>
						<Translate id='save' />
					</DefaultButton>
				</>,
				bottomLeftToolbarComponent: <>
					<DefaultButton onClick={() => history.goBack()}>
						<Translate id='back' />
					</DefaultButton>
				</>
			});
		}

	}, [form, savable]);
	return <>
		<Popup isOpen={openEditFields} popupMode={PopupMode.Details} onClickOut={() => setOpenEditFields(false)} content={
			<div style={{ width: '100%', height: '100%' }}>
				<LeftModal
					externalUse={true}
					allowEdit={true}
					errors={[]}

				/>
			</div>}
		/>
		<FormEditorProvider
			form={form}
			setForm={setForm}
		>
			<_VisualFormEditor/>
		</FormEditorProvider>
	</>;

}