import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { BlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { Radio } from '../../filterList/style/Style';
import Input from '../../input/Input';
import { FilterAction, FilterAdditionalValue, FilterId, FilterQueryResult, FilterTree, FilterValueOrValues, FilterValueResult } from '../model/Model';
import { Container, FilterText, InputContainer, RadioContainer } from '../style/AdvancedFilterStyle';
import { filterIdFormater } from './FilterSelection';
import { FilterToIndex, parseFloatOrUndefined } from './utils';

function switchFilterToIndex(filter: FilterAction | undefined): number | undefined {
	switch (filter) {
		case FilterAction.IS_EQUAL:
			return 0;
		case FilterAction.IS_NOT_EQUAL:
			return 1;
		case FilterAction.MORE_THAN:
			return 2;
		case FilterAction.EQUAL_OR_MORE_THAN:
			return 3;
		case FilterAction.LESS_THAN:
			return 4;
		case FilterAction.EQUAL_OR_LESS_THAN:
			return 5;
		case FilterAction.BETWEEN:
			return 6;
		case FilterAction.IS_EMPTY:
			return 7;
		case FilterAction.IS_NOT_EMPTY:
			return 8;
		default:
			return;
	}
}

function NumberFilter(props: {
  id: FilterId
  title: string
  onReturn: () => void
  onSubmit: (value: FilterValueResult) => void
  additionalValue?: FilterAdditionalValue
  percentage: boolean
  selectedValue: FilterTree | undefined
}): JSX.Element {
	let selectedValue: FilterQueryResult | undefined;
	if (props.selectedValue && 'val' in props.selectedValue) {
		selectedValue = props.selectedValue.val;
	}
	const [index, setindex] = React.useState<number | undefined>(FilterToIndex(selectedValue?.operator, switchFilterToIndex));

	const [value, setValue] = React.useState<number | undefined>(parseFloatOrUndefined(selectedValue?.value?.split(',')[0]));
	const [value2, setValue2] = React.useState<number | undefined>(parseFloatOrUndefined(selectedValue?.value?.split(',')[1]));

	const id = filterIdFormater(props.id, props.additionalValue);

	React.useEffect(() => {
		const splitted = selectedValue?.value?.split(',');
		setValue(parseFloatOrUndefined(splitted?.[0]));
		setValue2(parseFloatOrUndefined(splitted?.[1]));
	}, [index]);

	React.useEffect(() => {
		if (!value) {
			setValue(undefined);
		}
		if (!value2) {
			setValue2(undefined);
		}
	}, [value, value2]);

	function submit(): void {
		if (!(value !== undefined || index === 7 || index === 8 || (index === 6 && value !== undefined && value2 !== undefined))) {
			return;
		}
		let action: FilterAction;
		let b: FilterValueOrValues<number> = {
			value: props.percentage && value !== undefined ? value / 100 : value,
			displayedValue: props.percentage && value ? `${value}%` : value?.toString(),
		};

		switch (index) {
			case 0:
				action = FilterAction.IS_EQUAL;
				break;
			case 1:
				action = FilterAction.IS_NOT_EQUAL;
				break;
			case 2:
				action = FilterAction.MORE_THAN;
				break;
			case 3:
				action = FilterAction.EQUAL_OR_MORE_THAN;
				break;
			case 4:
				action = FilterAction.LESS_THAN;
				break;
			case 5:
				action = FilterAction.EQUAL_OR_LESS_THAN;
				break;
			case 6:
				action = FilterAction.BETWEEN;
				value && value2 && (b = {
					values: [props.percentage ? value / 100 : value, props.percentage ? value2 / 100 : value2],
					displayedValues: [
						props.percentage ? `${value}%` : value.toString(),
						props.percentage ? `${value2}%` : value2.toString()
					]
				});
				break;
			case 7:
				action = FilterAction.IS_EMPTY;
				break;
			case 8:
				action = FilterAction.IS_NOT_EMPTY;
				break;
			default:
				return;
		}
		props.onSubmit({
			action,
			id,
			title: props.title,
			// @ts-expect-error TODO TOFIX THIS IS LEGACY FONT ONLY
			type: 'number',
			titleComment: props.additionalValue?.titleComment,
			...b
		});
	}

	return (
		<Container>
			<FilterText color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.onReturn()}>&lt;<Translate id='back' /></FilterText>
			<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{props.title}</FilterText>
			<RadioContainer onClick={() => setindex(0)}>
				<Radio isActive={index === 0} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_equal_to' /></FilterText>
			</RadioContainer>
			{
				index === 0 &&
				<Input name="filter_value" type="number" onChange={(value) => setValue(value)} value={value}/>
			}
			<RadioContainer onClick={() => setindex(1)}>
				<Radio isActive={index === 1} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not_equal_to' /></FilterText>
			</RadioContainer>
			{
				index === 1 &&
				<Input name="filter_value" type="number" onChange={(value) => setValue(value)} value={value}/>
			}
			<RadioContainer onClick={() => setindex(2)}>
				<Radio isActive={index === 2} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_greater_than' /></FilterText>
			</RadioContainer>
			{
				index === 2 &&
				<Input name="filter_value" type="number" onChange={(value) => setValue(value)} value={value}/>
			}
			<RadioContainer onClick={() => setindex(3)}>
				<Radio isActive={index === 3} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_greater_or_equal_to' /></FilterText>
			</RadioContainer>
			{
				index === 3 &&
				<Input name="filter_value" type="number" onChange={(value) => setValue(value)} value={value}/>
			}
			<RadioContainer onClick={() => setindex(4)}>
				<Radio isActive={index === 4} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_less_than' /></FilterText>
			</RadioContainer>
			{
				index === 4 &&
				<Input name="filter_value" type="number" onChange={(value) => setValue(value)} value={value}/>
			}
			<RadioContainer onClick={() => setindex(5)}>
				<Radio isActive={index === 5} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_less_or_equal_to' /></FilterText>
			</RadioContainer>
			{
				index === 5 &&
				<Input name="filter_value" type="number" onChange={(value) => setValue(value)} value={value}/>
			}
			<RadioContainer onClick={() => setindex(6)}>
				<Radio isActive={index === 6} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='between' /></FilterText>
			</RadioContainer>
			{
				index === 6 &&
				<InputContainer>
					<Input inputStyle={{ width: '125px' }} name="filter_value" type="number" onChange={(value) => setValue(value)} value={value}/>
					<FilterText><Translate id='and' /></FilterText>
					<Input inputStyle={{ width: '125px' }} name="filter_value" type="number" onChange={(value) => setValue2(value)} value={value2}/>
				</InputContainer>

			}
			<RadioContainer onClick={() => setindex(7)}>
				<Radio isActive={index === 7} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_empty' /></FilterText>
			</RadioContainer>
			<RadioContainer onClick={() => setindex(8)}>
				<Radio isActive={index === 8} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not_empty' /></FilterText>
			</RadioContainer>

			<DefaultButton width="125px" disabled={
				!((value !== undefined && index !== 6) || index === 7 || index === 8 || (index === 6 && value !== undefined && value2 !== undefined))
			} onClick={() => submit()}><Translate id='add_filter' /></DefaultButton>
		</Container>
	);
}

export default NumberFilter;
