import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners from '../../../components_v2/dropdown/DropdownOwners';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import DropdownTagsCloud from '../../../components_v2/dropdown/DropdownTagsCloud';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { InputContainer, OptionBlock } from '../../../components_v2/dropdown/style/Style';
import Input from '../../../components_v2/input/Input';
import InputAddress from '../../../components_v2/input/InputAddress';
import InputImage from '../../../components_v2/input/InputImage';
import { ImageResult, InputAddressResult } from '../../../components_v2/input/model/Model';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import { getTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import storeLang from '../../../helpers/storeLang';
import { ITag } from '../../../../typings/proto/protobufs';
import { Dot } from '../../../styles/global/css/Dot';
import { DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import {
	emailValid,
	normalizeCapital,
	normalizeInt,
	validatePhoneNumber,
	validatePositiveNumberAndEmpty,
	validateWebsite
} from '../../globals/FieldFunction';
import { getClientStatuses } from '../../import/actions';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany, Owner } from '../../orders/model/Model';
import { FlexDiv } from '../../products/style';
import { CreateCompany, createFrequencies } from '../data/action';
import { ClientCompanyCreate, Company, CompanyStatus } from '../model/Model';
import { Label, Required, Spacer, TitleSection } from '../style/NewCompanyStyle';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../style/Style';
import { AEventTypes, AtomEventType } from '../../../atoms/global/events';
import { Open } from '../../../styles/global/css/Open';
import Collapse from '../../../components_v2/Collapse/Collapse';

function NewCompany(props: {
  onClickOut?: () => void
  onCreate?: (value: Company) => void
  company?: Company
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);

	const [selectedFile, setSelectedFile] = React.useState<ImageResult>();
	const [companyName, setCompanyName] = React.useState<string>(props.company?.name ?? '');

	const [selectedOwner, setSelectedOwner] = React.useState((props.company != null) ? {
		id: props.company.owner_id,
		name: props.company.owner_name
	} as Owner : undefined);
	const owners = useRecoilValue(AUsers);

	const [statuses, setStatuses] = React.useState<CompanyStatus[]>([]);
	const [selectedStatus, setSelectedStatus] = React.useState((props.company != null) ? {
		id: props.company.status_id,
		color_code: props.company.company_status_color,
		name: props.company.company_status_name
	} as CompanyStatus : undefined);

	const [tags, setTags] = React.useState<ITag[]>([]);
	const [selectedTags, setSelectedTags] = React.useState<ITag[]>(props.company?.tags ?? []);

	const [optionParentCompanies, setOptionParentCompanies] = React.useState<DropdownData[]>([]);
	const [selectedParentCompany, setSelectedParentCompany] = React.useState<ClientCompany>();

	const [phone, setPhone] = React.useState<string | undefined>(props.company?.phone);
	const [website, setWebsite] = React.useState<string | undefined>(props.company?.website);
	const [email, setEmail] = React.useState<string | undefined>(props.company?.email);
	const eventTypes = useRecoilValue(AEventTypes);
	const [selectedEventFrequency, setSelectedEventFrequency] = React.useState<[number | undefined, AtomEventType][]>([]);

	const [deliveryAddress, setDeliveryAddress] = React.useState<InputAddressResult>();
	const [billingAddress, setBillingAddress] = React.useState((props.company != null) ? {
		city: props.company.company_city ?? '',
		country: props.company.company_country ?? '',
		postcode: props.company.company_post_code ?? '',
		route: props.company.company_address ?? '',
		street_number: '',
		latitude: props.company.latitude,
		longitude: props.company.longitude
	} as InputAddressResult : undefined);
	const [isEventTypeOpen, setIsEventTypeOpen] = React.useState<boolean>(true);

	const [isBillingDelivery, setBillingDelivery] = React.useState<boolean>(false);

	React.useEffect(() => {
		let count = 2
	;(props.company == null) && setSelectedOwner(owners.find(o => o.isYou));

		getClientStatuses().then(response => {
			setStatuses(response.data)
			;(props.company == null) && setSelectedStatus(response.data.length > 0 ? response.data[0] : undefined);
			count = count - 1;
			if (count <= 0) {
				setLoading(LoadingStateEnum.LOADED);
			}
		});

		getTags(TagType.COMPANY).then(tags => {
			setTags(tags);
			count = count - 1;
			if (count <= 0) {
				setLoading(LoadingStateEnum.LOADED);
			}
		});
	}, []);

	function submit(): void {
		if (companyName.trim().length <= 0 || selectedOwner === undefined || selectedStatus === undefined) {
			return;
		}

		let billAddress = billingAddress?.street_number ?? undefined;
		billAddress = (billingAddress != null) && billingAddress.route ? (billAddress ? `${billAddress} ${billingAddress.route}` : billingAddress.route) : billAddress;

		let delyAddress = deliveryAddress?.street_number ?? undefined;
		delyAddress = (deliveryAddress != null) && deliveryAddress.route ? (delyAddress ? `${delyAddress} ${deliveryAddress.route}` : deliveryAddress.route) : delyAddress;

		const cName = companyName.trim();
		const cPhone = phone?.replaceAll(' ', '');
		const cEmail = email?.trim();
		const cWebsite = website?.trim();

		const params = {
			company_name: cName,
			owner_id: selectedOwner.id,
			client_status_id: selectedStatus.id,
			website: cWebsite && cWebsite.length > 0 ? cWebsite : undefined,
			phone: cPhone && cPhone.length > 0 ? cPhone : undefined,
			email: cEmail && cEmail.length > 0 ? cEmail : undefined,
			billing_address: isBillingDelivery ? delyAddress : billAddress,
			billing_post_code: isBillingDelivery ? deliveryAddress?.postcode : billingAddress?.postcode,
			billing_city: isBillingDelivery ? deliveryAddress?.city : billingAddress?.city,
			billing_country: isBillingDelivery ? deliveryAddress?.country : billingAddress?.country,
			delivery_address: undefined, // delyAddress,
			delivery_post_code: undefined, // deliveryAddress?.postcode,
			delivery_city: undefined, // deliveryAddress?.city,
			delivery_country: undefined, // deliveryAddress?.country,
			latitude: isBillingDelivery ? deliveryAddress?.latitude : billingAddress?.latitude,
			longitude: isBillingDelivery ? deliveryAddress?.longitude : billingAddress?.longitude,
			isBillingDelivery,
			tags: selectedTags.map(t => t.id),
			company_parent_id: selectedParentCompany?.id,
			logo: selectedFile && {
				name: selectedFile.file.name,
				file: selectedFile.content
			}
		} as ClientCompanyCreate;
		setLoading(LoadingStateEnum.LOADING);

		CreateCompany(params).then(async response => {
			if (response !== -1) {
				const newCompany: Company = {
					company_id: response,
					name: cName,
					company_city: billingAddress?.city ?? '',
					company_address: billAddress,
					company_country: billingAddress?.country,
					company_post_code: billingAddress?.postcode,
					latitude: billingAddress?.latitude,
					longitude: billingAddress?.longitude,
					email: cEmail,
					website: cWebsite,
					phone: cPhone,
					company_status_color: selectedStatus.color_code,
					status_id: selectedStatus.id,
					company_status_name: selectedStatus.name,
					owner_id: selectedOwner.id,
					owner_name: selectedOwner.name,
					owner_photo: selectedOwner.photoUrl,
					parent_id: selectedParentCompany?.id,
					parent_name: selectedParentCompany?.name,
					checked: false,
					tags: selectedTags.map(t => {
						return {
							id: t.id,
							name: t.name,
							color: t.color
						} as ITag;
					})
				} as Company;
				await createFrequencies(response, selectedEventFrequency.filter(sef => sef && sef[0]).map(([frequency, at]) => ({
					event_type: at.id,
					frequency: frequency ?? 0
				})));
				(props.onCreate != null) && props.onCreate(newCompany);
				(props.onClickOut != null) && props.onClickOut();
			}
			setLoading(LoadingStateEnum.LOADED);
		});
	}

	function body(): JSX.Element {
		return (
			<CreationPopupContainer>
				<TitleSection>{translate('company.creation.company.company_detail').toString()}</TitleSection>

				<Spacer />

				<InputImage onChange={(value) => {
					setSelectedFile(value);
				}} />

				<Label><Required /> {translate('company.creation.company.owner').toString()}</Label>
				<DropdownOwners
					open
					border
					dropdownStyle={creationPopupDropdownStyle}
					users={owners.map(o => ({ label: o.name, value: o, image: o.photoUrl }))}
					selected={(selectedOwner != null) ? { label: selectedOwner?.name, value: selectedOwner, image: selectedOwner.photoUrl } : undefined}
					required
					onChange={(value: DropdownData) => setSelectedOwner(value.value)}
				/>

				<Label><Required /> {translate('company.creation.company.company_name').toString()}</Label>
				<Input
					inputStyle={creationPopupInputStyle}
					name="company_name"
					type="text"
					placeholder={translate('company.creation.company.company_name').toString()}
					required
					normalize={[normalizeCapital]}
					value={companyName}
					onChange={(value) => setCompanyName(value)}
				/>

				<Label><Required /> {translate('company.creation.company.status').toString()}</Label>
				<Dropdown
					dropdownStyle={creationPopupDropdownStyle}
					datalist={statuses.map((s, i) => ({ label: s.name, value: s, color: s.color_code, selected: ((props.company != null) && props.company.status_id === s.id) ?? i === 0 }))}
					name='status_dropdown'
					required
					readOnly
					onChange={(value: DropdownData) => setSelectedStatus(value.value)}
					JSXButton={({ width, height }) => <InputContainer width={width} height={height} padding='0 10px'>
						<FlexDiv gap='10px' justify='center'>
							<Dot size='10px' color={selectedStatus?.color_code} />
							<DefaultTextDiv>{selectedStatus?.name}</DefaultTextDiv>
						</FlexDiv>
					</InputContainer>}
					JSXContent={(value: DropdownData) => <OptionBlock>
						<FlexDiv gap='10px' justify='center'>
							<Dot size='10px' color={value.color} />
							<DefaultTextDiv>{value.label}</DefaultTextDiv>
						</FlexDiv>
					</OptionBlock>}
				/>

				<Label>{translate('company.creation.company.parent_company').toString()}</Label>
				<DropdownSearch
					dropdownStyle={creationPopupDropdownStyle}
					datalist={optionParentCompanies}
					name="clientCompanyName"
					link={'/companies?id='}
					onChange={(value) => {
						setSelectedParentCompany(value?.value);
					}}
					selectedValue={(optionParentCompanies.find(comp => JSON.stringify(comp.value) == JSON.stringify(selectedParentCompany))) || undefined}
					onSearchChange={async(search, offset) => {
						return await getFilteredCompanies(search, 20, offset)
							.then(res => {
								const ret: boolean = res.data.length == 0 || res.data.length < 20;
								if (offset !== undefined) {
									setOptionParentCompanies([...optionParentCompanies, ...res.data.map((company: ClientCompany) => {
										return {
											label: company.name,
											value: company
										};
									})]);
								} else {
									setOptionParentCompanies(res.data.map(company => {
										return {
											label: company.name,
											value: company
										};
									}));
								}
								return ret;
							})
							.catch(error => {
								console.log(error);
								return false;
							});
					}}
				/>

				<Label>{translate('company.creation.company.phone').toString()}</Label>
				<Input
					inputStyle={creationPopupInputStyle}
					name="phone"
					type="tel"
					placeholder={translate('company.creation.company.phone').toString()}
					value={phone}
					onChange={(value) => setPhone(value)}
				/>

				<Label>{translate('company.creation.company.website').toString()}</Label>
				<Input
					inputStyle={creationPopupInputStyle}
					name="website"
					type="url"
					placeholder={translate('company.creation.company.website').toString()}
					value={website}
					onChange={(value) => setWebsite(value)}
				/>

				<Label>{translate('company.creation.company.email').toString()}</Label>
				<Input
					inputStyle={creationPopupInputStyle}
					name="email"
					type="email"
					placeholder={translate('company.creation.company.email').toString()}
					value={email}
					onChange={(value) => setEmail(value)}
				/>

				<Label>Tags</Label>
				<DropdownTagsCloud
					allwaysOpen
					addImage
					emptyWhenNone
					tags={tags}
					selected={selectedTags}
					autoOptionUp
					onChange={(values) => setSelectedTags(values) }
					onDelete={(id: number) => setSelectedTags(selectedTags.filter(t => t.id !== id)) }
				/>
				{/* <DropdownTags
					dropdownStyle={creationPopupDropdownStyle}
					tags={tags}
					selected={selectedTags}
					onChange={(values: any[]) => setSelectedTags(values.map(v => v.value)) }
					onDelete={(id: number) => setSelectedTags(selectedTags.filter(t => t.id !== id)) }
				/> */}

				{
					//     <DropdownTag
					//     dropdownStyle={{
					//         labelUp: true,
					//         optionWidth: '250px',
					//         optionHeight: '200px',
					//         optionFontWeight: '500',
					//         optionFontColor: '#FFFFFF',
					//         optionPadding: '5px 10px 5px 10px',
					//         optionTextWidth: 'fit-content',
					//         height: '38px'
					//     }}
					//     tags={tags}
					//     onChangeTags={(values) => setSelectedTags(values)}
					// />
				}

				<Spacer />

				<TitleSection>{translate('company.creation.company.address_details').toString()}</TitleSection>

				{
					// <InputAddress
					//     name='delivery_address_address'
					//     type='text'
					//     onChange={(value: InputAddressResult) => setDeliveryAddress(value)}
					//     label={translate('company.creation.company.address').toString()}
					//     placeholder={translate('company.creation.company.address').toString()}
					//     optionWidth='250px'
					//     optionTop='55px'
					//     inputStyle={creationPopupInputStyle}
					// />

					// <Spacer />

					// <Input
					//     inputStyle={creationPopupInputStyle}
					//     name="delivery_address_post_code"
					//     type="text"
					//     placeholder={translate('company.creation.company.post_code').toString()}
					//     label={translate('company.creation.company.post_code').toString()}
					//     value={deliveryAddress?.postcode}
					//     onChange={(value) => setDeliveryAddress(deliveryAddress ? { ...deliveryAddress, postcode: value } : { postcode: value, city: '', country: '', latitude: 0, longitude: 0, route: '', street_number: '' })}
					// />

					// <Spacer />

					// <Input
					//     inputStyle={creationPopupInputStyle}
					//     name="delivery_address_city"
					//     type="text"
					//     placeholder={translate('company.creation.company.city').toString()}
					//     label={translate('company.creation.company.city').toString()}
					//     disabled
					//     value={deliveryAddress?.city}
					// />

					// <Spacer />

					// <Input
					//     inputStyle={creationPopupInputStyle}
					//     name="delivery_address_country"
					//     type="text"
					//     placeholder={translate('company.creation.company.country').toString()}
					//     label={translate('company.creation.company.country').toString()}
					//     disabled
					//     value={deliveryAddress?.country}
					// />
					// <Spacer />
				}

				{
					// <FlexDiv onClick={() => setBillingDelivery(!isBillingDelivery)} clickable>
					//     <Checkbox isActive={isBillingDelivery} />
					//     <CheckboxText>{translate('company.creation.company.billing_delivery_same').toString()}</CheckboxText>
					// </FlexDiv>

					// <Spacer />
				}

				{
					!isBillingDelivery &&
					<>
						{/* <SubTitleSection>{translate('company.creation.company.billing_address').toString()}</SubTitleSection> */}

						<Label>{translate('company.creation.company.address').toString()}</Label>
						<InputAddress
							name='billing_address_address'
							type='text'
							onChange={(value: InputAddressResult) => setBillingAddress(value)}
							placeholder={translate('company.creation.company.address').toString()}
							optionWidth='250px'
							optionTop='55px'
							inputStyle={creationPopupInputStyle}
							value={billingAddress ? `${billingAddress?.street_number} ${billingAddress?.route}`.trim() : undefined}
						/>

						<Label>{translate('company.creation.company.post_code').toString()}</Label>
						<Input
							inputStyle={creationPopupInputStyle}
							name="billing_address_post_code"
							type="text"
							placeholder={translate('company.creation.company.post_code').toString()}
							value={billingAddress?.postcode}
							onChange={(value) => setBillingAddress((billingAddress != null) ? { ...billingAddress, postcode: value } : { postcode: value, city: '', country: '', latitude: 0, longitude: 0, route: '', street_number: '' })}
						/>

						<Label>{translate('company.creation.company.city').toString()}</Label>
						<Input
							inputStyle={creationPopupInputStyle}
							name="billing_address_city"
							type="text"
							placeholder={translate('company.creation.company.city').toString()}
							value={billingAddress?.city}
							normalize={[normalizeCapital]}
							onChange={(value) => setBillingAddress(billingAddress ? { ...billingAddress, city: value } : {
								city: value,
								country: '',
								latitude: 0,
								longitude: 0,
								postcode: '',
								route: '',
								street_number: ''
							})}
						/>

						<Label>{translate('company.creation.company.country').toString()}</Label>
						<Input
							inputStyle={creationPopupInputStyle}
							name="billing_address_country"
							type="text"
							placeholder={translate('company.creation.company.country').toString()}
							value={billingAddress?.country}
							normalize={[normalizeCapital]}
							onChange={(value) => setBillingAddress(billingAddress ? { ...billingAddress, country: value } : {
								city: '',
								country: value,
								latitude: 0,
								longitude: 0,
								postcode: '',
								route: '',
								street_number: ''
							})}
						/>
					</>
				}

				<Spacer />

				<FlexDiv gap='10px' onClick={() => setIsEventTypeOpen(!isEventTypeOpen)} clickable>
					<Open isOpen={isEventTypeOpen} />
					<TitleSection>
						{translate('frequency_details').toString()}
					</TitleSection>
				</FlexDiv>
				<Collapse isOpen={isEventTypeOpen}>
					{eventTypes.map((et, i) => 
						<>
							<Label>{translate('number_of_{{EVENT}}_per_year').toString().replace('{{EVENT}}', translate(`event.${et.name}`).toString())}</Label>
							<Input
								inputStyle={creationPopupInputStyle}
								name={`eventTypeFrequency[${et.name}]`}
								type="number"
								placeholder={translate('indicate_a_number').toString()}
								value={selectedEventFrequency[i]?.[0]}
								onChange={value => {
									if (value === '') value = undefined;
									selectedEventFrequency[i] = [parseInt(value), eventTypes[i]];
									setSelectedEventFrequency([...selectedEventFrequency]);
								}}
								normalize={[normalizeInt]}
								validate={[validatePositiveNumberAndEmpty]}
							/>
						</>
					)}
				</Collapse>
			</CreationPopupContainer>
		);
	}

	return (
		<PopupCreation
			title={translate('company.creation.company.create_company').toString()}
			content={body()}
			canValidate={
				companyName.trim().length > 0 &&
				selectedOwner !== undefined &&
				selectedStatus !== undefined &&
				((phone === undefined || phone.trim().length <= 0) || (phone.trim().length > 0 && validatePhoneNumber(phone.trim()))) &&
				((website === undefined || website.trim().length <= 0) || (website.trim().length > 0 && validateWebsite(website.trim()))) &&
				((email === undefined || email.trim().length <= 0) || (email.trim().length > 0 && emailValid(email.trim())))
			}
			onSubmit={() => submit()}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
		/>
	);
}

export default NewCompany;
