import * as React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { SidelyBlack } from './Utils';
import CheverontBlue from 'images/icon/cheveront_blue.svg';
import CheverontBlack from 'images/icon/cheveront_black.svg';
import CheverontWhite from 'images/icon/cheveront_white.svg';
import { FetchKey } from '../../../containers_v2/map/MapView';

export const Open = styled.div<{isOpen?: boolean, size?: number, width?: number, widthStr?: string, height?: number, color?: 'blue' | 'white' | 'black' }>`
	flex-shrink: 0;
    position: relative;
    cursor: pointer;
    transform: rotate(${p => p.isOpen === true ? '180deg' : '0deg'});
    transition: 0.25s;
    background-image: url("${p => {
		switch (p.color) {
			case 'blue': return CheverontBlue;
			case 'white': return CheverontWhite;
			default: return CheverontBlack;
		}
	}}");
    background-size: 100%;
    background-origin: initial;
    background-repeat: no-repeat;
    background-position: center;
    width: ${p => p.widthStr ?? `${p.width ?? p.size ?? 15}px`}};
    height: ${p => p.size ?? p.size ?? 15}px;
`;

const ToggleContainer = styled.div`
  padding: 17px 0px 17px 0px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: ${SidelyBlack};
`;

export function ButtonToggle(props: { title: string, alreadyTranslated?: boolean, isOpen: boolean, onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }): JSX.Element {
	const { title, alreadyTranslated, isOpen } = props;
	return (
		<ToggleContainer
			onClick={props.onClick}
		>
			{alreadyTranslated ? title : <Translate id={title} />}
			<span style={{ float: 'right', marginRight: 20, position: 'relative', top: 4 }}>
				<Open
					isOpen={isOpen}
					size={15}
				/>
			</span>
		</ToggleContainer>
	);
}
