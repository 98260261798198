import * as React from 'react';
import styled from 'styled-components';
import { BlueSidely, BorderColor, SidelyBlack } from '../../styles/global/css/Utils';
import { isSuperAdmin } from '../utils';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Open } from '../../styles/global/css/Open';
import { To } from '../../Types';
import Restricted from '../../containers_v2/permissions/Restricted';
import { isOwner, useMe } from '../../atoms/global/users';
import BetaImage from 'images/icon/beta.png';

const ReportTiles = styled.div`
	height: 53px;
	padding: 20px 24px;
	font-weight: 600;
	cursor: pointer;
	position: sticky;
	top: 0;
	background: white;
	z-index: 1;
`;
  
const LocalOpen = styled.div`
	float: right;
	top: 50%;
	transform: translateY(-50%);
	position: relative;
`;

const StyledCategories = styled.div`
	display: flex;
	flex-flow: column;

	.nav-tabs {
		border: none;
	}

	.nav-link {
		color: ${SidelyBlack};
		background-color: transparent;
		box-shadow: none;
		border-top: 1px solid ${BorderColor};
		border-bottom: 1px solid ${BorderColor};
		padding: 1.25rem 1.5rem;
		position: relative;
		border-left: none;
	}
	.active-block {
		position: absolute;
		top: 0;
		left: 0;
		width: 10px;
		height: 100%;
		background-color: ${BlueSidely};
	}
`;

const NavLinkNoHover = styled(NavLink)`
	border: none !important;
	border-top: 1px solid ${BorderColor} !important;
	border-bottom: 1px solid ${BorderColor} !important;
`;

export type CategoryChild<T = unknown> = {
	name?: React.ReactNode,
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	admin?: boolean,
	owner?: boolean
	active?: boolean,
	beta?: boolean,
	value?: T,
	restricted?: To | To[]
};

export type Category<T = unknown> = {
	name: React.ReactNode,
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	children: CategoryChild<T>[]
	defaultOpen?: boolean
	hidden?: boolean,
	value?: T,
	restricted?: To | To[],
	owner?: boolean,
	admin?: boolean
}

function concatRestriction(category: Category): To[] {
	const res: To[] = [];

	const pushToRes = (restricted?: To | To[]) => {
		if (Array.isArray(restricted)) {
			res.concat(restricted);
		} else if (restricted) {
			res.push(restricted);
		}
	};
	pushToRes(category.restricted);
	category.children.forEach(child => pushToRes(child.restricted));

	return res;
}

export default function CategorySelector(props: {
	categories: Category[],
	title?: React.ReactNode
}): JSX.Element {
	const me = useMe();
	const isAdmin = isSuperAdmin();
	const categories = props.categories?.filter(c => !((c.admin && !isAdmin) || (c.owner && !isOwner(me) && !isAdmin))) ?? [];
	const [isOpen, setIsOpen] = React.useState<number>(categories.findIndex(c => c.children.some(e => e.active)));

	React.useEffect(() => {
		setIsOpen((props.categories?.filter(c => !((c.admin && !isAdmin) || (c.owner && !isOwner(me) && !isAdmin))) ?? []).findIndex(c => c.children.some(e => e.active)));
	}, [props.categories]);

	return <StyledCategories>
		{props.title && <h5 className="p-3" style={{ color: SidelyBlack, position: 'sticky', backgroundColor: 'inherit', width: '100%', marginBottom: '-10px' }}>
			{props.title}
		</h5>}
		<Nav tabs vertical className="nav-pin" style={{ flexFlow: 'column', height: '100%', overflow: 'auto' }}>
			{categories
				.map((category, i) => <Restricted to={concatRestriction(category)} key={`category[${i}]`}>
					<ReportTiles
						hidden={category.hidden}
						onClick={e => {
							category.onClick?.(e);
							setIsOpen(isOpen == i ? -1 : i);
						} }
					>
						{category.name}
						<LocalOpen>
							<Open isOpen={isOpen === i} />
						</LocalOpen>
					</ReportTiles>
					<Collapse isOpen={isOpen === i} style={{ marginBottom: '1px' }}>
						{category.children
							.filter(c => !((c.admin && !isAdmin) || (c.owner && !isOwner(me) && !isAdmin)))
							.map((child, ci) => <Restricted to={child.restricted ?? []} key={`categoryChild[${i}][${ci}]`}>
								{child.name && <NavItem>
									<NavLinkNoHover onClick={child.onClick}>
										{child.active && <div className="active-block" />}
										{child.beta && <img src={BetaImage} style={{ width: '30px', marginTop: '-15px', marginLeft: '195px', position: 'absolute' }} />}
										<div style={{ color: child.active ? BlueSidely : undefined }}>
											{child.name}
										</div>
									</NavLinkNoHover>
								</NavItem>}
							</Restricted>)
						}
					</Collapse>
				</Restricted>)
			}
		</Nav>
	</StyledCategories>;
}