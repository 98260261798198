import * as React from 'react';
import { ToolTipHover } from './ReportInterpretor';
import { useRefState } from '../../../utils/customHooks';

export type EvolutionState = {
	[key: number]: string
};

interface HoverContextType {
  hover: ToolTipHover | undefined
  setHover: (a?: ToolTipHover) => void
  evolutionState: EvolutionState,
  setEvolutionState: React.Dispatch<React.SetStateAction<EvolutionState>>
  evolutionStateRef: React.MutableRefObject<EvolutionState>,
  onCompanyClick: (n: number) => void,
  onFormInstanceClick: (uuid: string) => void
}

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour: HoverContextType = {
	hover: undefined,
	setHover: () => undefined,
	evolutionState: {},
	setEvolutionState: () => undefined,
	evolutionStateRef: { current: {} },
	onCompanyClick: () => undefined,
	onFormInstanceClick: () => undefined
};

// Create the context
const ReportInterpretorContext = React.createContext<HoverContextType>(defaultBehaviour);

export default ReportInterpretorContext;

export type ReportInterpretorProviderLinksProps = { onCompanyClick: (n: number) => void, onFormInstanceClick: (uuid: string) => void };

export function ReportInterpretorProvider(props: { children } & ReportInterpretorProviderLinksProps) {
	const [hover, setHover] = React.useState<ToolTipHover>();
	const [evolutionStateRef, setEvolutionState, evolutionState] = useRefState<EvolutionState>({});

	const value = {
		hover,
		setHover,
		evolutionState,
		setEvolutionState,
		evolutionStateRef,
		onCompanyClick: props.onCompanyClick,
		onFormInstanceClick: props.onFormInstanceClick
	};

	return (
		<ReportInterpretorContext.Provider value={value}>
			{props.children}
		</ReportInterpretorContext.Provider>
	);
}