import * as React from 'react';
import { reportingAction, reportingState } from '../Reducer';

export default function MerchandisingPerCompanyFilters(props: {
  state: reportingState
  dispatch: (filters: reportingAction) => void
}) {
	return (
		<div style={{ margin: '10px 0px 0px 20px' }}>
			<h4>
                No filters yet for this repport
			</h4>
		</div>
	);
}
