import { FieldType } from 'bindings/forms/FieldType';
import { Granularity } from 'bindings/time/Granularity';
import new_tab from 'images/icon/new_tab.svg';
import optionGrey from 'images/icon/options_grey.png';
import event_column_button from 'images/icons/company/event_column_button.svg';
import eye from 'images/icons/company/eye.svg';
import phone_button from 'images/icons/company/phone_button.svg';
import * as moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { match_email } from 'validators-web';
import { MappingResume } from '../../../../../web-types/mapping';
import { IAdditionalColumnsSubValue, ITag } from '../../../../typings/proto/protobufs';
import { AdditionalColumn, deleteAdditionalColumnValue, updateAdditionalColumnValue } from '../../../atoms/additionalColumns';
import { AdditionalFieldColumn, isFieldTypeSortable } from '../../../atoms/additionalFieldColumns';
import { AssortmentShort } from '../../../atoms/assortment';
import { ShelfAuditTemplate } from '../../../atoms/filter/shelfAuditFilterAtom';
import { AtomEventType } from '../../../atoms/global/events';
import {
	getColorByFrequencyScore,
	getDailyFrequency,
	getDaysDelay,
	getEmojiByFrequencyScore,
	getRemainingDays
} from '../../../atoms/global/frequency';
import { AGranularity } from '../../../atoms/global/granularity';
import { AtomSelectedExternalMappingSelector } from '../../../atoms/global/mappings';
import { getUserColor } from '../../../components_v2/avatar/Data/Data';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners, { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import DropdownTagsCloud from '../../../components_v2/dropdown/DropdownTagsCloud';
import LightDropdown from '../../../components_v2/dropdown/lightDropdown/LightDropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import {
	FilterId,
	isCheckoutEvolutionFilter,
	isExternalIdFilter,
	isFrequencyFilter,
	isLastEventDateFilter,
	isLastFormDateFilter,
	isNextEventDateFilter
} from '../../../components_v2/filter/model/Model';
import { Checkbox } from '../../../components_v2/filterList/style/Style';
import Input from '../../../components_v2/input/Input';
import { InputStyle } from '../../../components_v2/input/model/Model';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import Switch from '../../../components_v2/Switch/Switch';
import { Column, DataType } from '../../../components_v2/table/Table';
import { updateTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import { DayPickerModal } from '../../../components_v2/toolbarFilter/time/DatePicker';
import storeLang from '../../../helpers/storeLang';
import { Dot } from '../../../styles/global/css/Dot';
import { DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import { DarkGreySidely2, GreenSidely, RedSidely } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { To } from '../../../Types';
import { AlertPropsDelete } from '../../alert/Alert';
import { AlertRes } from '../../alert/AlertProvider';
import { HoverEmailWrapper } from '../../email-templates/emailTemplatePopup';
import { FormTemplate } from '../../forms/jsonValidator';
import { validatePhoneNumber, validatePositiveNumberAndEmpty, validateWebsite } from '../../globals/FieldFunction';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany as CC, Owner } from '../../orders/model/Model';
import { RowComment, TableRow, TableRowDnContainer, TableRowStatus, TableRowStatusBackground, TableRowTitle } from '../../orders/templateOrders/style/Style';
import { IsAllowedToProps } from '../../permissions/PermissionProvider';
import Restricted from '../../permissions/Restricted';
import { ModalState } from '../../products/model';
import { FlexDiv } from '../../products/style';
import { Evolution, ValueInnerToNode } from '../../reports/interpretor/ReportInterpretor';
import { formatCurrency, getStringWidth } from '../../reports/utils';
import { additionalColumnIdFormatter, additionalFieldColumnIdFormatter, calculatedFieldIdFormatter, COMPANY_COLUMN_CATEGORIES_MAP } from '../Companies';
import { Company, CompanyStatus } from '../model/Model';
import { updateParentCompany } from '../popup/action';
import { ColumnOrganizer } from '../popup/ColumnOrganizer';
import { ButtonAction } from '../style/PopupStyle';
import { PartialOpen, Tag, TagContainer } from '../style/Style';
import { createFrequency, deleteCompany, deleteExternalId, deleteFrequency, setExternalId, updateCompany } from './action';
import { CalculatedField } from '../../../atoms/calculatedFields';
import { isSuperAdmin } from '../../../components_v2/utils';

export function DateAccessor(props: {
	date?: Date | string | null | moment.Moment,
	utc?: boolean,
	onDateChange?: (date: moment.Moment) => void
}) {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const date = props.date ? props.utc ? moment.utc(props.date) : moment(props.date) : undefined;
	return <>
		<TableRow cursor={props.onDateChange ? 'pointer' : 'inherit'} onClick={() => setIsOpen(true)}>{date?.format('L - LT')}</TableRow>
		{props.onDateChange && isOpen && <DayPickerModal
			noAbsolute
			future
			onClickOut={() => setIsOpen(false)}
			// noDayButton
			time={true}
			date={date}
			// noDate
			onDateChange={date => {
				setIsOpen(false);
				props.onDateChange?.(date);
			}}
		/>}
	</>;
}

const DropdownColumnContainer = styled(FlexDiv)`
	gap: 3px;
	line-height: 20px;
`;
const ScoreEmojiContainer = styled(FlexDiv) <{ backgroundColor: string }>`
	background: radial-gradient(50% 50% at 50% 50%, ${p => p.backgroundColor} 50%, transparent 100%);
	font-size: 19px;
	border-radius: 50%;
	height: 38px;
	width: 38px;
	justify-content: center;
	line-height: 35px;
	padding-right: 1px;
	padding-top: 1px;
	flex-shrink: 0;
`;
const TemplateContainer = styled.p<{ color: string }>`
	padding: 0.25em 0.75em;
	color: ${({ color }) => color};
	border-color: ${({ color }) => color};
	border: 1px solid;
	border-radius: 5px;
	margin: 0;
	cursor: pointer;
`;

export function deleteCompanyIdPopup(alert: (p: AlertPropsDelete & { name: string }) => Promise<AlertRes>, id: number, name: string, onDelete?: () => void) {
	alert({
		zIndex: 201,
		name
	})
		.then(result => {
			if (result === AlertRes.Ok) {
				deleteCompany(id).then(response => {
					if (response.valueOf()) {
						onDelete?.();
					}
				});
			}
		});
}

export function CompanySearchCell(props: { defaultValue: { name?: string, id?: number }, onUpdateCompany: (c: { id: number, name: string }) => Promise<void> }) {
	const { defaultValue } = props;
	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData<CC>[]>([]);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();
	const [inputValue, setInputValue] = React.useState<string | undefined>(defaultValue.name);

	useEffect(() => {
		setInputValue(defaultValue.name);
	}, [defaultValue]);

	return <DropdownSearch
		dropdownStyle={{ optionWidth: '250px', optionHeight: '200px', containerWidth: '250px' }}
		JSXButton={(handleChange) => <>
			{loadingState == LoadingStateEnum.LOADING && <FlexDiv height="100%"><PageLoader size={20} /></FlexDiv>}
			{loadingState !== LoadingStateEnum.LOADING &&
				<Input
					placeholder="-"
					inputStyle={{
						backgroundColor: 'transparent',
						border: 'none',
						width: '100%',
						height: '100%',
						placeholderColor: DarkGreySidely2,
						removePlaceHolderOnFocus: true
					}}
					name={'input-column[parent_name]'}
					type="text"
					value={inputValue}
					onChange={handleChange}
				/>
			}
		</>
		}
		onSearchChange={async(search, offset) => {
			try {
				const res = await getFilteredCompanies(search, 20, offset);
				const ret: boolean = res.data.length == 0 || res.data.length < 20;
				if (offset !== undefined) {
					setOptionCompanies([...optionCompanies, ...res.data.map((company: CC) => {
						return {
							label: company.name,
							value: company
						};
					})]);
				} else {
					setOptionCompanies(res.data.map(company_1 => {
						return {
							label: company_1.name,
							value: company_1
						};
					}));
				}
				return ret;
			} catch (error) {
				console.error(error);
				return false;
			}
		}}
		datalist={optionCompanies}
		selectedValue={{ label: defaultValue.name ?? '-', value: defaultValue }}
		name={''}
		onChange={(value: DropdownData<CC>) => {
			if (value.value.id === defaultValue.id) {
				setInputValue(value.value.name);
				return;
			}
			setLoadingState(LoadingStateEnum.LOADING);
			props.onUpdateCompany({ id: value.value.id, name: value.value.name })
				.then(() => {
					setLoadingState(LoadingStateEnum.LOADED);
					setInputValue(value?.value.name);
				})
				.catch(() => {
					setLoadingState(LoadingStateEnum.ERROR);
					setInputValue(defaultValue.name);
				});
		}}
	/>;
}

export function EditableContent<T>(props: {
	error?: string
	row: DataType<T> | undefined
	// When error is setted
	onUpdate?: (t: DataType<T>) => void
	valueKey: string,
	defaultValue?: string
	regex?: (s: string) => boolean
	// the value approved by regex, to push
	onValueUpdate?: (s: string | null) => void,
	onInputChange?: (s: string | null) => void,
	timeout?: number,
	style?: InputStyle,
	innerId: unknown
}) {
	const { row, error, onUpdate, valueKey, regex, onValueUpdate, defaultValue } = props;
	const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();
	const [value, setValue] = React.useState<string>(defaultValue ?? row?.[valueKey]);
	React.useEffect(() => setValue(defaultValue ?? row?.[valueKey]), [props.innerId]);

	return <Input
		placeholder="-"
		inputStyle={{
			removePlaceHolderOnFocus: true,
			backgroundColor: 'transparent',
			border: 'none',
			width: '100%',
			height: '100%',
			placeholderColor: DarkGreySidely2,
			...props.style
		}}
		name={`input-column[${valueKey}]`}
		type="text"
		value={value}
		onChange={(newValue: string) => {
			if (row) {
				props.onInputChange?.(newValue);
				setValue(newValue);
				if (regex) {
					localTimeout && clearTimeout(localTimeout);
					setLocalTimeout(setTimeout((newValue) => {
						const regexValue = regex(newValue);
						if (!regexValue && row.error !== (error ?? valueKey)) {
							onUpdate?.({ ...row, error: error ?? valueKey });
						} else if (regexValue && row.error === (error ?? valueKey)) {
							onUpdate?.({ ...row, error: undefined });
						}
					}, props.timeout ?? 500, newValue));
				}
			}
		}}
		onBlur={_ => {
			localTimeout && clearTimeout(localTimeout);
			let newValue: string | null = value;
			if ((regex?.(newValue)) || !regex) {
				newValue = newValue.length > 0 ? newValue : null;
				onValueUpdate?.(newValue);
			} else if (row) {
				onUpdate?.({ ...row, error: undefined });
				setValue(defaultValue ?? row[valueKey]);
			}
		}}
	/>;
}

export function ColumnPicker<T>(props: {
	datalist: DropdownData<T>[],
	selectedValue?: DropdownData<T>,
	onChange?: (value: DropdownData<T>) => void,
	columnId: string,
	height?: string,
	id?: string
}) {
	const jsxButton: (props: { value?: DropdownData<T> }) => JSX.Element = React.useCallback(({ value }) =>
		<div style={{ textDecoration: 'underline' }}>
			{value?.label ?? 'no-value'}
		</div>, []
	);

	return <Dropdown<T>
		id={props.id}
		stopPropagation
		onChange={props.onChange}
		datalist={props.datalist}
		selectedValue={props.selectedValue}
		JSXButton={jsxButton}
		dropdownStyle={{
			width: 'fit-content',
			optionWidth: 'fit-content',
			height: props.height ?? '20px',
			optionHeight: '200px'
		}}
		name={`ColumnPicker[${props.columnId}]`}
	/>;
}

export function filterIdToTranslatedName(filterId: FilterId): string {
	let before = '';
	if (filterId === 'dn' || filterId === 'last_shelf_audit_date') {
		before = '(v1) ';
	}
	return before + translateToString(`company.columns.${filterIdToName(filterId)}`);
}

export function filterIdToName(filterId: FilterId): string {
	if (isFrequencyFilter(filterId)) {
		if (filterId.frequencies.element === 'frequency')
			return 'frequency_event';
		return 'frequency_event_target';
	}
	if (isLastEventDateFilter(filterId)) {
		return 'last_event_date';
	}
	if (isNextEventDateFilter(filterId)) {
		return 'next_event_date';
	}
	if (isExternalIdFilter(filterId)) {
		return 'external_id';
	}
	if (isCheckoutEvolutionFilter(filterId)) {
		switch (filterId.checkout.element) {
			case 'evolution': return 'checkout_evolution';
			case 'current_sum': return 'checkout_current_sum';
			case 'previous_sum': return 'checkout_previous_sum';
			case 'uvc_evolution': return 'checkout_uvc_evolution';
			case 'current_uvc_sum': return 'checkout_current_uvc_sum';
			case 'previous_uvc_sum': return 'checkout_previous_uvc_sum';
		}

	}
	if (isLastFormDateFilter(filterId)) {
		return 'last_form_date';
	}
	switch (filterId) {
		case 'last_shelf_audit_date':
			return 'last_shelf_audit_date';
		case 'dn':
			return 'dn';
		case 'company_id':
			return 'company_id';
		case 'updated_by':
			return 'updated_by';
		case 'updated_at':
			return 'updated_at';
		case 'created_by':
			return 'created_by';
		case 'created_at':
			return 'created_at';
		case 'longitude':
			return 'longitude';
		case 'latitude':
			return 'latitude';
		case 'tag_id':
			return 'tag_id';
		case 'status_id':
			return 'status_id';
		case 'owner_id':
			return 'owner_id';
		case 'website':
			return 'website';
		case 'email':
			return 'email';
		case 'phone':
			return 'phone';
		case 'company_country':
			return 'company_country';
		case 'parent_name':
			return 'parent_name';
		case 'parent_id':
			return 'parent_id';
		case 'name':
			return 'name';
		case 'company_post_code':
			return 'company_post_code';
		case 'company_city':
			return 'company_city';
		case 'company_address':
			return 'company_address';
		case 'shelf_audit_templates': return 'shelf_audit_templates';
	}
	return '';
}

export function TagCell<T extends { tags: ITag[], owner_id: number }>(props: { row: T, onChange: (values: ITag[]) => Promise<void>, onDelete: (id: number) => Promise<void>, tags: ITag[] }) {
	const { row, tags } = props;
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const onChange = (values: ITag[]) => {
		setLoadingState(LoadingStateEnum.LOADING);
		props.onChange(values).then(() => setLoadingState(LoadingStateEnum.LOADED)).catch(() => setLoadingState(LoadingStateEnum.LOADED));
	};
	const onDelete = (id: number) => {
		setLoadingState(LoadingStateEnum.LOADING);
		props.onDelete(id).then(() => setLoadingState(LoadingStateEnum.LOADED)).catch(() => setLoadingState(LoadingStateEnum.LOADED));
	};
	const selected = row.tags.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0).sort((a, b) => a.color?.localeCompare(b.color ?? '') ?? 0);
	return <Restricted
		to={{ objectAction: 'UpdateCompany' }}
		fallback={<div style={{ overflow: 'auto', display: 'flex' }}>
			{selected.map(t =>
				<TagContainer key={`tagNoPermission[${t.id}]`} backgroundColor={t.color!}>
					<Tag backgroundColor={t.color!}>
						{t.name}
					</Tag>
				</TagContainer>)}
		</div>}
		ownerId={row.owner_id}
	>
		<DropdownTagsCloud
			addImageSize='1.25em'
			loadingState={loadingState}
			NoneContainer={<DefaultTextDiv>-</DefaultTextDiv>}
			autoOptionUp
			horizontal
			noSearch
			addImageOnHover
			dropdownSearch
			tags={tags}
			selected={selected}
			onChange={onChange}
			onDelete={onDelete}
		/>
	</Restricted>;
}

export function GranularityPicker(props: { name: React.ReactNode, id?: string }) {
	const [granularity, setGranularity] = useRecoilState(AGranularity);
	return <DropdownColumnContainer id={props.id}>
		{props.name}
		<ColumnPicker<Granularity>
			onChange={v => setGranularity(v.value)}
			datalist={['month', 'quarter', 'year'].map((g: Granularity) => ({ label: translateToString(g), value: g }))}
			selectedValue={{ label: translateToString(granularity), value: granularity }}
			columnId='frequency_event_target'
		/>
	</DropdownColumnContainer>;
}

export function FormFieldColumnDisplayer(props: { value: IAdditionalColumnsSubValue, fieldType: FieldType, users: Owner[] | undefined }) {
	const { value, fieldType } = props;
	switch (fieldType) {
		case 'Address':
		case 'Company':
		case 'Email':
		case 'Location':
		case 'Number':
		case 'Phone':
		case 'Text':
		case 'TextArea':
		case 'Url':
		case 'Integer':
		case 'Select':
			// DO NOT DO value?.name ?? value?.value
			return <TableRow>{value?.name ? value?.name : value?.value}</TableRow>;
		case 'Boolean':
			return value?.value ? <TableRow><Dot size='15px' color={JSON.parse(value.value) ? GreenSidely : RedSidely} /></TableRow> : <TableRow />;
		case 'Date':
			return <DateAccessor date={value?.value} />;
		case 'Multiselect': {
			try {
				return value?.value ? <TableRow>{(JSON.parse(value.value) as string[]).reduce((acc, v) => `${acc}, ${v}`)}</TableRow> : <TableRow />;
			} catch (e) {
				return <TableRow />;
			}
		}
		case 'File':
		case 'Image':
		case 'CheckIn':
		case 'Dispatch':
		case 'Form':
			return value?.value ? <TableRow>{translateToString('unable_to_display_this_field')}</TableRow> : <TableRow />;
		case 'User': {
			if (!value?.value) return <TableRow />;
			const userId: number = JSON.parse(value?.value ?? '0');
			const user = props.users?.find(u => u.id == userId);
			return <UserBlock user={user} />;
		}
	}
	return <TableRow>{value?.name ? value.name : value?.value}</TableRow>;
}

function CompanyColumns(
	onClick: (value: number, partialMode: boolean) => void,
	onUpdateCompany: (value: Company) => void,
	onUpdateCompanies: (values: Company[]) => void,
	onDuplicateCompany: (value: Company) => void,
	onDeleteCompany: (value: Company) => void,
	onModifyAddress: (value: Company) => void,
	statuses: CompanyStatus[] | undefined,
	users: Owner[] | undefined,
	tags: ITag[],
	allSelected: boolean,
	hidableColumns,
	setSortColumns: (v) => void,
	setHiddenColumns: (v) => void,
	onAllChecked: (b: boolean) => void,
	atomEventTypes: AtomEventType[],
	selectedColumnEventType: number,
	setSelectedColumnEventType: (n: number) => void,
	refresh: () => void,
	openEvent: (id: number) => void,
	loadingState: LoadingStateEnum | undefined,
	atomExternalMapping: MappingResume[],
	selectedExternalMappingId: AtomSelectedExternalMappingSelector,
	setSelectedExternalMappingId: (n: AtomSelectedExternalMappingSelector) => void,
	alertDelete: (p: AlertPropsDelete & { name: string }) => Promise<AlertRes>,
	isAllowed: (to: To | To[], opt: IsAllowedToProps) => boolean,
	shelfAuditTemplates: ShelfAuditTemplate[],
	additionalColumns: AdditionalColumn[],
	additionalFieldColumn: AdditionalFieldColumn[],
	assortments: AssortmentShort[],
	selectedColumnFormId: number,
	setSelectedColumnFormId: (n: number) => void,
	formTemplates: FormTemplate[],
	openFormInstance: (state: ModalState<string>) => void,
	calculatedFields: CalculatedField[],
	isAdmin?: boolean
): Array<Column<Company>> {
	const translate = getTranslate(storeLang.getState().localize);
	const DUPLICATE = 0; const DELETE = 1;
	const you = users?.find(u => u.isYou)?.id;
	if (!you) return [];
	const eventTypeList = atomEventTypes.map(c => ({ value: c, label: translate(`event.${c.name}`).toString() }));
	const externalMappingList = atomExternalMapping.filter(m => m.mapping_type_id === 1).map(m => ({ value: m, label: m.name }));
	const formTemplatesList = formTemplates.map(f => ({ value: f.id, label: f.name }));
	if (externalMappingList[0] && (!selectedExternalMappingId.company || !externalMappingList.find(em => em.value.id === selectedExternalMappingId.company))) {
		setSelectedExternalMappingId({ company: externalMappingList[0].value.id });
	}

	const colHeaderInnerText = {
		evolution: document.getElementById('evolution')?.innerText,
		previous_sum: document.getElementById('previous_sum')?.innerText,
		current_sum: document.getElementById('current_sum')?.innerText,
		external_id: document.getElementById('external_id')?.innerText,
		last_event_date: document.getElementById('last_event_date')?.innerText,
		next_event_date: document.getElementById('next_event_date')?.innerText,
		yearly_frequency: document.getElementById('yearly_frequency')?.innerText,
		frequency_delay: document.getElementById('frequency_delay')?.innerText,
		last_form_date: document.getElementById('last_form_date')?.innerText,
		quick_actions: document.getElementById('quick_actions')?.innerText,
		uvcEvolution: document.getElementById('uvc_evolution')?.innerText,
		previousUvcSum: document.getElementById('previous_uvc_sum')?.innerText,
		currentUvcSum: document.getElementById('current_uvc_sum')?.innerText,
	};

	const columns: Array<Column<Company>> = [
		{
			id: 'select',
			Header: <Checkbox isActive={allSelected} onClick={() => onAllChecked(!allSelected)} />,
			accessor: row => <Checkbox isActive={row.checked ?? false} onClick={() => {
				const c = { ...row };
				c.checked = !c.checked;
				onUpdateCompany(c);
			}} />,
			width: 40,
			minWidth: 40,
			disableSortBy: true,
			freeze: 'left',
			disableFilter: true,
			unresizeable: true
		},
		{
			id: 'name',
			Header: filterIdToTranslatedName('name'),
			accessor: row => <TableRowTitle
				onClick={() => onClick(row.company_id, false)}

			>
				{row.name}
			</TableRowTitle>,
			hoverImage: row => <PartialOpen src={eye} onClick={() => onClick(row.company_id, true)} />,
			width: 200,
			minWidth: 150,
			freeze: 'left',
			type: 'string',
			toolTip: row => row.name
		},
		{
			id: 'parent_id',
			Header: filterIdToTranslatedName('parent_id'),
			accessor: row => <TableRow>{row.parent_id}</TableRow>,
			width: 100,
			minWidth: 60,
			type: 'numeric',
		},
		{
			id: 'checkout_evolution',
			filterId: {
				checkout: {
					element: 'evolution',
				}
			},
			filterName: filterIdToTranslatedName({ checkout: { element: 'evolution' } }),
			Header: <GranularityPicker id='evolution' name={filterIdToTranslatedName({ checkout: { element: 'evolution' } })} />,
			accessor: row => <TableRow>{row.checkoutEvolution && <Evolution percentage={(row.checkoutEvolution.evolution ?? 0) / 100} />}</TableRow>,
			width: colHeaderInnerText.evolution ? getStringWidth(colHeaderInnerText.evolution) + 20 : 300,
			minWidth: 150,
			type: 'numeric',
			editable: true,
			noHeaderEllipsis: true
		},
		{
			id: 'checkout_previous_sum',
			filterId: {
				checkout: {
					element: 'previous_sum',
				}
			},
			filterName: filterIdToTranslatedName({ checkout: { element: 'previous_sum' } }),
			Header: <GranularityPicker id='previous_sum' name={filterIdToTranslatedName({ checkout: { element: 'previous_sum' } })} />,
			accessor: row => <TableRow>{row.checkoutEvolution?.previousSum !== undefined && formatCurrency(row.checkoutEvolution.previousSum)}</TableRow>,
			width: colHeaderInnerText.previous_sum ? getStringWidth(colHeaderInnerText.previous_sum) + 20 : 300,
			minWidth: 150,
			type: 'numeric',
			editable: true,
			noHeaderEllipsis: true
		},
		{
			id: 'checkout_current_sum',
			filterId: {
				checkout: {
					element: 'current_sum',
				}
			},
			filterName: filterIdToTranslatedName({ checkout: { element: 'current_sum' } }),
			Header: <GranularityPicker id='current_sum' name={filterIdToTranslatedName({ checkout: { element: 'current_sum' } })} />,
			accessor: row => <TableRow>{row.checkoutEvolution && <FlexDiv gap='5px'>
				{formatCurrency(row.checkoutEvolution.currentSum ?? 0)}
				<Evolution percentage={(row.checkoutEvolution.evolution ?? 0) / 100} kind='small' />
			</FlexDiv>}</TableRow>,
			width: colHeaderInnerText.current_sum ? getStringWidth(colHeaderInnerText.current_sum) + 20 : 300,
			minWidth: 150,
			type: 'numeric',
			editable: true,
			noHeaderEllipsis: true
		},
		{
			id: 'checkout_uvc_evolution',
			filterId: {
				checkout: {
					element: 'uvc_evolution',
				}
			},
			filterName: filterIdToTranslatedName({ checkout: { element: 'uvc_evolution' } }),
			Header: <GranularityPicker id='evolution' name={filterIdToTranslatedName({ checkout: { element: 'uvc_evolution' } })} />,
			accessor: row => <TableRow>{row.checkoutEvolution && <Evolution percentage={(row.checkoutEvolution.uvcEvolution ?? 0) / 100} />}</TableRow>,
			width: colHeaderInnerText.uvcEvolution ? getStringWidth(colHeaderInnerText.uvcEvolution) + 20 : 300,
			minWidth: 150,
			type: 'numeric',
			editable: true,
			noHeaderEllipsis: true
		},
		{
			id: 'checkout_previous_uvc_sum',
			filterId: {
				checkout: {
					element: 'previous_uvc_sum',
				}
			},
			filterName: filterIdToTranslatedName({ checkout: { element: 'previous_uvc_sum' } }),
			Header: <GranularityPicker id='previous_sum' name={filterIdToTranslatedName({ checkout: { element: 'previous_uvc_sum' } })} />,
			accessor: row => <TableRow>{row.checkoutEvolution?.previousUvcSum !== undefined && row.checkoutEvolution.previousUvcSum?.toFixed(2)}</TableRow>,
			width: colHeaderInnerText.previousUvcSum ? getStringWidth(colHeaderInnerText.previousUvcSum) + 20 : 300,
			minWidth: 150,
			type: 'numeric',
			editable: true,
			noHeaderEllipsis: true
		},
		{
			id: 'checkout_current_uvc_sum',
			filterId: {
				checkout: {
					element: 'current_uvc_sum',
				}
			},
			filterName: filterIdToTranslatedName({ checkout: { element: 'current_uvc_sum' } }),
			Header: <GranularityPicker id='current_sum' name={filterIdToTranslatedName({ checkout: { element: 'current_uvc_sum' } })} />,
			accessor: row => <TableRow>{row.checkoutEvolution && <FlexDiv gap='5px'>
				{row.checkoutEvolution.currentUvcSum?.toFixed(2) ?? 0}
				<Evolution percentage={(row.checkoutEvolution.uvcEvolution ?? 0) / 100} kind='small' />
			</FlexDiv>}</TableRow>,
			width: colHeaderInnerText.currentUvcSum ? getStringWidth(colHeaderInnerText.currentUvcSum) + 20 : 300,
			minWidth: 150,
			type: 'numeric',
			editable: true,
			noHeaderEllipsis: true
		},
		{
			id: 'external_id',
			filterId: {
				external_id: null
			},
			filterName: filterIdToTranslatedName({ external_id: null }),
			Header: externalMappingList.length == 1 ? externalMappingList[0].label : <FlexDiv gap='4px'> <Translate id='company.columns.external_id' />:<ColumnPicker<MappingResume>
				onChange={value => setSelectedExternalMappingId({ company: value.value.id })}
				datalist={externalMappingList}
				selectedValue={externalMappingList.find(et => et.value.id === selectedExternalMappingId.company)}
				columnId='frequency_event_target'
				id='external_id'
			/></FlexDiv>,
			accessor: row =>
				<Restricted
					to={{ objectAction: 'UpdateCompany' }}
					fallback={<TableRow padding='5px'>{row.externalId}</TableRow>}
					isSelf={row.owner_id === you}
				>
					<EditableContent<Company>
						innerId={`${row.company_id}-${loadingState}`}
						row={row}
						valueKey="externalId"
						onValueUpdate={value => {
							if ((!value && !row.externalId) || (value === row.externalId)) return;
							if (!value && selectedExternalMappingId.company) {
								deleteExternalId(row.company_id, selectedExternalMappingId.company)
									.then(_ => {
										const c = { ...row };
										c.externalId = undefined;
										onUpdateCompany(c);
									});
							} else if (selectedExternalMappingId.company && value) {
								setExternalId(row.company_id, selectedExternalMappingId.company, value)
									.then(_ => {
										const c = { ...row };
										c.externalId = value ? value : undefined;
										onUpdateCompany(c);
									});
							}
						}}
					/>
				</Restricted>,
			width: colHeaderInnerText.external_id ? getStringWidth(colHeaderInnerText.external_id) + 20 : 200,
			minWidth: 150,
			maxWidth: 600,
			type: 'string',
			editable: true,
			noHeaderEllipsis: true
		},
		{
			id: 'parent_name',
			Header: filterIdToTranslatedName('parent_name'),
			accessor: row => <>
				<Restricted
					to={{ objectAction: 'UpdateCompany' }}
					fallback={<TableRow padding='5px'>{row.parent_name}</TableRow>}
					isSelf={row.owner_id === you}
				>
					{
						row.parent_name && row.parent_name.length > 0
							? <TableRow>{row.parent_name}</TableRow>
							: <CompanySearchCell
								defaultValue={{ name: row.parent_name, id: row.parent_id }}
								onUpdateCompany={({ id, name }) => updateParentCompany(row.company_id, id)
									.then(() => {
										const c = { ...row };
										c.parent_name = name;
										c.parent_id = id;
										onUpdateCompany(c);
									})
									.catch(e => {
										const c = { ...row };
										c.error = 'parent_name';
										setTimeout(() => onUpdateCompany({ ...row, error: undefined }), 1500, c);
										throw e;
									})
								} />
					}
				</Restricted>
			</>,
			hoverImage: row => row.parent_id ? <PartialOpen src={eye} onClick={() => row.parent_id && onClick(row.parent_id, true)} /> : undefined,
			width: 200,
			minWidth: 150,
			type: 'string',
			toolTip: row => row.parent_name,
			editable: true
		},
		{
			id: 'company_address',
			Header: filterIdToTranslatedName('company_address'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow>{row.company_address}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<TableRow cursor="pointer" onClick={() => onModifyAddress(row)}>{row.company_address && row.company_address.trim().length > 0 ? row.company_address : '-'}</TableRow>
			</Restricted>,
			hoverImage: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				isSelf={row.owner_id === you}
			>
				{row.company_address && row.company_address.length > 0 ? <ButtonAction src={new_tab} alt="address" onClick={() => window.open(`/map?company=${row.company_id}`, '_blank')} /> : <></>}
			</Restricted>,
			width: 300,
			minWidth: 200,
			type: 'string',
		},
		{
			id: 'company_post_code',
			Header: filterIdToTranslatedName('company_post_code'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow>{row.company_post_code}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<TableRow cursor="pointer" onClick={() => onModifyAddress(row)}>{row.company_post_code}</TableRow>
			</Restricted>,
			width: 150,
			minWidth: 150,
			type: 'string',
		},
		{
			id: 'company_city',
			Header: filterIdToTranslatedName('company_city'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow>{row.company_city}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<TableRow cursor="pointer" onClick={() => onModifyAddress(row)}>{row.company_city}</TableRow>
			</Restricted>,
			width: 150,
			minWidth: 120,
			type: 'string',
		},
		{
			id: 'company_country',
			Header: filterIdToTranslatedName('company_country'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow>{row.company_country}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<TableRow cursor="pointer" onClick={() => onModifyAddress(row)}>{row.company_country}</TableRow>
			</Restricted>,
			width: 150,
			minWidth: 150,
			type: 'string',
		},
		{
			id: 'phone',
			Header: filterIdToTranslatedName('phone'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow padding='5px'>{row.phone}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<EditableContent<Company>
					innerId={`${row.company_id}-${loadingState}`}
					row={row}
					onUpdate={onUpdateCompany}
					valueKey="phone"
					regex={validatePhoneNumber}
					onValueUpdate={value => {
						updateCompany({
							client_company_id: row.company_id,
							phone: value
						}).then(res => {
							if (res.valueOf()) {
								const c = { ...row };
								c.phone = value !== null ? value : '';
								onUpdateCompany(c);
							}
						});
					}} />
			</Restricted>,
			hoverImage: row => row.phone ? <ButtonAction src={phone_button} alt="phone" onClick={() => window.open(`tel:${row.phone}`)} /> : undefined,
			width: 170,
			minWidth: 150,
			editable: true,
			type: 'string',
		},
		{
			id: 'email',
			Header: filterIdToTranslatedName('email'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow padding='5px'>{row.email}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<EditableContent<Company>
					innerId={`${row.company_id}-${loadingState}`}
					row={row}
					onUpdate={onUpdateCompany}
					valueKey="email"
					regex={match_email}
					onValueUpdate={value => {
						updateCompany({
							client_company_id: row.company_id,
							email: value
						}).then(res => {
							if (res.valueOf()) {
								const c = { ...row };
								c.email = value !== null ? value : '';
								onUpdateCompany(c);
							}
						});
					}}
				/>
			</Restricted>,
			hoverImage: row => {
				if (row.email) {
					//TODO: add a request to get [first_name, last_name] by user_id
					//beacause Company has only name(first + last)
					//but I can control the case that has space on first or last name
					//ex: Francois Faure Dumont
					const [first_name, last_name] = (row.name)
						? row.name.split(' ')
						: ['', ''];

					return <HoverEmailWrapper
						emailTemplateVariable={{
							first_name,
							last_name,
							company_name: row.name,
						}}
						email={row.email} />;
				}
				return undefined;
			},
			width: 180,
			minWidth: 150,
			editable: true,
			type: 'string',
		},
		{
			id: 'website',
			Header: filterIdToTranslatedName('website'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow padding='5px'>{row.website}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<EditableContent<Company>
					innerId={`${row.company_id}-${loadingState}`}
					row={row}
					onUpdate={onUpdateCompany}
					valueKey="website"
					regex={validateWebsite}
					onValueUpdate={value => {
						updateCompany({
							client_company_id: row.company_id,
							website: value
						}).then(res => {
							if (res.valueOf()) {
								const c = { ...row };
								c.website = value !== null ? value : '';
								onUpdateCompany(c);
							}
						});
					}} />
			</Restricted>,
			hoverImage: row => row.website ? <ButtonAction
				width="20px"
				src={new_tab}
				alt="website"
				onClick={() => {
					if (row.website?.includes('http://') || row.website?.includes('https://')) {
						window.open(row.website, '_blank');
						return;
					}
					window.open(`http://${row.website}`, '_blank');
				}}
			/> : undefined,
			width: 180,
			minWidth: 150,
			editable: true,
			type: 'string',
		},
		{
			id: 'owner_id',
			Header: filterIdToTranslatedName('owner_id'),
			accessor: row => {
				const user = users?.find(u => u.id == row.owner_id);
				return <Restricted
					to={{ objectAction: 'UpdateCompany', scope: 'User' }}
					fallback={<UserBlock user={user} />}
					isSelf={row.owner_id === you}
				>
					<DropdownOwners
						dropdownStyle={{
							containerWidth: 'initial',
							containerJustifyContent: 'flex-start',
							optionWidth: '200px',
							optionHeight: '150px',
							optionPadding: '5px 10px 5px 10px',
							optionLeft: '-10px',
							width: '180px',
							backgroundColor: 'none',
							fontSize: 12
						}}
						users={users?.map(u => ({ label: u.name, value: u, image: u.photoUrl })) ?? []}
						selected={{ label: row.owner_name, value: users?.find(u => u.id == row.owner_id), image: row.owner_photo }}
						onChange={value => {
							const owner = value.value;
							updateCompany({
								client_company_id: row.company_id,
								owner_id: owner?.id
							}).then(res => {
								if (res.valueOf()) {
									const c = { ...row };
									if (owner) {
										c.owner_id = owner.id;
										c.owner_name = owner.name;
										c.owner_photo = owner.photoUrl;
									}
									onUpdateCompany(c);
								}
							});
						}}
					/>
				</Restricted>;
			},
			width: 200,
			minWidth: 200,
			type: 'user',
			// visualization: true
		},
		{
			id: 'status_id',
			Header: filterIdToTranslatedName('status_id'),
			accessor: row => {
				return <Restricted
					to={{ objectAction: 'UpdateCompany', scope: 'User' }}
					fallback={<TableRowStatusBackground noPointer><TableRowStatus backgroundColor={row.company_status_color}>{row.company_status_name}</TableRowStatus></TableRowStatusBackground>}
					isSelf={row.owner_id === you}
				>
					<LightDropdown
						dropdownStyle={{
							containerHeight: 'unset',
							optionWidth: '200px'
						}}
						datalist={statuses?.sort((a, b) => a.sequence - b.sequence).map(s => ({ label: s.name, value: s, color: s.color_code })) ?? []}
						JSXButton={({ onClick }) => <TableRowStatusBackground onClick={onClick}><TableRowStatus backgroundColor={row.company_status_color}>{row.company_status_name}</TableRowStatus></TableRowStatusBackground>}
						JSXContent={({ value, onClick }) => <div style={{ margin: 5 }}><TableRowStatusBackground onClick={onClick}><TableRowStatus backgroundColor={value.color}>{value.label}</TableRowStatus></TableRowStatusBackground></div>}
						onChange={value => {
							const status = value.value;
							updateCompany({
								client_company_id: row.company_id,
								client_status_id: value.value.id
							}).then(res => {
								if (res.valueOf()) {
									const c = { ...row };
									c.status_id = status.id;
									c.company_status_color = status.color_code;
									c.company_status_name = status.name;
									onUpdateCompany(c);
								}
							});
						}}
					/>
				</Restricted>;
			},
			width: 150,
			minWidth: 150,
			type: 'status',
		},
		{
			id: 'tag_id',
			Header: filterIdToTranslatedName('tag_id'),
			accessor: row => <TagCell row={row} tags={tags} onChange={(values: ITag[]) => {
				const idsAdd: ITag[] = values.filter(t => !row.tags.some(ct => ct.id == t.id));
				return updateTags(TagType.COMPANY,
					{
						ressource_id: row.company_id,
						ids_add: idsAdd.map(t => t.id!)
					}).then(response => {
					if (response.valueOf()) {
						const c = { ...row };
						c.tags = c.tags.concat(idsAdd);
						onUpdateCompany(c);
					}
				});
			}}
			onDelete={(id: number) => updateTags(TagType.COMPANY, {
				ressource_id: row.company_id,
				ids_delete: [id]
			}).then(response => {
				if (response.valueOf()) {
					const c = { ...row };
					c.tags = c.tags.filter(t2 => t2.id !== id);
					onUpdateCompany(c);
				}
			})
			} />,
			width: 250,
			minWidth: 250,
			type: 'tag',
			disableSortBy: true,
			// visualization: true
		},
		{
			id: 'latitude',
			Header: filterIdToTranslatedName('latitude'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow>{row.latitude}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<TableRow cursor="pointer" onClick={() => onModifyAddress(row)}>{row.latitude}</TableRow>
			</Restricted>,
			width: 200,
			minWidth: 150,
			type: 'numeric',
		},
		{
			id: 'longitude',
			Header: filterIdToTranslatedName('longitude'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={<TableRow>{row.longitude}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<TableRow cursor="pointer" onClick={() => onModifyAddress(row)}>{row.longitude}</TableRow>
			</Restricted>,
			width: 200,
			minWidth: 150,
			type: 'numeric',
		},
		{
			id: 'created_at',
			Header: filterIdToTranslatedName('created_at'),
			accessor: row => <DateAccessor date={row.created_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'created_by',
			Header: filterIdToTranslatedName('created_by'),
			accessor: row => <UserBlock user={users?.find(u => u.id == row.created_by)} />,
			width: 200,
			minWidth: 150,
			type: 'user'
		},
		{
			id: 'updated_at',
			Header: filterIdToTranslatedName('updated_at'),
			accessor: row => <DateAccessor date={row.updated_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'updated_by',
			Header: filterIdToTranslatedName('updated_by'),
			accessor: row => <UserBlock user={users?.find(u => u.id == row.updated_by)} />,
			width: 200,
			minWidth: 150,
			type: 'user',
		},
		{
			id: 'company_id',
			Header: filterIdToTranslatedName('company_id'),
			accessor: row => <TableRow>{row.company_id}</TableRow>,
			width: 100,
			minWidth: 100,
			type: 'numeric',
		},
		{
			id: 'dn',
			Header: filterIdToTranslatedName('dn'),
			accessor: row => <TableRow>{row.dn !== undefined && row.latest_shelf_audit_date ? <TableRowDnContainer dn={row.dn}>{(row.dn * 100).toFixed(2)} %</TableRowDnContainer> : undefined}</TableRow>,
			hoverImage: row => row.latest_shelf_audit_id ? <ButtonAction width="20px" src={new_tab} alt="address" onClick={() => window.open(`/enform/detaildata/${row.latest_shelf_audit_id}`, '_blank')} /> : undefined,
			width: 175,
			type: 'percentage',
		},
		{
			id: 'last_shelf_audit_date',
			Header: filterIdToTranslatedName('last_shelf_audit_date'),
			accessor: row => <DateAccessor date={row.latest_shelf_audit_date} utc />,
			hoverImage: row => row.latest_shelf_audit_id ? <ButtonAction width="20px" src={new_tab} alt="address" onClick={() => window.open(`/enform/detaildata/${row.latest_shelf_audit_id}`, '_blank')} /> : undefined,
			width: 200,
			type: 'temporal',
		},
		{
			id: 'last_event_date',
			filterId: {
				last_event_date: null
			},
			filterName: filterIdToTranslatedName({ last_event_date: null }),
			noHeaderEllipsis: true,
			Header: <DropdownColumnContainer id='last_event_date'>
				<Translate id='last_event_date_part1' />
				<ColumnPicker<AtomEventType>
					onChange={(value: DropdownData<AtomEventType>) => setSelectedColumnEventType(value.value.id)}
					datalist={eventTypeList}
					selectedValue={eventTypeList.find(et => et.value.id === selectedColumnEventType)}
					columnId='frequency_event_target'
				/>
				<Translate id='last_event_date_part2' />
			</DropdownColumnContainer>,
			accessor: (row) => {
				const date = row.latest_event_date?.date;
				return <DateAccessor date={date && new Date((date?.seconds as number ?? 0) * 1000)} />;
			},
			hoverImage: row => <Restricted
				to={{ objectAction: 'CreateEvent' }}
				fallback={<TableRow>{row.latitude}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<PartialOpen
					src={event_column_button}
					onClick={() => openEvent(row.company_id)}
				/>
			</Restricted>,
			width: colHeaderInnerText.last_event_date ? getStringWidth(colHeaderInnerText.last_event_date) + 20 : 250,
			type: 'temporal',
		},
		{
			id: 'next_event_date',
			filterId: {
				next_event_date: null
			},
			filterName: filterIdToTranslatedName({ next_event_date: null }),
			noHeaderEllipsis: true,
			Header: <DropdownColumnContainer id='next_event_date'>
				<Translate id='next_planned_event_part1' />
				<ColumnPicker<AtomEventType>
					onChange={(value: DropdownData<AtomEventType>) => setSelectedColumnEventType(value.value.id)}
					datalist={eventTypeList}
					selectedValue={eventTypeList.find(et => et.value.id === selectedColumnEventType)}
					columnId='frequency_event_target'
				/>
				<Translate id='next_planned_event_part2' />
			</DropdownColumnContainer>,
			accessor: row => {
				const date = row.next_event_date?.date;
				return <>
					{<DateAccessor date={date && new Date((date?.seconds as number ?? 0) * 1000)} />}
				</>;
			},
			width: colHeaderInnerText.next_event_date ? getStringWidth(colHeaderInnerText.next_event_date) + 20 : 250,
			type: 'temporal',
		},
		{
			id: 'frequency_event',
			filterName: filterIdToTranslatedName({ frequencies: { element: 'frequency' } }),
			filterId: {
				frequencies: {
					element: 'frequency'
				}
			},
			noHeaderEllipsis: true,
			Header: <DropdownColumnContainer id='yearly_frequency'>
				<Translate id='yearly_frequency' />
				<ColumnPicker<AtomEventType>
					onChange={(value: DropdownData<AtomEventType>) => setSelectedColumnEventType(value.value.id)}
					datalist={eventTypeList}
					selectedValue={eventTypeList.find(et => et.value.id === selectedColumnEventType)}
					columnId='frequency_event_target'
				/>
			</DropdownColumnContainer>,
			accessor: row => {
				const defaultValue = row.frequency?.target?.toString() ?? '';
				return <>
					<Restricted
						to={{ objectAction: 'UpdateCompany' }}
						fallback={<TableRow padding='5px'>{defaultValue}</TableRow>}
						isSelf={row.owner_id === you}
					>
						<EditableContent<Company>
							innerId={`${row.company_id}[${selectedColumnEventType}][${loadingState}]`}
							row={row}
							onUpdate={onUpdateCompany}
							valueKey="phone"
							error='frequency_event'
							defaultValue={defaultValue}
							regex={validatePositiveNumberAndEmpty}
							onValueUpdate={async value => {
								if (value === defaultValue || ((value?.length ?? 0) === (defaultValue?.length ?? 0) && (value?.length ?? 0) === 0)) return;
								if (value && parseInt(value) > 0) {
									await createFrequency(row.company_id, { event_type: selectedColumnEventType, frequency: parseInt(value) });
								} else {
									await deleteFrequency(row.company_id, selectedColumnEventType);
								}
								refresh();
							}}
						/>
					</Restricted>
					<RowComment>
						{row.frequency?.target ? `(${translate('every_{{NUMBER}}_days').toString().replace('{{NUMBER}}', getDailyFrequency(row.frequency.target).toFixed())})` : undefined}
					</RowComment>
				</>;
			},
			width: colHeaderInnerText.yearly_frequency ? getStringWidth(colHeaderInnerText.yearly_frequency) + 20 : 250,
			type: 'numeric',
			editable: true
		},
		{
			id: 'frequency_event_target',
			filterName: filterIdToTranslatedName({ frequencies: { element: 'score' } }),
			filterId: {
				frequencies: {
					element: 'score'
				}
			},
			noHeaderEllipsis: true,
			Header: <DropdownColumnContainer id='frequency_delay'>
				<Translate id='frequency_delay' />
				<ColumnPicker<AtomEventType>
					onChange={(value: DropdownData<AtomEventType>) => setSelectedColumnEventType(value.value.id)}
					datalist={eventTypeList}
					selectedValue={eventTypeList.find(et => et.value.id === selectedColumnEventType)}
					columnId='frequency_event_target'
				/>
			</DropdownColumnContainer>,
			accessor: row => {
				const frequency = row.frequency;
				if (!frequency) return <></>;
				const score = frequency.score ?? 0;
				const prec = score.toFixed(2);
				return <TableRow>
					{frequency &&
						<FlexDiv justify='center' gap='10px'>
							<div style={{ visibility: 'hidden' }}>{prec}</div>
							<ScoreEmojiContainer backgroundColor={getColorByFrequencyScore(score)}>
								{getEmojiByFrequencyScore(score)}
							</ScoreEmojiContainer>
							<div>{prec}</div>
						</FlexDiv>
					}
				</TableRow>;
			},
			width: colHeaderInnerText.frequency_delay ? getStringWidth(colHeaderInnerText.frequency_delay) + 20 : 250,
			type: 'numeric',
			toolTip: company => {
				if (!company.frequency || !company.frequency.score) return;
				else if (company.frequency.score >= 1) return translate('days_delay') + ': ' + getDaysDelay(company.frequency).toFixed();
				else return translate('remaining_days') + ': ' + getRemainingDays(company.frequency).toFixed();
			}
		},
		{
			// FIXME: change link to new shelf audit page
			id: 'shelf_audit_templates',
			Header: filterIdToTranslatedName('shelf_audit_templates'),
			accessor: row => <TableRow>
				<FlexDiv gap='0.5em'>
					{row.shelfAuditTemplates?.map(sat => {
						const name = shelfAuditTemplates.find(s => s.id === sat)?.name;
						if (!name) return undefined;
						return <TemplateContainer key={`templateContainer[${row.company_id}][${sat}]`} color={getUserColor(name)} onClick={() => {
							window.open(`shelf-audits/${sat}`);
						}}>
							{name}
						</TemplateContainer>;
					})}
				</FlexDiv>
			</TableRow>,
			width: 250,
			minWidth: 250,
			type: 'tag',
			disableSortBy: true,
			disableFilter: true,
		},
		{
			id: 'last_form_date',
			filterId: {
				last_form_date: null
			},
			filterName: filterIdToTranslatedName({ last_form_date: null }),
			noHeaderEllipsis: true,
			Header: <DropdownColumnContainer id='last_form_date'>
				<Translate id='last_form_date_part1' />
				<ColumnPicker
					onChange={(value) => setSelectedColumnFormId(value.value)}
					datalist={formTemplatesList}
					selectedValue={formTemplatesList.find(et => et.value === selectedColumnFormId)}
					columnId='last_form_date'
				/>
				<Translate id='last_form_date_part2' />
			</DropdownColumnContainer>,
			accessor: (row) => {
				const date = row.lastFormDate?.date;
				return <TableRow cursor='pointer' onClick={() => row.lastFormDate?.uuid ? openFormInstance({ isOpen: true, data: row.lastFormDate.uuid, fullOpenMode: true }) : undefined}>
					<DateAccessor date={date && new Date((date?.seconds as number ?? 0) * 1000)}
					/></TableRow>;
			},
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => row.lastFormDate?.uuid ? openFormInstance({ isOpen: true, data: row.lastFormDate.uuid, fullOpenMode: false }) : undefined}
			/>,
			width: colHeaderInnerText.last_form_date ? getStringWidth(colHeaderInnerText.last_form_date) + 20 : 250,
			type: 'temporal',
		},
	];
	additionalFieldColumn.forEach(afc => columns.push({
		id: additionalFieldColumnIdFormatter(afc.field_id),
		filterId: {
			additional_field_columns: [afc.field_id]
		},
		Header: afc.field_name,
		disableSortBy: isFieldTypeSortable(afc.field_type),
		accessor: row => {
			return <FormFieldColumnDisplayer fieldType={afc.field_type} value={row.additionalFieldColumnsValue[afc.field_id.toString()]} users={users} />;
		}
	}));

	additionalColumns.forEach(ac => columns.push({
		id: additionalColumnIdFormatter(ac.id),
		filterId: {
			additional_columns: [{ id: ac.id }]
		},
		Header: ac.name,
		accessor: row => {
			const value = row.additionalColumnsValue[ac.id.toString()];
			const onUpdate = (value, name?) => (value === undefined || value === null ? deleteAdditionalColumnValue(ac.id, row.company_id) : updateAdditionalColumnValue(ac.id, row.company_id, value)).then(() => {
				const c = { ...row };
				if (!c.additionalColumnsValue[ac.id.toString()]) c.additionalColumnsValue[ac.id.toString()] = {};
				c.additionalColumnsValue[ac.id.toString()].value = value;
				if (name) c.additionalColumnsValue[ac.id.toString()].name = name;
				onUpdateCompany(c);
			});
			switch (ac.type) {
				case 'Integer':
				case 'Number':
				case 'String':
					return <EditableContent<Company>
						innerId={`${row.company_id}-${loadingState}`}
						row={row}
						valueKey={`additional-column[${ac.id}]`}
						defaultValue={value?.value ?? ''}
						onValueUpdate={value => {
							if (ac.type === 'Integer') {
								return onUpdate(!value ? null : parseInt(value));
							}
							if (ac.type === 'Number') {
								return onUpdate(!value ? null : parseFloat(value.replaceAll(',', '.')));
							}
							return onUpdate(value);
						}} />;
				case 'Boolean': return <Switch onChange={onUpdate} value={JSON.parse(value?.value ?? 'false')} />;
				case 'Date': return <DateAccessor date={value?.value} onDateChange={date => {
					onUpdate(date.toJSON());
				}} />;
				case 'Catalogue': return <Restricted
					to={{ objectAction: 'UpdateCompany', scope: 'User' }}
					fallback={<TableRow>{value?.name ? value.name : value?.value}</TableRow>}
					ownerId={row.owner_id}
				>
					<LightDropdown
						dropdownStyle={{
							containerHeight: 'unset',
							optionWidth: '200px'
						}}
						datalist={assortments.map(s => ({ label: s.name, value: s }))}
						JSXButton={({ onClick }) => <TableRow onClick={onClick} cursor='pointer'>{value?.name ? value.name : value?.value}</TableRow>}
						JSXContent={({ value, onClick }) => <div key={`Asortment-DD-content[${value.value.id}]`} style={{ margin: 5 }}><TableRowStatusBackground onClick={onClick}>{value?.label}</TableRowStatusBackground></div>}
						onChange={value => {
							onUpdate(value.value.id, value.value.name);
						}}
					/>
				</Restricted>;
				case 'ReportColumn':
					if (value?.value === 'empty' || !value || !value.value) return <TableRow />;
					return <TableRow><ValueInnerToNode value={JSON.parse(value.value)} emptyTile /></TableRow>;
				case 'Select':
					return <Restricted to={{ objectAction: 'UpdateCompany' }} fallback={<TableRow>{value?.name ? value.name : value?.value}</TableRow>}>
						<LightDropdown
							dropdownStyle={{
								containerHeight: 'unset',
								optionWidth: '200px'
							}}
							datalist={ac.data.map(s => ({ label: s, value: s }))}
							JSXButton={({ onClick }) => <TableRow onClick={onClick} cursor='pointer'>{value?.name ? value.name : value?.value}</TableRow>}
							JSXContent={({ value, onClick }) => <div style={{ margin: 5 }}><TableRowStatusBackground onClick={onClick}>{value?.label}</TableRowStatusBackground></div>}
							onChange={value => {
								onUpdate(value.value);
							}}
						/>
					</Restricted>;
				case 'MultiSelect': {
					let selected: string[];
					try {
						if (Array.isArray(value?.value)) {
							selected = value.value;
						} else {
							selected = JSON.parse(value?.value ?? '[]');
						}
					} catch (e) {
						selected = [];
					}
					return <DropdownTagsCloud
						addImageSize='1.25em'
						loadingState={loadingState}
						NoneContainer={<DefaultTextDiv>-</DefaultTextDiv>}
						autoOptionUp
						horizontal
						noSearch
						addImageOnHover
						dropdownSearch
						selected={selected.map(s => ({ name: s, id: s }))}
						onChange={values => onUpdate(values.map(t => t.name))}
						onDelete={value => onUpdate(selected.filter(s => s !== value))}
						tags={ac.data.map(s => ({ name: s, id: s }))}
					/>;

				}
				case 'Company':
					return <Restricted
						to={{ objectAction: 'UpdateCompany' }}
						fallback={<TableRow padding='5px'>{value?.name ? value.name : value?.value}</TableRow>}
						isSelf={row.owner_id === you}
					>
						<CompanySearchCell
							defaultValue={{ name: (value?.name ?? undefined), id: value?.value ? parseInt(value.value) : undefined }}
							onUpdateCompany={({ id, name }) => onUpdate(id, name)} />
					</Restricted>;

			}
			return <TableRow>{value?.name ? value.name : value?.value}</TableRow>;
		},
		hoverImage: row => {
			if (ac.type !== 'Company') return undefined;
			const value = row.additionalColumnsValue[ac.id.toString()];
			if (!value?.value) return undefined;
			const id = parseInt(value.value);
			return <PartialOpen src={eye} onClick={() => onClick(id, true)} />;
		},
		width: getStringWidth(ac.name) + 20,
		minWidth: 150,
	}));
	if (isAdmin && calculatedFields)
		calculatedFields.forEach(cf => columns.push({
			id: calculatedFieldIdFormatter(cf.id),
			filterId: {
				calculated_field_columns: [{ id: cf.id }]
			},
			Header: cf.field_name,
			accessor: row => {
				const value = row.calculatedFieldColumnsValue[cf.id.toString()];
				if (value?.value === 'empty' || !value || !value.value) return <TableRow />;
				return <TableRow><ValueInnerToNode value={JSON.parse(value.value)} emptyTile /></TableRow>;
			}
		}));
	columns.push(
		{
			id: 'quick_actions',
			noHeaderEllipsis: true,
			Header: <ColumnOrganizer
				categories={Object.keys(COMPANY_COLUMN_CATEGORIES_MAP)}
				columns={hidableColumns}
				onSort={setSortColumns}
				setHiddenColumns={setHiddenColumns}
			></ColumnOrganizer>,
			accessor: row => {
				const list: DropdownData<number>[] = [];
				if (isAllowed({ objectAction: 'CreateCompany' }, { ownerId: row.owner_id })) {
					list.push({
						label: translate('global.quick_action.duplicate').toString(),
						value: DUPLICATE
					});
				}
				if (isAllowed({ objectAction: 'DeleteCompany' }, { ownerId: row.owner_id })) {
					list.push({
						label: translate('global.quick_action.delete').toString(),
						value: DELETE
					});
				}
				if (list.length == 0) return;
				return (
					<Dropdown
						name='quick_action'
						datalist={list}
						readOnly
						dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
						JSXButton={() =>
							<img
								src={optionGrey}
								width={25}
								height={25}
								style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
							/>
						}
						onChange={(value: DropdownData) => {
							if (value.value === DELETE) {
								deleteCompanyIdPopup(alertDelete, row.company_id, row.name, () => onDeleteCompany(row));
							} else if (value.value === DUPLICATE) {
								onDuplicateCompany(row);
							}
						}}
					/>
				);
			},
			width: colHeaderInnerText.quick_actions ? getStringWidth(colHeaderInnerText.quick_actions) + 20 : 40,
			minWidth: 40,
			disableSortBy: true,
			disableFilter: true,
			unresizeable: true,
			freeze: 'right'
			// visualization: true
		});
	return columns;
}

export default CompanyColumns;
