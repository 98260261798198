import styled from 'styled-components';

interface DefaultImageProps {
  width?: string
  height?: string
  round?: boolean
  cursor?: string
  padding?: string
  margin?: string
  rotate?: string
  filter?: string,
  isHidden?: boolean
}

const DefaultImage = styled.img<DefaultImageProps>`
    width: ${p => p.width ? p.width : '25px'};
    height: ${p => p.height ? p.height : '25px'};
    border-radius: ${p => p.round ? '50%' : '0'};
    cursor: ${p => p.cursor ? p.cursor : 'auto'};
    padding: ${p => p.padding ? p.padding : '0'};
    margin: ${p => p.margin ? p.margin : '0'};
    transform: ${p => p.rotate ? `rotate(${p.rotate})` : 'none'};
    filter: ${p => p.filter ? p.filter : 'none'};
    visibility: ${p => p.isHidden ? 'hidden' : 'visible'};
`;

export {
	DefaultImage
};
