import * as React from 'react';
import { reportingAction, reportingState } from '../Reducer';
import { Collapse } from 'reactstrap';
import { CheckGroupFilter } from '../utils';
import { ButtonToggle } from '../../../styles/global/css/Open';

interface users {
  label: string
  value: string
}

export default function PresencePerUsersFilters(props: {
  state: reportingState
  dispatch: (filters: reportingAction) => void
}) {
	const [users, setUsers] = React.useState<users[]>([]);
	const [activeCollapse, setActiveCollapse] = React.useState<string[]>([]);
	const filterName = 'PresencePerUsers';

	React.useEffect(() => {
		if (props.state.users) {
			const users = props
				.state
				.users
				.map((user, i) => {
					return {
						label: user,
						value: user,
						checked: props.state.filters[filterName].includes(user)
					};
				});
			users.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
			setUsers([{
				label: '',
				value: '-1',
				checked: props.state.users.length == props.state.filters[filterName].length
			}].concat(users));
		} else {
			setUsers([]);
		}
	}, [props.state.users]);

	function toggle(type: string) {
		const active = [...activeCollapse];
		if (active.includes(type)) {
			active.splice(active.indexOf(type), 1);
		} else {
			active.push(type);
		}
		setActiveCollapse(active);
	}

	function buttonToggle(title: string, key: string, alreadyTranslated?: boolean) {
		return <ButtonToggle
			onClick={() => toggle(key)}
			alreadyTranslated={alreadyTranslated}
			key={key}
			title={title}
			isOpen={activeCollapse.includes(key)}
		/>;
	}

	return (
		<div style={{ margin: '10px 0px 0px 20px' }}>
			<div className='col'>
				{buttonToggle('global.users', 'users')}
				<Collapse isOpen={activeCollapse.includes('users')}>
					<CheckGroupFilter list={users} setList={setUsers} filterName={filterName} {...props} />
				</Collapse>
			</div>
		</div>
	);
}
