import arrow_right_date_picker from 'images/icon/arrow_right_date_picker.svg';
import * as React from 'react';
import 'react-dates/initialize';
import { DateRangePicker, DayPickerSingleDateController, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';
import * as moment from 'moment';
import { Translate } from 'react-localize-redux';
import { useRecoilState } from 'recoil';
import { ADatePicker } from '../../../atoms/filter/timeFilterAtom';
import { BlueSidely, DarkGreySidely, DisabledColor, SidelyBlack } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../../containers_v2/products/style';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import calendarImage from 'images/icon/small_calendar2.svg';
import { translateToString } from '../../../styles/global/translate';
import * as ReactDates from 'react-dates';
import { useFunctionState } from '../../../utils/customHooks';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
export { SingleDatePicker } from 'react-dates';

const Style = styled.div<{ noBorder?: boolean, fillWidth?: boolean, height?: string, zIndex?: string }>`
	${DefaultText}
	color: ${SidelyBlack}
	z-index: ${p => p.zIndex ?? '9'};
	min-width: 248px;
	.DateRangePicker_picker {
		z-index: 9;
	}
	.CalendarDay__selected_span {
		background: ${BlueSidely}33;
		color: ${SidelyBlack};
		border: 1px double ${BlueSidely};
	}
	.CalendarDay__selected {
		background: ${BlueSidely};
		color: white;
		border: 1px solid ${BlueSidely};
	}
	.CalendarDay__selected:hover {
		background: ${BlueSidely};
		color: white;
	}
	.CalendarDay__hovered_span:hover,
	.CalendarDay__hovered_span {
		background: ${BlueSidely}33;
		color: ${SidelyBlack};
		border: 1px double ${BlueSidely};
	}
	.DateInput {
		margin: 0;
		padding: 0;
		background: #ffffff;
		position: relative;
		display: inline-block;
		width: 95px;
		height: 38px;
		vertical-align: middle
		border-radius: 10%;
		background-color:rgba(0, 0, 0, 0);
	}
	.DateInput_input {
		font-weight: 500;
		font-size: 11px;
		line-height: 19px;
		color: ${DarkGreySidely};
		width: 100%;
		padding: 11px 11px 9px;
		border-radius: 6px;
		background-color:rgba(0, 0, 0, 0);
		height: 38px;
		text-align: center;
	}
	.DateInput_input:hover,
	.DateInput_input__focused {
		background: rgba(0, 178, 255, 0.2);
		border-radius: 6px;
		border-bottom: 2px solid rgba(0, 178, 255, 0.2);
	}
	.DateRangePicker {
		margin: 0;
		${p => p.fillWidth ? 'width: 100%;' : ''}
	}
	.DateRangePickerInput_calendarIcon {
		height: 38px;
		padding: 0px;
	}
	.DateRangePickerInput__withBorder {
	  border-radius: 5px;
	  border: ${p => p.noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.125)'};
	  height: ${p => p.height ?? '38px'};
	  ${p => p.fillWidth ? `
		display: flex;
		align-items: center;
		justify-content: space-around;
	  ` : ''}
	}
	.DateRangePickerInput__disabled {
		background-color: ${DisabledColor};
		border-color: ${DisabledColor} !important;
	}
`;

const ModalStyle = styled(Style) <{ timeOnly?: boolean }>`
	min-width: ${p => p.timeOnly ? '0px' : '288px'};
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
	border-radius: 3px;
	background-color: white;
	display: flex;
`;

const TimeButton = styled.div<{ isActive: boolean, width?: string, noMargin?: boolean, numberOfMonths?: number }>`
	height: 35px;
	width: ${p => p.width ?? p.numberOfMonths === 1 ? '48%' : '23%'};
	border: 1px solid ${p => p.isActive ? BlueSidely : '#EBEBEB'};
	border-radius: 7px;
	cursor: pointer;
	display: inline-block;
	${p => p.noMargin
		? ''
		: `
		margin-left: 1%;
		margin-right: 1%;
		margin-bottom: 10px;
		`
}
	color: ${p => p.isActive ? BlueSidely : ''};
	flex-shrink: 0;
`;
const ButtonText = styled.div`
	font-size: 12px;
	font-weight: 400;
	width: fit-content;
	position: relative;
	top: 47%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

function checkClickOut(ref: React.RefObject<HTMLDivElement>, disableClickOut: boolean, clickOut?: () => void) {
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (!disableClickOut) {
				if ((ref.current != null) && !ref.current.contains(event.target)) {
					clickOut?.();
				}
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, disableClickOut, clickOut]);
}

function PickerModalWrapper(props: {
	children: React.ReactNode | undefined
	onClickOut?: () => void
	clickOutDisable?: boolean
	buttons?: React.ReactNode
	top?: string
	transform?: string
	noDate?: boolean
	noAbsolute?: boolean
}) {
	let resizeObserver: ResizeObserver | undefined = undefined;
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	checkClickOut(wrapperRef, props.clickOutDisable ?? false, props.onClickOut);
	const [height, setHeight] = React.useState(0);
	const calendarRef = React.useCallback((node: HTMLDivElement | null) => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
			resizeObserver = new ResizeObserver(e => setHeight(e[0].target.clientHeight));
			resizeObserver.observe(node);
		}
	}, []);

	return <div style={{ position: props.noAbsolute ? undefined : 'absolute', zIndex: '10', top: props.top, transform: props.transform }} ref={wrapperRef}>
		<ModalStyle timeOnly={props.noDate && props.buttons !== undefined}>
			<FlexDiv height='fit-content' flow='column' innerRef={calendarRef} >
				{props.children}
			</FlexDiv>
			{props.buttons &&
				<FlexDiv flow='column' width='90px' padding='0 5px' overflow='auto' maxHeight={props.noDate ? '250px' : height + 'px'} margin='10px 0' gap='5px'>
					{props.buttons}
				</FlexDiv>
			}
		</ModalStyle>
	</div>;

}

export function RangePickerModal(props: {
	start?: moment.Moment,
	end?: moment.Moment,
	future?: boolean,
	onDateChange?: (p: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => void,
	onClickOut?: (p: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => void,
	clickOutDisable?: boolean,
	saveButton?: boolean,
	top?: string,
	transform?: string,
	noAbsolute?: boolean,
	noDayButton?: boolean,
	defaultFocus?: ReactDates.FocusedInputShape
}) {
	moment.locale(localStorage.language);
	const [focusedInput, setFocusedInput] = React.useState<ReactDates.FocusedInputShape | null>(props.defaultFocus ?? null);
	const [dates, setDates] = useFunctionState<{ startDate: moment.Moment | null, endDate: moment.Moment | null }>(
		{ startDate: props.start ?? null, endDate: props.end ?? null },
		({ newValue }) => {
			!props.saveButton && props.onDateChange?.(newValue);
			return newValue;
		}
	);

	return <PickerModalWrapper {...props} onClickOut={() => props.onClickOut?.(dates)}>
		<>
			<DayPickerRangeController
				noBorder
				focusedInput={focusedInput ?? 'startDate'}
				onFocusChange={setFocusedInput}
				startDate={dates.startDate}
				endDate={dates.endDate}
				onDatesChange={setDates}
				numberOfMonths={2}
				// initialVisibleMonth={() => props.date ?? moment()}
				// isOutsideRange={(date) => props.future ? false : moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').diff(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'), 'day') > 0}
				hideKeyboardShortcutsPanel
				monthFormat="MMMM YYYY"
				isDayHighlighted={(date) => date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')}
				renderCalendarInfo={props.noDayButton ? undefined : RangeTimesButtons(dates.startDate, dates.endDate, setDates, props.future, false, 2)}
			/>
			{props.saveButton &&
				<DefaultButton
					disabled={dates.startDate === null || dates.endDate === null}
					onClick={() => (dates.startDate !== null && dates.endDate !== null) && props.onDateChange?.(dates)}
					width='50%'
				>
					<Translate id='save' />
				</DefaultButton>
			}
		</>
	</PickerModalWrapper>;
}

export function DayPickerModal(props: GenericToolbarFilterProps & {
	date?: moment.Moment,
	future?: boolean,
	onDateChange?: (date: moment.Moment) => void,
	onClickOut?: (date?: moment.Moment) => void,
	clickOutDisable?: boolean,
	noDayButton?: boolean,
	saveButton?: boolean,
	time?: boolean,
	top?: string,
	transform?: string,
	noDate?: boolean,
	noAbsolute?: boolean,
	search?: string
}) {
	moment.locale(localStorage.language);
	const [date, setDate] = useFunctionState<moment.Moment | undefined>(props.date, ({ newValue }) => {
		!props.saveButton && props.onDateChange?.(newValue as moment.Moment);
		return newValue;
	});
	const [focus, setFocus] = React.useState(true);
	const timeRef = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		timeRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}, [timeRef]);
	if (props.hidden) return <></>;

	function defaultTimesButtons(): JSX.Element {
		const oneDayButtons = [
			{ name: 'tool_bar.today', value: moment().startOf('day') }
		];
		if (props.future) {
			oneDayButtons.push({ name: 'tool_bar.tomorrow', value: moment().add(1, 'day').startOf('day') });
			oneDayButtons.push({ name: 'tool_bar.in_7_days', value: moment().add(7, 'day').startOf('day') });
		}
		return (
			<div style={{ padding: '0 5%' }}>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					{oneDayButtons.map((b, i) =>
						<TimeButton key={`DayPickerButtonTime[${i}]`} onClick={() => onDateChange(b.value)} isActive={b.value.format('YYYY-MM-DD') == date?.format('YYYY-MM-DD')} width='30%'>
							<ButtonText>
								<Translate id={b.name} />
							</ButtonText>
						</TimeButton>
					)}
				</div>
			</div>
		);
	}

	const onTimeChange = (time: string) => setDate(moment(`${(date ?? moment()).format('YYYY-MM-DD')} ${time}`));
	const onDateChange = (newDate: moment.Moment) => setDate(moment(`${newDate.format('YYYY-MM-DD')} ${date?.format('HH:mm') ?? '12:00'}`));

	return <PickerModalWrapper {...props}
		onClickOut={() => props.onClickOut?.(date)}
		buttons={props.time ? <HoursTimesButtons date={date} onTimeChange={onTimeChange} search={props.search} timeRef={timeRef} /> : undefined}
	>
		<>
			{!props.noDate && <DayPickerSingleDateController
				noBorder
				date={date ?? null}
				onDateChange={onDateChange}
				focused={focus}
				onFocusChange={({ focused }) => setFocus(focused ?? false)}
				numberOfMonths={1}
				initialVisibleMonth={() => props.date ?? moment()}
				isOutsideRange={(date) => props.future ? false : moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').diff(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'), 'day') > 0}
				hideKeyboardShortcutsPanel
				monthFormat="MMMM YYYY"
				isDayHighlighted={(date) => date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')}
				calendarInfoPosition='bottom'
				renderCalendarInfo={props.noDayButton ? undefined : defaultTimesButtons}
			/>}
			{props.saveButton &&
				<DefaultButton
					disabled={date == null}
					onClick={() => (date != null) && props.onDateChange?.(date)}
					width='50%'
				>
					<Translate id='save' />
				</DefaultButton>
			}
		</>
	</PickerModalWrapper>;
}

// eslint-disable-next-line react/display-name
function HoursTimesButtons(props: { date: moment.Moment | undefined, onTimeChange: (time: string) => void, search?: string, timeRef?}): JSX.Element {
	const { date, onTimeChange, search, timeRef } = props;
	const times: string[] = [...Array(24)].reduce((acc: string[], _, i) => {
		const label0 = i > 9 ? `${i}:00` : `0${i}:00`;
		const label15 = i > 9 ? `${i}:15` : `0${i}:15`;
		const label30 = i > 9 ? `${i}:30` : `0${i}:30`;
		const label45 = i > 9 ? `${i}:45` : `0${i}:45`;
		return [...acc, label0, label15, label30, label45];
	}, []);

	return <>
		{times.filter(time => !search || time.includes(search)).map(time =>
			<TimeButton
				key={`DayPickerButtonTime[${time}]`}
				onClick={() => onTimeChange(time)}
				isActive={date?.format('HH:mm') == time}
				width='100%'
				noMargin
				innerRef={date?.format('HH:mm') == time ? timeRef : undefined}
			>
				<ButtonText>
					{time}
				</ButtonText>
			</TimeButton>)}
	</>;
}

const RangeTimesButtons = (
	start: moment.Moment | null,
	end: moment.Moment | null,
	onDatesChange: (p: OnDateChangeType) => void,
	future: boolean | undefined,
	oneDay: boolean | undefined,
	numberOfMonths: number | undefined
	// eslint-disable-next-line react/display-name
) => (): JSX.Element => {
	// Tomorow and Yesterday disabled
	const oneDayButtons = [
		{ name: 'tool_bar.yesterday', value: { startDate: moment().subtract(1, 'day').startOf('day'), endDate: moment().subtract(1, 'day').endOf('day') } },
		{ name: 'tool_bar.today', value: { startDate: moment().startOf('day'), endDate: moment().endOf('day') } }
	];
	if (future) {
		oneDayButtons.push({ name: 'tool_bar.tomorrow', value: { startDate: moment().add(1, 'day').startOf('day'), endDate: moment().add(1, 'day').endOf('day') } });
	}
	const buttonsSettings = [
		{ name: 'tool_bar.this_week', value: { startDate: moment().startOf('week'), endDate: future ? moment().endOf('week') : moment() } },
		{ name: 'tool_bar.this_month', value: { startDate: moment().startOf('month'), endDate: future ? moment().endOf('month') : moment() } },
		{ name: 'tool_bar.this_quarter', value: { startDate: moment().startOf('quarter'), endDate: future ? moment().endOf('quarter') : moment() } },
		{ name: 'tool_bar.this_year', value: { startDate: moment().startOf('year'), endDate: future ? moment().endOf('year') : moment() } },
		{ name: 'tool_bar.last_week', value: { startDate: moment().subtract(1, 'week').startOf('week'), endDate: moment().subtract(1, 'week').endOf('week') } },
		{ name: 'tool_bar.last_month', value: { startDate: moment().subtract(1, 'month').startOf('month'), endDate: moment().subtract(1, 'month').endOf('month') } },
		{ name: 'tool_bar.last_quarter', value: { startDate: moment().subtract(1, 'quarter').startOf('quarter'), endDate: moment().subtract(1, 'quarter').endOf('quarter') } },
		{ name: 'tool_bar.last_year', value: { startDate: moment().subtract(1, 'year').startOf('year'), endDate: moment().subtract(1, 'year').endOf('year') } }
	];
	return (
		<div style={{ padding: '0 5%' }}>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				{oneDay && oneDayButtons.map((b, i) =>
					<TimeButton numberOfMonths={numberOfMonths} key={`DatePickerButtonTime[${i}]`} onClick={() => onDatesChange(b.value)} isActive={b.value.startDate.format('YYYY-MM-DD') == start?.format('YYYY-MM-DD') && b.value.endDate.format('YYYY-MM-DD') == end?.format('YYYY-MM-DD')}>
						<ButtonText>
							<Translate id={b.name} />
						</ButtonText>
					</TimeButton>
				)}
			</div>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{buttonsSettings.map((s, i) => {
					return (
						<TimeButton numberOfMonths={numberOfMonths} key={`DatePickerButtonSetting[${i}]`} onClick={() => onDatesChange(s.value)} isActive={s.value.startDate.format('YYYY-MM-DD') == start?.format('YYYY-MM-DD') && s.value.endDate.format('YYYY-MM-DD') == end?.format('YYYY-MM-DD')}>
							<ButtonText>
								<Translate id={s.name} />
							</ButtonText>
						</TimeButton>
					);
				})}
			</div>
		</div>
	);
};

export type OnDateChangeType = {
	startDate: moment.Moment | null,
	endDate: moment.Moment | null
};

export function RawDatePicker(props: {
	onDatesChange: (p: OnDateChangeType) => void,
	future?: boolean,
	oneDay?: boolean,
	start: moment.Moment | null,
	end: moment.Moment | null,
	noIcon?: boolean,
	noBorder?: boolean,
	button?: 'range' | 'hours',
	numberOfMonths?: number,
	height?: string,
	fillWidth?: boolean,
	zIndex?: string,
	onTimeChange?: (p: OnDateChangeType) => void,
	readOnly?: boolean
}) {
	const [focusedInput, setfocusedInput] = React.useState<ReactDates.FocusedInputShape | null>(null);
	const timeRef = React.useRef<HTMLDivElement>();
	React.useEffect(() => {
		timeRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}, [focusedInput]);
	const setRef = React.useCallback((node: HTMLDivElement) => {
		if (node) {
			if (!timeRef.current) {
				node.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
			timeRef.current = node;
		}
	}, []);

	let buttons: (() => JSX.Element) | undefined = undefined;
	if (props.button === 'range') {
		buttons = RangeTimesButtons(props.start, props.end, props.onDatesChange, props.future, props.oneDay, props.numberOfMonths);
	} else if (props.button === 'hours') {
		buttons = () => {
			let date: moment.Moment | undefined = undefined;
			if (focusedInput === 'startDate') {
				date = props.start ?? undefined;
			} else if (focusedInput === 'endDate') {
				date = props.end ?? undefined;
			}
			return <FlexDiv flow='column' width='70px' padding='0 5px' overflow='auto' maxHeight='300px' margin='5px 0' gap='5px'>
				<HoursTimesButtons date={date} onTimeChange={time => {
					if (!focusedInput) return;
					else if (focusedInput === 'startDate') {
						props.onTimeChange?.({
							startDate: moment(`${(props.start ?? moment()).format('YYYY-MM-DD')} ${time}`),
							endDate: props.end
						});
					} else {
						props.onTimeChange?.({
							startDate: props.start,
							endDate: moment(`${(props.end ?? moment()).format('YYYY-MM-DD')} ${time}`),
						});
					}
				}}
				timeRef={setRef}
				/>
			</FlexDiv>;
		};
	}

	return (
		(<Style noBorder={props.noBorder} height={props.height} fillWidth={props.fillWidth} zIndex={props.zIndex}>
			<DateRangePicker
				disabled={props.readOnly}
				startDate={props.start}
				startDateId="your_unique_start_date_id"
				endDate={props.end}
				endDateId="your_unique_end_date_id"
				onDatesChange={props.onDatesChange}
				isOutsideRange={(date) => props.future ? false : moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').diff(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'), 'day') > 0}
				focusedInput={focusedInput}
				onFocusChange={focusedInput => setfocusedInput(focusedInput)}
				customInputIcon={props.noIcon ? undefined : <img src={calendarImage} style={{ width: '20px' }} />}
				customArrowIcon={<img src={arrow_right_date_picker} />}
				hideKeyboardShortcutsPanel
				monthFormat="MMMM YYYY"
				startDatePlaceholderText={translateToString('startdate')}
				endDatePlaceholderText={translateToString('end date')}
				isDayHighlighted={(date) => date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')}
				calendarInfoPosition={props.button === 'hours' ? 'after' : 'bottom'}
				renderCalendarInfo={buttons}
				minimumNights={props.oneDay ? 0 : 1}
				numberOfMonths={props.numberOfMonths ?? 2}
				
			/>
		</Style>)
	);
}

export default function DatePicker(props: GenericToolbarFilterProps & {
	atomId: number
	future?: boolean
	oneDay?: boolean
	defaultStart?: moment.Moment
	defaultEnd?: moment.Moment,
	numberOfMonths?: number
}) {
	moment.locale(localStorage.language);
	const { defaultStart, defaultEnd } = props;
	const [pickedDates, setPickedDates] = useRecoilState(ADatePicker);
	React.useEffect(() => {
		if (pickedDates == undefined) {
			let end = defaultEnd;
			if (!end && props.future) end = moment().endOf('day');
			else if (!end) end = moment().set({ hour: moment().get('hours'), minute: moment().get('minutes'), seconds: moment().get('minutes') });
			setPickedDates([defaultStart ?? moment().subtract(3, 'month').startOf('day'), end]);
		}
	}, []);
	const [globalStartDate, setStartDate] = React.useState<moment.Moment | null>(pickedDates?.[0] ?? defaultStart ?? moment().subtract(3, 'month').startOf('day'));
	const [globalEndDate, setEndDate] = React.useState<moment.Moment | null>(pickedDates?.[1] ?? defaultEnd ?? moment().endOf('day'));

	const onDatesChange = ({ startDate, endDate }: OnDateChangeType) => {
		startDate?.startOf('day');
		if (props.future) endDate?.endOf('day');
		else endDate?.set({ hour: moment().get('hours'), minute: moment().get('minutes'), seconds: moment().get('minutes') });
		if (startDate !== globalStartDate || endDate !== globalEndDate) {
			setPickedDates([startDate ?? undefined, endDate ?? undefined]);
			setStartDate(startDate);
			setEndDate(endDate);
		}
	};
	React.useEffect(() => {
		if (pickedDates) {
			setStartDate(pickedDates[0] ?? defaultStart ?? moment().subtract(3, 'month').startOf('day'));
			setEndDate(pickedDates[1] ?? defaultEnd ?? moment().endOf('day'));
		}
	}, [pickedDates]);
	if (props.hidden) return <></>;
	return <RawDatePicker onDatesChange={onDatesChange} start={globalStartDate} end={globalEndDate} future={props.future} oneDay={props.oneDay} button='range' fillWidth={props.fullScreen} numberOfMonths={props.numberOfMonths}/>;
}
