import { getLocalStorage, setLocalStorage } from '../../helpers/localStorage';

const FORM_LOCAL_STORAGE_PATH = 'current-form';
export type LocalStorageForm = {
	id?: number,
	name: string,
	lib?: string,
	screens?: string
};

type LocalStorageFormUpdate = {
	name?: string,
	lib?: string,
	screens?: string
}

export type LocalStorageFormWrapper = {
	[key: number]: LocalStorageForm,
	newForm?: LocalStorageForm
}

function getLocalForm(): LocalStorageFormWrapper | undefined {
	return getLocalStorage(FORM_LOCAL_STORAGE_PATH);
}
export function getLocalFormById(id?: number): LocalStorageForm | undefined {
	return getLocalFormWrapperById(id)?.[0];
}


function getLocalFormWrapperById(id?: number): [LocalStorageForm | undefined, LocalStorageFormWrapper | undefined] {
	const form = getLocalStorage<LocalStorageFormWrapper>(FORM_LOCAL_STORAGE_PATH);
	if (!id) return [form?.newForm, form];
	return [form?.[id], form];	
}

export function setLocalForm(form: LocalStorageForm, id?: number) {
	const actualForm = getLocalForm() ?? {};
	if (id === undefined) return setLocalStorage(FORM_LOCAL_STORAGE_PATH, { ...actualForm, newForm: form });
	actualForm[id] = form;
	setLocalStorage(FORM_LOCAL_STORAGE_PATH, { ...actualForm });
}

export function updateLocalForm(update: LocalStorageFormUpdate, id?: number) {
	const [form, wrapper] = getLocalFormWrapperById(id);
	if (form) {
		if (update.name) form.name = update.name;
		if (update.lib) form.lib = update.lib;
		if (update.screens) form.screens = update.screens;
	}
	setLocalStorage(FORM_LOCAL_STORAGE_PATH, { ...wrapper });
}

export function removeLocalForm(id?: number) {
	const actualForm = getLocalForm() ?? {};
	if (id === undefined) delete actualForm.newForm;
	else delete actualForm[id];
	setLocalStorage(FORM_LOCAL_STORAGE_PATH, { ...actualForm });
}