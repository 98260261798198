import edit from 'images/icon/edit.png';
import trash_black from 'images/ui_icon/trash_black.svg';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AProfile } from '../../atoms/global/users';
import { DbView, ViewKey } from '../../atoms/global/views';
import { FlexDiv, HideTrash } from '../../containers_v2/products/style';
import { Open } from '../../styles/global/css/Open';
import { BlueSidely, BorderColor, FilterBlue, FilterRed, GreySidely2, LightGreySidely2, SidelyBlack } from '../../styles/global/css/Utils';
import { Translate, translateToString } from '../../styles/global/translate';
import { checkClickOut } from '../dropdown/Dropdown';
import { OptionText } from '../dropdown/style/Style';
import InputSearch from '../input/InputSearch';
import Popup from '../popup/Popup';
import SidelyViews from './SidelyViews.json';

type PropsRestriction = {
	name: string
}

type ViewPickerType = 'companies' | 'contacts' | 'reports'

const GridContainer = styled.div`
	display: grid;
	grid-template-rows: 40px 1fr 20px;
	grid-row-gap: 10px;
	height: 100%;
`;

const ButtonContainer = styled(FlexDiv)<{ noBorder?: boolean }>`
	height: 100%;
	${p => p.noBorder ? '' : `border-right: 1px solid ${BorderColor};`}
	gap: 10px;
	padding: 0 10px;
	max-width: 200px;
	color: ${SidelyBlack};
	font-weight: 600;
	font-size: 14px;
`;

const OptionViewBlock = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
    ${HideTrash}
	&:hover {
		background-color: ${LightGreySidely2};
	}
`;

const SelectedViewSpan = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const VuesContainter = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
	height: 100%;
	overflow-y: auto;
`;

const VueContainer = styled.div<{ index: number, isReports?: boolean }>`
	height: 100%;
	padding: 5px 10px;
	border-left: ${p => p.index === 1 && !p.isReports ? '2px solid ' + BorderColor : 'none'};
	border-right: ${p => p.index === 1 || p.isReports ? '2px solid ' + BorderColor : 'none'};
	border-bottom: 2px solid ${BorderColor};
	overflow-y: auto;
	@media (max-width: 1015px) {
		border-right: none;
	}
	@media (max-width: 685px) {
		border-left: none;
		height: auto;
		overflow-y: visible;
	}
`;

const VueTitle = styled.div`
	font-size: 14px;
	font-weight: 600;
	padding: 10px;
`;

const NewViewButton = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	padding: 5px 10px;
	color: ${BlueSidely};
	font-size: 13px;
	&::before {
		content: '+';
		font-size: 15px;
	}
`;

const IconImage = styled.img<{ hoverRed?: boolean }>`
	height: 15px;
	cursor: pointer;
	&:hover {
		filter: ${p => p.hoverRed ? FilterRed : FilterBlue};
	}
`;

export default function ViewPicker<T extends PropsRestriction>(props: {
	views: DbView<T>[],
	selectedView?: DbView<T>
	onViewChange?: (view: DbView<T> | undefined) => void
	onDelete?: (option: DbView<T>) => void,
	onCreate?: () => void,
	onEdit?: () => void,
	setEditedView?: (view: DbView<T>) => void,
	noBorder?: boolean,
	type?: ViewKey
}): JSX.Element {
	const { views, selectedView, onViewChange, onDelete, onCreate, onEdit } = props;
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const [offset, setOffset] = React.useState<DOMRect | null>(null);
	const [offsetIsSet, setOffsetIsSet] = React.useState<boolean>(false);
	const [debouncedOffset, setDebouncedOffset] = React.useState<boolean>(false);
	const [inputValue, setInputValue] = React.useState<string>('');
	const [debouncedInputValue, setDebouncedInputValue] = React.useState<string>('');
	const _aProfile = useRecoilValue(AProfile);
	const alphabetViews = React.useMemo(() => {
		const parsedViews = JSON.parse(JSON.stringify(SidelyViews).replaceAll('"$current_user_id"', _aProfile.id.toString()));
		const sidelyViews = parsedViews.views.filter(v => v.type === props.type);
		sidelyViews.forEach(v => v.data.name = translateToString(v.data.name));
		const alphabetViews = [...views].concat(sidelyViews).sort((a, b) => a.data.name.toLowerCase().localeCompare(b.data.name.toLowerCase()));
		return alphabetViews;
	}, [views]);
	const [searchedViews, setSearchedViews] = React.useState<DbView<T>[]>(alphabetViews);
	const privateViews = searchedViews.filter(v => v.id > 0 && !v.public);
	const publicViews = searchedViews.filter(v => v.id > 0 && v.public);
	const sidelyViews = searchedViews.filter(v => v.id < 0);
	const defaultViewName = props.type === 'companies' ? 'views.view_names.all_companies' : props.type === 'contacts' ? 'views.view_names.all_contacts' : 'views.view_names.all_data';
	const isReports = props.type === 'reports';

	checkClickOut(wrapperRef, setOpen);
	React.useEffect(() => {
		const timeout = setTimeout(() => setDebouncedOffset(offsetIsSet), 500);
		return () => clearTimeout(timeout);
	}, [offsetIsSet]);

	React.useEffect(() => {
		if (!wrapperRef.current) return;
		const bounding = wrapperRef.current.getBoundingClientRect();
		if (bounding.left != 0) {
			setOffset(bounding);
			setOffsetIsSet(true);
		}
		else
			setOffsetIsSet(!offsetIsSet);
	}, [debouncedOffset]);

	React.useEffect(() => {
		const timeout = setTimeout(() => setDebouncedInputValue(inputValue), 300);
		return () => clearTimeout(timeout);
	}, [inputValue, 300]);

	React.useEffect(() => {
		setSearchedViews(alphabetViews.filter(v => v.data.name.toLowerCase().includes(debouncedInputValue.toLowerCase())));
	}, [debouncedInputValue]);

	React.useEffect(() => {
		setSearchedViews(alphabetViews);
	}, [views]);

	return <div ref={wrapperRef}>
		<ButtonContainer noBorder={props.noBorder} cursor='pointer' onClick={() => setOpen(!isOpen)}><SelectedViewSpan>{selectedView ? selectedView.data.name : translateToString(defaultViewName)}</SelectedViewSpan><Open isOpen={isOpen} height={7} width={12}/></ButtonContainer>
		<Popup isOpen={isOpen && offset?.left != 0} disableOutClick onClickOut={() => setOpen(false)}
			popupStyle={
				{
					zIndex: 10,
					width: isReports ? '50vw' : '70vw',
					height: 'auto',
					minWidth: '200px',
					minHeight: '450px',
					overflow: 'auto',
					left: offset ? (offset.left + 5).toString() + 'px' : undefined,
					top: offset ? (offset.top + 30).toString() + 'px' : undefined,
					borderRadius: '5px',
					noTransition: true,
					boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.09)'
				}}
			content={
				<>
					<GridContainer>
						<FlexDiv padding='10px 10px 0px 10px'>
							<InputSearch name='search-views' type='text' value={inputValue} onChange={(value) => setInputValue(value)} placeholder={translateToString('views.search_views')} inputStyle={
								{
									backgroundColor: LightGreySidely2,
									border: 'none',
									borderRadius: '7px',
									width: '240px',
									padding: '5px 10px',
									placeholderColor: GreySidely2,
								}
							}/>
						</FlexDiv>
						<VuesContainter>
							{props.type != 'reports' && <VueContainer index={0}><FlexDiv flow='column' align='stretch'>
								<VueTitle><Translate id='views.sidely_views'/> ({sidelyViews.length + 1})</VueTitle>
								<FlexDiv flow='column' align='stretch' gap='6px'>
									<OptionViewBlock onClick={() => {onViewChange?.(undefined); setOpen(false);}}>
										<OptionText ellipsis>{translateToString(defaultViewName)}</OptionText>
									</OptionViewBlock>
									{sidelyViews.map((v) => <OptionViewBlock key={v.id} onClick={() => {onViewChange?.(v); setOpen(false);}}>
										<OptionText ellipsis>{v.data.name}</OptionText>
									</OptionViewBlock>)}
								</FlexDiv>
							</FlexDiv></VueContainer>}
							<VueContainer index={1} isReports={isReports}><FlexDiv flow='column' align='stretch'>
								<VueTitle><Translate id='views.my_views'/> ({privateViews.length + (isReports ? 1 : 0)})</VueTitle>
								<FlexDiv flow='column' align='stretch' gap='6px'>
									{isReports && <OptionViewBlock onClick={() => {onViewChange?.(undefined); setOpen(false);}}>
										<OptionText ellipsis>{translateToString(defaultViewName)}</OptionText>
									</OptionViewBlock>}
									{privateViews.map((v) => <OptionViewBlock key={v.id} onClick={() => {onViewChange?.(v); setOpen(false);}}>
										<OptionText ellipsis>{v.data.name}</OptionText>
										{onDelete && onEdit && <FlexDiv gap='2px'>
											<IconImage src={edit} className='trash' onClick={e => {
												e.stopPropagation();
												props.setEditedView?.(v);
												onEdit();
												setOpen(false);
											}}/>
											<IconImage src={trash_black} hoverRed className='trash' onClick={e => {
												e.stopPropagation();
												onDelete(v);
											}}/></FlexDiv>}
									</OptionViewBlock>)}
								</FlexDiv>
							</FlexDiv></VueContainer>
							<VueContainer index={2}><VueTitle><Translate id='views.admin_views'/> ({publicViews.length})</VueTitle>
								{publicViews.map((v) => <OptionViewBlock key={v.id} onClick={() => {onViewChange?.(v); setOpen(false);}}>
									<OptionText ellipsis>{v.data.name}</OptionText>
									{onDelete && 
										<IconImage src={trash_black} hoverRed className='trash' onClick={e => {
											e.stopPropagation();
											onDelete(v);
										}}/>}
								</OptionViewBlock>)}
							</VueContainer>
						</VuesContainter>
						{ onCreate && <NewViewButton onClick={() => {onCreate(); setOpen(false);}}><Translate id='views.create_view'/></NewViewButton>}
					</GridContainer>
				</>
			}></Popup>
	</div>;
}
