import * as React from 'react';
import { LoadingStateEnum } from '../import/model';
import { Field } from './actions';

// eslint-disable-next-line react/display-name
const FormEditor = React.forwardRef((props: {
	setLoadingState: React.Dispatch<React.SetStateAction<LoadingStateEnum>>,
	setErrors: React.Dispatch<React.SetStateAction<object[][]>>,
	screens: string,
	setScreens: React.Dispatch<React.SetStateAction<string>>,
	lib: string,
	setLib: React.Dispatch<React.SetStateAction<string>>,
}, parentRef) => {
	const [obj, setObj] = React.useState<object>();
	const [screenIndexList, setScreenIndexList] = React.useState<number[]>([0]);
	React.useEffect(() => {
		props.setLoadingState(LoadingStateEnum.LOADED);
	}, []);

	React.useImperativeHandle(parentRef, () => ({
		onFieldClicked(field: Field) {
			console.log(field);
		}
	}), []);

	React.useEffect(() => {
		try {
			setObj(JSON.parse(props.screens));
		} catch (e) {
			console.log(e);
		}
	}, [props.screens]);
	console.log(obj);
	if (!obj) return <></>;
	console.log(obj[screenIndexList[screenIndexList.length - 1]]);

	
	return <></>;
});

export default FormEditor;