import { FieldType } from 'bindings/forms/FieldType';
import * as React from 'react';
import { DateAccessor } from '../client-companies/data/CompanyColumns';
import { Translate } from '../../styles/global/translate';
import { Metadata, getClientCompanyByUuid } from './actions';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';
import { BlackContainer, Close, ImageSwapper, PhotoCounter } from '../gallery/style';
import close from 'images/icons/orders/close.svg';
import { AProducts } from '../../atoms/product';
import { LoadingStateEnum } from '../import/model';
import { Loader } from '../../styles/global/css/GlobalLoader';
import { getPhotoFromHash } from '../gallery_v2/actions';
import PageLoader, { PageLoaderColors } from '../../components_v2/pageLoader/PageLoader';
import styled from 'styled-components';

function CompanyDisplayer(props: { value: string }) {
	const [company, setCompany] = React.useState<string>();
	React.useEffect(() => {
		getClientCompanyByUuid(props.value).then(e => setCompany(e.name));
	}, []);
	return <>{company}</>;
}

function ImageDisplayer(props: {value: string | string[]}) {
	const [modal, setModal] = React.useState<number>();
	const [images, setImages] = React.useState<{[key: string]: { original?: string, thumb?: string }}>({});
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();

	React.useEffect(() => {
		async function getImageAndHash(image: string, newImages: {[key: string]: { original?: string, thumb?: string }}) {
			let index = image.indexOf('thumbs/');
			if (index === 0) {
				const webpIndex = image.lastIndexOf('.webp');
				const hash = image.slice(index + 7, webpIndex);
				if (!newImages[hash]) newImages[hash] = {};
				if (newImages[hash].thumb) return;
				const { original, thumb } = await getPhotoFromHash({ hash: hash + '.webp', thumb: true, original: !newImages[hash].original });
				if (original) newImages[hash].original = original;
				if (thumb) newImages[hash].thumb = thumb;
				return;
			} else if (index !== -1) {
				const webpIndex = image.lastIndexOf('.webp');
				const hash = image.slice(index + 7, webpIndex);
				if (!newImages[hash]) newImages[hash] = {};
				newImages[hash].thumb = image;
				return;
			}
			index = image.indexOf('originals/');
			if (index === 0) {
				const webpIndex = image.lastIndexOf('.webp');
				const hash = image.slice(index + 7, webpIndex);
				if (!newImages[hash]) newImages[hash] = {};
				if (newImages[hash].original) return;
				const { original, thumb } = await getPhotoFromHash({ hash: hash + '.webp', thumb: !newImages[hash].thumb, original: true });
				if (original) newImages[hash].original = original;
				if (thumb) newImages[hash].thumb = thumb;
				return;
			} else if (index !== -1) {
				const webpIndex = image.lastIndexOf('.webp');
				const hash = image.slice(index + 10, webpIndex);
				if (!newImages[hash]) newImages[hash] = {};
				newImages[hash].original = image;
				return;
			}
			return;
		}

		const api = async() => {
			const newImages: {[key: string]: { original?: string, thumb?: string }} = {};
			setLoadingState(LoadingStateEnum.LOADING);
			let newValue;
			try {
				if (typeof props.value == 'string') newValue = JSON.parse(props.value);
				else newValue = props.value;
			} catch (_e) {
				if (typeof props.value == 'string' && props.value.includes(',')) newValue = props.value.split(',');
				else newValue = props.value;
			}
			if (Array.isArray(newValue)) {
				for (const image of newValue) {
					await getImageAndHash(image, newImages);
				}
			} else {
				await getImageAndHash(newValue, newImages);
			}
			setImages(newImages);
			setLoadingState(undefined);
		};
		api();
	}, [JSON.stringify(props.value)]);

	const mapped = Object.values(images);
	return <>
		{modal !== undefined && <OriginalImageDisplayer images={mapped.map(i => i.original ?? i.thumb ?? '')} onClickOut={() => setModal(undefined)}/>}
		{loadingState == LoadingStateEnum.LOADING && <Loader width='30px'/>}
		{mapped.length > 0 && <CardeImageDisplayerWrapper><CardImageDisplayer images={mapped.map((i) => i.thumb ?? i.original ?? '')} onClick={() => setModal(0)}/></CardeImageDisplayerWrapper>}
	</>;
}

function OriginalImageDisplayer(props: {images: string[], onClickOut: () => void, defaultIndex?: number}) {
	const [index, setIndex] = React.useState<number>(props.defaultIndex ?? 0);

	const changeIndex = (newIndex: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent> | KeyboardEvent) => {
		e.stopPropagation();
		if (newIndex < 0 || newIndex > props.images.length - 1) return;
		setIndex(newIndex);
	};

	function keyHandler(event: KeyboardEvent) {
		switch (event.key) {
			case 'Escape':
				props.onClickOut();
				return;
			case 'ArrowLeft':
				changeIndex(index - 1)(event);
				return;
			case 'ArrowRight':
				changeIndex(index + 1)(event);
		}
	}

	React.useEffect(() => {
		document.addEventListener('keydown', keyHandler, false);
		return () => {
			document.removeEventListener('keydown', keyHandler, false);
		};
	}, [index]);

	return <BlackContainer onClick={props.onClickOut}>
		<div style={{ height: '90%', display: 'flex', justifyContent: 'center' }}>
			{props.images.length > 1 && <ImageSwapper onClick={changeIndex(index - 1)} />}
			<img
				key={`IMAGE${props.images[index]}`}
				height='100%'
				src={props.images[index]}
				alt="" style={{ zIndex: 1, maxWidth: '90%', objectFit: 'cover', minWidth: 50 }} onClick={(e) => e.stopPropagation()}/>
			{props.images.length > 1 && <ImageSwapper right onClick={changeIndex(index + 1)} />}
		</div>
		<div style={{ position: 'absolute' }}>
			<PageLoader color={PageLoaderColors.White} size={50} />
		</div>
		<Close src={close} onClick={props.onClickOut} />
	</BlackContainer>;

}

const CardeImageDisplayerWrapper = styled.div`
	border-radius: 5px;
	height: 200px;
	width: 200px;
	overflow: hidden;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
`;

// TODO USE THIS FOR GALLERY
const CardImageDisplayerDiv = styled.div`
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	height: 100%;
	width: 100%;
	overflow: hidden;
	column-gap: 4px;
	row-gap: 4px;
	position: relative;
	cursor: pointer;
`;
const gridPosition = [
	[],
	['1 / 1 / 3 / 3'],
	['1 / 1 / 3 / 2', '1 / 2 / 3 / 2'],
	['1 / 1 / 2 / 2', '2 / 1 / 3 / 2', '1 / 2 / 3 / 2'],
	['1 / 1 / 2 / 2', '2 / 1 / 3 / 2', '1 / 2 / 2 / 2', '2 / 2 / 2 / 2']
];
const CardImageDisplayerContainer = styled.div<{nb: number, index: number}>`
	overflow: hidden;
	grid-area: ${({ index, nb }) => gridPosition[nb][index]};
`;

const CardImageDisplayerImage = styled.img`
	object-fit: cover;
	height: 100%;
	width: 100%;
`;

export function CardImageDisplayer(props: {images: string[], onClick: () => void}) {
	const t = props.images.slice(0, 4);
	const nb = t.length;
	return <CardImageDisplayerDiv onClick={props.onClick}>
		{t.map((image, index) => <CardImageDisplayerContainer key={`IMAGE[${index}]`} nb={nb} index={index}><CardImageDisplayerImage src={image}/></CardImageDisplayerContainer>)}
		<PhotoCounter len={props.images.length}><div style={{ marginTop: 1 }}>{props.images.length}</div></PhotoCounter>
	</CardImageDisplayerDiv>;
}

export function FieldDisplayer(props: { field_type: FieldType, value, metadata?: Metadata, fieldId: number }) {
	const users = useRecoilValue(AUsers);
	switch (props.field_type) {
		case 'Text':
		case 'Number':
			return <>{props.value}</>;
		case 'Date':
			return <DateAccessor date={props.value} />;
		case 'Boolean': {
			let res;
			if (typeof props.value == 'boolean') res = props.value;
			else if (typeof props.value == 'string') res = JSON.parse(props.value);
			else return <></>;
			return <><Translate id={res ? 'yes' : 'no'} /></>;
		}
		case 'Company':
			return <CompanyDisplayer value={props.value}/>;
		case 'Url':
			return <><a href={props.value.toString()}>{props.value}</a></>;
		case 'Email':
			return <>{props.value}</>;
		case 'Phone':
			return <>{props.value}</>;
		case 'Address':
			return <>{props.value}</>;
		case 'Location':
			return <>{props.value}</>;
		case 'Integer':
			return <>{props.value}</>;
		case 'TextArea':
			return <>{props.value}</>;
		case 'Select':
			return <>{props.value}</>;
		case 'CheckIn': {
			const check_in = props.value ? true : false;
			return <>{ <Translate id={check_in ? 'true' : 'false'} />}</>;

		}
		case 'Multiselect': {
			if (Array.isArray(props.value)) {
				return <>{props.value.map((e: string) => <>{e}<br /></>)}</>;
			}
			break;
		}
		case 'User':
			return <>{users.find(e => e.id.toString() == props.value?.toString())?.name}</>;
		case 'Image': return <ImageDisplayer value={props.value} />;
		case 'Signature': return <SignatureField value={props.value} />;
		default:
			return <><Translate id={'display_coming_soon'} /></>;
	}

	return <></>;
}

function SignatureField(props: { value: string }) {
	return <CardeImageDisplayerWrapper>
		<div dangerouslySetInnerHTML={{ __html: props.value }} />
	</CardeImageDisplayerWrapper>;
}

export function ProductDisplayer(props: { uuid: string }) {
	const products = useRecoilValue(AProducts);

	return <>{products.find(p => p.uuid === props.uuid)?.name}</>;
}