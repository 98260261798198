import * as React from 'react';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { Translate, translateToNode, translateToString } from '../../styles/global/translate';
import { FlexDiv } from '../products/style';
import styled from 'styled-components';
import { BlueSidely, LightGreySidely2, RedSidely, DarkGreySidely2 } from '../../styles/global/css/Utils';
import { deleteField, postForm } from './actions';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { LoadingStateEnum } from '../import/model';
import { useHistory } from 'react-router';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { Open } from '../../styles/global/css/Open';
import Add from '../../components_v2/add/Add';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import { ButtonStyle } from '../../components_v2/popup/PopupCreation';
import { ModalState } from '../products/model';
import PopupField, { ALL_FIELD_TYPES } from './PopupField';
import { FieldType } from 'bindings/forms/FieldType';
import AdvancedFormEditor from './advancedFormEditor'; 
import FormEditor from './formEditor'; 
import { useFunctionState, useKeyPress } from '../../utils/customHooks';
import { getLocalFormById, removeLocalForm, updateLocalForm } from './localForm';
import { getUrlParameterByName } from '../../components_v2/utils';
import bugSvg from 'images/bug.svg';
import dropdownSvg from 'images/dropdown.svg';
import useAlert from '../alert/UseAlert';
import WarningImage from 'images/icons/import/warning.svg';
import ErrorImage from 'images/icons/import/error.svg';
import { AlertRes } from '../alert/AlertProvider';
import { Field, getFields } from '../../atoms/forms';
import SettingIcon from 'images/icon/setting.png';
import DeleteIcon from 'images/icon/delete.png';
import InputSearch from '../../components_v2/input/InputSearch';


const MAX_WARNING_SEVERITY = 4; 

export type MonacoError = {
	code?: string,
	message: string,
	endColumn: number,
	endLineNumber: number,
	owner: string
	relatedInformation?: string
	resource?: object,
	severity: number,
	source: string,
	startColumn: number
	startLineNumber: number
	tags?: string
}

const EditorContainer = styled.div<{ white?: boolean }>`
	height: 100%;
	width: 100%;
	flex-shrink: 1;
	background: ${p => p.white ? 'white' : LightGreySidely2};
`;

const LeftModalSideBar = styled.div`
	width: 50px;
	height: 100%;
	border-right: 1px solid white;
	flex-shrink: 0;
`;

export const LeftModalContent = styled.div`
	display: flex;
	flex-flow: column;
	padding: 5px 0;
	overflow: auto;
	width: 100%;
`;

const LeftModalSideBarImg = styled.div<{ src: string, selected?: boolean, size?: string }>`
	height: 50px;
	width: 50px;
	background-image: url(${({ src }) => src});
    background-position: center;
	background-size: ${({ size }) => size ?? 'calc(100% - 5px)'};
    background-repeat: no-repeat;
	cursor: pointer;
	&:before {
		content: '';
		position: absolute;
		width: 3px;
		height: 50px;
		${p => p.selected ? `
			background-color: ${BlueSidely};
		` : ''}
		
	}
`;


const LeftModalContainer = styled.div<{ fullWidth?: boolean }>`
	background: ${LightGreySidely2};
	width: ${({ fullWidth }) => fullWidth ? '100%' : '20%'};
	height: 100%;
	flex-shrink: 0;
	display: flex;
`;


const P = styled.p`
	font-size: 13px;
	margin: 0;
`;

const ErrorCount = styled.div<{ display: boolean, severity: number }>`
	padding: 5px;
	background-color: ${p => p.severity > MAX_WARNING_SEVERITY ? RedSidely : '#FFB946'};
    position: relative;
    top: 100%;
    left: 100%;
    color: white;
    font-size: 7px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
	display: ${({ display }) => !display ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    translate: -100% -100%;
`;

const FieldSvg = styled.div<{ src: string }>`
	width: 17px;
	height: 17px;
	background: url("${p => p.src}");
	background-size: cover;
	flex-shrink: 0;

`;

export const FieldName = styled(P)`
	font-size: 14px;
	overflow: hidden;
	flex-shrink: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 400;
	color: ${DarkGreySidely2};
`;

export const FieldContainer = styled.div<{ padding?: string, justify?: string}>`
	display: flex;
	align-items: center;
	gap: 0.5em;
	cursor: pointer;
	padding: ${({ padding }) => padding ?? '0px 15px'};
	justify-content: ${({ justify }) => justify ?? 'flex-start'};
	height: 30px;
	flex-shrink: 0;
	font-size: 14px;
	&:hover {
		background: lightgrey;
	}
`;

export const OpenContainer = styled(FieldContainer)`
	font-size: 14px;
	font-weight: 500;
`;

const FieldId = styled(P)`
	color: ${BlueSidely};
	display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid ${BlueSidely};
    width: 15px;
    height: 15px;
    border-radius: 5px;
	font-size: 10px;
`;

const Button = styled(DefaultButton)`
	margin: 0;
`;

const Invisible = styled.div<{hide?: boolean}>`
	display: ${({ hide }) => hide ? 'none' : 'block'};
`;

export type ModalData = {
	type: FieldType,
	field_id?: number,
}


const RemoveIconDiv = styled.div`
	cursor: pointer;
`;

const SettingIconDiv = styled.div`

	cursor: pointer;
`;


export function TypeFieldsContainer(props: { search?: string, fields: Field[], type: FieldType, onFieldClicked?: (field: Field) => void, setModalState: React.Dispatch<React.SetStateAction<ModalState<ModalData>>>, creationNotAllowed?: boolean, hideId?: boolean, allowEdit?: boolean }) {
	const [isOpen, setIsOpen] = React.useState(true);
	const { type } = props;
	const alert = useAlert();

	const [fields, setFields] = React.useState<Field[]>(props.fields);

	React.useEffect(() => {
		setFields(props.fields);
	}, [props.fields]);

	const filteredFields = fields.filter(f => f.name.toLocaleLowerCase().includes(props.search?.toLocaleLowerCase() ?? ''));
	if (filteredFields.length === 0) return <></>;

	return <>
		<FlexDiv flow='column' align='stretch' margin='10px 0px 0px 0px'>
			<OpenContainer onClick={() => setIsOpen(!isOpen)} justify='space-between'>
				{props.hideId ? translateToString('report_editor.form_field_types.' + type.toLowerCase()) : type}
				{fields.length != 0 && <Open isOpen={isOpen} size={15} />}
			</OpenContainer>
			{isOpen &&
				<FlexDiv flow='column' align='stretch'>
					{filteredFields.map(field =>
						<>
							<FlexDiv flow='row' style={{ justifyContent: 'space-between' }}>
								<FieldContainer key={`Field[${field.id}]`} onClick={() => props.onFieldClicked?.(field)}>
									<Invisible hide={props.hideId}><FieldId>{field.id}</FieldId></Invisible>
									<FieldName>{field.name}</FieldName>
								</FieldContainer>
								<FlexDiv style={{ paddingRight: '20px' }}>
									{props.allowEdit && <SettingIconDiv>
										<img src={SettingIcon} style={{ width: '15px', height: '15px' }}
											onClick={() => 
											{
												props.setModalState({ isOpen: true, data: { type: type, field_id: field.id } });
											}}
										/>
									</SettingIconDiv>}
									{props.allowEdit && <RemoveIconDiv>
										<img src={DeleteIcon} style={{ width: '15px', height: '15px' }}
											onClick={() => 
											{
												alert({
													title: translateToNode('import.are_you_sure'),
													content: <>{translateToString('form_editor.delete_field')}{field.name} ?</>,
													buttons: [
														{
															title: translateToNode('cancel'),
															res: AlertRes.Break,
															style: ButtonStyle.White
				
														},
														{
															title: translateToNode('yes'),
															res: AlertRes.Ok
														}
													]
												}).then(res => {
													if (res === AlertRes.Break) return;
													deleteField(field.id).then(() => {
														setFields(fields.filter(f => f.id !== field.id));
													});
												});
											}}
										/>
									</RemoveIconDiv>}
								</FlexDiv>
							</FlexDiv>
						</>
						
					)}
					{!props.creationNotAllowed &&
						<FieldContainer onClick={() => props.setModalState({ isOpen: true, data: { type: type } })}><Add size='17px' /></FieldContainer>}
				</FlexDiv>
			}
		</FlexDiv>
	</>;
}

export function LeftModal(props: { onFieldClicked?: (field: Field) => void, errors: MonacoError[][], onErrorClicked?: (error: MonacoError) => void, creationNotAllowed?: boolean, externalUse?: boolean, allowEdit?: boolean }) {
	const [fields, setFields] = React.useState<{ [type in FieldType]: Field[]}>();
	const [modalState, setModalState] = React.useState<ModalState<ModalData>>({ isOpen: false, data: undefined });
	const [selectedPanel, setSelectedPanel] = React.useState<'fields' | 'errors'>('fields');
	const refresh = React.useCallback(() => {
		getFields().then(fields => setFields(fields.reduce((acc: { [key in FieldType]: Field[] }, f) => {
			if (!acc[f.type]) acc[f.type] = [];
			acc[f.type].push(f);
			return acc;
		}, {} as { [key in FieldType]: Field[] })));
	}, []);
	const [search, setSearch] = React.useState<string>('');

	React.useEffect(() => {
		refresh();
	}, []);
	
	return <>
		<LeftModalContainer fullWidth={props.externalUse}>
			{!props.externalUse && <LeftModalSideBar>
				<LeftModalSideBarImg src={dropdownSvg} selected={selectedPanel === 'fields'} onClick={() => setSelectedPanel('fields')} />
				<LeftModalSideBarImg src={bugSvg} size='60%' selected={selectedPanel === 'errors'} onClick={() => setSelectedPanel('errors')}>
					<ErrorCount display={props.errors && props.errors[0] && props.errors[0].length !== 0} severity={props.errors && props.errors[0] && props.errors[0].reduce((acc, c) => Math.max(acc, c.severity), 0)}>{props.errors[0]?.length}</ErrorCount>
				</LeftModalSideBarImg>
			</LeftModalSideBar>}
			<LeftModalContent>
				{selectedPanel === 'fields' && <>
					<InputSearch name={''} value={search} type={'text'} onChange={setSearch}/>
					{...ALL_FIELD_TYPES.map((type) => {
						return <TypeFieldsContainer
							search={search}
							key={`Key[${type}]`}
							fields={fields?.[type] ?? []}
							type={type}
							onFieldClicked={props.onFieldClicked}
							setModalState={setModalState}
							creationNotAllowed={props.creationNotAllowed}
							allowEdit={props.allowEdit}
						/>;
					})}
				</>}
				{selectedPanel === 'errors' && <>
					<OpenContainer>{props.errors[0]?.length ?? 0} {translateToString('error(s)')}</OpenContainer>
						{...props.errors?.[0]?.sort((a, b) => b.severity - a.severity).map((error, i) => {
							return <FieldContainer key={`error${i}`} onClick={() => props.onErrorClicked?.(error)}>
								<FieldSvg src={error.severity > MAX_WARNING_SEVERITY ? ErrorImage : WarningImage} />
								<FieldName>{error['message']}</FieldName>
							</FieldContainer>;
						}) ?? []}
				</>}
			</LeftModalContent>
		</LeftModalContainer>
		<PopupField
			onCreate={() => {
				refresh();
				setModalState({ isOpen: false });
			}}
			isOpen={modalState.isOpen}
			setIsOpen={isOpen => setModalState({ isOpen })}
			type={modalState.data?.type}
			field_id={modalState.data?.field_id}
		/>
	</>;
}

type Path = 'basic' | 'advanced';

const DEFAULT_JSON = [{
	components: [],
	fields: [],
	layout: {
		type: 'field'
	},
	next_screens: []
}];

export default function FormTemplateCreator(props: ComponentProps) {
	const history = useHistory();
	const formId = parseInt(getUrlParameterByName('id') ?? 'NaN');
	const form = getLocalFormById(isNaN(formId) ? undefined : formId);
	if (!form) {
		history.replace({ pathname: 'settings', hash: 'form_templates' });
		return <></>;
	}
	const [screens, setScreens] = useFunctionState<string>(typeof form?.screens === 'object' ? JSON.stringify(form.screens) : form?.screens ?? JSON.stringify(DEFAULT_JSON), ({ newValue }) => {
		updateLocalForm({ screens: newValue }, formId);
		return newValue;
	});
	const [lib, setLib] = useFunctionState<string>(form?.lib ?? '', ({ newValue }) => {
		updateLocalForm({ lib: newValue }, formId);
		return newValue;
	});
	const [errors, setErrors] = React.useState<MonacoError[][]>([]);
	const [loadingState, setLoadingState] = React.useState(LoadingStateEnum.LOADING);
	// eslint-disable-next-line @typescript-eslint/type-annotation-spacing
	const ref = React.useRef<{ onFieldClicked? :(field: Field) => void, onErrorClicked?: (error: MonacoError) => void }>();
	const [selectedPath] = React.useState<Path>('advanced');
	const alert = useAlert();

	const saveForm = React.useCallback((quit: boolean) => async() => {
		if (errors.some(e => e.some(e => e.severity > MAX_WARNING_SEVERITY))) {
			return alert({
				svg: 'error',
				title: translateToString('error'),
				content: translateToString('cant_save_errors', [['NUMBER', errors.reduce((acc, e) => acc + e.filter(e => e.severity > MAX_WARNING_SEVERITY).length, 0).toString()]]),
				noButtons: true
			});
		}
		if (errors.some(e => e.length)) {
			const res = await alert({
				svg: 'warning',
				title: translateToString('Attention'),
				content: translateToString('are_you_sure_save_warning', [['NUMBER', errors.reduce((acc, e) => acc + e.length, 0).toString()]]),
				buttons: [{ res: AlertRes.Ok, title: translateToString('yes') }]
			});
			if (res == AlertRes.Break) return;
		}
		const form = getLocalFormById(formId);
		if (!form) return;
		setLoadingState(LoadingStateEnum.LOADING);
		postForm({ ...form, screens: JSON.parse(form.screens ?? '[]') }).then(() => {
			if (quit) {
				removeLocalForm(formId);
				history.push({ pathname: 'settings', hash: 'form_templates' });
			} else {
				setLoadingState(LoadingStateEnum.LOADED);
			}
		});
	}, [errors]);
	useKeyPress([{ key: 's', metaKey: true }], () => {
		saveForm(false)();
	});

	React.useEffect(() => {
		props.setToolBarState({
			title: form?.name,
			bottomLeftToolbarComponent: <></>,
			bottomRightToolbarComponent: <ToolbarBox>
				{/* <PageSwitch<Path>
					selectedValue={selectedPath}
					list={[
						{ value: 'basic', src: CompanyBlackImage, onClick: setSelectedPath },
						{ value: 'advanced', src: CompanyMapBlackImage, onClick: setSelectedPath }
					]} /> */}
				<Button onClick={() => history.push({ pathname: 'settings', hash: 'form_templates' })} buttonStyle={ButtonStyle.White}><Translate id='back' /></Button>
				<Button onClick={saveForm(false)}><Translate id='save' /></Button>
				<Button onClick={saveForm(true)}><Translate id='save_and_quit' /></Button>
			</ToolbarBox>
		});
	}, [errors]);

	return <>
		<FlexDiv width='100%' height="calc(100vh - 120px)">
			<LeftModal onFieldClicked={ref.current?.onFieldClicked} allowEdit={true} onErrorClicked={ref.current?.onErrorClicked} errors={errors} />
			<EditorContainer white={selectedPath === 'basic'}>
				{selectedPath === 'advanced' && <AdvancedFormEditor
					ref={ref}
					setLoadingState={setLoadingState}
					setErrors={setErrors}
					screens={screens}
					setScreens={setScreens}
					lib={lib}
					setLib={setLib}
				/>}
				{selectedPath === 'basic' && <FormEditor
					ref={ref}
					setLoadingState={setLoadingState}
					setErrors={setErrors}
					screens={screens}
					setScreens={setScreens}
					lib={lib}
					setLib={setLib}
				/>}
			</EditorContainer>
			<ComponentLoader loadingState={loadingState} allScreen/>
		</FlexDiv>
	</>;
}