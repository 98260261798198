// RED
export const RedSidely = '#F7685B';
// BLUE
export const LightBlueSidely = '#E8F8FF';
export const BlueSidely = '#00B2FF';
export const SidelyBlack = '#323C47';
// GREY
export const LightGreySidely = '#F9F9FB';
export const LightGreySidely2 = '#F1F4F8';
export const LightGreySidely3 = '#F5F5F7';
export const GreySidely = '#CED7E3';
export const GreySidely2 = '#C2CFE0';
export const DarkGreySidely = '#254153';
export const DarkGreySidely2 = '#707683';
// GREEN
export const GreenSidely = '#2ED47A';

export const BorderColor = '#EBEFF2';
export const BorderColor2 = '#E2E2E2';

export const FilterBlue = 'invert(65%) sepia(60%) saturate(5339%) hue-rotate(167deg) brightness(103%) contrast(104%)';
export const FilterRed = 'invert(56%) sepia(26%) saturate(5134%) hue-rotate(326deg) brightness(102%) contrast(94%)';
export const FilterWhite = 'brightness(1000%) contrast(1000%)';
export const FilterGreen = 'invert(66%) sepia(48%) saturate(646%) hue-rotate(94deg) brightness(95%) contrast(87%)';

export const DisabledColor = '#E9ECEF';
