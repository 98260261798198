import filter from 'images/icons/filter.svg';
import { FilterType } from 'bindings/filters/FilterType';
import * as React from 'react';
import { Column as ReactTableColumn, HeaderGroup, Row, useBlockLayout, useExpanded, useResizeColumns, useSortBy, useTable, useColumnOrder } from 'react-table';
import { LoadingStateEnum } from '../../containers_v2/import/model';
import { Loader } from '../../styles/global/css/GlobalLoader';
import { FilterId } from '../filter/model/Model';
import { Checkbox } from '../filterList/style/Style';
import BodyRow from './bodyRow';
import {
	ArrowDown,
	ArrowUp,
	Body,
	Container,
	ContainerAnimation,
	FilterButton,
	Header,
	HeaderBlock,
	HeaderContent,
	HeaderContentRight,
	HeaderContentRightSort,
	HeaderRow,
	InformationHeader,
	InformationHeaderContainer,
	InformationHeaderHidder,
	Resizer
} from './style/Style';
import TableCellToolTip, { TableCellToolTipProps } from './TableCellToolTip';
import { useFunctionState } from '../../utils/customHooks';

export type DataType<T = object> = T & {
  error?: string
}

export type FreezePosition = 'left' | 'right'

export type Column<T = object> = ReactTableColumn<DataType<T>> & {
  type?: FilterType
  editable?: boolean
  freeze?: FreezePosition
  unresizeable?: boolean
  disableSortBy?: boolean
  disableFilter?: boolean
  checked?: boolean
  toolTip?: (row: T) => React.ReactNode,
  hoverImage?: (row: T) => React.ReactNode
  // please visualize eavy components
  visualization?: boolean,
  filterName?: string
  filterId?: FilterId,
  padding?: number,
  centerHeader?: boolean,
  centerRow?: boolean,
  maxWidth?: string | number,
  noHeaderEllipsis?: boolean
}

export interface TableSortType { id: string, desc: boolean }

interface TableInput<T> {
  height: string
  columns: Array<Column<T>>
  data: any[]
  hiddenColumns?: string[]
  sortColumns?: string[]
  onSort?: (sort: TableSortType) => void
  initialSortBy?: TableSortType
  onCellClicked?: (value: any, Xpos: number, Ypos: number) => void
  onClickFilter?: (id: string) => void
  onResize?: (columnWidths: any) => void
  resizeValue?
  isInformationHeaderOpen?: boolean
  informationHeader?: JSX.Element | string
  loading?: LoadingStateEnum
  noBorder?: boolean
  innerRef?: React.MutableRefObject<HTMLDivElement | undefined>
  EnableResize?: boolean,
  isIdSelected?: (row?: T) => boolean,
  setSelectedCheckbox?: (row?: T) => boolean,
  selectable?: boolean,
  allSelectable?: boolean,
}

const DEFAULT_FIXED_WIDTH = 180;

function RowCheckbox(props: { checked: boolean, setChecked: () => boolean, row? }) {
	const [checked, setChecked] = React.useState<boolean>(props.checked);

	React.useEffect(() => {setChecked(props.checked);}, [props.checked]);

	return <Checkbox
		isActive={checked}
		onClick={() => {
			const res = props.setChecked();
			if (props.row) {
				props.row.selected = res;
			}
			setChecked(res);
		}}
	/>;

}

export function Table<T extends object>(props: TableInput<T>) {
	// Use the state and functions returned from useTable to build your UI

	const { height, data, onSort, initialSortBy } = props;

	const [selected, setSelected] = React.useState<{ i: number, j: number }>();
	const [showFreezeColumnLeft, setShowFreezeColumnLeft] = React.useState<boolean>(false);
	const [showFreezeColumnRight, setShowFreezeColumRight] = React.useState<boolean>(false);
	const [tableWidth, setTableWidth] = useFunctionState<number>(0, ({ oldValue, newValue }) => {
		if (!newValue) return oldValue;
		return newValue;
	});
	const [columns, setColumns] = React.useState<Column<T>[]>(props.columns);
	const [hovered, setHovered] = React.useState<TableCellToolTipProps>();

	const [containerAnimation, setContainerAnimation] = React.useState<ContainerAnimation>('waiting_closed');

	const ref = React.useRef<HTMLElement>();
	const setRef = React.useCallback((node: HTMLElement) => {
		ref.current = node;
	}, []);

	React.useEffect(() => {
		if (props.isInformationHeaderOpen && containerAnimation !== 'opening' && containerAnimation !== 'waiting_opened') {
			setContainerAnimation('opening');
		} else if (!props.isInformationHeaderOpen && containerAnimation !== 'closing' && containerAnimation !== 'waiting_closed') {
			setContainerAnimation('closing');
		}
	}, [props.isInformationHeaderOpen]);

	const defaultColumn = React.useMemo(
		() => ({
			minWidth: 40,
			maxWidth: 600
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		// @ts-expect-error sort-by
		state: { sortBy, columnResizing },
		setHiddenColumns,
		setColumnOrder
	} = useTable<T>(
		{
			// @ts-expect-error columns
			columns,
			data,
			defaultColumn,
			useResizeColumns,
			initialState: {
				// @ts-expect-error sort-by
				sortBy: initialSortBy ? [initialSortBy] : [],
				hiddenColumns: props.hiddenColumns ?? []
			},
			manualSorting: true,
			manualSortBy: true,
			autoResetExpanded: false,
			// defaultColumn,
		},
		useSortBy,
		useResizeColumns,
		useBlockLayout,
		useExpanded,
		useColumnOrder,
		hooks => {
			if (props.selectable || props.allSelectable)
				hooks.visibleColumns.push(columns => {
					return [
						{
							id: 'selection',
							Header: () => props.allSelectable ? <RowCheckbox checked={props.isIdSelected?.() ?? false} setChecked={() => props.setSelectedCheckbox?.() ?? false} /> : <></>,
							Cell: ({ row }) => <RowCheckbox row={row.original} checked={props.isIdSelected?.(row.original) ?? false} setChecked={() => props.setSelectedCheckbox?.(row.original) ?? false} />,
							canResize: false,
							canSort: false,
							depth: 0,
							disableSortBy: true,
							disableFilter: true,
							minWidth: 35,
							originalWidth: 35,
							totalFlexWidth: 35,
							totalWidth: 35,
							width: 35,
							unresizeable: true,
							noDefaultResizeDiv: true,
							freeze: 'left',
						},
						...columns,
					];
				});
		}
	);

	React.useEffect(() => {
		setHiddenColumns(props.hiddenColumns ?? []);
	}, [JSON.stringify(props.hiddenColumns)]);

	React.useEffect(() => {
		setColumnOrder(props.sortColumns ?? []);
	}, [JSON.stringify(props.sortColumns)]);

	React.useEffect(() => {
		onSort?.(sortBy);
	}, [sortBy]);

	React.useEffect(() => {
		props.onResize && props.onResize(columnResizing.columnWidths);
		setTableWidth(w => ref.current?.offsetWidth ?? w);
	}, [columnResizing]);

	React.useEffect(() => {
		setTableWidth(w => ref.current?.offsetWidth ?? w);
		setColumns(props.columns.map(c => {
			if (props.resizeValue && props.resizeValue[c.id] !== undefined) {
				c.width = props.resizeValue[c.id];
			}
			return c;
		}));
	}, [props.columns]);

	function getFixedHeader(headerGroup: HeaderGroup, id: string, position?: FreezePosition): number {
		const right = position === 'right';
		let i = right ? headerGroup.headers.length - 1 : 0;
		let res = 0;
		while (headerGroup.headers[i].id !== id && (right ? i > 0 : i < headerGroup.headers.length)) {
			res += Number.parseInt(headerGroup.headers[i].width ? `${headerGroup.headers[i].width}` : `${DEFAULT_FIXED_WIDTH}`) ?? DEFAULT_FIXED_WIDTH;
			i = right ? i - 1 : i + 1;
		}
		return right ? -res : res;
	}

	function getFixedBody(row: Row<T>, id: string, position?: FreezePosition): number {
		const right = position === 'right';
		let i = right ? row.cells.length - 1 : 0;
		let res = 0;
		while (row.cells[i].column.id !== id && (right ? i > 0 : i < row.cells.length)) {
			const cell = row.cells[i];
			res += Number.parseInt(cell.column.width ? `${cell.column.width}` : `${DEFAULT_FIXED_WIDTH}`) ?? DEFAULT_FIXED_WIDTH;
			i = right ? i - 1 : i + 1;
		}
		return right ? -res : res;
	}


	function isScrollable(): boolean {
		const sum_width = columns.filter(col => col.width !== undefined).filter(col => !props.hiddenColumns?.includes(col.id ?? '')).reduce((prev, c) => (c.width as number) + prev, 0);
		return sum_width >= tableWidth;
	}

	const default_width = React.useMemo(() => {
		const totalFixedLength = columns.reduce((acc, col) => (col.width as number ?? 0) + acc, 0);
		return `calc((${tableWidth ? `${tableWidth - 1}px` : '100% - 1px'} - ${props.noBorder ? '2em - ' : ''}${totalFixedLength}px) / ${columns.filter(col => !col.width).filter(col => !props.hiddenColumns?.includes(col.id ?? '')).length})`;
	}, [tableWidth, JSON.stringify(props.hiddenColumns), columns.length]);

	const onWheel = React.useCallback(e => e.currentTarget.scrollLeft += e.deltaY, []);
	const onScroll = React.useCallback((e) => {
		setShowFreezeColumRight(e.currentTarget.scrollWidth - e.currentTarget.clientWidth !== e.currentTarget.scrollLeft);
		setShowFreezeColumnLeft(e.currentTarget.scrollLeft > 0);
	}, []);

	function getWidth(header: HeaderGroup): string {
		if (columnResizing.columnWidths[header.id] !== undefined) {
			return `${columnResizing.columnWidths[header.id]}px`;
		} else if (typeof header.width === 'number') {
			return header.width + 'px';
		}
		return header.width ?? default_width;
	}

	return (<>
		<Container
			innerRef={props.innerRef}
			onLoad={(e) => setTableWidth(e.currentTarget.clientWidth)}
			height={height} {...getTableProps()} id={`table[${JSON.stringify(columns.map(c => ({ id: c.id, width: c.width })))}]`} onScroll={onScroll}>
			<Header>
				{headerGroups.map((headerGroup, i) => (
					<HeaderRow id="table_header_row" onWheel={onWheel} key={`headerGroup[${i}]`} noBorder={props.noBorder}>
						{headerGroup.headers.map(column => (
							<HeaderBlock
								key={`headerBlock[${column.id}]`}
								// @ts-expect-error type
								freeze={column.freeze}
								// @ts-expect-error type
								showFreezeShadow={column.freeze == 'right' ? showFreezeColumnRight : showFreezeColumnLeft}
								// @ts-expect-error type
								freezeLeft={column.freeze ? `${getFixedHeader(headerGroup, column.id)}px` : undefined}
								// @ts-expect-error type
								freezePadding={column.freeze ? getFixedHeader(headerGroup, column.id, column.freeze) : undefined}
								width={getWidth(column)}
								// @ts-expect-error type
								minWidth={column.minWidth > 0 ? column.minWidth : (column.width ? column.width : DEFAULT_FIXED_WIDTH)}
								maxWidth={column.maxWidth}
								noBorder={props.noBorder}
							>
								<HeaderContent>
									<div {...column.getSortByToggleProps({ title: undefined })} style={{ padding: '10px 0', width: '100%', textOverflow: !column.noHeaderEllipsis ? 'ellipsis' : undefined, overflow: !column.noHeaderEllipsis ? 'hidden' : undefined }}>{column.render('Header')}</div>
									<HeaderContentRight>
										{
											// @ts-expect-error type
											(props.onClickFilter != null) && !column.disableFilter &&
											<FilterButton onClick={(e) => {
												e.stopPropagation();
												props.onClickFilter?.(column.filterId ?? column.id);
												// @ts-expect-error type
											} } src={filter} alt='filter' isActive={column.activeFilter} />}
										{
											// @ts-expect-error type
											(onSort != null) && !column.disableSortBy &&
											<HeaderContentRightSort {...column.getSortByToggleProps({ title: undefined })}>
												{<>
													<ArrowUp isActive={column.isSorted && !column.isSortedDesc} />
													<ArrowDown rotate180 isActive={column.isSorted && column.isSortedDesc} />
												</>}
											</HeaderContentRightSort>}
										{props.EnableResize && !column.unresizeable
											? <Resizer {...column.getResizerProps()} onClick={(e) => e.stopPropagation()} />
											: column.noDefaultResizeDiv
												? undefined
												: <div style={{ width: '15px' }}></div>}
									</HeaderContentRight>
								</HeaderContent>
							</HeaderBlock>
						))}
					</HeaderRow>
				))}
				<InformationHeaderContainer animation={containerAnimation} isOpen={props.isInformationHeaderOpen} onAnimationEnd={() => setContainerAnimation(containerAnimation.includes('open') ? 'waiting_opened' : 'waiting_closed')}>
					<InformationHeader id="table_header_row">
						{props.isInformationHeaderOpen && props.informationHeader}
					</InformationHeader>
					<InformationHeaderHidder />
				</InformationHeaderContainer>
			</Header>
			<Body {...getTableBodyProps()} id="table_body_row" innerRef={setRef}>
				{
					props.loading && props.loading === LoadingStateEnum.LOADING &&
					<Loader center width='30px' />
				}
				{((props.loading && props.loading === LoadingStateEnum.LOADED) || !props.loading) && rows.map((row, i) => {
					prepareRow(row);
					return (
						<BodyRow
							key={`BodyRow[${row.id}]`}
							isIdSelected={props.isIdSelected}
							i={i}
							row={row}
							isScrollable={isScrollable()}
							setSelected={setSelected}
							selected={selected}
							showFreezeColumnLeft={showFreezeColumnLeft}
							showFreezeColumnRight={showFreezeColumnRight}
							onCellClicked={props.onCellClicked}
							data={data}
							getFixedBody={getFixedBody}
							default_width={default_width}
							DEFAULT_FIXED_WIDTH={DEFAULT_FIXED_WIDTH}
							noBorder={props.noBorder}
							columnWidths={columnResizing.columnWidths}
							setHovered={setHovered}
						/>
					);
				})}
			</Body>
		</Container>
		{hovered && <TableCellToolTip {...hovered} maxWidth='200px'/>}
	</>);
}
