import { utils, writeFileXLSX, CellObject } from 'xlsx';
import { ComplexCell, Report, Row, Value, Cell, Tooltip } from '../interpretor/bareReportingDecoder';
import { Product } from '../../../atoms/product';
import { translateToString } from '../../../styles/global/translate';
import { calcEvolution, reportingDateToString } from '../interpretor/ReportInterpretor';
import * as moment from 'moment';
import { Owner } from '../../orders/model/Model';
import { EvolutionState } from '../interpretor/hoverContext';

export function exportReport(report: Report, reprasentation: any, products: Product[], users: Owner[], evolutionStateRef: React.MutableRefObject<EvolutionState>) {
	const valueToCell = function(val: Value | ComplexCell | Cell, columnIndex?: number): CellObject | undefined {
		const { value, tooltip }: { value: Value, tooltip?: Tooltip | null } = 'tag' in val ? { value: val, tooltip: undefined } : val;
		switch (value.tag) {
			case 0:
				return { t: 'n', v: value.val.percentage, z: '0.00%' };
			case 1:
				return { t: 's', v: value.val.text };
			case 2:
				return { t: 's', v: translateToString(value.val.key) };
			case 3: {
				let res = translateToString(value.val.formattedKey.key);
				value.val.formattedKey.formats.forEach((replacementKey, i) => res = res?.replaceAll(`{{${i + 1}}}`, replacementKey));
				return { t: 's', v: res };
			}
			case 4:
				return { t: 'n', v: value.val.float, z: '0.00' };
			case 5:
				return { t: 'n', v: value.val.int };
			case 7: {
				if (tooltip == null || tooltip.content.tag != 1) return { t: 's', v: 'Piecharts are not supported here' };
                
				const str = tooltip.content.val.table.map(row => `${row[2].val?.['int']} x ${row[0].val?.['text']}`).join(', ');
				return { t: 's', v: str };
			}
			case 8:
				return { t: 'd', v: reportingDateToString(value.val.date) };
			case 9:
				return { t: 'd', v: moment.unix(value.val.datetime).toDate() };
			case 10:
				return { t: 's', v: users.find(u => u.id === value.val.user)!.name };
			case 11: {
				const flat = evolutionStateRef.current[columnIndex ?? -1] === '+';
				const evo = calcEvolution(value.val.evolution);
				if (flat) return { t: 'n', v: evo?.flat, z: '0.00' };
				if (evo?.percentage !== undefined && isNaN(evo.percentage)) return { t: 'n', v: undefined, z: '0.00%' };
				return { t: 'n', v: evo?.percentage, z: '0.00%' };
			}
			case 12:
				return { t: 's', v: products.find(p => p.uuid === value.val.product)!.name };
			case 13:
				return { t: 's', v: value.val.list.join(', ') };
			case 14:
				return undefined;
			default:
				console.log(value);
				return { t: 's', v: Object.values(value.val)[0].toString() };
		}
	};

	const wb = utils.book_new();
	wb.Props = {
		Title: reprasentation.name,
		Subject: 'Exported report',
		Author: 'Sidely',
		CreatedDate: new Date()
	};
	// wb.SheetNames.push("Report");
	// let ws_data = [['test', '1']];
	// ws_data.push(['test', '2']);
	// let ws = utils.aoa_to_sheet(ws_data);
	// wb.Sheets["Report"] = ws;

	const header: (CellObject | undefined)[] = [{ t: 's', v: 'Id du point de vente' }];
	header.push(...report.headers.reduce((acc: (CellObject | undefined)[], header) => {
		const res = valueToCell(header.cell);
		if (!res) {
			const old = acc[acc.length - 1];
			if (old?.t === 's') {
				acc.push({
					t: 's',
					v: 'Variation - ' + old.v
				});
			} else {
				acc.push(undefined);
			}
		} else acc.push(res);
		return acc;
	}, []));
	header.push({ t: 's', v: 'Parent 1' }, { t: 's', v: 'Parent 2' }, { t: 's', v: 'Parent 3' }, { t: 's', v: 'Parent 4' }, { t: 's', v: 'Parent 5' });
	const primaryCount = report.headers.filter(h => h.primary).length;
	const nonPrimaryCount = report.headers.filter(h => !h.primary).length;

	for (const i in report.panels) {
		const panel = report.panels[i];
		const str = (valueToCell(panel.value)?.v?.toString() ?? (report.panels.length == 1 ? reprasentation.name : `Sheet ${i}`)).substring(0, 30).replace(/[\/\\?*:\[\]]/g, '-');
		wb.SheetNames.push(str);
		const rows = [header];
		const exportRowRecursively = function(row: Row, parents: CellObject[] = []) {
			const parentsCopy = [...parents];
			parentsCopy.length = 5;
			const primaryCells = row.primaryCell.map((cell, i) => valueToCell(cell, i));
			primaryCells.length = primaryCount;
			const dataCells = row.cells[i].map((cell, i) => valueToCell(cell, i + primaryCount)); 
			dataCells.length = nonPrimaryCount;
			const link = row.primaryCell[0].link;
			const clientCompanyId = link ? Object.values(link.val)[0] : undefined;
			rows.push([{ t: 'n', v: clientCompanyId }, ...primaryCells, ...dataCells, ...parentsCopy]);
			const newParents = [...parents, valueToCell(row.primaryCell[0].value)!];
			for (const subRow of row.rows) {
				exportRowRecursively(subRow, newParents);
			}
		};
		for (const row of report.rows) {
			exportRowRecursively(row);
		}
		const worksheet = utils.aoa_to_sheet(rows);
		worksheet['!cols'] = header.map(h => ({ wch: Math.max(10, (h?.v?.toString().length ?? 7) + 3) }));
		wb.Sheets[str] = worksheet;
	}

	const out = writeFileXLSX(wb, `export-${reprasentation.name}-${(new Date()).toISOString()}.xlsx`, { bookType: 'xlsx', type: 'binary' });
	console.log(out);
}
