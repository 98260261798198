import close from 'images/icons/orders/close.svg';
import eye from 'images/icons/company/eye.svg';
import * as React from 'react';
import styled from 'styled-components';
import { DefaultImage } from '../../styles/global/css/GlobalImage';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { BorderColor, SidelyBlack } from '../../styles/global/css/Utils';
import { translateToString } from '../../styles/global/translate';
import { PartialOpen } from '../client-companies/style/Style';
import { Owner } from '../orders/model/Model';
import { FlexDiv } from '../products/style';
import { ClusterProperties, modalLeftState, Point } from './model';
import { FixedSizeList as List } from 'react-window';
import { useWindowDimensions } from '../../components_v2/utils';
import { CompanyStatus } from '../client-companies/model/Model';

const CompanyContainer = styled.div`
	${DefaultText}
	height: 2.75rem;
	display: flex;
	align-items: center;
	width: 90%;
	border-bottom: 1px solid ${BorderColor};
	flex-shrink: 0;
	padding: 0 1em;
	justify-content: space-between;
`;

const CompanyName = styled.div`
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const CompanyColorCode = styled(CompanyName)<{ statusColor?: string }>`
	color: ${p => p.statusColor ?? ''};
	font-size: 10px;
`;

const Title = styled.div`
	${DefaultText}
	display: flex;
	align-items: center;
	height: 45px;
	font-weight: 600;
	font-size: 15px;
	color: ${SidelyBlack};
	flex-shrink: 0;
	width: 90%;
	border-bottom: 1px solid ${BorderColor};
	justify-content: space-between;
`;

export default function ClusterPopupContainer(props: {
	properties: ClusterProperties[],
	points: Point[],
	statuses: CompanyStatus[],
	onPointClicked: (id: number) => void,
	close: () => void,
	state: modalLeftState,
	users: Array<Owner> | undefined,
}) {
	const { height } = useWindowDimensions(); 

	const Row = (rowProps: { index: number, style: object }) => {
		const { index, style } = rowProps;
		const p = props.properties[index];
		const company = props.points.find(point => point.properties.id === p.id)?.properties.detail;
		if (!company) return undefined;
		let child: React.ReactNode;
		if (props.state.tabType === 'per_users' && props.users) {
			const user = props.users.find(u => u.id === p.owner);
			child = user !== undefined ? <CompanyColorCode statusColor={user.color}>{user.name}</CompanyColorCode> : <CompanyColorCode />;
		} else {
			const status = props.statuses.find(s => s.id === company.clientStatusId);
			child = status !== undefined ? <CompanyColorCode statusColor={status.color_code}>{status.name}</CompanyColorCode> : <CompanyColorCode />;
		}
		return <CompanyContainer key={`companyDetail[${index}]`} style={style}>
			<FlexDiv flow='column' align='flex-start' overflow='hidden'>
				<CompanyName>{company.name}</CompanyName>
				{child}
			</FlexDiv>
			<PartialOpen src={eye} onClick={() => props.onPointClicked(company.id ?? -1)} parent={CompanyContainer}/>
		</CompanyContainer>;
	};
	
	return <FlexDiv flow='column' height='100%'>
		<Title>
			<div>{props.properties.length} {translateToString('companies')}</div>
			<DefaultImage cursor="pointer" src={close} onClick={() => props.close()} />
		</Title>
		<List
			height={height - 45}
			itemCount={props.properties.length}
			itemSize={44}
			width='100%'
		>
			{Row}
		</List>
	</FlexDiv>;
}