import * as React from 'react';
import { reportingAction, reportingState } from '../Reducer';
import { Collapse } from 'reactstrap';
import { CheckGroupFilter } from '../utils';
import { ButtonToggle } from '../../../styles/global/css/Open';

interface product {
  label: string
  value: string
}

export default function PresencePerProductsFilters(props: {
  state: reportingState
  dispatch: (filters: reportingAction) => void
}) {
	const [products, setProducts] = React.useState<product[]>([]);
	const [activeCollapse, setActiveCollapse] = React.useState<string[]>([]);
	const filterName = 'PresencePerProducts';

	React.useEffect(() => {
		if (props.state.products != null) {
			const products = props
				.state
				.products
				.map((p, i) => {
					return {
						label: p,
						value: p,
						checked: props.state.filters[filterName].includes(p)
					};
				});
			products.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
			setProducts([{
				label: '',
				value: '-1',
				checked: props.state.products.length == props.state.filters[filterName].length
			}].concat(products));
		}
	}, [props.state.products]);

	function toggle(type: string) {
		const active = [...activeCollapse];
		if (active.includes(type)) {
			active.splice(active.indexOf(type), 1);
		} else {
			active.push(type);
		}
		setActiveCollapse(active);
	}

	function buttonToggle(title: string, key: string, alreadyTranslated?: boolean) {
		return <ButtonToggle
			onClick={() => toggle(key)}
			alreadyTranslated={alreadyTranslated}
			key={key}
			title={title}
			isOpen={activeCollapse.includes(key)}
		/>;
	}

	return (
		<div style={{ margin: '10px 0px 0px 20px' }}>
			<div className='col'>
				{buttonToggle('global.products', 'products')}
				<Collapse isOpen={activeCollapse.includes('products')}>
					<CheckGroupFilter list={products} setList={setProducts} filterName={filterName} {...props} />
				</Collapse>
			</div>
		</div>
	);
}
