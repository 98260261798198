import { Fields, Form, Options } from '../type';

export const shelf_audit_final_step = 10;

export const shelf_audit_form: Form = [
	{
		'buttons': {
			'children': [
				// { // UNCOMENT WHEN START BUTTON IS FIXED
				// 	'action': 'continue',
				// 	'name': 'Commencer',
				// 	'type': 'button',
				// 	'visible_if': 'calcProgression() == 0'
				// },
				// {
				// 	'action': 'continue',
				// 	'name': 'Continuer',
				// 	'type': 'button',
				// 	'visible_if': 'calcProgression() ~= 0 and calcProgression() ~= 1'
				// },
				{
					'action': 'next',
					'name': 'Terminer',
					'type': 'button',
					'visible_if': 'calcProgression() == 1'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'pagination',
					'value': [
						{
							'name': 'Relevé de présence',
							'screen': {
								'condition': 'true',
								'screen_name': 'Choix assortiment'
							}
						},
						{
							'name': 'Compte rendu de visite',
							'screen': {
								'condition': 'true',
								'screen_name': 'Compte rendu de visite'
							}
						}
					]
				},
				'name': 'Tâches à mener',
				'type': 'pagination'
			},
			{
				'data': [],
				'name': 'progression',
				'type': 'summary'
			},
			{
				'data': [
					'Choix assortiments liés'
				],
				'name': 'lien-qualification',
				'type': 'links',
				'options': {
					'display': 'text'
				},
			}
		],
		'fields': [
			{
				'field_id': 12,
				'metadata': {},
				'name': 'point-de-vente',
				'validator': 'current_screen.fields["point-de-vente"].filled'
			},
			{
				'field_id': 1,
				'metadata': {},
				'name': 'check-in'
			},
			{
				'field_id': 40,
				'calculated_value': 'calc_dn(false)',
				'metadata': {},
				'name': 'd.n.'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'point-de-vente',
					'type': 'field'
				},
				{
					'name': 'progression',
					'type': 'component'
				},
				{
					'children': [
						{
							'name': 'check-in',
							'type': 'field'
						},
						{
							'name': 'd.n.',
							'type': 'field'
						},
						{
							'name': 'lien-qualification',
							'type': 'component'
						},
						{
							'name': 'Tâches à mener',
							'type': 'component'
						}
					],
					'type': 'column',
					'visible_if': 'current_screen.fields["point-de-vente"].filled'
				}
			],
			'type': 'column'
		},
		'name': 'Page de garde',
		'next_screens': [
			{
				'condition': 'true'
			}
		]
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'continue',
					'type': 'button',
					'visible_if': 'calcProgression() == 100'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'catalogue',
					'value': 'filter_map_columns(current_company.additional_columns)'
				},
				'name': 'catalogue-dispatcher',
				'screen': {
					'condition': 'true',
					'screen_name': 'État du linéaire avant passage'
				},
				'type': 'catalogue_dispatcher'
			}
		],
		'fields': [],
		'layout': {
			'name': 'catalogue-dispatcher',
			'type': 'component'
		},
		'name': 'Choix assortiment',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Page de garde'
			}
		]
	},
	{
		'components': [],
		'fields': [
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null
				},
				'name': 'lineaire-avant-releve'
			}
		],
		'layout': {
			'name': 'lineaire-avant-releve',
			'type': 'field'
		},
		'name': 'État du linéaire avant passage',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Choix du produit'
			}
		]
	},
	{
		
		'components': [
			{
				'filters': [
					{
						'name': 'Présence',
						'type': 'checkbox',
						'values': [
							{
								'function': 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(4, {["product"] = _F.item}) == true',
								'label': 'Produits présents'
							},
							{
								'function': 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(4, {["product"] = _F.item}) == false',
								'label': 'Produits absents'
							}
						]
					}
				],
				'data': {
					'callback': [
						{
							'color': 'green',
							'condition': 'getSavedFieldByIdAndCurrentMetadata(4) == true',
							'type': 'border-side'
						},
						{
							'color': 'red',
							'condition': 'getSavedFieldByIdAndCurrentMetadata(4) == true and calcProgressionWithEndScreen(x) ~= 1',
							'svg': 'close',
							'type': 'svg'
						}
					],
					'name': 'product',
					'value': 'catalogue'
				},
				'name': 'liste-produits',
				'screen': {
					'condition': 'true',
					'screen_name': 'Informations du produit'
				},
				'type': 'product_dispatcher'
			}
		],
		'fields': [],
		'layout': {
			'name': 'liste-produits',
			'type': 'component'
		},
		'name': 'Choix du produit',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'État du linéaire après passage'
			}
		]
	},
	{
		'components': [
			{
				'data': 'getProductByUuid(_G.product).images',
				'name': 'product_image',
				'type': 'image'
			},
			{
				'data': 'getProductByUuid(_G.product).name',
				'name': 'product_text',
				'type': 'text'
			}],
		'fields': [
			{
				'default': false,
				'field_id': 4,
				'metadata': {
					'catalogue': null,
					'product': null
				},
				'name': 'produit-present',
				'history': true,
				'validator': 'current_screen.fields["produit-present"].filled'
			},
			{
				'field_id': 43,
				'metadata': {
					'catalogue': null,
					'product': null
				},
				'name': 'produit-negocie',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null
				},
				'history': true,
				'name': 'presence-etiquette'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'nombre-de-facings'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'risque-de-dereferencement'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'zone-du-magasin'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'name': 'zone-du-rayon'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'bon-rayon'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'name': 'zone-chaude'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'regroupement-offre'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'type-de-rayon'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'niveau-d\'etagere'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'type-mise-en-avant'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'presence-plv'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'niveau-promotion'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'prix-constate'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'promotion'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'type-de-promotion'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'vmj'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'vmh'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'vmm'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'rupture'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'quantite-risque-de-casse'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'quantite-produits'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'distribution-de-goodies'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'bri-donne'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'valeur-bri-donne'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'history': true,
				'name': 'notes'
			},
		],
		'layout': {
			'children': [
				{
					'children': [
						{
							'child': {
								'name': 'product_image',
								'type': 'component'
							},
							'options': {
								'display': {
									'width': 50
								}
							},
							'type': 'container'
						},
						{
							'name': 'product_text',
							'type': 'component'
						}
					],
					'name': 'Product',
					'type': 'row'
				},
				{
					'name': 'produit-present',
					'type': 'field',
				},
				{
					'name': 'produit-negocie',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == false'
				},
				{
					'name': 'presence-etiquette',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'nombre-de-facings',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'risque-de-dereferencement',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'zone-du-magasin',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'zone-du-rayon',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'bon-rayon',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'zone-chaude',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'regroupement-offre',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'type-de-rayon',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'niveau-d\'etagere',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'type-mise-en-avant',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'presence-plv',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'niveau-promotion',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'prix-constate',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'promotion',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'type-de-promotion',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'vmj',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'vmh',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'vmm',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'rupture',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'quantite-risque-de-casse',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'quantite-produits',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'distribution-de-goodies',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'bri-donne',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'valeur-bri-donne',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
				{
					'name': 'notes',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
			],
			'type': 'column'
		},
		'name': 'Informations du produit',
		'template_title': 'Choisissez les éléments que vous souhaitez relever pour chacun de vos produits',
		'template_description': 'Cochez les éléments que vous souhaitez faire remonter par vos équipes lors de leurs relevés de linéaires',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Choix du produit'
			}
		]
	},
	{
		'components': [],
		'fields': [
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null
				},
				'name': 'lineaire-apres-releve'
			},
			{
				'field_id': 40,
				'calculated_value': 'calc_dn(true)',
				'metadata': {
					'catalogue': null,
				},
				'name': 'd.n.'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'lineaire-apres-releve',
					'type': 'field'
				},
				{
					'name': 'd.n.',
					'type': 'field',
				}
			],
			'type': 'column'
		},
		'name': 'État du linéaire après passage',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Choix assortiment'
			}
		]
	},
	{
		'components': [
			{
				'name': 'nouveau-contact',
				'type': 'linked_contact'
			},
			{
				'name': 'nouvel-evenement',
				'type': 'event_creator'
			}
		],
		'fields': [
			{
				'field_id': 9,
				'metadata': {},
				'history': true,
				'name': 'question',
				'validator': 'current_screen.fields["question"].filled',
				default: false,
				
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'dropdown-action',
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'dropdown-question-who',
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'remarques-additionnelles'
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'prochaines-etapes'
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'ajouter-un-contact'
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'planifier-un-nouvel-evenement'
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'remarques-additionnelles-prochaine-visite',
			},
			{
				'field_id': 0,
				'metadata': {},
				'history': true,
				'name': 'concurrent-present',
			}
		],
		'layout': {
			'children': [
				{
					'name': 'question',
					'type': 'field'
				},
				{
					'name': 'remarques-additionnelles',
					'type': 'field'
				},
				{
					'name': 'remarques-additionnelles-prochaine-visite',
					'type': 'field'
				},
				{
					'name': 'concurrent-present',
					'type': 'field'
				},
				{
					'name': 'prochaines-etapes',
					'type': 'field'
				},
				{
					'name': 'dropdown-question-who',
					'type': 'field',
					'visible_if': 'current_screen.fields["question"].value == true'
				},
				{
					'name': 'ajouter-un-contact',
					'type': 'field'
				},
				{
					'name': 'nouveau-contact',
					'type': 'component',
					'visible_if': 'current_screen.fields["ajouter-un-contact"].value == true'
				},
				{
					'name': 'planifier-un-nouvel-evenement',
					'type': 'field'
				},
				{
					'name': 'nouvel-evenement',
					'type': 'component',
					'visible_if': 'current_screen.fields["planifier-un-nouvel-evenement"].value == true'
				}
			],
			'type': 'column'
		},
		'name': 'Compte rendu de visite',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Page de garde'
			}
		]
	},
	{
		'components': [
			{
				'data': {
					'name': 'rayon',
					'value': 'filter_map_additional_columns(getAdditionalColumns())'
				},
				'name': 'rayon-dispatcher',
				'type': 'additional_column_qualifier'
			}
		],
		'fields': [],
		'layout': {
			'name': 'rayon-dispatcher',
			'type': 'component'
		},
		'name': 'Choix assortiments liés',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Page de garde'
			}
		]
	}
];

export const shelf_audit_fields: Fields[] = [
// Partie concernant les pages non modifiables
	{
		field_id: 1,
		name: 'check-in',
		display_name: 'Check-in',
		type_: 'CheckIn',
		descriptionTitle: 'Choisissez vos règles concernant les check-in',
		description: 'Choisissez si vous souhaitez rendre les check in visibles, et si vous souhaitez les rendre obligatoires afin d’accéder à la suite de votre formulaire',
		validator: 'current_screen.fields["check-in"].filled',
		field_data: 100,
	},
	{
		field_id: 2,
		name: 'lineaire-avant-releve',
		display_name: 'Photo avant relevé',
		type_: 'Image',
		descriptionTitle: 'Choisissez vos règles concernant la photo au début',
		description: 'Choisissez si vous souhaitez activer la photo au début du relevé',
	},
	{
		field_id: 3,
		name: 'lineaire-apres-releve',
		display_name: 'Photo après relevé',
		type_: 'Image',
		descriptionTitle: 'Choisissez vos règles concernant la photo de fin',
		description: 'Choisissez si vous souhaitez activer la photo à la fin du relevé.'
	},
	{
		field_id: 12,
		name: 'point-de-vente',
		display_name: 'Point de vente',
		type_: 'Company',
		mandatory: true,
		inDraggable: false,
	},
	{
		field_id: 40,
		name: 'd.n.',
		type_: 'Number',
		display_name: 'D.N. (en %)',
		mandatory: true,
		inDraggable: false,
	},
	// Partie concernant la page de présence produit
	{
		field_id: 4,
		name: 'produit-present',
		display_name: 'Produit présent',
		type_: 'Boolean',
		mandatory: true,
		inDraggable: true,
	},
	{
		field_id: 43,
		name: 'produit-negocie',
		display_name: 'Produit négocié',
		type_: 'Boolean',
		category: 'Merchandising'
	},
	{
		field_id: 6,
		name: 'presence-etiquette',
		display_name: 'Etiquette produit présente',
		type_: 'Boolean',
		category: 'Merchandising'
	},
	{
		field_id: 17,
		name: 'nombre-de-facings',
		display_name: 'Nombre de facings',
		type_: 'Integer',
		category: 'Merchandising'
	},
	{
		field_id: 23,
		name: 'risque-de-dereferencement',
		display_name: 'Risque de déréférencement',
		type_: 'Boolean',
		category: 'Merchandising'
	},
	{
		field_id: 27,
		name: 'zone-du-magasin',
		display_name: 'Zone du magasin',
		field_data: [
			'Ambiant',
			'Autre',
			'Bio',
			'Boissons',
			'Caisses',
			'Cycle',
			'Droguerie',
			'Entretien et hygiène',
			'Fitness',
			'Frais',
			'Non-alimentaire',
			'Produits laitiers',
			'Rayon promotion',
			'Running',
			'Santé du sportif',
			'Traiteur'
		],
		type_: 'Select',
		category: 'Merchandising'
	},
	{
		field_id: 7,
		name: 'zone-du-rayon',
		display_name: 'Zone du rayon',
		field_data: [
			'En haut à gauche',
			'Au milieu à gauche',
			'En bas à gauche',
			'En haut au centre',
			'Au milieu au centre',
			'En bas au centre',
			'En haut à droite',
			'Au milieu à droite',
			'En bas à droite'
		],
		type_: 'Select',
		category: 'Merchandising'
	},
	{
		field_id: 18,
		name: 'bon-rayon',
		display_name: 'Bon rayon',
		type_: 'Boolean',
		category: 'Merchandising'
	},
	{
		field_id: 26,
		name: 'zone-chaude',
		display_name: 'Zone chaude',
		type_: 'Boolean',
		category: 'Merchandising'
	},
	{
		field_id: 13,
		name: 'regroupement-offre',
		display_name: 'Regroupement offre',
		type_: 'Boolean',
		category: 'Merchandising'
	},
	{
		field_id: 25,
		name: 'type-de-rayon',
		display_name: 'Type de rayon',
		field_data: [
			'Bazar',
			'Bio',
			'Droguerie',
			'Epicerie salée',
			'Epicerie sucrée',
			'Frais',
			'Surgelés',
			'Textile',
			'Produits de la mer',
			'Bières, Vins & Spiritueux',
			'Conserves',
			'Traiteur',
			'Papeterie',
			'Fruits & Légumes',
			'Produits laitiers',
			'Boissons non-alcoolisées',
			'Hygiène & Beauté',
			'Magazines & Journaux',
			'Allée centrale',
			'Fournitures de bureau',
			'Sec',
			'Caisses',
			'Produits d\'entretien',
			'Viandes',
			'Maison & Entretien',
			'Céréales',
			'Confitures',
			'Biscuits',
			'Apéritif',
			'Diététique - sans gluten',
			'Aliments animaux',
			'Autres',
			'Produits du monde',
			'Boulangerie',
			'Nutrition',
			'Nutrition et caisse',
			'Ligne de caisse',
			'Vin classique',
			'Vin BIO'
		],
		type_: 'Select',
		category: 'Merchandising'
	},
	{
		field_id: 16,
		name: 'niveau-d\'etagere',
		display_name: 'Niveau d\'étagère',
		type_: 'Integer',
		category: 'Merchandising'
	},
	{
		field_id: 33,
		name: 'type-mise-en-avant',
		display_name: 'Type de mise en avant',
		field_data: [
			'Aucune',
			'Box',
			'Box et PLV rayon',
			'Colerette',
			'Comptoir caisse',
			'Fond de rayon',
			'Fronton',
			'Îlot',
			'Joue de TG',
			'Meuble de présentoir',
			'PLV rayon',
		],
		type_: 'Select',
		category: 'Merchandising'
	},
	{
		field_id: 34,
		name: 'presence-plv',
		display_name: 'Présence PLV',
		type_: 'Boolean',
		category: 'Merchandising'
	},
	{
		field_id: 35,
		name: 'niveau-promotion',
		display_name: 'Niveau de promotion',
		field_data: [
			'Enseigne',
			'Locale',
			'Nationale',
			'Régionale',
		],
		type_: 'Select',
		category: 'Pricing'
	},
	{
		field_id: 5,
		name: 'prix-constate',
		display_name: 'Prix du produit constaté',
		type_: 'Number',
		category: 'Pricing'
	},
	{
		field_id: 36,
		name: 'promotion',
		display_name: 'Promotion',
		type_: 'Boolean',
		category: 'Pricing'
	},
	{
		field_id: 37,
		name: 'type-de-promotion',
		display_name: 'Type de promotion',
		field_data: [
			'Promotion catalogue',
			'Promotion pirate',
		],
		type_: 'Select',
		category: 'Pricing'
	},
	{
		field_id: 24,
		name: 'vmj',
		display_name: 'VMJ',
		type_: 'Number',
		category: 'Ventes',
	},
	{
		field_id: 14,
		name: 'vmh',
		display_name: 'VMH',
		type_: 'Number',
		category: 'Ventes',
	},
	{
		field_id: 21,
		name: 'vmm',
		display_name: 'VMM',
		type_: 'Number',
		category: 'Ventes',
	},
	{
		field_id: 38,
		name: 'rupture',
		display_name: 'Rupture',
		type_: 'Boolean',
		category: 'Quantités'
	},
	{
		field_id: 20,
		name: 'quantite-risque-de-casse',
		display_name: 'Quantité risque de casse',
		type_: 'Integer',
		category: 'Quantités'
	},
	{
		field_id: 22,
		name: 'quantite-produits',
		display_name: 'Quantité produits',
		type_: 'Integer',
		category: 'Quantités'
	},
	{
		field_id: 28,
		name: 'distribution-de-goodies',
		display_name: 'Distribution de goodies',
		type_: 'Boolean',
		category: 'Réduction'
	},
	{
		field_id: 15,
		name: 'bri-donne',
		display_name: 'BRI donné',
		type_: 'Boolean',
		category: 'Réduction'
	},
	{
		field_id: 19,
		name: 'valeur-bri-donne',
		display_name: 'Valeur BRI donnée',
		type_: 'Integer',
		category: 'Réduction'
	},
	{
		field_id: 39,
		name: 'notes',
		display_name: 'Notes',
		type_: 'TextArea',
		category: 'Notes'
	},

	// Partie concernant la conclusion
	{
		field_id: 8,
		name: 'commentaire',
		display_name: 'Commentaire de conclusion',
		type_: 'TextArea',
		category: 'Conclusion'
	},
	{
		field_id: 9,
		name: 'question',
		display_name: 'Interlocuteur rencontré',
		type_: 'Boolean',
		category: 'Conclusion',
		mandatory: true,
	},
	{
		field_id: 10,
		name: 'dropdown-action',
		display_name: 'Action(s) menée(s) lors de la visite',
		field_data: ['Vente promotion', 'Présentation innovation', 'Pose PLV', 'Implantation', 'Prise de commande'],
		type_: 'Multiselect',
		category: 'Conclusion'
	},
	{
		field_id: 11,
		name: 'dropdown-question-who',
		display_name: 'Interlocuteur(s)',
		field_data: ['Directeur', 'Gérant', 'Responsable segment', 'Chef de rayon'],
		type_: 'Multiselect',
		category: 'Conclusion',
	},
	{
		field_id: 29,
		name: 'remarques-additionnelles',
		display_name: 'Remarques additionnelles',
		type_: 'TextArea',
		category: 'Conclusion',
	},
	{
		field_id: 30,
		name: 'prochaines-etapes',
		display_name: 'Actions à mener lors de la prochaine visite',
		type_: 'Multiselect',
		category: 'Conclusion',
		field_data: ['Vente promotion', 'Présentation innovation', 'Pose PLV', 'Implantation', 'Prise de commande'],
	},
	{
		field_id: 31,
		name: 'ajouter-un-contact',
		display_name: 'Nouveau contact',
		type_: 'Boolean',
		category: 'Conclusion'
	},
	{
		field_id: 32,
		name: 'planifier-un-nouvel-evenement',
		display_name: 'Nouvel évènement',
		type_: 'Boolean',
		category: 'Conclusion'
	},
	{
		field_id: 41,
		name: 'remarques-additionnelles-prochaine-visite',
		display_name: 'Remarques additionnelles prochaine visite',
		type_: 'TextArea',
		category: 'Conclusion',
	},
	{
		field_id: 42,
		name: 'concurrent-present',
		display_name: 'Concurrents présents',
		type_: 'Multiselect',
		category: 'Conclusion',
		field_data: [],
	},
];


export const shelf_audit_options: Options[] = [
	{
		'name': 'presence-produit',
		'title': 'Présence des produits dans le point de vente',
		'description': 'Pour remonter la présence de vos produits dans le point de vente et calculer sa détention',
		'value': true,
		'mandatory': true
	},
	{
		'name': 'merchandising',
		'title': 'Relevé d’éléments merchandising des produits',
		'description': 'Pour remonter des informations qualitative et quantitative de la représenatation de vos produits dans le point de vente',
		'value': true,
		'mandatory': true
	},
	{
		'name': 'compte-rendu',
		'title': 'Compte rendu de la visite',
		'description': 'Pour permettre un compte rendu exhaustif de la visite, des objectifs réalisés, des contacts établis et des prochaines étapes à planifier',
		'value': true,
		'mandatory': true
	},
	{
		'name': 'qualification',
		'title': 'Accès à la qualification du point de vente',
		'description': 'Pour permettre à vos commerciaux de choisir l’assortiment lié au point de vente',
		'value': true,
		'mandatory': true
	},
	{
		'name': 'recapitulatif',
		'title': 'Récapitulatif des produits présents',
		'description': 'Affiche un récapitulatif des produits présents dans le point de vente',
		'value': true,
	},
	{
		'name': 'grid',
		'title': 'Mode grille',
		'description': 'Pour permettre de renseigner les informations sur un produit par ligne',
		'value': false
	},
	{
		'name': 'part-lineaire',
		'title': 'Calcul de parts de linéaires',
		'description': 'Pour mesurer l’espace occupé par vos produits en rayon, à la référence, par catégorie, et au global',
		'value': false
	},
	{
		'name': 'picking',
		'title': 'Possibilité de picking ',
		'description': 'Pour permettre de choisir la présence de produits en dehors de l’assortiment du point de vente, et calculer la détention avec et sans picking',
		'value': false
	}
];