import * as React from 'react';
import { getEventStatuses, getEventTypes } from './actions';
import { Collapse } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { CheckGroupFilter } from '../utils';
import { reportingAction, reportingState } from '../Reducer';
import { ButtonToggle } from '../../../styles/global/css/Open';

type LoadingState = 'loading' | 'loaded' | 'error' | null

export default function EventsPerUserFilters(props: {
  state: reportingState
  dispatch: (filters: reportingAction) => void
}) {
	const filterName = 'EventsPerCompany';
	const translate = getTranslate(storeLang.getState().localize);
	const [loadingState, setLoadingState] = React.useState<LoadingState>(null);
	const [eventTypes, setEventTypes] = React.useState<any[]>([]);
	const [eventStatuses, setEventStatuses] = React.useState<any[]>([]);
	const [activeCollapse, setActiveCollapse] = React.useState<string[]>([]);

	React.useEffect(() => {
		if (!loadingState) {
			setLoadingState('loading');
			const apis = async() => {
				try {
					const eventTypesFilter = props.state.filters[filterName]['event_type'];
					const eventTypes = await getEventTypes();
					setEventTypes([{ label: '', value: -1, checked: !(eventTypesFilter && eventTypesFilter.length != eventTypes.data.data.length) }].concat(eventTypes.data.data.map(e => {
						return {
							label: translate(`event.${e.name}`),
							value: e.id,
							checked: !(eventTypesFilter && eventTypesFilter.every(x => x != e.id))
						};
					})));
					const eventStatusesFilter = props.state.filters[filterName]['event_status'];
					const eventStatuses = await getEventStatuses();
					setEventStatuses([{ label: '', value: -1, checked: !(eventStatusesFilter && eventStatusesFilter.length != eventStatuses.data.data.length) }].concat(eventStatuses.data.data.map(e => {
						return {
							label: translate(`event.${e.name}`),
							value: e.id,
							checked: !(eventStatusesFilter && eventStatusesFilter.every(x => x != e.id))
						};
					})));
					setLoadingState('loaded');
				} catch (e) {
					console.log(e);
					setLoadingState('error');
				}
			};
			apis();
		}
	}, [loadingState]);

	function toggle(type: string) {
		const active = [...activeCollapse];
		if (active.includes(type)) {
			active.splice(active.indexOf(type), 1);
		} else {
			active.push(type);
		}
		setActiveCollapse(active);
	}

	function buttonToggle(title: string, key: string, alreadyTranslated?: boolean) {
		return <ButtonToggle
			onClick={() => toggle(key)}
			alreadyTranslated={alreadyTranslated}
			key={key}
			title={title}
			isOpen={activeCollapse.includes(key)}
		/>;
	}

	return (
		<div style={{ margin: '10px 0px 0px 20px' }}>
			<div className='col'>
				{buttonToggle('calendar.types', 'types')}
				<Collapse isOpen={activeCollapse.includes('types')}>
					<CheckGroupFilter list={eventTypes} setList={setEventTypes} name="event_type" filterName={filterName} {...props} />
				</Collapse>
			</div>
			<div className='col'>
				{buttonToggle('status', 'status')}
				<Collapse isOpen={activeCollapse.includes('status')}>
					<CheckGroupFilter list={eventStatuses} setList={setEventStatuses} name="event_status" filterName={filterName} {...props} />
				</Collapse>
			</div>
		</div>
	);
}
