/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from 'axios';
import new_tab from 'images/icon/new_tab.svg';
import eye from 'images/icons/company/eye.svg';
import phone_button from 'images/icons/company/phone_button.svg';
import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { Collapse } from 'reactstrap';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { ITag } from '../../../../typings/proto/protobufs';
import { ACompanyEdition } from '../../../atoms/company/companyEdition';
import AddressModificator from '../../../components_v2/address/AddressModificator';
import { addressToString } from '../../../components_v2/address/function/Function';
import { Address } from '../../../components_v2/address/model/Model';
import DropdownOwners, { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import DropdownTagsCloud from '../../../components_v2/dropdown/DropdownTagsCloud';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { blurOnEnterPressed } from '../../../components_v2/input/Input';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import { PopupContext } from '../../../components_v2/popup/Popup';
import { getTags, updateTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import storeLang from '../../../helpers/storeLang';
import { Loader } from '../../../styles/global/css/GlobalLoader';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, GreySidely, GreySidely2 } from '../../../styles/global/css/Utils';
import ToolTip from '../../../styles/global/ToolTip';
import { HoverEmailWrapper } from '../../email-templates/emailTemplatePopup';
import { emailValid, validatePhoneNumber, validateWebsite } from '../../globals/FieldFunction';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany as CC, Owner } from '../../orders/model/Model';
import Restricted from '../../permissions/Restricted';
import { ModalState } from '../../products/model';
import { TitleWithOpen } from '../../products/product';
import { FlexDiv, InnerContainerTitle } from '../../products/style';
import { updateCompany } from '../data/action';
import { ClientCompanyEdit, CompanyDetail } from '../model/Model';
import { BodyMainText, BodyMainTextEditable, ButtonAction } from '../style/PopupStyle';
import { Tag, TagContainer } from '../style/Style';
import { deleteParentCompany, updateParentCompany } from './action';

interface ErrorType {
  parent_company?: string
  website?: boolean
  phone?: boolean
  email?: boolean
}

const LocalCollapse = styled(Collapse)`
	width: inherit;
	display: flex;
	justify-content: center;
	&:not(.show) &:collapse {
		display: none;
	}
`;

const CompanyDetailDisplayerTitle = styled.h4`
	color: ${GreySidely2};
	margin: 0;
	font-weight: 500;
	font-size: 13px;
`;

export const CompanyDetailDisplayerWrapper = styled.div`
	display: flex;
	gap: 15px;
	margin-top: 15px;
	flex-flow: column;
	width: 100%;
	padding-left: 50px;
`;

export const CompanyDetailDisplayerRowWrapper = styled.div`
	${DefaultText}
`;

export function CompanyDetailDisplayer(props: {title: React.ReactNode, children}) {
	return <CompanyDetailDisplayerRowWrapper>
		<CompanyDetailDisplayerTitle>
			{props.title}
		</CompanyDetailDisplayerTitle>
		{props.children}
	</CompanyDetailDisplayerRowWrapper>;

}

export const LineHoverDiv = styled.div<{hover?: boolean}>`
	position: relative;
	margin-left: 7px;
	::before {
		content: '';
		height: 100%;
		width: 1px;
		position: absolute;
		left: 0;
		top: 4px;
		border-left: ${props => '1.5px solid ' + (props.hover ? BlueSidely : GreySidely)}
	}
	:hover {
		::before {
			border-left: 1.5px solid ${BlueSidely};
		}
	}
`;

export const Volet = styled(LineHoverDiv)`
	width: 30%;
	min-width: 340px;
	padding: 10px 20px;
`;

export const EditorWrapper = styled(LineHoverDiv)`
  display: inline-block;
  min-width: 370px;
  font-size: 13px;
  width: 30%;
  padding: 15px 15px 0px 15px;
`;

export const OptionsModal = styled(LineHoverDiv)<{gap?: string, width?: string}>`
	display: flex;
	flex-direction: column;
	gap: ${props => props.gap ?? '20px'};
	width: ${props => props.width ?? '30%'};
	min-width: 370px;
	font-size: 13px;
	padding: 10px 20px;
`;

export function TitleAndChild(props: {children, title, overrideOpen?: boolean, defaultOpen?: boolean, titleStyle?: React.CSSProperties, localCollapseStyle?: React.CSSProperties, margin?: string, addDiv?: boolean}): JSX.Element {
	const [isOpen, setIsOpen] = React.useState(props.defaultOpen ?? false);
	return <>
		<InnerContainerTitle noBorder margin={props.margin ?? '15px 30px 0 0'} style={props.titleStyle}>
			<TitleWithOpen isOpen={props.overrideOpen ?? isOpen} setIsOpen={setIsOpen}>
				{props.title}
			</TitleWithOpen>
		</InnerContainerTitle>
		{props.addDiv && <div><LocalCollapse style={props.localCollapseStyle} isOpen={props.overrideOpen ? props.overrideOpen : isOpen}>
			{props.children}
		</LocalCollapse></div>}
		{!props.addDiv && <LocalCollapse style={props.localCollapseStyle} isOpen={props.overrideOpen ? props.overrideOpen : isOpen}>
			{props.children}
		</LocalCollapse>}
	</>;
}

function Detail(props: {
  clientCompanyId: number
  loading: LoadingStateEnum
  setLoading: (value: LoadingStateEnum) => void
  owners: Owner[]
  companyDetail: CompanyDetail | undefined
  companyInformation: CompanyDetail | undefined
  refresh: () => void
  isSelf: boolean
  isExtend: boolean,
  openSubCompany: React.Dispatch<React.SetStateAction<ModalState<number>>>
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const { isChild } = React.useContext(PopupContext);

	const [tags, setTags] = React.useState<ITag[]>([]);
	const [selectedUser, setSelectedUser] = React.useState<Owner>();

	// const [isOpenDeliveryAddress, setOpenDeliveryAddress] = React.useState<boolean>(false);
	const [isOpenBillingAddress, setOpenBillingAddress] = React.useState<boolean>(false);
	const [billingAddress, setBillingAddress] = React.useState<Address>();
	// const [deliveryAddress, setDeliveryAddress] = React.useState<Address>();
	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);

	const [phone, setPhone] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [website, setWebsite] = React.useState<string>('');

	const [companyDetail, setCompanyDetail] = React.useState<CompanyDetail>();
	const setAtomCompanyEdition = useSetRecoilState(ACompanyEdition);

	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [tagLoadingState, setTagLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [error, setError] = React.useState<ErrorType>({});

	const [dropdownWidth, setDropdownWidth] = React.useState<string>(`${window.innerWidth / 6}px`);

	React.useEffect(() => {
		const handleResize = () => {
			setDropdownWidth(`${window.innerWidth / 6}px`);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	function getOwnerOptions(owners: Owner[]): DropdownData<Owner>[] {
		return owners.map(owner => {
			return {
				label: owner.name,
				value: owner,
				image: owner.photoUrl,
				selected: owner.isYou
			};
		});
	}

	function update(params: ClientCompanyEdit): void {
		updateCompany(params).then(_ => setAtomCompanyEdition(params));
	}

	function updateOwner(owner: Owner): void {
		setSelectedUser(owner);
		update({
			client_company_id: props.clientCompanyId,
			owner_id: owner.id
		});
	}

	function updateBillingAddress(address: Address): void {
		setBillingAddress(address);
		update({
			client_company_id: props.clientCompanyId,
			billing_country: address.country,
			billing_city: address.town,
			billing_postcode: address.postalCode,
			billing_address: address.street,
			latitude: address.latitude,
			longitude: address.longitude
		});
	}

	// function updateDeliveryAddress(address: Address): void {
	// 	setDeliveryAddress(address);
	// 	update({
	// 		client_company_id: props.clientCompanyId,
	// 		delivery_address: {
	// 			address: address.street,
	// 			city: address.town,
	// 			country: address.country,
	// 			post_code: address.postalCode
	// 		}
	// 	});
	// }

	function updatePhone(nphone: string): void {
		if (phone !== nphone) {
			setPhone(nphone);
			update({
				client_company_id: props.clientCompanyId,
				phone: nphone
			});
		}
	}

	function updateMail(nemail: string): void {
		if (email !== nemail) {
			setEmail(nemail);
			update({
				client_company_id: props.clientCompanyId,
				email: nemail
			});
		}
	}

	function updateWebsite(nwebsite: string): void {
		if (website != nwebsite) {
			setWebsite(nwebsite);
			update({
				client_company_id: props.clientCompanyId,
				website: nwebsite
			});
		}
	}

	React.useEffect(() => {
		if (tags.length <= 0) {
			getTags(TagType.COMPANY).then(res => {
				const detail: CompanyDetail = {
					...props.companyDetail!,
					tags: props.companyDetail?.tag_ids.map(id => res.find(t => t.id === id)!) ?? []
				};
				setTags(res);

				setCompanyDetail(detail);
				if (detail.company_billing_country || detail.company_billing_post_code || detail.company_billing_address || detail.company_billing_city) {
					setBillingAddress({
						country: detail.company_billing_country,
						postalCode: detail.company_billing_post_code,
						street: detail.company_billing_address,
						town: detail.company_billing_city
					} as Address);
				}
				setPhone(detail.company_phone);
				setWebsite(detail.company_website);
				setEmail(detail.company_email);
				setError({ ...error, phone: !validatePhoneNumber(detail.company_phone ?? ''), website: !validateWebsite(detail.company_website ?? ''), email: !emailValid(detail.company_email ?? '') });
				setSelectedUser(props.owners.find(u => u.id === props.companyDetail?.owner_id));
				props.setLoading(LoadingStateEnum.LOADED);
				setLoadingState(LoadingStateEnum.LOADED);
			});
		} else {
			const detail: CompanyDetail = {
				...props.companyDetail!,
				tags: props.companyDetail!.tag_ids.map(id => tags.find(t => t.id === id)!)
			};
			setCompanyDetail(detail);
			setLoadingState(LoadingStateEnum.LOADED);
		}
	}, [props.companyDetail]);

	if (props.loading === LoadingStateEnum.LOADING) {
		return (
			<Loader center width="40px" />
		);
	}

	return <FlexDiv flow="column" width='100%' padding='15px 0 0 0'>
		{(companyDetail != null) &&
				<Restricted
					to={{ objectAction: 'UpdateCompany' }}
					isSelf={props.isSelf}
					fallback={<FlexDiv width='100%' flexWrap='wrap'>
						{companyDetail?.tags.map(t => t ? <TagContainer key={`tagNoPermission[${t.id}]`} backgroundColor={t.color!}>
							<Tag backgroundColor={t.color!}>
								{t.name}
							</Tag>
						</TagContainer> : undefined)}
					</FlexDiv>}
				>
					<DropdownTagsCloud
						loadingState={tagLoadingState}
						addImage
						addImageSize='1.25em'
						noSearch
						dropdownSearch
						tags={tags}
						selected={companyDetail?.tags}
						onChange={(values: ITag[]) => {
							setTagLoadingState(LoadingStateEnum.LOADING);
							const idsAdd = values.filter(t => !companyDetail.tags.some(ct => ct.id == t.id));
							const params = {
								ressource_id: companyDetail.company_id,
								client_company_id: companyDetail.company_id,
								ids_add: idsAdd.map(t => t.id!)
							};
							updateTags(TagType.COMPANY, params).then(response => {
								setTagLoadingState(LoadingStateEnum.LOADED);
								if (response.valueOf()) {
									setAtomCompanyEdition(params);
									const newDetail = { ...companyDetail };
									newDetail.tags = newDetail.tags.concat(idsAdd);
									setCompanyDetail(newDetail);
								}
							}).catch(_ => setTagLoadingState(LoadingStateEnum.LOADED));
						}}
						onDelete={(id: number) => {
							const params = {
								ressource_id: companyDetail.company_id,
								client_company_id: companyDetail.company_id,
								ids_delete: [id]
							};
							updateTags(TagType.COMPANY, params).then(response => {
								if (response.valueOf()) {
									setAtomCompanyEdition(params);
									const newDetail = { ...companyDetail };
									newDetail.tags = newDetail.tags.filter(t2 => t2.id !== id);
									setCompanyDetail(newDetail);
								}
							});
						}}
					/>
				</Restricted>
		}
		<TitleAndChild title={<Translate id='overview' />} defaultOpen>
			<CompanyDetailDisplayerWrapper>
				<CompanyDetailDisplayer title={<Translate id='owner' />}>
					<Restricted
						to={{ objectAction: 'UpdateCompany' }}
						isSelf={props.isSelf}
						fallback={<UserBlock user={selectedUser}/>}
					>
						<DropdownOwners
							dropdownStyle={{ optionWidth: '175px', optionHeight: '150px', width: '100%' }}
							users={getOwnerOptions(props.owners)}
							selected={{ label: selectedUser?.name ?? '', value: selectedUser, image: selectedUser?.photoUrl }}
							onChange={(value: DropdownData) => updateOwner(value.value)}
							open
						/>
					</Restricted>
				</CompanyDetailDisplayer>
				<CompanyDetailDisplayer title={<Translate id='company.columns.company_address' />}>
					<FlexDiv height='25px'>
						<Restricted
							to={{ objectAction: 'UpdateCompany' }}
							isSelf={props.isSelf}
							fallback={<BodyMainText height='fit-content' marginLeft='0' minHeight='unset' display='block'>{addressToString(billingAddress ?? undefined, true)}</BodyMainText>}
						>
							<BodyMainText height='fit-content' marginLeft='0' minHeight='unset' display='block' cursor="pointer" onClick={() => setOpenBillingAddress(true)}>{addressToString(billingAddress ?? undefined, true)}</BodyMainText>
						</Restricted>
						<Restricted
							to={{ objectAction: 'ViewMap' }}
							isSelf={props.isSelf}
						>
							<>
								{billingAddress !== undefined && <ButtonAction parent={CompanyDetailDisplayerRowWrapper} width="20px" src={new_tab} alt="billing address" onClick={() => window.open(`/map?company=${companyDetail?.company_id}`, '_blank')} />}
							</>
						</Restricted>
					</FlexDiv>
				</CompanyDetailDisplayer>
				<CompanyDetailDisplayer title={<Translate id='phone' />}>
					<FlexDiv height='25px'>
						<Restricted
							to={{ objectAction: 'UpdateCompany' }}
							isSelf={props.isSelf}
							fallback={<BodyMainText height='fit-content' marginLeft='0' minHeight='unset' display='block'>{phone}</BodyMainText>}
						>
							<BodyMainTextEditable
								height='fit-content'
								marginLeft='0'
								minHeight='unset'
								width='100%'
								suppressContentEditableWarning
								contentEditable
								error={error.phone}
								onInput={e => {
									if (validatePhoneNumber(e.currentTarget.innerText.trim())) {
										setError({ ...error, phone: false });
									} else {
										setError({ ...error, phone: true });
									}
								}}

								onKeyDownCapture={blurOnEnterPressed}
								onBlur={(e) => {
									const newPhone = e.currentTarget.innerText.trim().replaceAll(' ', '');
									if (validatePhoneNumber(newPhone)) {
										updatePhone(newPhone);
										setPhone(newPhone);
									} else {
										setError({ ...error, phone: true });
									}
								}}
							>
								{phone}
							</BodyMainTextEditable>
						</Restricted>
						{phone && <ButtonAction parent={CompanyDetailDisplayerRowWrapper} src={phone_button} alt="phone" onClick={() => window.open(`tel:${phone}`)} />}
					</FlexDiv>
				</CompanyDetailDisplayer>
				<CompanyDetailDisplayer title={<Translate id='website' />}>
					<FlexDiv height='25px'>
						<Restricted
							to={{ objectAction: 'UpdateCompany' }}
							isSelf={props.isSelf}
							fallback={<BodyMainText height='fit-content' marginLeft='0' minHeight='unset' display='block'>{website}</BodyMainText>}
						>
							<BodyMainTextEditable
								height='fit-content'
								marginLeft='0'
								minHeight='unset'
								width='100%'
								error={error.website}
								suppressContentEditableWarning
								onInput={e => {
									if (validateWebsite(e.currentTarget.innerText.trim())) {
										setError({ ...error, website: false });
									} else {
										setError({ ...error, website: true });
									}
								}}
								contentEditable
								onKeyDownCapture={blurOnEnterPressed}
								onBlur={(e) => {
									const newWebsite = e.currentTarget.innerText.trim();
									if (validateWebsite(newWebsite)) {
										updateWebsite(newWebsite);
										setWebsite(newWebsite);
									} else {
										setError({ ...error, website: true });
									}
								}}
							>
								{website}
							</BodyMainTextEditable>
						</Restricted>
						{
							website && website.length > 0 &&
							<ButtonAction parent={CompanyDetailDisplayerRowWrapper} src={new_tab} alt="link" onClick={() => {
								if (website) {
									const web = website.startsWith('http') ? website : `https://${website}`;
									window.open(web, '_blank');
								}
							}} />
						}
					</FlexDiv>
				</CompanyDetailDisplayer>
				<CompanyDetailDisplayer title={<Translate id='email' />}>
					<FlexDiv height='25px'>
						<Restricted
							to={{ objectAction: 'UpdateCompany' }}
							isSelf={props.isSelf}
							fallback={<BodyMainText height='fit-content' marginLeft='0' minHeight='unset' display='block'>{email}</BodyMainText>}
						>
							<BodyMainTextEditable
								height='fit-content'
								marginLeft='0'
								minHeight='unset'
								width='100%'
								error={error.email}
								onInput={e => {
									if (emailValid(e.currentTarget.innerText.trim())) {
										setError({ ...error, email: false });
									} else {
										setError({ ...error, email: true });
									}
								}}
								suppressContentEditableWarning
								contentEditable
								onKeyDownCapture={blurOnEnterPressed}
								onBlur={(e) => {
									const newEmail = e.currentTarget.innerText.trim();
									if (emailValid(newEmail)) {
										updateMail(newEmail);
										setEmail(newEmail);
									} else {
										setError({ ...error, email: true });
									}
								}}
							>
								{email}
							</BodyMainTextEditable>
						</Restricted>
						{email && companyDetail &&
					<HoverEmailWrapper parent={CompanyDetailDisplayerRowWrapper} emailTemplateVariable={{
						first_name:companyDetail.owner_name.split(' ')[0] ?? '',
						last_name:companyDetail.owner_name.split(' ')[1] ?? '',
						company_name:companyDetail.company_name ?? ''
					}} email={email} />
						}
					</FlexDiv>
				</CompanyDetailDisplayer>
				<CompanyDetailDisplayer title={<Translate id='Parent company' />}>
					<FlexDiv height='25px'>
						<Restricted
							to={{ objectAction: 'UpdateCompany' }}
							isSelf={props.isSelf}
							fallback={<BodyMainText height='fit-content' marginLeft='0' minHeight='unset' display='block'>{props.companyInformation?.parent_name}</BodyMainText>}
						>
							<>
								<BodyMainText noOverflow height='fit-content' marginLeft='0' minHeight='unset'>
									<ToolTip
										toolTipContent={loadingState == LoadingStateEnum.ERROR && error.parent_company ? <Translate id={error.parent_company} /> : undefined}
										allwaysOpen={loadingState == LoadingStateEnum.ERROR}
										error
										wrap
										mouseLeave={() => setLoadingState(LoadingStateEnum.LOADED)}
									>
										<DropdownSearch
											dropdownStyle={{ optionWidth: dropdownWidth, optionHeight: '200px', containerWidth: '100%', height: '25px' }}
											JSXButton={(handleChange) => <>
												{loadingState == LoadingStateEnum.LOADING && <FlexDiv height="100%"><PageLoader size={20} /></FlexDiv>}
												{loadingState !== LoadingStateEnum.LOADING &&
											<BodyMainTextEditable
												noOverflow height='fit-content' marginLeft='0' minHeight='unset'
												width='100%'
												suppressContentEditableWarning
												contentEditable
												onKeyDownCapture={blurOnEnterPressed}
												onInput={(e) => handleChange(e.currentTarget.innerText.trim())}
												display='block'
											>
												{props.companyInformation?.parent_name}
											</BodyMainTextEditable>}
											</>
											}
											onSearchChange={async(search, offset) => {
												try {
													const res = await getFilteredCompanies(search, 20, offset);
													const ret: boolean = res.data.length == 0 || res.data.length < 20;
													if (offset !== undefined) {
														setOptionCompanies([...optionCompanies, ...res.data.map((company: CC) => {
															return {
																label: company.name,
																value: company
															};
														})]);
													} else {
														setOptionCompanies(res.data.map(company_1 => {
															return {
																label: company_1.name,
																value: company_1
															};
														}));
													}
													return ret;
												} catch (error) {
													console.log(error);
													return false;
												}
											}}
											datalist={optionCompanies}
											selectedValue={{ label: props.companyInformation?.parent_name ?? '-', value: props.companyInformation }}
											name={''}
											onChange={(value: DropdownData<CC>) => {
												setLoadingState(LoadingStateEnum.LOADING);
												updateParentCompany(props.clientCompanyId, value?.value.id)
													.then(() => {
														props.refresh?.();
														setAtomCompanyEdition({ client_company_id: props.clientCompanyId, parentCompany: value ? { updated: value.value } : 'deleted' });
													})
													.catch((e: AxiosError<string>) => {
														setError({ ...error, parent_company: e.response?.data });
														setLoadingState(LoadingStateEnum.ERROR);
													});
											}}
										/>
									</ToolTip>
								</BodyMainText>
								{props.companyInformation?.parent_id && <ButtonAction
									parent={CompanyDetailDisplayerRowWrapper}
									src={close}
									onClick={() => deleteParentCompany(props.clientCompanyId)
										.then(_ => {
											props.refresh();
											setAtomCompanyEdition({ client_company_id: props.clientCompanyId, parentCompany: 'deleted' });
										})
										.catch(() => setLoadingState(LoadingStateEnum.ERROR))
									}
								/>}
							</>
						</Restricted>

						{props.companyInformation?.parent_id && <ButtonAction parent={CompanyDetailDisplayerRowWrapper} src={eye} onClick={() => isChild ? window.open(`companies?id=${props.companyInformation?.parent_id}`) : props.openSubCompany({ isOpen: true, data: props.companyInformation?.parent_id })} />}
					</FlexDiv>
				</CompanyDetailDisplayer>

			</CompanyDetailDisplayerWrapper>
		</TitleAndChild>
		<AddressModificator
			isOpen={isOpenBillingAddress}
			setOpen={setOpenBillingAddress}
			defaultAddress={billingAddress}
			onChange={(na) => updateBillingAddress(na)}
			title={translate('company.columns.company_address').toString()}
		/>
	</FlexDiv>;
}

export default Detail;
