import trash from 'images/formbuilder/trash.svg';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Add from '../../../components_v2/add/Add';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { Checkbox } from '../../../components_v2/dropdown/style/Style';
import Input from '../../../components_v2/input/Input';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import Popup from '../../../components_v2/popup/Popup';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import { Column, Table } from '../../../components_v2/table/Table';
import { DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { AlertRes } from '../../alert/AlertProvider';
import useAlert from '../../alert/UseAlert';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../../client-companies/style/Style';
import { Spacer } from '../../contact/style/NewContactStyle';
import { normalizeIntAbove0, normalizeFloat, validateNumberAndEmpty } from '../../globals/FieldFunction';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { TableRow } from '../../orders/templateOrders/style/Style';
import Restricted from '../../permissions/Restricted';
import usePermission from '../../permissions/usePermission';
import { FlexDiv } from '../../products/style';
import { getCurrencySymbol } from '../../reports/utils';
import { getTaxes, getTaxTypes, postTax } from '../actions';
import { Tax, TaxType } from '../models';

const taxesColumns = (onClick: (tax: Tax) => void, onDelete: (tax: Tax) => void, isAllowedtoDelete: boolean, isAllowedtoCreate: boolean): Array<Column<Tax>> => {
	const columns: Array<Column<Tax>> = [
		{
			id: 'name',
			Header: <Translate id='name' />,
			accessor: (row: Tax) => <TableRow fontWeight="500" color={DarkGreySidely} cursor="pointer" onClick={() => isAllowedtoCreate && onClick(row)}>
				{row.name ?? '-'}
			</TableRow>,
			width: undefined
		},
		{
			id: 'value',
			Header: 'value',
			accessor: (row: Tax) => row.tax_value ?? '-',
			disableSortBy: true,
			width: undefined
		},
		{
			id: 'type',
			Header: <Translate id='type' />,
			accessor: (row: Tax) => {
				let value = '-';
				switch (row.tax_type_name) {
					case 'amount':
						value = getCurrencySymbol();
						break;
					case 'percentage':
						value = '%';
						break;
				}
				return <TableRow>{value}</TableRow>;
			},
			width: undefined
		},
		{
			id: 'is_additive',
			Header: <Translate id='additive' />,
			accessor: (row: Tax) => <TableRow>{translateToString(row.is_additive ? 'yes' : 'no')}</TableRow>,
			disableSortBy: true,
			width: undefined
		},
		{
			id: 'sequence',
			Header: <Translate id='application_order' />,
			accessor: (row: Tax) => <TableRow>{row.sequence + 1}</TableRow>,
			disableSortBy: true,
			width: undefined
		}
	];
	if (isAllowedtoDelete) {
		columns.push(
			{
				id: 'options',
				Header: ' ',
				accessor: (row: Tax) => <TableRow onClick={() => onDelete(row)} cursor="pointer">
					<img src={trash} width={15} />
				</TableRow>,
				disableSortBy: true,
				width: 35,
				minWidth: 35,
				unresizeable: true
			}
		);
	}
	return columns;
};

export default function TaxManagement(props: {
	setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [taxes, setTaxes] = React.useState<Tax[]>([]);
	const [taxTypes, setTaxTypes] = React.useState<TaxType[]>([]);
	const [selectedTax, setSelectedTax] = React.useState<Tax>();
	const [isCreationOpen, setIsCreationOpen] = React.useState<boolean>(false);
	const alert = useAlert();

	const onTaxClicked = (tax: Tax) => {
		setSelectedTax(tax);
		setIsCreationOpen(true);
	};

	const onModalClose = () => {
		setSelectedTax(undefined);
		setIsCreationOpen(false);
	};

	const fetchTaxes = async() => getTaxes().then(setTaxes).catch(console.error);

	const onDelete = (tax: Tax) => {
		alert({
			title: translateToString('do_you_want_to_delete_the_target', [['target', translateToString('orders.templateOrder.field.tax').toLocaleLowerCase()]]),
			content: <div>{translateToNode('attention_delete_target', {
				variables: [['target', translateToString('orders.templateOrder.field.tax').toLocaleLowerCase()], ['base', translateToString('products').toLocaleLowerCase()]]
			})}</div>,
			width: '600px',
			mode: 'delete'
		}).then(res => {
			if (res === AlertRes.Ok) {
				const body = {
					tax_value: tax.tax_value,
					name: tax.name,
					tax_type_id: tax.tax_type_id,
					id: tax.id,
					deleted: true,
					is_additive: tax.is_additive ?? false,
					sequence: tax.sequence
				};
				postTax(body)
					.then(fetchTaxes)
					.catch(console.log);
			}
		});
	};

	const isAllowedtoDelete = usePermission({ objectAction: 'DeleteCompanySettings' });
	const isAllowedtoCreate = usePermission({ objectAction: 'CreateCompanySettings' });

	const columns = React.useMemo(() => taxesColumns(onTaxClicked, onDelete, isAllowedtoDelete, isAllowedtoCreate), []);

	React.useEffect(() => {
		fetchTaxes();
		getTaxTypes().then(setTaxTypes).catch(console.log);
		props.setToolBarState({
			bottomRightToolbarComponent: <>
				<Restricted to={{ objectAction: 'CreateCompanySettings' }}>
					<Add onClick={() => {
						setSelectedTax(undefined);
						setIsCreationOpen(true);
					}}/>
				</Restricted>
			</>
		});
	}, []);

	return <>
		<Table
			height='100%'
			columns={columns}
			data={taxes}
			noBorder
		/>
		<Popup
			isOpen={isCreationOpen}
			onClickOut={onModalClose}
			popupMode={PopupMode.Details}
		>
			<PopupTax taxTypes={taxTypes} onClose={onModalClose} refresh={fetchTaxes} tax={selectedTax} />
		</Popup>
	</>;
}

function PopupTax(props: {
  taxTypes: TaxType[]
  onClose: () => void
  refresh: () => void
  tax?: Tax
}): JSX.Element {
	const { taxTypes, onClose, refresh, tax } = props;

	const taxTypesMapped: DropdownData[] = taxTypes.map(tt => {
		switch (tt.name) {
			case 'percentage':
				return ({ label: '%', value: tt });
			case 'amount':
				return ({ label: getCurrencySymbol(), value: tt });
			default:
				return ({ label: tt.name, value: tt });
		}
	});

	const [value, setValue] = React.useState<number | undefined>(tax?.tax_value);
	const [sequence, setSequence] = React.useState<number>(tax?.sequence ?? 1);
	const [additive, setAdditive] = React.useState<boolean>(tax?.is_additive ?? false);
	const [taxName, setTaxName] = React.useState<string | undefined>(tax?.name);
	const [taxType, setTaxType] = React.useState<DropdownData>(taxTypesMapped.find(tt => tt.value.id == tax?.tax_type_id) ?? taxTypesMapped[0]);

	function onCreate(): void {
		if (taxName) {
			const body = {
				tax_value: value,
				name: taxName,
				tax_type_id: taxType.value?.id,
				id: tax?.id,
				is_additive: additive,
				sequence: sequence ?? 1
			};
			postTax(body)
				.then(refresh)
				.catch(console.log);
		}
	}

	return <>
		<PopupCreation
			title={translateToString((tax != null) ? 'tax_modification' : 'tax_creation')}
			canValidate={taxName !== undefined && taxName.length > 0}
			onSubmit={() => {
				onCreate();
				onClose();
			}}
			messageButton={(tax != null)
				? <Translate id='global.edit' />
				: <Translate id='global.create' />
			}
			onClose={props.onClose}
		>
			<CreationPopupContainer>
				<Input
					name="tax_name"
					type="text"
					value={taxName}
					onChange={setTaxName}
					inputStyle={creationPopupInputStyle}
					required
					label={<Translate id='name' />}
				/>
				<Spacer />
				<FlexDiv gap='2px'>
					<Input
						inputStyle={{ ...creationPopupInputStyle,
							width: 'calc(100% - 56px)'
						}}
						key={'value_input'}
						name={'value_input'}
						type='text'
						label={<Translate id='amount' />}
						value={value}
						onChange={(newValue) => {
							if (newValue == '') {
								setValue(undefined);
								return;
							}
							const parsed = parseFloat(newValue);
							if (isNaN(parsed)) return;
							setValue(parsed);
						}}
						validate={[validateNumberAndEmpty]}
						normalize={[normalizeFloat]}
					/>
					<Dropdown
						name={'value_dropdown'}
						key={'value_dropdown'}
						dropdownStyle={{ ...creationPopupDropdownStyle, containerWidth: '54px', width: '54px', optionWidth: '60px', margin: '42px 0 0 0' }}
						readOnly
						datalist={taxTypesMapped}
						selectedValue={taxType}
						onChange={(value: DropdownData) => setTaxType(value)}
					/>
				</FlexDiv>
				<Spacer />
				<Input
					inputStyle={creationPopupInputStyle}
					key={'sequence_input'}
					name={'sequence_input'}
					type='text'
					label={<Translate id='application_order' />}
					value={sequence}
					onChange={(newValue) => {
						const parsed = parseInt(newValue);
						if (isNaN(parsed)) return;
						setSequence(parsed);
					}}
					validate={[validateNumberAndEmpty]}
					normalize={[normalizeIntAbove0]}
				/>
				<Spacer />
				<FlexDiv gap='10px' onClick={() => setAdditive(!additive)} clickable>
					<Checkbox isActive={additive} />
					<DefaultTextDiv><Translate id='additive' /></DefaultTextDiv>
				</FlexDiv>
			</CreationPopupContainer>
		</PopupCreation>
	</>;
}
