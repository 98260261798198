import { Fields, Form, Options } from '../type';

export const beer_final_step = 7;

export const beer_form: Form = [
	{
		'buttons': {
			'children': [
				{
					'action': 'continue',
					'name': 'Commencer',
					'type': 'button',
					'visible_if': 'calcProgression() == 0'
				},
				{
					'action': 'continue',
					'name': 'Continuer',
					'type': 'button',
					'visible_if': 'calcProgression() ~= 0 and calcProgression() ~= 1'
				},
				{
					'action': 'next',
					'name': 'Terminer',
					'type': 'button',
					'visible_if': 'calcProgression() == 1'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'pagination',
					'value': [
						{
							'name': 'Qualification',
							'screen': {
								'condition': 'true',
								'screen_name': 'qualification'
							}
						},
						{
							'name': 'Interlocuteur',
							'screen': {
								'condition': 'true',
								'screen_name': 'interlocuteur'
							}
						},
						{
							'name': 'Visite',
							'screen': {
								'condition': 'true',
								'screen_name': 'visite'
							}
						}
					]
				},
				'name': 'Tâches à mener',
				'type': 'pagination'
			},
			{
				'data': [],
				'type': 'summary'
			}
		],
		'fields': [
			{
				'field_id': 20,
				'metadata': {},
				'name': 'point-de-vente',
				'validator': 'current_screen.fields["point-de-vente"].filled'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'point-de-vente',
					'type': 'field'
				},
				{
					'index': 1,
					'type': 'component'
				},
				{
					'index': 0,
					'type': 'component',
					'visible_if': 'current_screen.fields["point-de-vente"].filled'
				}
			],
			'type': 'column'
		},
		'name': 'Choix du point de vente',
		'next_screens': [
			{
				'condition': 'true'
			}
		]
	},
	{
		'components': [],
		'fields': [
			{
				'field_id': 0,
				'metadata': {},
				'name': 'type-point-de-vente',
				'validator': 'current_screen.fields["type-point-de-vente"].value ~= nil',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'prix-de-vente-25',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'prix-de-vente-50',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'type-clientele',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'souscontrat',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'distributeur',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'pression-disponible',
				'validator': 'current_screen.fields["pression-disponible"].value ~= nil',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'nombre-bec-de-biere',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'styles-biere',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'nb-futs',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'taille-fut',
				'history': true,
			},
			{
				'calculated_value': 'calcHecto()',
				'field_id': 0,
				'metadata': {},
				'name': 'hecto-an',
				'history': true,
			}
		],
		'layout': {
			'children': [
				{
					'name': 'type-point-de-vente',
					'type': 'field'
				},
				{
					'name': 'prix-de-vente-25',
					'type': 'field'
				},
				{
					'name': 'prix-de-vente-50',
					'type': 'field'
				},
				{
					'name': 'type-clientele',
					'type': 'field'
				},
				{
					'name': 'souscontrat',
					'type': 'field'
				},
				{
					'name': 'distributeur',
					'type': 'field'
				},
				{
					'name': 'pression-disponible',
					'type': 'field'
				},
				{
					'name': 'nombre-bec-de-biere',
					'type': 'field',
					'visible_if': 'current_screen.fields["pression-disponible"].value == true'
				},
				{
					'name': 'styles-biere',
					'type': 'field',
					'visible_if': 'current_screen.fields["pression-disponible"].value == true'
				},
				{
					'name': 'nb-futs',
					'type': 'field',
					'visible_if': 'current_screen.fields["pression-disponible"].value == true'
				},
				{
					'name': 'taille-fut',
					'type': 'field',
					'visible_if': 'current_screen.fields["pression-disponible"].value == true'
				},
				{
					'name': 'hecto-an',
					'type': 'field',
					'visible_if': 'current_screen.fields["pression-disponible"].value == true'
				}
			],
			'type': 'column'
		},
		'name': 'qualification',
		'next_screens': [
			{
				'condition': 'true',
				'screen_index': 0
			}
		]
	},
	{
		'components': [
			{
				'name': 'nouveau-contact',
				'type': 'linked_contact'
			},
			{
				'name': 'nouvel-evenement',
				'type': 'event_creator'
			}
		],
		'fields': [
			{
				'field_id': 0,
				'metadata': {},
				'name': 'personnel-rencontre',
				'validator': 'current_screen.fields["personnel-rencontre"].filled',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'ouvert-nvlle-biere',
				'validator': 'current_screen.fields["ouvert-nvlle-biere"].value ~= nil',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'Engagement',
				'validator': 'current_screen.fields["Engagement"].filled',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'Potentiel',
				'validator': 'current_screen.fields["Potentiel"].filled',
				'history': true,
			}
		],
		'layout': {
			'children': [
				{
					'name': 'personnel-rencontre',
					'type': 'field'
				},
				{
					'name': 'nouveau-contact',
					'type': 'component',
					'visible_if': 'current_screen.fields["ajouter-un-contact"].value == true'
				},
				{
					'name': 'ouvert-nvlle-biere',
					'type': 'field'
				},
				{
					'name': 'Engagement',
					'type': 'field'
				},
				{
					'name': 'Potentiel',
					'type': 'field'
				},
				{
					'name': 'nouvel-evenement',
					'type': 'component',
					'visible_if': 'current_screen.fields["planifier-un-nouvel-evenement"].value == true'
				}
			],
			'type': 'column'
		},
		'name': 'interlocuteur',
		'next_screens': [
			{
				'condition': 'true',
				'screen_index': 0
			}
		]
	},
	{
		'components': [],
		'fields': [
			{
				'field_id': 0,
				'metadata': {},
				'name': 'prochaineactione',
				'validator': 'current_screen.fields["prochaineactione"].filled',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'suivi',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'photos',
				'history': true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'becsanous',
				'history': true,
			}
		],
		'layout': {
			'children': [
				{
					'name': 'prochaineactione',
					'type': 'field'
				},
				{
					'name': 'suivi',
					'type': 'field'
				},
				{
					'name': 'photos',
					'type': 'field'
				},
				{
					'name': 'becsanous',
					'type': 'field'
				}
			],
			'type': 'column'
		},
		'name': 'visite',
		'next_screens': [
			{
				'condition': 'true',
				'screen_index': 0
			}
		]
	}
];

export const beer_fields: Fields[] = [
	{
		field_id: 20,
		name: 'point-de-vente',
		display_name: 'Point de vente',
		type_: 'Company',
	},
	{
		field_id: 1,
		name: 'type-point-de-vente',
		display_name: 'Type de point de vente',
		type_: 'Select',
		field_data: ['Bar', 'Restaurant', 'Cave', 'Hötel', 'Boite de nuit'],
		category: 'Qualification'
	},
	{
		field_id: 2,
		name: 'prix-de-vente-25',
		display_name: 'Prix de vente 25cl',
		type_: 'Number',
		category: 'Qualification'
	},
	{
		field_id: 3,
		name: 'prix-de-vente-50',
		display_name: 'Prix de vente 50cl',
		type_: 'Number',
		category: 'Qualification'
	},
	{
		field_id: 4,
		name: 'type-clientele',
		display_name: 'Type de clientèle',
		type_: 'Select',
		field_data: ['Touriste', 'Standard', 'Bourgeois-Bohème', 'CSP', 'Etudiant', 'Pilier de comptoir'],
		category: 'Qualification'
	},
	{
		field_id: 5,
		name: 'souscontrat',
		display_name: 'Sous contrat',
		type_: 'Boolean',
		category: 'Qualification'
	},
	{
		field_id: 6,
		name: 'distributeur',
		display_name: 'Distributeur',
		type_: 'Multiselect',
		field_data: ['Stokbox', 'Guinand', 'Plumejeau', 'VDD', 'Comptoir Littoral', 'Lambelin', 'Cave à Titoune', 'BAB', 'Mespoulet', 'Limoges boissons', 'Direct', 'Robert', 'Tafanel', 'Gedis', 'OBD', 'Karak', 'Milliet', 'Murmure', 'Barnum', 'CHR Boissons', 'DBI', 'Mondial Boissons', 'France Boissons'],
		category: 'Qualification'
	},
	{
		field_id: 7,
		name: 'pression-disponible',
		display_name: 'Pression disponible',
		type_: 'Boolean',
		category: 'Qualification'
	},
	{
		field_id: 8,
		name: 'nombre-bec-de-biere',
		display_name: 'Nombre de bec de bière',
		type_: 'Number',
		category: 'Qualification'
	},
	{
		field_id: 9,
		name: 'styles-biere',
		display_name: 'Styles de bière',
		type_: 'Multiselect',
		field_data: ['PALE ALE', 'LAGER', 'IPA', 'TRIPLE', 'STOUT', 'SOUR', 'DOUBLE IPA', 'DDH', 'GOSE', 'ROUSSE'],
		category: 'Qualification'
	},
	{
		field_id: 10,
		name: 'nb-futs',
		display_name: 'Nombre de fûts',
		type_: 'Number',
		category: 'Qualification'
	},
	{
		field_id: 11,
		name: 'taille-fut',
		display_name: 'Taille du fût',
		type_: 'Number',
		category: 'Qualification'
	},
	{
		field_id: 12,
		name: 'hecto-an',
		display_name: 'Hectolitres par an',
		type_: 'Number',
		category: 'Qualification'
	},
	{
		field_id: 13,
		name: 'personnel-rencontre',
		display_name: 'Personnel rencontré',
		type_: 'Multiselect',
		mandatory: true,
		field_data: ['Gérant', 'Directeur', 'Responsable bière', 'Barman/Barmaid', 'Serveur'],
		category: 'Interlocuteur'
	},
	{
		field_id: 14,
		name: 'ouvert-nvlle-biere',
		display_name: 'Ouvert à une nouvelle bière',
		type_: 'Boolean',
		category: 'Interlocuteur'
	},
	{
		field_id: 15,
		name: 'Engagement',
		display_name: 'Engagement',
		type_: 'Select',
		field_data: ['⭐', '⭐⭐', '⭐⭐⭐'],
		category: 'Interlocuteur'
	},
	{
		field_id: 16,
		name: 'Potentiel',
		display_name: 'Potentiel',
		type_: 'Select',
		field_data: ['⭐', '⭐⭐', '⭐⭐⭐'],
		category: 'Interlocuteur'
	},
	{
		field_id: 17,
		name: 'prochaineactione',
		display_name: 'Prochaines actions ?',
		type_: 'Multiselect',
		field_data: ['Dégustation', 'Deuxième visite', 'Troisième visite', 'Suivi'],
		category: 'Visite'
	},
	{
		field_id: 18,
		name: 'suivi',
		display_name: 'Suivi',
		type_: 'Text',
		category: 'Visite'
	},
	{
		field_id: 19,
		name: 'photos',
		display_name: 'Photos',
		type_: 'Image',
		category: 'Visite'
	},
	{
		field_id: 21,
		name: 'becsanous',
		display_name: 'Becs de la marque',
		type_: 'Number',
		category: 'Visite'
	}
];


export const beer_form_options: Options[] = [
	
];
