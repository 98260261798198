import * as React from 'react';
import { reportingAction, reportingState } from './Reducer';
import storeLang from '../../helpers/storeLang';
import { getTranslate } from 'react-localize-redux';
import { GreenSidely, GreySidely, RedSidely, SidelyBlack } from '../../styles/global/css/Utils';

export const currencies = [
	['EUR', '€'],
	['USD', '$'],
	['JPY', '¥'],
	['GBP', '£'],
	['CHF', 'Fr.'],
	['SEK', 'kr'],
	['KRW', '₩'],
	['CAD', '$']
];

export function getLang() {
	const active_lang = storeLang.getState().localize.languages.filter(l => l.active);
	if (active_lang.length > 0) {
		if (active_lang[0].code == 'en') {
			return 'en-GB';
		} else if (active_lang[0].code == 'fr') {
			return 'fr-FR';
		}
	}
	return 'en-GB';
}

function checkCurrencyCode(currencyCode: string): string {
	for (let i = 0; i < currencies.length; i++) {
		if (currencies[i][0] == currencyCode) {
			return currencyCode;
		}
	}
	return 'EUR';
}

export function formatCurrency(amount: number | null): string {
	const userLocale =
		navigator.languages && (navigator.languages.length > 0)
			? navigator.languages[0]
			: navigator.language;
	let currency = localStorage.getItem('currency') || 'EUR';
	currency = checkCurrencyCode(currency);
	if (amount == null) {
		return '-';
	} else {
		return new Intl.NumberFormat(userLocale, { style: 'currency', currency }).format(amount);
	}
}

export function getCurrencySymbol(): string {
	const userLocale =
		navigator.languages && (navigator.languages.length > 0)
			? navigator.languages[0]
			: navigator.language;
	let currency = localStorage.getItem('currency') || 'EUR';
	currency = checkCurrencyCode(currency);
	return (0).toLocaleString(userLocale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();
}

export function queryConstructor(state, date, additional_object?: object) {
	const { userId, tags, products, userIdList, timeRange, brandList } = state;
	let userIdListQuery = '';
	if (userIdList && userIdList.some(e => Array.isArray(e))) {
		if (userIdList[0] !== undefined) {
			userIdListQuery += userIdList[0].length
				? userIdList[0].reduce((acc, x) => {
					return acc + x + ',';
				}, 'company_owners=[').slice(0, -1) + ']&'
				: 'company_owners=[]&';
		}
		if (userIdList[1] !== undefined) {
			userIdListQuery += userIdList[1].length
				? userIdList[1].reduce((acc, x) => {
					return acc + x + ',';
				}, 'users=[').slice(0, -1) + ']&'
				: 'users=[]&';
		}
	} else if (userIdList && userIdList.length) {
		userIdListQuery = userIdList.reduce((acc, x) => {
			return acc + x + ',';
		}, 'company_owners=[').slice(0, -1) + ']&';
	}
	let ret = (
		'?' +
		(userId ? 'id=' + userId + '&' : '') +
		(date ? 'date=' + date + '&' : '') +
		(brandList ? `brands=[${brandList.map(b => b.id).join(',')}]&` : '') +
		(timeRange ? 'time_range=' + timeRange + '&' : '') +
		(tags && tags.length
			? tags.reduce((acc, x) => {
				return acc + x + '-';
			}, 'tags=').slice(0, -1) + '&'
			: '') +
		(products != null
			? products.reduce((acc, x) => {
				return acc + x + '-';
			}, 'products=').slice(0, -1) + '&'
			: '') +
		userIdListQuery
	);
	additional_object && Object.entries(additional_object).forEach(([key, value]) => ret = `${ret}${key}=${value}&`);
	return ret.slice(0, -1);
}

type PercentageColor = {
	pct: number,
	color: {
		r: number,
		g: number,
		b: number
	}
}

const defaultPercentColors = [
	{ pct: 0.0, color: { r: 0xff, g: 0x76, b: 0x69 } },
	{ pct: 0.65, color: { r: 0xff, g: 0xea, b: 0x2c } },
	{ pct: 1.0, color: { r: 0x2e, g: 0xd4, b: 0x7a } }];

export function getColorForEvolution(dnEvolution: number | null) {
	if (dnEvolution && dnEvolution > 0) return GreenSidely;
	if (dnEvolution && dnEvolution < 0) return RedSidely;
	return GreySidely;
}

export function getColorForPercentage(pct: number, alpha = 1.0, percentColors: PercentageColor[] = defaultPercentColors, isScale01?: boolean) {
	if (isScale01 === true && pct > 1)
		return 'rgb(43, 170, 255)';
	if (pct > 100)
		pct = 100;
	for (var i = 1; i < percentColors.length - 1; i++) {
		if (pct < percentColors[i].pct) {
			break;
		}
	}
	const lower = percentColors[i - 1];
	const upper = percentColors[i];
	const range = upper.pct - lower.pct;
	const rangePct = (pct - lower.pct) / range;
	const pctLower = 1 - rangePct;
	const pctUpper = rangePct;
	const color = {
		r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
		g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
		b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
	};
	return 'rgb(' + [color.r, color.g, color.b].join(',') + ',' + alpha + ')';
}

export const getStringWidth = (s: string) => {
	const maxWidth = 400;
	const minWidth = 120;
	const magicSpacing = 8;
	const sLength = Math.min(maxWidth, s.length * magicSpacing);
	return Math.min(Math.max(minWidth, sLength), maxWidth);
};

export const getColumnWidth = (rows, accessor, headerText) => {
	const maxWidth = 400;
	const magicSpacing = 10;
	const cellLength = Math.max(
		...rows.map(row => (`${row[accessor]}` || '').length),
		headerText.length
	);
	return Math.min(maxWidth, cellLength * magicSpacing);
};

export function CheckGroupFilter(props: {
  list: any[]
  setList: Function
  name?: string
  filterName: string
  state: reportingState
  dispatch: (filters: reportingAction) => void
}) {
	return (
		<>
			{
				props.list.map((option, i) => (
					<React.Fragment key={`reporting_check_box_${props.name}s[${i}]`}>
						<label style={{ width: '150px', height: option.value == -1 ? '15px' : '30px', margin: '0' }} className='check-sidely'>
							<input
								name={`reporting_check_box_${props.name}[${i}]`}
								id={`reporting_check_box_${props.name}[${i}]`}
								type="checkbox"
								value={option.value}
								checked={option.checked}
								onChange={element => {
									let newList;
									const checked = element.target.checked;
									if (option.value == -1) {
										newList = props.list.map(e => {
											return {
												...e,
												checked
											};
										});
										props.setList(newList);
									} else {
										const nbChecked = props.list.slice(1).reduce((acc, e) => e.checked ? acc + 1 : acc, 0);
										const allChecked = nbChecked + 1 == props.list.slice(1).length && checked;
										newList = props.list.map(e => {
											if (e.value == -1) {
												return {
													...e,
													checked: allChecked
												};
											} else if (e.value == option.value) {
												return {
													...e,
													checked
												};
											} else {
												return e;
											}
										});
										props.setList(newList);
									}
									if (props.name !== undefined) {
										props.state.filters[props.filterName][props.name] = newList.filter(e => e.checked && e.value != -1).map(e => e.value);
									} else {
										props.state.filters[props.filterName] = newList.filter(e => e.checked && e.value != -1).map(e => e.value);
									}
									props.dispatch({ type: 'SET_FILTERS', value: { ...props.state.filters } });
								}

								}
							/>
							<div className="checkbox-style" style={{ top: '7px' }} />
							<span style={{
								display: 'inline',
								position: 'relative',
								fontFamily: 'Poppins',
								fontStyle: 'normal',
								fontWeight: 'normal',
								fontSize: '12px',
								lineHeight: '21px',
								left: '20px',
								color: option.color
							}}>{option.label}</span>
						</label>
						{option.value == -1 && <hr className="my-2" />}
					</React.Fragment>
				))
			}
		</>
	);
}

export function stringToColour(str: string) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	let colour = '#';
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xFF;
		colour += ('00' + value.toString(16)).substr(-2);
	}
	return colour;
}

export function renderTimestampAxisTick(value: any, index: number) {
	const translate = getTranslate(storeLang.getState().localize);
	const date = new Date(value);
	const now = new Date(Date.now());
	if (date.getDay() == now.getDay() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
		return translate('shelf_audit.sections.misc.today').toString();
	} else {
		return date.toLocaleString(getLang(), { month: 'short', year: 'numeric' });
	}
}

export function renderPercentageAxisTick(value: any, index: number) {
	return `${value}%`;
}
