import * as React from 'react';
import { DropdownData, DropdownProps } from './model/Model';
import {
	ArrowBlock,
	Asterisk,
	Checkbox,
	CleanButton,
	ContainerCss,
	DropdownContainer,
	InputBlock,
	InputContainer,
	Label,
	LabelContainer,
	OptionBlock,
	OptionBlockClickable,
	OptionContainer,
	OptionImage,
	OptionImageJsx,
	OptionText,
	SortBlock,
	ValidateButton,
	ValidateContainer
} from './style/Style';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FlexDiv } from '../../containers_v2/products/style';
import { Translate } from 'react-localize-redux';
import { DefaultTextDiv } from '../../styles/global/css/GlobalText';
import optionGrey from 'images/icon/options_grey.png';
import { PopupStateContext } from '../popup/PopupStateContext';
import { useFunctionState, useKeyPress } from '../../utils/customHooks';

export function checkClickOut(ref: React.RefObject<HTMLDivElement | undefined>, setOpen: (value: boolean) => void) {
	React.useEffect(() => {
		function handleClickOutside(event) {
			if ((ref.current != null) && !ref.current.contains(event.target)) {
				setOpen(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

function Dropdown<T = any, IsMulti extends boolean = false, isCheckBox extends boolean = false>(props: DropdownProps<T, IsMulti, isCheckBox>): JSX.Element {
	type OnChangeType = IsMulti extends true ? DropdownData<T>[] : isCheckBox extends true ? DropdownData<T>[] : DropdownData<T>;
	const [list, setList] = React.useState<Array<DropdownData<T>>>(props.datalist);
	const [isOpen, setOpen] = useFunctionState<boolean>(props.isOpen ?? false, ({ newValue }) => {
		props.onOpen?.(newValue);
		return newValue;
	});
	const [label, setLabel] = React.useState<any>(props.defaultShowLabel ? props.defaultShowLabel : props.datalist.find(data => data.selected)?.label);
	const [value, setValue] = React.useState<DropdownData<T> | undefined>(props.datalist.find(data => data.selected));
	const [isOptionUp, setOptionUp] = React.useState<boolean>(props.dropdownStyle?.optionUp ?? false);
	const { popupState, setPopupState } = React.useContext(PopupStateContext);
	const tagId = React.useId();

	React.useEffect(() => {
		setList(props.datalist.map((d, i) => { return { ...d, sequence: i }; }));
		if (props.selectedValue == null) {
			const newValue = props.datalist.filter(data => data.selected).shift();
			setLabel(newValue?.label);
			setValue(newValue);
		} else {
			setLabel(props.selectedValue?.label);
			setValue(props.selectedValue);
		}
	}, [JSON.stringify(props.datalist.map(data => ({ value: data.value, checked: data.checked })))]);

	React.useEffect(() => {
		setLabel(props.selectedValue?.label);
		setValue(props.selectedValue);
	}, [props.selectedValue]);

	React.useEffect(() => {
		if (props.defaultShowLabel) {
			setLabel(props.defaultShowLabel ? props.defaultShowLabel : props.datalist.find(data => data.selected)?.label);
		} else {
			setLabel((props.selectedValue != null) ? props.selectedValue.label : props.datalist.find(data => data.selected)?.label);
		}
	}, [props.defaultShowLabel]);

	React.useEffect(() => {
		setOpen(props.isOpen ?? false);
	}, [props.isOpen]);

	React.useEffect(() => {
		setPopupState(state => {
			if (props.isOpen) {
				if (!state.find(id => id == tagId)) return [...state, tagId];
			} else {
				const index = state.findIndex(id => id == tagId);
				if (index >= 0) {
					const newPopupstate = [...state];
					newPopupstate.splice(index, 1);
					return newPopupstate;
				}
			}
			return state;
		});
		return () => {
			setPopupState(state => {
				const index = state.findIndex(id => id == tagId);
				if (index >= 0) {
					const newPopupstate = [...state];
					newPopupstate.splice(index, 1);
					return newPopupstate;
				}
				return state;
			});
		};
	}, [isOpen]);

	const wrapperRef = React.useRef<HTMLDivElement>(null);

	checkClickOut(wrapperRef, setOpen);
	useKeyPress([{ key: 'Escape' }], () => {
		if (popupState[popupState.length - 1] == tagId) {
			setOpen(false);
		}});

	const width = props.dropdownStyle?.width ?? '200px';
	const height = props.dropdownStyle?.height ?? '30px';
	const optionHeight = props.dropdownStyle?.optionHeight ?? '100px';
	const optionWidth = props.dropdownStyle?.optionWidth ?? '400px';
	const optionBlockHeight = props.dropdownStyle?.optionBlockHeight ?? 'auto';

	function reorder(list: DropdownData[], startIndex: number, endIndex: number): DropdownData[] {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result.map((r, i) => { return { ...r, sequence: i }; });
	}

	function onDragEnd(result): void {
		if (!result.destination) {
			return;
		}

		const nList = reorder(list, result.source.index, result.destination.index)
	;(props.onSort != null) && !props.validateButton && props.onSort(nList);
		setList(nList);
	}

	const onOptionClicked = (option: DropdownData<T>, optionI: number) => {
		if (!props.freezeShowLabel) {
			setValue(option);
			setLabel(option.label);
		}
		if (props.checkbox || props.isMulti) {
			list[optionI].checked = !option.checked;
			!props.validateButton && props.onChange?.(list.filter(o => o.checked) as OnChangeType);
			setList([...list]);
			return;
		}
		!props.checkbox && !props.isMulti && setOpen(false);
		!props.validateButton && props.onChange?.(option as OnChangeType);
	};

	return (
		(<div id={props.id} onClick={() => props.containerClickable} ref={wrapperRef} style={ContainerCss(height, props.dropdownStyle?.containerWidth, props.dropdownStyle?.containerJustifyContent, props.containerClickable, props.dropdownStyle?.labelUp, props.dropdownStyle?.margin, props.dropdownStyle?.marginRight, props.dropdownStyle?.alignSelf)} >
			{
				props.label &&
				<LabelContainer alignSelf={props.dropdownStyle?.labelUp ? 'start' : 'center'}>
					{
						props.required &&
						<Asterisk />
					}
					<Label {...props.dropdownStyle?.labelStyle}>{props.label}</Label>
				</LabelContainer>
			}
			<DropdownContainer disabled={props.disabled} cursor={props.dropdownStyle?.cursor} onClick={(event) => {
				props.stopPropagation && event.stopPropagation();
				if (!isOpen && props.autoOptionUp) {
					setOptionUp(event.clientY >= (window.innerHeight / 2.0));
				}
				if (isOpen && !(props.checkbox || props.isMulti) || !isOpen && !props.controlledJSXButtonOpen && !props.disabled) {
					setOpen(!isOpen);
				}
			}}>
				{(props.JSXButton == null) && (
					<InputContainer border={props.dropdownStyle?.containerBorder} width={width} height={height} backgroundColor={props.dropdownStyle?.backgroundColor} >
						{
							props.image
								? <OptionImage src={props.image} alt='' width={props.dropdownStyle?.imageWidth ?? '20px'} height={props.dropdownStyle?.imageHeight} />
								: value?.image
									? <OptionImage src={value.image} alt={typeof value.label === 'string' ? value.label : undefined} />
									: ((value?.imageJsx) != null)
										? <OptionImageJsx>{value?.imageJsx}</OptionImageJsx>
										: <></>
						}
						<InputBlock
							margin={props.dropdownStyle?.inputMargin}
							padding={props.dropdownStyle?.inputPadding}
							clickable={props.onClick !== undefined}
							id={props.id} 
							name={props.name} 
							fontSize={props.dropdownStyle?.fontSize}
							fontColor={props.dropdownStyle?.fontColor}
							fontWeight={props.dropdownStyle?.fontWeight}
							type="text" 
							onClick={props.onClick}
							onChange={(e) => {
								setOpen(true);
								setLabel(e.target.value);
								setList(props.datalist.filter(data => typeof data.label === 'string' ? data.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) : true));
							// ValueChange(value, e.target.value, props, setValue)
							}} value={label} backgroundColor={value?.color ?? props.dropdownStyle?.backgroundColor} required={props.required} readOnly={props.readOnly} disabled={props.disabled} placeholder={props.placeholder} />

						{label && props.cancellable && (
							<CleanButton
								className="btn btn-transparent p-0"
								type="button"
								onClick={e => {
									e.stopPropagation();
									setLabel('');
									setValue(undefined);
									props.onChange?.({ label: '', value: null } as OnChangeType);
									setOpen(false);
								}}
							>
								<i className="fas fa-times" />
							</CleanButton>
						)}

						<ArrowBlock
							className={props.dropdownStyle?.arrowClassName}
							arrowSize={props.dropdownStyle?.arrowSize}
							url={props.dropdownStyle?.arrowClassName ? ' ' : props.dropdownStyle?.arrowImage}
							color={props.dropdownStyle?.arrowColor}
							fontSize={props.dropdownStyle?.arrowFontSize}
						/>
					</InputContainer>
				)}
				{(props.JSXButton != null) && (
					<FlexDiv cursor={props.disabled ? 'not-allowed' : 'pointer'} height='100%' width='100%' justify="center" 
						onClick={() => !props.controlledJSXButtonOpen && !props.disabled && setOpen(!isOpen)}>
						{props.JSXButton({ isOpen, setIsOpen: setOpen, width, height, value })}
					</FlexDiv>
				)}
				{(props.allwaysOpen || isOpen) && props.JSXOption && <OptionContainer isClose={!isOpen} top={isOptionUp ? `-${optionHeight}` : props.dropdownStyle?.containerTop ?? height} height={optionHeight} minHeight={props.dropdownStyle?.optionMinHeight} width={optionWidth} left={props.dropdownStyle?.optionLeft}>
					{props.JSXOption({ options: list, onOptionClicked, setIsOpen: setOpen })}
				</OptionContainer>
				}
				{(props.allwaysOpen || isOpen) && !props.JSXOption && 
					<OptionContainer isClose={!isOpen} top={isOptionUp ? `-${optionHeight}` : props.dropdownStyle?.containerTop ?? height} height={optionHeight} minHeight={props.dropdownStyle?.optionMinHeight} width={optionWidth} left={props.dropdownStyle?.optionLeft}>
						{
							props.checkbox && props.checkboxAll &&
							<div
								style={OptionBlockClickable()}
								key={`option[${props.name}][all]`}
								onClick={() => {
									const checked = list.find(o => !o.checked) === undefined;
									const nList = list.map(o => {
										return {
											...o,
											checked: !checked
										};
									});
									setList(nList);
									!props.validateButton && props.onChange?.(nList.filter(o => o.checked) as OnChangeType);
								}}
							>
								<OptionBlock
									borderBottom="rgb(235, 239, 242) 1px solid"
									heightLength={optionBlockHeight}
									padding={props.dropdownStyle?.optionPadding}
								>
									<Checkbox isActive={list.find(o => !o.checked) === undefined} />
									<OptionImageJsx />
									<OptionText size={props.dropdownStyle?.optionFontSize}><Translate id='all' /></OptionText>
								</OptionBlock>
							</div>
						}
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="dropdown_droppable" placeholder>
								{(droppableProvided, _) => (
									<div style={{ width: '100%', height: '100%', ...(props.gallery ? { display: 'flex', flexWrap: 'wrap' } : {}) }} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} >
										{(props.isMulti ? list.filter(o => !o.checked) : list).length == 0 &&
											<FlexDiv height={'30px'} justify="center"><DefaultTextDiv><Translate id='no_option' /></DefaultTextDiv></FlexDiv>
										}
										{
											list.map((option, optionI) => {
												if (option.checked && props.isMulti) return <></>;
												return (
													<Draggable draggableId={`${option.sequence}`} index={option.sequence} key={`D&D-Option[${optionI}]`}>
														{(dragProvided, _) => (
															<div
																style={OptionBlockClickable()}
																key={`option[${props.name}][${optionI}]`}
																ref={dragProvided.innerRef}
																{...dragProvided.draggableProps}
																onClick={e => {
																	if (option.disabled) e.stopPropagation();
																	!option.disabled && onOptionClicked(option, optionI);
																}}
															>
																{(props.JSXContent != null)
																	? props.JSXContent(option)
																	: (props.JSXContentAndButton != null)
																		? props.JSXContentAndButton(isOpen, setOpen, option)
																		: <OptionBlock
																			disabled={option.disabled}
																			heightLength={optionBlockHeight}
																			backgroundColor={option.color ?? props.dropdownStyle?.backgroundColor}
																			padding={props.dropdownStyle?.optionPadding}
																			width={props.dropdownStyle?.optionTextWidth}
																		>

																			{
																				props.checkbox && <Checkbox isActive={option.checked ?? false} />
																			}
																			{
																				option.image &&
																				<OptionImage src={option.image} alt='' onError={_ => setList(list.map(v => {
																					if (JSON.stringify(v.value) === JSON.stringify(option.value)) {
																						v.image = undefined;
																						return v;
																					}
																					return v;
																				}))} />
																			}
																			{
																				(option.imageJsx != null) && <OptionImageJsx>{option.imageJsx}</OptionImageJsx>
																			}
																			<OptionText color={props.dropdownStyle?.optionFontColor} size={props.dropdownStyle?.optionFontSize} weight={props.dropdownStyle?.optionFontWeight} ellipsis={props.dropdownStyle?.optionEllipsis}>{option.label}</OptionText>

																			{
																				props.sortable &&
																				<SortBlock src={optionGrey} {...dragProvided.dragHandleProps} />
																			}
																		</OptionBlock>
																}
															</div>
														)}
													</Draggable>

												);
											})
										}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						{
							props.validateButton &&
							<ValidateContainer>
								<ValidateButton onClick={() => {
									props.onChange?.(list.filter(l => l.checked) as OnChangeType);
									props.sortable && (props.onSort != null) && props.onSort(list);
									setOpen(false);
								}}><Translate id='validate' /></ValidateButton>
							</ValidateContainer>
						}
					</OptionContainer>
				}
			</DropdownContainer>
		</div>)
	);
}

export default Dropdown;
