import { Layout, Screen } from '../jsonValidator';
import { Fields, Form } from './type';

export const kebabCase = string => string
	.replace(/([a-z])([A-Z])/g, '$1-$2')
	.replace(/[\s_]+/g, '-')
	.toLowerCase();


export function isComponent(layout?: Layout) {
	return layout ? layout.type === 'component' : false;
}

export function changeFieldId(form: Form, fieldName: string, fieldId: number): Form {
	form.forEach(page => {
		page.fields.forEach(field => {
			if (field.name === fieldName) {
				field.field_id = fieldId;
			}
		});
	});
	return form;
}

export function setValidator(form: Form, fieldName: string, validator?: string): Form {
	form.forEach(page => {
		page.fields.forEach(field => {
			if (field.name === fieldName) {
				field.validator = validator;
			}
		});
	});
	return form;

}

export function hasFieldId(form: Form, fieldName: string): boolean {
	let hasFieldId = false;
	form.forEach(page => {
		page.fields.forEach(field => {
			if (field.name === fieldName && field.field_id > 0) {
				hasFieldId = true;
			}
		});
	});
	return hasFieldId;
}

export function swapTwoElementsInLayout(form: Form, page: number, source: number, destination: number): Form {
	const [removedItem] = form[page].layout.children?.splice(source, 1) ?? [];

	form[page].layout.children?.splice(destination, 0, removedItem);
	return JSON.parse(JSON.stringify(form));
}

export function getEnabledFields(page: Screen, fieldList: Fields[]): Fields[] {
	const enabledFields: Fields[] = [];
	page.layout.children?.forEach(child => {
		if (child.type === 'field' && hasFieldId([page], child.name)) {
			enabledFields.push(fieldList.find(element => element.name === child.name));
		}
	});
	return enabledFields;
}

export function addFieldToJsonFields(form: Form, page: number, field: Fields, template: string): Form {
	if (template === 'order') {
		form[page].fields.push({
			field_id: field.field_id,
			metadata: {
				'catalogue': null,
				'key': null,
				'product': null
			},
			name: field.name,
			validator: field.validator,
		});
	}
	else {
		form[page].fields.push({
			field_id: field.field_id,
			metadata: {},
			name: field.name,
			validator: field.validator,
		});
	}
	form[page].layout.children?.push({
		type: 'field',
		name: field.name,
		visible_if: (form[page].name === 'Informations du produit') ? 'current_screen.fields["produit-present"].value == true' : undefined,
	});

	return JSON.parse(JSON.stringify(form));
}

export function setNewFieldData(fieldList: Fields[], name: string, field_data: string[] | number): Fields[] {
	fieldList.forEach(field => {
		if (field.name === name) {
			field.field_data = field_data;
		}
	});
	return JSON.parse(JSON.stringify(fieldList));
}

export function setMandatory(form: Form, fieldName: string, mandatory: boolean): Form {
	form.forEach(page => {
		page.fields.forEach(field => {
			if (field.name === fieldName) {
				if (page.name === 'Informations du produit') {
					if (mandatory) {
						field.validator = `current_screen.fields["produit-present"].value == false or current_screen.fields["${fieldName}"].filled`;
					}
					else {
						field.validator = undefined;
					}
				}
				else {
					if (mandatory) {
						field.validator = `current_screen.fields["${fieldName}"].filled`;
					}
					else {
						field.validator = undefined;
					}
				}
			}
		});
	});
	return form;

}

export function isMandatory(form: Form, fieldName: string): boolean {
	let isMandatory = false;
	form.forEach(page => {
		page.fields.forEach(field => {
			if (field.default != undefined) {
				isMandatory = false;
			}
			else {
				if (field.name === fieldName && field.validator && field.validator.includes(`and current_screen.fields["${fieldName}"].filled`)) {
					isMandatory = true;
				}
				else if (field.name === fieldName && field.validator && field.validator.includes(`or current_screen.fields["${fieldName}"].filled`)) {
					isMandatory = false;
				}
				else if (field.name === fieldName && field.validator && field.validator === `current_screen.fields["${fieldName}"].filled`) {
					isMandatory = true;
				}
			}
		});
	});
	return isMandatory;
}

export function isHistory(form: Form, fieldName: string): boolean {
	let isHistory = false;
	form.forEach(page => {
		page.fields.forEach(field => {
			if (field.name === fieldName && field.history) {
				isHistory = true;
			}
		});
	});
	return isHistory;
}

export function setHistory(form: Form, fieldName: string, history: boolean): Form {
	form.forEach(page => {
		page.fields.forEach(field => {
			if (field.name === fieldName) {
				field.history = history;
			}
		});
	});
	return form;
}
