import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import { FileResult } from './model/Model';
import { AddBlockFolder, Container, FileInput } from './style/InputImageStyle';

export enum InputErrorMode {
  LIMIT_REACHED
}

export interface InputError {
  type: InputErrorMode
  message: string
}

function InputFile(props: {
  onChange?: (value: FileResult) => void
  onError?: (value: InputError) => void
  limitMo?: number
  backgroundColor?: string
  size?: number
  hideAddIcon?: boolean
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const reader = new FileReader();

	return (
		<Container size={props.size ?? 30}>
			{!props.hideAddIcon && <AddBlockFolder backgroundColor={props.backgroundColor} />}
			<FileInput name='image_input' type='file' autoComplete='off' onInput={(e) => {
				const files = e.currentTarget.files;
				const file: File | undefined = (files != null) && files.length > 0 ? files[0] : undefined;
				if (file != null) {
					const url = URL.createObjectURL(file);

					if (props.limitMo && parseFloat(((file.size / 1024) / 1024).toFixed(4)) > props.limitMo) {
						(props.onError != null) && props.onError({
							type: InputErrorMode.LIMIT_REACHED,
							message: translate('global.fire.size_exceeded').toString().replace('{{SIZE}}', props.limitMo.toString())
						});
						return;
					}

					reader.onload = evt => {
						const tab = evt.target?.result?.toString().split(',');
						const contents = (tab != null) ? tab[1] : '';
						const contentType = (tab != null) ? tab[0] : ''
                  ;(props.onChange != null) && props.onChange({
							file,
							content: contents,
							contentType,
							url
						});
					};
					reader.readAsDataURL(file);
				}
			}} />
		</Container>
	);
}

export default InputFile;
