import * as React from 'react';
import styled from 'styled-components';
import { BlueSidely } from '../../styles/global/css/Utils';
import { TOP_HEADBAND_HEIGHT_NB, TopHeadBandContext } from '../../containers_v2/topHeadBand/TopHeadBandProvider';

export type TableCellToolTipProps = {
	bounds: DOMRect,
	content: React.ReactNode
	maxWidth?: string,
	center?: boolean
}

const TableCellToolTipDiv = styled.div<{ bounds: DOMRect, center: boolean | undefined, headBandOpen: boolean }>`
	position: absolute;
	padding: 0.75em 1.5em;
	background-color: ${BlueSidely};
	left: calc(${p => p.bounds.left}px - 58px);
	top: ${p => p.bounds.top - (p.headBandOpen ? TOP_HEADBAND_HEIGHT_NB : 0)}px;
	border-radius: 3px;
	z-index: 8;
	color: white;
	font-size: 13px;
	&:after {
		content: " ";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: ${BlueSidely} transparent transparent transparent;
	}
	translate: ${p => p.center ? `calc(-50% + ${p.bounds.width / 2}px)` : '0'} calc(-100% - 5px);

`;

const ToolTipContentDiv = styled.div<{ maxWidth: string | undefined }>`
	${({ maxWidth }) => maxWidth ? `max-width: ${maxWidth};` : ''}
	
`;

export default function TableCellToolTip(props: TableCellToolTipProps): JSX.Element {
	const { bounds, content } = props;
	const headBand = React.useContext(TopHeadBandContext);
	return <>
		<TableCellToolTipDiv bounds={bounds} center={props.center} headBandOpen={headBand.isOpen}>
			<ToolTipContentDiv maxWidth={props.maxWidth}>
				{content}
			</ToolTipContentDiv>
		</TableCellToolTipDiv>
	</>;
}