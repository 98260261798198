import axios from 'axios';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { ADatePicker, AGranularitySelect } from '../../../atoms/filter/timeFilterAtom';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { momentToNaiveDateTime, useWindowDimensions } from '../../../components_v2/utils';
import storeLang from '../../../helpers/storeLang';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../../import/model';
import { FlexDiv } from '../../products/style';
import ReportingInterpretor from '../interpretor/ReportInterpretor';
import { reportingAction, reportingState } from '../Reducer';
import { getPresenceEvolutionPerProduct } from './action';
import type { Granularity as ValidatorGranularity } from 'validators-web';
import * as wasm from 'validators-web';
import { Granularity } from 'bindings/time/Granularity';
import { Report } from '../interpretor/bareReportingDecoder';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { ReportInterpretorProviderLinksProps } from '../interpretor/hoverContext';

export default function PresenceEvolutionPerProduct(props: {
    state: reportingState
    dispatch: (action: reportingAction) => void
    setToolBarState: (value: ToolbarState) => void
} & ReportInterpretorProviderLinksProps) {
	const [report, setReport] = React.useState<Report>();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();
	const [subComponentToolBarState, setSubComponentToolBarState] = React.useState<ToolbarState>();
	const translate = getTranslate(storeLang.getState().localize);
	const { height, width } = useWindowDimensions();
	const pickedDates = useRecoilValue(ADatePicker);
	const granularity = useRecoilValue(AGranularitySelect);
	const user = useRecoilValue(AUserPicker(0));

	// DO NOT REMOVE THIS OR THE APP WILL CRASH
	() => console.log(wasm);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 200}
					category={AtomCategory.REPORTS}
					elements={[
						{
							kind: ToolbarElement.DATE_PICKER
						},
						{
							kind: ToolbarElement.USER_PICKER
						},
						ToolbarElement.GRANULARITY_SELECT,
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(undefined)} isLoading={loadingState == LoadingStateEnum.LOADING}/>
				{subComponentToolBarState?.bottomLeftToolbarComponent}
			</FlexDiv>,
			bottomRightToolbarComponent: subComponentToolBarState?.bottomRightToolbarComponent ?? <></>

		});
	}, [loadingState, subComponentToolBarState]);

	function convertGranularity(g: Granularity): ValidatorGranularity {
		switch (g) {
			case 'day':
				return wasm.Granularity.Day;
			case 'week':
				return wasm.Granularity.Week;
			case 'month':
				return wasm.Granularity.Month;
			case 'quarter':
				return wasm.Granularity.Quarter;
			case 'year':
				return wasm.Granularity.Year;
		}
	}

	React.useEffect(() => {
		if (!loadingState && (pickedDates != null) && pickedDates[0] && pickedDates[1]) {
			if (!wasm.validate_granularity(pickedDates[0]!.toDate(), pickedDates[1]!.toDate(), convertGranularity(granularity))) {
				console.error('Report is too big');
				setLoadingState(LoadingStateEnum.ERROR);
			} else try {
				setLoadingState(LoadingStateEnum.LOADING);
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });

				getPresenceEvolutionPerProduct({
					begin: momentToNaiveDateTime(pickedDates[0]),
					end: momentToNaiveDateTime(pickedDates[1]),
					granularity,
					user_ids: user?.[0] ?? [],
				}).then(report => {
					setReport(report);
					if (loadingState != LoadingStateEnum.LOADED) {
						setLoadingState(LoadingStateEnum.LOADED);
					}
					props.dispatch({ type: 'SET_IS_LOADING', value: false });
				})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job in: OrderPerProductPerCompany ', e);
						} else {
							console.error(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState(LoadingStateEnum.ERROR);
					});
			} catch (e) {
				console.error(e);
				setLoadingState(LoadingStateEnum.ERROR);
			}
		}
	}, [loadingState, pickedDates]);

	if (!report || loadingState === LoadingStateEnum.LOADING) return <FlexDiv height='100%' width='100%' justify='center'>
		<PageLoader/>
	</FlexDiv>;

	return <ReportingInterpretor
		{...props}
		setToolBarState={setSubComponentToolBarState}
		report={report}
		height={height - 260}
	/>;
}