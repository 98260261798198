import styled, { css } from 'styled-components';
import { DefaultEditable, DefaultText } from '../../styles/global/css/GlobalText';

import {
	BlueSidely,
	BorderColor,
	DarkGreySidely,
	DarkGreySidely2,
	RedSidely,
	SidelyBlack
} from '../../styles/global/css/Utils';


export const TABLE_COLUMN_XL_WIDTH = 220;
export const TABLE_COLUMN_WIDTH = 100;
export const TABLE_IMAGE_WIDTH = 50;

export enum TABLE_IMAGE_PADDING {
	XS = 5,
	DEFAULT = 8,
}

export const Content = styled.div<{ row?: boolean, gap?: number, left?: boolean, wrap?: boolean }>`
  display: flex;
  flex-direction: ${p => p.row ? 'row' : 'column'};
  ${p => p.gap ? `gap: ${p.gap}px;` : ''}
  align-items: center;
  justify-content: ${p => p.left ? 'left' : 'center'};
  ${p => p.wrap ? 'flex-wrap: wrap;' : ''}
`;

export const RightContainer = styled.div<{ isOpen }>`
  transition: width 0.4s;
  width: ${p => p.isOpen ? '75%' : '0'};
  ${p => p.isOpen ? `border-left: 1px solid ${BorderColor};` : ''}
  overflow: hidden;
  height: 100vh;
  overflow: scroll;
`;

export const TopRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const PictureTrash = styled.img`
  width: 15px;
  cursor: pointer;
`;

export const Picture = styled.img`
  max-height: 90px;
  cursor: pointer;
`;

export const TableTitle = styled.td<{ clickable?: boolean, centered?: boolean, verticalAlign?: string }>`
  ${p => p.clickable ? 'cursor: pointer;' : ''}
  ${p => p.centered
		? `
            width: 100%;
            display: flex;
            justify-content: center;
        `
		: ''
}
  padding: 5px 20px 5px 20px;
  color: rgba(37, 65, 83, 0.8);
  ${p => p.verticalAlign ? `vertical-align: ${p.verticalAlign};` : ''}
`;

export const HideTrash = css`
  .trash {
    display: none;
  }

  &:hover {
    .trash {
      display: block;
    }
  }
`;

export const HideTrashDiv = styled.div<{ hoverStyle?: string, hoverTrashStyle?: string }>`
  position: relative;

  .trash {
    display: none;
  }

  &:hover {
    ${p => p.hoverStyle ?? ''}
    .trash {
      ${p => p.hoverTrashStyle ?? ''}
      display: block;
    }
  }
`;

export const TagJSX = styled.div<{ color: string }>`
  border: 1px solid ${p => p.color};
  color: ${p => p.color};
  border-radius: 4px;
  font-size: 12px;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: fit-content;
  margin: 5px;
`;

export const NoneContainer = styled.div`
  ${DefaultText}
  font-weight: 600;
  padding: 3px 8px 3px 8px;
  color: black;
`;

export const InnerContainerTitle = styled.div<{ noBorder?: boolean, margin?: string }>`
  font-size: 14px;
  font-weight: 600;
  margin: ${p => p.margin ?? '0px 30px 15px 30px'};
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  ${p => p.noBorder ? '' : `border-bottom: 1px solid ${BorderColor};`}
  align-items: center;
  padding: 5px 0;
`;

export const InnerContainerContent = styled.div`
  margin: 0 30px;
`;

export const InnerContainer = styled.div`
  width: 100%;
  font-size: 13px;
  margin-top: 15px;
`;

export const TableAnswer = styled.td<{ flex?: boolean, clickable?: boolean }>`
  color: ${SidelyBlack};
  padding: 0 20px;
  max-width: 1000px;
  ${p => p.flex ? 'display: flex;align-items: center;' : ''}
  ${p => p.clickable ? 'cursor: pointer;' : ''}
`;

export const UserPhotoContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserPhoto = styled.img`
  width: 30px;
`;

export const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const ProductPicture = styled.img`
  max-height: 30px;
  max-width: 40px;
`;

export const Link = styled.div<{ centered?: boolean, background?: string }>`
  ${p => p.centered
		? `
            width: 100%;
            display: flex;
            justify-content: center;
        `
		: ''
}
  cursor: pointer;
  color: ${BlueSidely};
  ${p => p.background
		? `
            background-image: url('${p.background}');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            width: 100%;
        `
		: ''
}
`;
export const Status = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  ${p => p.color ? `background-color: ${p.color}; color: white;` : ''}
  border-radius: 5px;
  margin: -2px;
  align-items: center;
  height: 25px;
  padding: 10px;
  width: fit-content;
`;

export const LoaderContainer = styled.div`
  margin-left: 10px;
`;

export const BodyMainTextEditable = styled.p<{
	color?: string,
	noBorder?: boolean,
	height?: string,
	width?: string,
	minHeight?: string,
	after?: string
}>`
  ${DefaultText}
  transition: all 0.4s ease, border-color 0s;
  height: ${p => p.height ?? '100%'};
  display: flex;
  align-items: center;
  min-height: ${p => p.minHeight ?? '1em'};
  ${DefaultEditable}
  ${p => p.color ? `color: ${p.color}` : ''};

  &:empty {
    &:not(:focus) {
      &:before {
        content: '-';
      }
    }
  }

  &:hover {
    ${p => p.noBorder
		? ''
		: `
            border-bottom: 1px solid;
            border-color: ${BlueSidely};
        `}
  }

  ${p => p.width ? `width: ${p.width};` : ''}

  ${p => p.after ? `
        &:after {
            content: "${p.after}";
        }
    ` : ''}
`;

export const Shaking = styled.div`
  :hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(1px, 0, 0);
    }

    30%, 70% {
      transform: translate3d(-2px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(2px, 0, 0);
    }

    50% {
      transform: translate3d(-3px, 0, 0);
    }
  }
`;

export interface FlexDivProps {
	gap?: string
	clickable?: boolean
	cursor?: string
	justify?: 'center' | 'space-between' | 'space-evenly' | 'left' | 'right' | 'space-around' | 'flex-end' | 'flex-start'
	flow?: 'row' | 'column' | 'column-reverse'
	width?: string
	maxWidth?: string
	height?: string
	padding?: string
	margin?: string
	overflow?: 'hidden' | 'scroll' | 'auto'
	scrollGutter?: boolean
	border?: string
	borderTop?: string
	borderBottom?: string
	borderLeft?: string
	borderRight?: string
	borderRadius?: string
	align?: 'center' | 'stretch' | 'flex-start' | 'start' | 'baseline' | 'flex-end'
	flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
	fontWeight?: string
	fontSize?: string
	hideTrash?: boolean
	ellipsis?: boolean
	defaultText?: boolean
	minHeight?: string
	maxHeight?: string
	flexShrink?: number
	backgroundColor?: string
	alignSelf?: string
}

export const FlexDiv = styled.div<FlexDivProps>`
  display: flex;
  ${p => p.defaultText ? DefaultText : ''};
  align-items: ${p => p.align ?? 'center'};
  ${p => p.padding ? `padding: ${p.padding};` : ''}
  ${p => p.width ? `width: ${p.width};` : ''}
  ${p => p.maxWidth ? `max-width: ${p.maxWidth};` : ''}
  ${p => p.height ? `height: ${p.height};` : ''}
  ${p => p.flow ? `flex-flow: ${p.flow};` : ''}
  ${p => p.justify ? `justify-content: ${p.justify};` : ''}
  ${p => p.gap ? `gap: ${p.gap};` : ''}
  ${p => p.clickable ? 'cursor: pointer;' : ''}
  ${p => p.cursor ? `cursor: ${p.cursor};` : ''}
  ${p => p.margin ? `margin: ${p.margin};` : ''}
  ${p => p.overflow ? `overflow: ${p.overflow};` : ''}
  ${p => p.scrollGutter ? 'scrollbar-gutter: stable;' : ''}
  ${p => p.border ? `border: ${p.border};` : ''}
  ${p => p.borderTop ? `border-top: ${p.borderTop};` : ''}
  ${p => p.borderBottom ? `border-bottom: ${p.borderBottom};` : ''}
  ${p => p.borderLeft ? `border-left: ${p.borderLeft};` : ''}
  ${p => p.borderRight ? `border-right: ${p.borderRight};` : ''}
  ${p => p.borderRadius ? `border-radius: ${p.borderRadius};` : ''}
  ${p => p.flexWrap ? `flex-wrap: ${p.flexWrap};` : ''}
  ${p => p.color ? `color: ${p.color};` : ''}
  ${p => p.fontWeight ? `font-weight: ${p.fontWeight};` : ''}
  ${p => p.hideTrash ? HideTrash : ''}
  ${p => p.ellipsis ? `
        text-overflow: ellipsis;
        overflow: hidden;
    ` : ''}
  ${p => p.fontSize ? `font-size: ${p.fontSize};` : ''}
  ${p => p.minHeight ? `min-height: ${p.minHeight};` : ''}
  ${p => p.maxHeight ? `max-height: ${p.maxHeight};` : ''}
  ${p => p.flexShrink !== undefined ? `flex-shrink: ${p.flexShrink};` : ''}
  ${p => p.backgroundColor ? `background-color: ${p.backgroundColor};` : ''}
  ${p => p.alignSelf ? `align-self: ${p.alignSelf};` : ''}
`;

export const SeeAll = styled.div`
  ${DefaultText}
  color: ${BlueSidely};
`;

export const EllipsisOverflowDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  .hide {
    display: none;
  }

  &:hover {
    .hide {
      display: block;
    }
  }

`;

export const TagSearch = styled.input<{ width: string }>`
  ${DefaultText}
  min-width: 50px;
  width: ${p => p.width};
  border: none;
  background: none;
`;

export const TextBorder = styled.div`
  width: 100%;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  position: relative;
  margin-top: 15px
`;

export const TextTile = styled.div`
  ${DefaultText}
  color: #516775;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: -12px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
`;

export const TextArea = styled.div`
  ${DefaultText}
  padding: 15px;
`;

export const SymbolContainer = styled.div`
  ${DefaultText}
  border: 1px solid #F2F2F2;
  font-size: 12px;
  padding: 0.75rem;
  position: relative;
  top: 21px;
  display: flex;
  align-items: center;
  height: 50px;
`;


export const BarcodeContainer = styled.div<{ margin: string }>`
  margin: ${p => p.margin ?? ''};
  overflow: hidden;
`;

export const BarcodeError = styled.div`
  margin: 20px 0 30px 0;
  color: ${RedSidely};
  font-weight: 500;
`;

export const BarcodeTitle = styled.div`
  color: ${DarkGreySidely2};
`;