import { Fields, Form } from '../type';

export const order_final_step = 7;

export const order_form: Form = [
	{
		'buttons': {
			'children': [
				{
					'action': 'next',
					'name': 'Détails facturation',
					'options': {
						'display': {
							'color': 'primary',
							'selected': true
						}
					},
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'columns': [
						{
							'children': [
								{
									'child': {
										'name': 'product_image',
										'type': 'component'
									},
									'options': {
										'display': {
											'width': 50
										}
									},
									'type': 'container'
								},
								{
									'name': 'product_text',
									'type': 'component'
								},
								{
									'name': 'Delete product',
									'type': 'component'
								}
							],
							'name': 'Product',
							'type': 'row'
						},
						{
							'children': [
								{
									'name': 'quantitée-produit',
									'type': 'field'
								},
								{
									'name': 'conditionnement',
									'type': 'field'
								}
							],
							'type': 'row'
						},
						{
							'children': [
								{
									'name': 'remise',
									'type': 'field'
								},
								{
									'name': 'type-de-remise',
									'type': 'field'
								}
							],
							'type': 'row'
						},
						{
							'children': [
								{
									'name': 'total-ht',
									'type': 'field'
								},
								{
									'name': 'total-ttc',
									'type': 'field'
								}
							],
							'type': 'row'
						}
					],
					'name': '{"product": null, "key": null}',
					'read_only': false,
					'value': 'get_present_products()'
				},
				'name': 'recap-table',
				'type': 'card_list'
			},
			{
				'data': 'getProductByUuid(_R.product).images',
				'name': 'product_image',
				'type': 'image'
			},
			{
				'data': 'getProductByUuid(_R.product).name',
				'name': 'product_text',
				'type': 'text'
			},
			{
				'data': [
					'Ajouter produit(s)'
				],
				'name': 'add_product',
				'options': {
					'display': 'add'
				},
				'type': 'links'
			},
			{
				'data': [
					'Catalogues liés au point de vente'
				],
				'name': 'lien-qualification',
				'options': {
					'display': 'text'
				},
				'type': 'links'
			},
			{
				'child': {
					'child': {
						'name': 'trash-svg',
						'type': 'component'
					},
					'options': {
						'display': {
							'color': 'red',
							'height': 30,
							'padding': 5,
							'shape': 'circle',
							'width': 30
						}
					},
					'type': 'container'
				},
				'data': 'deleteFieldsWithSubMetadata({["product"] = _R.product, ["catalogue"] = _R.catalogue, ["key"] = _R.key})',
				'name': 'Delete product',
				'popup': {
					'actions': [
						{
							'action': 'ok',
							'text': 'Oui'
						},
						{
							'action': 'quit',
							'text': 'Non'
						}
					],
					'content': 'Êtes vous sûr de vouloir supprimer le produit ?',
					'title': 'Supprimer produit ?'
				},
				'type': 'button'
			},
			{
				'data': '"trash"',
				'name': 'trash-svg',
				'options': {
					'display': {
						'color': 'white',
						'size': 20
					}
				},
				'type': 'svg'
			}
		],
		'fields': [
			{
				'field_id': 1,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'quantitée-produit',
				'validator': 'current_screen.fields["quantitée-produit"].filled'
			},
			{
				'default': 'PCB',
				'field_id': 2,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'conditionnement',
				'validator': 'current_screen.fields["conditionnement"].filled'
			},
			{
				'field_id': 4,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'remise'
			},
			{
				'field_id': 3,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'type-de-remise',
				'validator': 'current_screen.fields["remise"].filled == false or (current_screen.fields["type-de-remise"].filled and current_screen.fields["remise"].filled)'
			},
			{
				'calculated_value': 'calc_order_product_sum({})',
				'field_id': 5,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'total-ht'
			},
			{
				'calculated_value': 'calc_order_product_sum({ ["taxes"] = true })',
				'field_id': 6,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'total-ttc'
			},
			{
				'field_id': 17,
				'metadata': {},
				'name': 'point-de-vente',
				'options': {
					'display': {
						'map': false
					}
				},
				'validator': 'current_screen.fields["point-de-vente"].filled'
			},
			{
				'calculated_value': 'calc_order_sum({})',
				'field_id': 5,
				'metadata': {},
				'name': 'total-ht-global'
			},
			{
				'calculated_value': 'calc_order_sum({ ["taxes"] = true })',
				'field_id': 6,
				'metadata': {},
				'name': 'total-ttc-global'
			},
			{
				'field_id': 7,
				'metadata': {},
				'name': 'remise-total',
				'options': {
					'display': 'checkbox'
				}
			},
			{
				'field_id': 4,
				'metadata': {},
				'name': 'remise-globale',
				'validator': 'current_screen.fields["remise-globale"].filled'
			},
			{
				'field_id': 3,
				'metadata': {},
				'name': 'type-de-remise-globale',
				'validator': 'current_screen.fields["type-de-remise-globale"].filled'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'point-de-vente',
					'type': 'field'
				},
				{
					'children': [
						{
							'name': 'lien-qualification',
							'type': 'component'
						},
						{
							'children': [
								{
									'name': 'total-ht-global',
									'type': 'field'
								},
								{
									'name': 'total-ttc-global',
									'type': 'field'
								}
							],
							'type': 'row'
						},
						{
							'name': 'recap-table',
							'type': 'component'
						},
						{
							'name': 'add_product',
							'type': 'component'
						},
						{
							'name': 'remise-total',
							'type': 'field'
						},
						{
							'children': [
								{
									'name': 'remise-globale',
									'type': 'field'
								},
								{
									'name': 'type-de-remise-globale',
									'type': 'field'
								}
							],
							'type': 'row',
							'visible_if': 'current_screen.fields["remise-total"].value == true'
						}
					],
					'type': 'column',
					'visible_if': 'current_screen.fields["point-de-vente"].filled'
				}
			],
			'type': 'column'
		},
		'name': 'Nouvelle commande',
		'next_screens': [
			{
				'condition': 'current_screen.fields["point-de-vente"].filled',
				'screen_name': 'Détails facturation'
			}
		],
		'progression': false
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'next',
					'name': 'Ajouter au panier',
					'options': {
						'display': {
							'color': 'primary',
							'selected': true
						}
					},
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': 'getProductByUuid(_G.product).images',
				'name': 'product_image',
				'type': 'image'
			},
			{
				'data': 'getProductByUuid(_G.product).name',
				'name': 'product_text',
				'type': 'text'
			}
		],
		'fields': [
			{
				'field_id': 1,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'quantitée-produit',
				'validator': 'current_screen.fields["quantitée-produit"].filled'
			},
			{
				'default': 'PCB',
				'field_id': 2,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'conditionnement',
				'validator': 'current_screen.fields["conditionnement"].filled'
			},
			{
				'field_id': 4,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'remise'
			},
			{
				'field_id': 3,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'type-de-remise',
				'validator': 'current_screen.fields["remise"].filled == false or (current_screen.fields["type-de-remise"].filled and current_screen.fields["remise"].filled)'
			},
			{
				'calculated_value': 'calc_order_product_sum({})',
				'field_id': 5,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'total-ht'
			},
			// {
			// 	'field_id': 15,
			// 	'metadata': {
			// 		'catalogue': null,
			// 		'key': null,
			// 		'product': null
			// 	},
			// 	'name': 'numéro-lot'
			// },
			{
				'calculated_value': 'calc_order_product_sum({ ["taxes"] = true })',
				'field_id': 6,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'total-ttc'
			},
			{
				'field_id': 16,
				'metadata': {
					'catalogue': null,
					'key': null,
					'product': null
				},
				'name': 'commentaire-produit'
			}
		],
		'layout': {
			'children': [
				{
					'children': [
						{
							'child': {
								'name': 'product_image',
								'type': 'component'
							},
							'options': {
								'display': {
									'width': 50
								}
							},
							'type': 'container'
						},
						{
							'name': 'product_text',
							'type': 'component'
						}
					],
					'name': 'Product',
					'type': 'row'
				},
				{
					'name': 'quantitée-produit',
					'type': 'field'
				},
				{
					'name': 'conditionnement',
					'type': 'field'
				},
				{
					'name': 'remise',
					'type': 'field'
				},
				{
					'name': 'type-de-remise',
					'type': 'field'
				},
				{
					'name': 'total-ht',
					'type': 'field'
				},
				{
					'name': 'total-ttc',
					'type': 'field'
				},
				{
					'name': 'commentaire-produit',
					'type': 'field'
				}
			],
			'type': 'column'
		},
		'name': 'Détails produit',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Catalogue produits'
			}
		],
		'progression': false
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'next',
					'name': 'Récapitulatif commande',
					'options': {
						'display': {
							'color': 'primary',
							'selected': true
						}
					},
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [],
		'fields': [
			{
				'field_id': 8,
				'metadata': {},
				'name': 'adresse-facturation'
			},
			{
				'field_id': 9,
				'metadata': {},
				'name': 'addresse-livraison'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'adresse-facturation',
					'type': 'field'
				},
				{
					'name': 'addresse-livraison',
					'type': 'field'
				}
			],
			'type': 'column'
		},
		'name': 'Détails facturation',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Récapitulatif commande'
			}
		],
		'progression': false
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'finish',
					'condition': 'true',
					'name': 'Terminer commande',
					'options': {
						'display': {
							'color': 'primary',
							'selected': true
						}
					},
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [],
		'fields': [
			{
				'calculated_value': 'calc_order_sum({})',
				'field_id': 5,
				'metadata': {},
				'name': 'total-ht'
			},
			{
				'calculated_value': 'calc_order_sum({ ["taxes"] = true })',
				'field_id': 6,
				'metadata': {},
				'name': 'total-ttc'
			},
			{
				'field_id': 10,
				'metadata': {},
				'name': 'statut-commande'
			},
			{
				'field_id': 11,
				'metadata': {},
				'name': 'date-livraison-souhaitée'
			},
			{
				'field_id': 12,
				'metadata': {},
				'name': 'mode-livraison'
			},
			{
				'field_id': 13,
				'metadata': {},
				'name': 'commentaire-commande'
			},
			{
				'field_id': 14,
				'metadata': {},
				'name': 'distributeur'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'total-ht',
					'type': 'field'
				},
				{
					'name': 'total-ttc',
					'type': 'field'
				},
				{
					'name': 'statut-commande',
					'type': 'field'
				},
				{
					'name': 'distributeur',
					'type': 'field'
				},
				{
					'name': 'date-livraison-souhaitée',
					'type': 'field'
				},
				{
					'name': 'mode-livraison',
					'type': 'field'
				},
				{
					'name': 'commentaire-commande',
					'type': 'field'
				}
			],
			'type': 'column'
		},
		'name': 'Récapitulatif commande',
		'next_screens': [
			{
				'condition': 'true'
			}
		],
		'progression': false
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'next',
					'options': {
						'display': {
							'color': 'primary',
							'selected': true
						}
					},
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'rayon',
					'value': 'filter_map_additional_columns(getAdditionalColumns())'
				},
				'name': 'rayon-dispatcher',
				'type': 'additional_column_qualifier'
			}
		],
		'fields': [],
		'layout': {
			'name': 'rayon-dispatcher',
			'type': 'component'
		},
		'name': 'Catalogues liés au point de vente',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Nouvelle commande'
			}
		],
		'progression': false
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'catalogue',
					'value': 'filter_map_columns(current_company.additional_columns)'
				},
				'name': 'catalogue-dispatcher',
				'options': {
					'display': {
						'progression': false
					}
				},
				'screen': {
					'condition': 'true',
					'screen_name': 'Catalogue produits'
				},
				'type': 'catalogue_dispatcher'
			},
			{
				'data': [
					'Catalogues non liés'
				],
				'name': 'see_all_link',
				'options': {
					'display': 'text'
				},
				'type': 'links'
			}
		],
		'fields': [],
		'layout': {
			'children': [
				{
					'name': 'see_all_link',
					'type': 'component'
				},
				{
					'name': 'catalogue-dispatcher',
					'type': 'component'
				}
			],
			'type': 'column'
		},
		'name': 'Ajouter produit(s)',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Nouvelle commande'
			}
		],
		'progression': false
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'next',
					'options': {
						'display': {
							'color': 'primary',
							'selected': true
						}
					},
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'catalogue',
					'value': 'get_other_assortments()'
				},
				'name': 'catalogue-dispatcher',
				'screen': {
					'condition': 'true',
					'screen_name': 'Catalogue produits'
				},
				'type': 'catalogue_dispatcher'
			}
		],
		'fields': [],
		'layout': {
			'children': [
				{
					'name': 'catalogue-dispatcher',
					'type': 'component'
				}
			],
			'type': 'column'
		},
		'name': 'Catalogues non liés',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Nouvelle commande'
			}
		],
		'progression': false
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'next',
					'name': 'Retour panier',
					'options': {
						'display': {
							'color': 'primary',
							'selected': true
						}
					},
					'type': 'button'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'callback': [
						{
							'color': 'green',
							'condition': 'getSavedFieldByIdAndSubMetaData(1, { ["catalogue"] = catalogue, ["product"] = product }) ~= nil',
							'type': 'border-side'
						}
					],
					'name': 'product',
					'value': 'catalogue'
				},
				'name': 'liste-produits',
				'options': {
					'unique_key': true
				},
				'screen': {
					'condition': 'true',
					'screen_name': 'Détails produit'
				},
				'type': 'product_dispatcher'
			}
		],
		'fields': [],
		'layout': {
			'children': [
				{
					'name': 'liste-produits',
					'type': 'component'
				}
			],
			'type': 'column'
		},
		'name': 'Catalogue produits',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Nouvelle commande'
			}
		],
		'progression': false
	}
];

export const order_fields: Fields[] = [
	{
		field_id: 1,
		name: 'quantitée-produits',
		display_name: 'Quantité produits',
		type_: 'Integer',
		mandatory: true,
		inDraggable: false,
	},
	{
		field_id: 2,
		name: 'conditionnement',
		display_name: 'Conditionnement',
		type_: 'Select',
		category: 'Autres',
		field_data: [
			'PCB',
			'Unité'
		]
	},
	{
		field_id: 3,
		name: 'remise',
		display_name: 'Remise',
		type_: 'Number',
		category: 'Autres'
	},
	{
		field_id: 4,
		name: 'type-de-remise',
		display_name: 'Type de remise',
		type_: 'Select',
		category: 'Autres',
		field_data: [
			'Pourcentage',
			'Valeur',
		]
	},
	{
		field_id: 5,
		name: 'total-ht',
		display_name: 'Total HT',
		type_: 'Number',
		category: 'Autres'
	},
	{
		field_id: 6,
		name: 'total-ttc',
		display_name: 'Total TTC',
		type_: 'Number',
		category: 'Autres'
	},
	{
		field_id: 7,
		name: 'remise-total',
		display_name: 'Remise total',
		type_: 'Boolean',
		category: 'Autres'
	},
	{
		field_id: 8,
		name: 'adresse-facturation',
		display_name: 'Adresse facturation',
		type_: 'Address',
		category: 'Autres'
	},
	{
		field_id: 9,
		name: 'addresse-livraison',
		display_name: 'Addresse livraison',
		type_: 'Text',
		category: 'Autres'
	},
	{
		field_id: 10,
		name: 'statut-commande',
		display_name: 'Statut commande',
		type_: 'Select',
		category: 'Autres',
		field_data: ['Commande passée',
			'En cours de traitement',
			'En préparation',
			'Commande livrée',
			'Commande payée',
			'Commande annulée'
		]
	},
	{
		field_id: 11,
		name: 'date-livraison-souhaitée',
		display_name: 'Date livraison souhaitée',
		type_: 'Date',
		category: 'Autres'
	},
	{
		field_id: 12,
		name: 'mode-livraison',
		display_name: 'Choix du transporteur',
		type_: 'Select',
		category: 'Autres',
		field_data: ['So Colissimo',
			'DHL',
			'Chronopost',
			'UPS',
			'FedEx',
			'Geodis Calberson'
		]
	},
	{
		field_id: 13,
		name: 'commentaire-commande',
		display_name: 'Commentaire commande',
		type_: 'Text',
		category: 'Autres'
	},
	{
		field_id: 14,
		name: 'distributeur',
		display_name: 'Distributeur',
		type_: 'Multiselect',
		category: 'Autres',
		field_data: ['Distributeur 1', 'Distributeur 2', 'Distributeur 3']
	},
	{
		field_id: 15,
		name: 'type-livraison',
		display_name: 'Type de livraison',
		type_: 'Select',
		category: 'Autres',
		field_data: ['Envoi du siège', 'Livraison par le commercial', 'Livraison par le distributeur']
	},
	{
		field_id: 16,
		name: 'commentaire-produit',
		display_name: 'Commentaire produit',
		type_: 'Text',
		category: 'Autres'
	},
	{
		field_id: 17,
		name: 'point-de-vente',
		display_name: 'Point de vente',
		type_: 'Company',
		category: 'Autres'
	}
];
