import * as bare from '@bare-ts/lib';

const config = /* @__PURE__ */ bare.Config({});

export type f32 = number
export type i32 = number
export type u8 = number
export type int = bigint

export type PrimaryCell = null

export type PrimaryValue = null

export type Reference =
    | { readonly tag: 0, readonly val: PrimaryCell }
    | { readonly tag: 1, readonly val: PrimaryValue }
    | { readonly tag: 2, readonly val: {
        readonly complexValue: u8,
    } }
    | { readonly tag: 3, readonly val: {
        readonly primaryValueTupple: u8,
    } }

export function readReference(bc: bare.ByteCursor): Reference {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return { tag, val: null };
		case 1:
			return { tag, val: null };
		case 2:
			return { tag, val: {
				complexValue: bare.readU8(bc),
			} };
		case 3:
			return { tag, val: {
				primaryValueTupple: bare.readU8(bc),
			} };
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeReference(bc: bare.ByteCursor, x: Reference): void {
	bare.writeU8(bc, x.tag);
	switch (x.tag) {
		case 2: {
			{
				bare.writeU8(bc, x.val.complexValue);
			}
			break;
		}
		case 3: {
			{
				bare.writeU8(bc, x.val.primaryValueTupple);
			}
			break;
		}
	}
}

export type PieChartData = {
    readonly perc: f32,
    readonly color: string,
}

export function readPieChartData(bc: bare.ByteCursor): PieChartData {
	return {
		perc: bare.readF32(bc),
		color: bare.readString(bc),
	};
}

export function writePieChartData(bc: bare.ByteCursor, x: PieChartData): void {
	bare.writeF32(bc, x.perc);
	bare.writeString(bc, x.color);
}

export type Empty = null

export type Date =
    | { readonly tag: 0, readonly val: {
        readonly day: string,
    } }
    | { readonly tag: 1, readonly val: {
        readonly week: string,
    } }
    | { readonly tag: 2, readonly val: {
        readonly month: string,
    } }
    | { readonly tag: 3, readonly val: {
        readonly quarter: string,
    } }
    | { readonly tag: 4, readonly val: {
        readonly year: string,
    } }

export function readDate(bc: bare.ByteCursor): Date {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return { tag, val: {
				day: bare.readString(bc),
			} };
		case 1:
			return { tag, val: {
				week: bare.readString(bc),
			} };
		case 2:
			return { tag, val: {
				month: bare.readString(bc),
			} };
		case 3:
			return { tag, val: {
				quarter: bare.readString(bc),
			} };
		case 4:
			return { tag, val: {
				year: bare.readString(bc),
			} };
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeDate(bc: bare.ByteCursor, x: Date): void {
	bare.writeU8(bc, x.tag);
	switch (x.tag) {
		case 0: {
			{
				bare.writeString(bc, x.val.day);
			}
			break;
		}
		case 1: {
			{
				bare.writeString(bc, x.val.week);
			}
			break;
		}
		case 2: {
			{
				bare.writeString(bc, x.val.month);
			}
			break;
		}
		case 3: {
			{
				bare.writeString(bc, x.val.quarter);
			}
			break;
		}
		case 4: {
			{
				bare.writeString(bc, x.val.year);
			}
			break;
		}
	}
}

export type EvolutionTuple = {
    readonly old: Value,
    readonly new: Value,
}

export function readEvolutionTuple(bc: bare.ByteCursor): EvolutionTuple {
	return {
		old: readValue(bc),
		new: readValue(bc),
	};
}

export function writeEvolutionTuple(bc: bare.ByteCursor, x: EvolutionTuple): void {
	writeValue(bc, x.old);
	writeValue(bc, x.new);
}

function read0(bc: bare.ByteCursor): readonly string[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [bare.readString(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = bare.readString(bc);
	}
	return result;
}

function write0(bc: bare.ByteCursor, x: readonly string[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		bare.writeString(bc, x[i]);
	}
}

function read1(bc: bare.ByteCursor): readonly PieChartData[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readPieChartData(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readPieChartData(bc);
	}
	return result;
}

function write1(bc: bare.ByteCursor, x: readonly PieChartData[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writePieChartData(bc, x[i]);
	}
}

export type Value =
    | { readonly tag: 0, readonly val: {
        readonly percentage: f32,
    } }
    | { readonly tag: 1, readonly val: {
        readonly text: string,
    } }
    | { readonly tag: 2, readonly val: {
        readonly key: string,
    } }
    | { readonly tag: 3, readonly val: {
        readonly formattedKey: {
            readonly key: string,
            readonly formats: readonly string[],
        },
    } }
    | { readonly tag: 4, readonly val: {
        readonly float: f32,
    } }
    | { readonly tag: 5, readonly val: {
        readonly int: i32,
    } }
    | { readonly tag: 6, readonly val: {
        readonly colorDot: string,
    } }
    | { readonly tag: 7, readonly val: {
        readonly pieChart: readonly PieChartData[],
    } }
    | { readonly tag: 8, readonly val: {
        readonly date: Date,
    } }
    | { readonly tag: 9, readonly val: {
        readonly datetime: i32,
    } }
    | { readonly tag: 10, readonly val: {
        readonly user: i32,
    } }
    | { readonly tag: 11, readonly val: {
        readonly evolution: EvolutionTuple,
    } }
    | { readonly tag: 12, readonly val: {
        readonly product: string,
    } }
    | { readonly tag: 13, readonly val: {
        readonly list: readonly string[],
    } }
    | { readonly tag: 14, readonly val: Empty }

export function readValue(bc: bare.ByteCursor): Value {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return { tag, val: {
				percentage: bare.readF32(bc),
			} };
		case 1:
			return { tag, val: {
				text: bare.readString(bc),
			} };
		case 2:
			return { tag, val: {
				key: bare.readString(bc),
			} };
		case 3:
			return { tag, val: {
				formattedKey: {
					key: bare.readString(bc),
					formats: read0(bc),
				},
			} };
		case 4:
			return { tag, val: {
				float: bare.readF32(bc),
			} };
		case 5:
			return { tag, val: {
				int: bare.readI32(bc),
			} };
		case 6:
			return { tag, val: {
				colorDot: bare.readString(bc),
			} };
		case 7:
			return { tag, val: {
				pieChart: read1(bc),
			} };
		case 8:
			return { tag, val: {
				date: readDate(bc),
			} };
		case 9:
			return { tag, val: {
				datetime: bare.readI32(bc),
			} };
		case 10:
			return { tag, val: {
				user: bare.readI32(bc),
			} };
		case 11:
			return { tag, val: {
				evolution: readEvolutionTuple(bc),
			} };
		case 12:
			return { tag, val: {
				product: bare.readString(bc),
			} };
		case 13:
			return { tag, val: {
				list: read0(bc),
			} };
		case 14:
			return { tag, val: null };
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeValue(bc: bare.ByteCursor, x: Value): void {
	bare.writeU8(bc, x.tag);
	switch (x.tag) {
		case 0: {
			{
				bare.writeF32(bc, x.val.percentage);
			}
			break;
		}
		case 1: {
			{
				bare.writeString(bc, x.val.text);
			}
			break;
		}
		case 2: {
			{
				bare.writeString(bc, x.val.key);
			}
			break;
		}
		case 3: {
			{
				{
					bare.writeString(bc, x.val.formattedKey.key);
					write0(bc, x.val.formattedKey.formats);
				}
			}
			break;
		}
		case 4: {
			{
				bare.writeF32(bc, x.val.float);
			}
			break;
		}
		case 5: {
			{
				bare.writeI32(bc, x.val.int);
			}
			break;
		}
		case 6: {
			{
				bare.writeString(bc, x.val.colorDot);
			}
			break;
		}
		case 7: {
			{
				write1(bc, x.val.pieChart);
			}
			break;
		}
		case 8: {
			{
				writeDate(bc, x.val.date);
			}
			break;
		}
		case 9: {
			{
				bare.writeI32(bc, x.val.datetime);
			}
			break;
		}
		case 10: {
			{
				bare.writeI32(bc, x.val.user);
			}
			break;
		}
		case 11: {
			{
				writeEvolutionTuple(bc, x.val.evolution);
			}
			break;
		}
		case 12: {
			{
				bare.writeString(bc, x.val.product);
			}
			break;
		}
		case 13: {
			{
				write0(bc, x.val.list);
			}
			break;
		}
	}
}

export type ValueOrRef =
    | { readonly tag: 0, readonly val: {
        readonly value: Value,
    } }
    | { readonly tag: 1, readonly val: {
        readonly reference: Reference,
    } }

export function readValueOrRef(bc: bare.ByteCursor): ValueOrRef {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return { tag, val: {
				value: readValue(bc),
			} };
		case 1:
			return { tag, val: {
				reference: readReference(bc),
			} };
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeValueOrRef(bc: bare.ByteCursor, x: ValueOrRef): void {
	bare.writeU8(bc, x.tag);
	switch (x.tag) {
		case 0: {
			{
				writeValue(bc, x.val.value);
			}
			break;
		}
		case 1: {
			{
				writeReference(bc, x.val.reference);
			}
			break;
		}
	}
}

export type Link =
    | { readonly tag: 0, readonly val: {
        readonly clientCompany: i32,
    } }
    | { readonly tag: 1, readonly val: {
        readonly contact: i32,
    } }
    | { readonly tag: 2, readonly val: {
        readonly order: i32,
    } }
    | { readonly tag: 3, readonly val: {
        readonly product: i32,
    } }
    | { readonly tag: 4, readonly val: {
        readonly assortment: i32,
    } }
    | { readonly tag: 5, readonly val: {
        readonly shelfAudit: i32,
    } }
    | { readonly tag: 6, readonly val: {
        readonly user: i32,
    } }
    | { readonly tag: 7, readonly val: {
        readonly formInstance: string,
    } }

export function readLink(bc: bare.ByteCursor): Link {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return { tag, val: {
				clientCompany: bare.readI32(bc),
			} };
		case 1:
			return { tag, val: {
				contact: bare.readI32(bc),
			} };
		case 2:
			return { tag, val: {
				order: bare.readI32(bc),
			} };
		case 3:
			return { tag, val: {
				product: bare.readI32(bc),
			} };
		case 4:
			return { tag, val: {
				assortment: bare.readI32(bc),
			} };
		case 5:
			return { tag, val: {
				shelfAudit: bare.readI32(bc),
			} };
		case 6:
			return { tag, val: {
				user: bare.readI32(bc),
			} };
		case 7:
			return { tag, val: {
				formInstance: bare.readString(bc),
			} };
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeLink(bc: bare.ByteCursor, x: Link): void {
	bare.writeU8(bc, x.tag);
	switch (x.tag) {
		case 0: {
			{
				bare.writeI32(bc, x.val.clientCompany);
			}
			break;
		}
		case 1: {
			{
				bare.writeI32(bc, x.val.contact);
			}
			break;
		}
		case 2: {
			{
				bare.writeI32(bc, x.val.order);
			}
			break;
		}
		case 3: {
			{
				bare.writeI32(bc, x.val.product);
			}
			break;
		}
		case 4: {
			{
				bare.writeI32(bc, x.val.assortment);
			}
			break;
		}
		case 5: {
			{
				bare.writeI32(bc, x.val.shelfAudit);
			}
			break;
		}
		case 6: {
			{
				bare.writeI32(bc, x.val.user);
			}
			break;
		}
		case 7: {
			{
				bare.writeString(bc, x.val.formInstance);
			}
			break;
		}
	}
}

function read2(bc: bare.ByteCursor): readonly Value[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readValue(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readValue(bc);
	}
	return result;
}

function write2(bc: bare.ByteCursor, x: readonly Value[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writeValue(bc, x[i]);
	}
}

function read3(bc: bare.ByteCursor): readonly (readonly Value[])[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [read2(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = read2(bc);
	}
	return result;
}

function write3(bc: bare.ByteCursor, x: readonly (readonly Value[])[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		write2(bc, x[i]);
	}
}

export type TooltipContent =
    | { readonly tag: 0, readonly val: {
        readonly simpleValue: Value,
    } }
    | { readonly tag: 1, readonly val: {
        readonly table: readonly (readonly Value[])[],
    } }

export function readTooltipContent(bc: bare.ByteCursor): TooltipContent {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return { tag, val: {
				simpleValue: readValue(bc),
			} };
		case 1:
			return { tag, val: {
				table: read3(bc),
			} };
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeTooltipContent(bc: bare.ByteCursor, x: TooltipContent): void {
	bare.writeU8(bc, x.tag);
	switch (x.tag) {
		case 0: {
			{
				writeValue(bc, x.val.simpleValue);
			}
			break;
		}
		case 1: {
			{
				write3(bc, x.val.table);
			}
			break;
		}
	}
}

export type Tooltip = {
    readonly title: Value,
    readonly content: TooltipContent,
}

export function readTooltip(bc: bare.ByteCursor): Tooltip {
	return {
		title: readValue(bc),
		content: readTooltipContent(bc),
	};
}

export function writeTooltip(bc: bare.ByteCursor, x: Tooltip): void {
	writeValue(bc, x.title);
	writeTooltipContent(bc, x.content);
}

function read4(bc: bare.ByteCursor): readonly ValueOrRef[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readValueOrRef(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readValueOrRef(bc);
	}
	return result;
}

function write4(bc: bare.ByteCursor, x: readonly ValueOrRef[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writeValueOrRef(bc, x[i]);
	}
}

function read5(bc: bare.ByteCursor): readonly (readonly ValueOrRef[])[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [read4(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = read4(bc);
	}
	return result;
}

function write5(bc: bare.ByteCursor, x: readonly (readonly ValueOrRef[])[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		write4(bc, x[i]);
	}
}

export type TooltipTemplate = {
    readonly title: Reference,
    readonly content: readonly (readonly ValueOrRef[])[],
}

export function readTooltipTemplate(bc: bare.ByteCursor): TooltipTemplate {
	return {
		title: readReference(bc),
		content: read5(bc),
	};
}

export function writeTooltipTemplate(bc: bare.ByteCursor, x: TooltipTemplate): void {
	writeReference(bc, x.title);
	write5(bc, x.content);
}

function read6(bc: bare.ByteCursor): Link | null {
	return bare.readBool(bc)
		? readLink(bc)
		: null;
}

function write6(bc: bare.ByteCursor, x: Link | null): void {
	bare.writeBool(bc, x !== null);
	if (x !== null) {
		writeLink(bc, x);
	}
}

function read7(bc: bare.ByteCursor): Tooltip | null {
	return bare.readBool(bc)
		? readTooltip(bc)
		: null;
}

function write7(bc: bare.ByteCursor, x: Tooltip | null): void {
	bare.writeBool(bc, x !== null);
	if (x !== null) {
		writeTooltip(bc, x);
	}
}

export type Cell = {
    readonly value: Value,
    readonly link: Link | null,
    readonly tooltip: Tooltip | null,
}

export function readCell(bc: bare.ByteCursor): Cell {
	return {
		value: readValue(bc),
		link: read6(bc),
		tooltip: read7(bc),
	};
}

export function writeCell(bc: bare.ByteCursor, x: Cell): void {
	writeValue(bc, x.value);
	write6(bc, x.link);
	write7(bc, x.tooltip);
}

export type Point = {
    readonly name: Value,
    readonly color: string,
}

export function readPoint(bc: bare.ByteCursor): Point {
	return {
		name: readValue(bc),
		color: bare.readString(bc),
	};
}

export function writePoint(bc: bare.ByteCursor, x: Point): void {
	writeValue(bc, x.name);
	bare.writeString(bc, x.color);
}

export type ComplexCell = {
    readonly value: Value,
    readonly values: readonly Value[],
    readonly link: Link | null,
    readonly tooltip: Tooltip | null,
}

export function readComplexCell(bc: bare.ByteCursor): ComplexCell {
	return {
		value: readValue(bc),
		values: read2(bc),
		link: read6(bc),
		tooltip: read7(bc),
	};
}

export function writeComplexCell(bc: bare.ByteCursor, x: ComplexCell): void {
	writeValue(bc, x.value);
	write2(bc, x.values);
	write6(bc, x.link);
	write7(bc, x.tooltip);
}

function read8(bc: bare.ByteCursor): readonly ComplexCell[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readComplexCell(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readComplexCell(bc);
	}
	return result;
}

function write8(bc: bare.ByteCursor, x: readonly ComplexCell[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writeComplexCell(bc, x[i]);
	}
}

function read9(bc: bare.ByteCursor): readonly (readonly ComplexCell[])[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [read8(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = read8(bc);
	}
	return result;
}

function write9(bc: bare.ByteCursor, x: readonly (readonly ComplexCell[])[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		write8(bc, x[i]);
	}
}

function read10(bc: bare.ByteCursor): readonly Cell[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readCell(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readCell(bc);
	}
	return result;
}

function write10(bc: bare.ByteCursor, x: readonly Cell[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writeCell(bc, x[i]);
	}
}

function read11(bc: bare.ByteCursor): readonly Row[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readRow(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readRow(bc);
	}
	return result;
}

function write11(bc: bare.ByteCursor, x: readonly Row[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writeRow(bc, x[i]);
	}
}

export type Row = {
    readonly cells: readonly (readonly ComplexCell[])[],
    readonly primaryCell: readonly Cell[],
    readonly rows: readonly Row[],
    readonly id: i32,
}

export function readRow(bc: bare.ByteCursor): Row {
	return {
		cells: read9(bc),
		primaryCell: read10(bc),
		rows: read11(bc),
		id: bare.readI32(bc),
	};
}

export function writeRow(bc: bare.ByteCursor, x: Row): void {
	write9(bc, x.cells);
	write10(bc, x.primaryCell);
	write11(bc, x.rows);
	bare.writeI32(bc, x.id);
}

function read12(bc: bare.ByteCursor): TooltipTemplate | null {
	return bare.readBool(bc)
		? readTooltipTemplate(bc)
		: null;
}

function write12(bc: bare.ByteCursor, x: TooltipTemplate | null): void {
	bare.writeBool(bc, x !== null);
	if (x !== null) {
		writeTooltipTemplate(bc, x);
	}
}

function read13(bc: bare.ByteCursor): readonly (TooltipTemplate | null)[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [read12(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = read12(bc);
	}
	return result;
}

function write13(bc: bare.ByteCursor, x: readonly (TooltipTemplate | null)[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		write12(bc, x[i]);
	}
}

function read14(bc: bare.ByteCursor): readonly Point[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readPoint(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readPoint(bc);
	}
	return result;
}

function write14(bc: bare.ByteCursor, x: readonly Point[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writePoint(bc, x[i]);
	}
}

function read15(bc: bare.ByteCursor): readonly Point[] | null {
	return bare.readBool(bc)
		? read14(bc)
		: null;
}

function write15(bc: bare.ByteCursor, x: readonly Point[] | null): void {
	bare.writeBool(bc, x !== null);
	if (x !== null) {
		write14(bc, x);
	}
}

function read16(bc: bare.ByteCursor): readonly (readonly Point[] | null)[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [read15(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = read15(bc);
	}
	return result;
}

function write16(bc: bare.ByteCursor, x: readonly (readonly Point[] | null)[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		write15(bc, x[i]);
	}
}

export type Header = {
    readonly cell: Cell,
    readonly primary: boolean,
    readonly visualisable: boolean,
    readonly tooltip: readonly (TooltipTemplate | null)[],
    readonly barChart: readonly (readonly Point[] | null)[],
    readonly higherIsBetter: boolean,
    readonly storeable: boolean,
}

export function readHeader(bc: bare.ByteCursor): Header {
	return {
		cell: readCell(bc),
		primary: bare.readBool(bc),
		visualisable: bare.readBool(bc),
		tooltip: read13(bc),
		barChart: read16(bc),
		higherIsBetter: bare.readBool(bc),
		storeable: bare.readBool(bc),
	};
}

export function writeHeader(bc: bare.ByteCursor, x: Header): void {
	writeCell(bc, x.cell);
	bare.writeBool(bc, x.primary);
	bare.writeBool(bc, x.visualisable);
	write13(bc, x.tooltip);
	write16(bc, x.barChart);
	bare.writeBool(bc, x.higherIsBetter);
	bare.writeBool(bc, x.storeable);
}

export enum CurveRepresentation {
    BAR_CHART = 'BAR_CHART',
    CURVE = 'CURVE',
}

export function readCurveRepresentation(bc: bare.ByteCursor): CurveRepresentation {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return CurveRepresentation.BAR_CHART;
		case 1:
			return CurveRepresentation.CURVE;
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeCurveRepresentation(bc: bare.ByteCursor, x: CurveRepresentation): void {
	switch (x) {
		case CurveRepresentation.BAR_CHART: {
			bare.writeU8(bc, 0);
			break;
		}
		case CurveRepresentation.CURVE: {
			bare.writeU8(bc, 1);
			break;
		}
	}
}

export type Panel = {
    readonly representation: CurveRepresentation,
    readonly value: Value,
}

export function readPanel(bc: bare.ByteCursor): Panel {
	return {
		representation: readCurveRepresentation(bc),
		value: readValue(bc),
	};
}

export function writePanel(bc: bare.ByteCursor, x: Panel): void {
	writeCurveRepresentation(bc, x.representation);
	writeValue(bc, x.value);
}

export enum AxisKey {
    X = 'X',
    Y = 'Y',
}

export function readAxisKey(bc: bare.ByteCursor): AxisKey {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return AxisKey.X;
		case 1:
			return AxisKey.Y;
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeAxisKey(bc: bare.ByteCursor, x: AxisKey): void {
	switch (x) {
		case AxisKey.X: {
			bare.writeU8(bc, 0);
			break;
		}
		case AxisKey.Y: {
			bare.writeU8(bc, 1);
			break;
		}
	}
}

export enum AxisRepresentation {
    DEFAULT = 'DEFAULT',
    COMPANIES = 'COMPANIES',
}

export function readAxisRepresentation(bc: bare.ByteCursor): AxisRepresentation {
	const offset = bc.offset;
	const tag = bare.readU8(bc);
	switch (tag) {
		case 0:
			return AxisRepresentation.DEFAULT;
		case 1:
			return AxisRepresentation.COMPANIES;
		default: {
			bc.offset = offset;
			throw new bare.BareError(offset, 'invalid tag');
		}
	}
}

export function writeAxisRepresentation(bc: bare.ByteCursor, x: AxisRepresentation): void {
	switch (x) {
		case AxisRepresentation.DEFAULT: {
			bare.writeU8(bc, 0);
			break;
		}
		case AxisRepresentation.COMPANIES: {
			bare.writeU8(bc, 1);
			break;
		}
	}
}

function read17(bc: bare.ByteCursor): readonly Panel[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readPanel(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readPanel(bc);
	}
	return result;
}

function write17(bc: bare.ByteCursor, x: readonly Panel[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writePanel(bc, x[i]);
	}
}

function read18(bc: bare.ByteCursor): readonly Header[] {
	const len = bare.readUintSafe(bc);
	if (len === 0) { return []; }
	const result = [readHeader(bc)];
	for (let i = 1; i < len; i++) {
		result[i] = readHeader(bc);
	}
	return result;
}

function write18(bc: bare.ByteCursor, x: readonly Header[]): void {
	bare.writeUintSafe(bc, x.length);
	for (let i = 0; i < x.length; i++) {
		writeHeader(bc, x[i]);
	}
}

export type Report = {
    readonly panels: readonly Panel[],
    readonly headers: readonly Header[],
    readonly rows: readonly Row[],
    readonly footers: readonly (readonly ComplexCell[])[],
    readonly stacked: boolean,
    readonly title: Value,
    readonly mainAxisKey: AxisKey,
    readonly mainAxisRepresentation: AxisRepresentation,
}

export function readReport(bc: bare.ByteCursor): Report {
	return {
		panels: read17(bc),
		headers: read18(bc),
		rows: read11(bc),
		footers: read9(bc),
		stacked: bare.readBool(bc),
		title: readValue(bc),
		mainAxisKey: readAxisKey(bc),
		mainAxisRepresentation: readAxisRepresentation(bc),
	};
}

export function writeReport(bc: bare.ByteCursor, x: Report): void {
	write17(bc, x.panels);
	write18(bc, x.headers);
	write11(bc, x.rows);
	write9(bc, x.footers);
	bare.writeBool(bc, x.stacked);
	writeValue(bc, x.title);
	writeAxisKey(bc, x.mainAxisKey);
	writeAxisRepresentation(bc, x.mainAxisRepresentation);
}

export function encodeReport(x: Report): Uint8Array {
	const bc = new bare.ByteCursor(
		new Uint8Array(config.initialBufferLength),
		config
	);
	writeReport(bc, x);
	return new Uint8Array(bc.view.buffer, bc.view.byteOffset, bc.offset);
}

export function decodeReport(bytes: Uint8Array): Report {
	const bc = new bare.ByteCursor(bytes, config);
	const result = readReport(bc);
	if (bc.offset < bc.view.byteLength) {
		throw new bare.BareError(bc.offset, 'remaining bytes');
	}

	return result;
}