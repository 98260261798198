import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import { AdditionalColumnType, ReportColumnType } from '../../atoms/additionalColumns';
import { ClusterProperties, MapCompany } from './model';
import { translateToString } from '../../styles/global/translate';
import { GreenSidely, RedSidely } from '../../styles/global/css/Utils';
import { DEFAULT_MAP_PIN_COLOR, getPinColorFromRange } from './cluster';
import * as moment from 'moment';
import { AssortmentShort } from '../../atoms/assortment';
import { getUserColor } from '../../components_v2/avatar/Data/Data';
import { FieldType } from 'bindings/forms/FieldType';
import { IAdditionalColumnsSubValue } from 'proto/protobufs';
import { getColorForPercentage } from '../reports/utils';
import * as React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import MapToolTip from './toolTip';
import { MinMaxAdditionalColumn } from './MapView';

type FnReturn = Omit<DataEntry, 'value'> & { color: string };

export function aggregateNumberField(item: IAdditionalColumnsSubValue | null | undefined, acc: { min: number, max: number }) {
	let val;
	if (!item || item.value === 'empty') return;
	try {
		val = JSON.parse(item?.value ?? '');
		if (typeof val === 'number') {
			acc.min = Math.min(val, acc.min);
			acc.max = Math.max(val, acc.max);
		}
		const value = val as ReportColumnType;
		if (value === null) return;
		if ('int' in value) {
			acc.min = Math.min(value.int, acc.min);
			acc.max = Math.max(value.int, acc.max);
		} else if ('float' in value) {
			acc.min = Math.min(value.float, acc.min);
			acc.max = Math.max(value.float, acc.max);
		} else if ('percentage' in value) {
			acc.min = Math.min(value.percentage * 100, acc.min);
			acc.max = Math.max(value.percentage * 100, acc.max);
		} else if ('evolution' in value && value.evolution.new.val && 'percentage' in value.evolution.new.val) {
			acc.min = Math.min(value.evolution.new.val.percentage * 100, acc.min);
			acc.max = Math.max(value.evolution.new.val.percentage * 100, acc.max);
		}
	} catch (_) {
		return;
	}
}

export function genAdditionalColumnClusterData(
	points: ClusterProperties[],
	pointToValue: (point: ClusterProperties) => IAdditionalColumnsSubValue | null | undefined,
	type: AdditionalColumnType | FieldType | 'CalculatedField',
	rangeColor: { firstRange: number, lastRange: number },
	assortiments: AssortmentShort[],
	minMaxAdditionalColumn: MinMaxAdditionalColumn[],
	field: boolean,
	id: string
): {data: DataEntry[], noToolType?: boolean} {
	let fn: (value: string | null | undefined, name: string | null | undefined) => FnReturn | Array<FnReturn>;
	let noToolType = false;
	const noDataOption = { label: translateToString('no data'), color: DEFAULT_MAP_PIN_COLOR };
	switch (type) {
		case 'Company':
			fn = (_, name) => {
				if (name === undefined || name === null) return noDataOption;
				return { label: name, color: getUserColor(name) };
			};
			break;
		case 'Boolean':
			fn = (value) => {
				if (value === undefined || value === null) return noDataOption;
				const parsed = JSON.parse(value);
				if (parsed === false) return { label: translateToString('false'), color: RedSidely };
				return { label: translateToString('true'), color: GreenSidely };
			};
			break;
		case 'Date': 
			fn = (value) => {
				if (value === undefined || value === null) noDataOption;
				const range = moment().diff(moment.utc(value), 'days');
				const color = getPinColorFromRange(range, rangeColor);
				return { label: color, color: color };
			};
			noToolType = true;
			break;
		case 'Catalogue':
			fn = value => {
				if (value === undefined || value === null) return noDataOption;
				const parsed: number = JSON.parse(value);
				const assortment = assortiments.find(a => a.id === parsed);
				return { label: assortment?.name ?? '', color: getUserColor(assortment?.name ?? '') };
			};
			break;
		case 'String':
		case 'Select':
			fn = value => {
				if (value === undefined || value === null) return noDataOption;
				return { label: value, color: getUserColor(value) };
			};
			break;
		case 'MultiSelect':
		case 'Multiselect':
			fn = value => {
				if (value === undefined || value === null) return noDataOption;
				const parsed: string[] = JSON.parse(value);
				return parsed.map(p => ({ label: p, color: getUserColor(p) }));
			};
			break;
		case 'Integer':
		case 'Number': {
			const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
			const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
			fn = value => {
				if (value === undefined || value === null) return noDataOption;
				const parsed: number = JSON.parse(value);
				const { color, stepIndex } = getColorAndStepBySteps(parsed, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
				return { label: translateToString('from_{{NAME1}}_to_{{NAME2}}', [['NAME1', stepIndex === 0 ? '-inf' : steps[stepIndex - 1].toFixed(2)], ['NAME2', steps[stepIndex].toFixed(2)]]), color };
			};
			break;
		}
		case 'CalculatedField':
		case 'ReportColumn':
			fn = comming => {
				if (comming === undefined || comming === null || comming === 'empty') return noDataOption;
				const value: ReportColumnType = JSON.parse(comming);
				if (typeof value === 'string' || !value) {
					return noDataOption;
				}
				if ('percentage' in value) {
					const steps = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
					const { color, stepIndex } = getColorAndStepBySteps(value.percentage, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
					return { label: translateToString('from_{{NAME1}}_to_{{NAME2}}', [['NAME1', stepIndex === 0 ? '-inf' : `${steps[stepIndex - 1] * 100}%`], ['NAME2', `${steps[stepIndex] * 100}%`]]), color };
				}
				if ('text' in value) {
					return { label: value.text, color: getUserColor(value.text) };
				}
				if ('key' in value) {
					const translated = translateToString(value.key);
					return { label: translated, color: getUserColor(translated) };
				}
				if ('formattedKey' in value) {
					const translated = translateToString(value.formattedKey.key, value.formattedKey.formats.map((replace, i) => [i.toString(), replace]));
					return { label: translated, color: getUserColor(translated) };
				}
				if ('float' in value) {
					const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
					const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
					const { color, stepIndex } = getColorAndStepBySteps(value.float, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
					return { label: translateToString('from_{{NAME1}}_to_{{NAME2}}', [['NAME1', stepIndex === 0 ? '-inf' : steps[stepIndex - 1].toFixed(2)], ['NAME2', steps[stepIndex].toFixed(2)]]), color };
				}
				if ('int' in value) {
					const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
					const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
					const { color, stepIndex } = getColorAndStepBySteps(value.int, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
					return { label: translateToString('from_{{NAME1}}_to_{{NAME2}}', [['NAME1', stepIndex === 0 ? '-inf' : steps[stepIndex - 1].toFixed(2)], ['NAME2', steps[stepIndex].toFixed(2)]]), color };
				}
				if ('colorDot' in value) {
					return { label: value.colorDot, color: value.colorDot };
				}
				if ('pieChart' in value) {
					//TODO pieChart
					return noDataOption;
				}
				if ('date' in value) {
					const range = moment().diff(moment.utc(Object.values(value.date.val)[0]), 'days');
					const color = getPinColorFromRange(range, rangeColor);
					return { label: color, color: color };
				}
				if ('datetime' in value) {
					const range = moment().diff(moment.unix(value.datetime), 'days');
					const color = getPinColorFromRange(range, rangeColor);
					return { label: color, color: color };
				}
				if ('user' in value) {
					//TODO user
					return noDataOption;
				}
				if ('evolution' in value && value.evolution.new.val && 'percentage' in value.evolution.new.val) {
					const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
					const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
					const { color, stepIndex } = getColorAndStepBySteps(value.evolution.new.val.percentage, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
					return { label: translateToString('from_{{NAME1}}_to_{{NAME2}}', [['NAME1', stepIndex === 0 ? '-inf' : steps[stepIndex - 1].toFixed(2)], ['NAME2', steps[stepIndex].toFixed(2)]]), color };
				}
				if ('product' in value) {
					//TODO product
					return noDataOption;
				}
				if ('list' in value) {
					return value.list.map(p => ({ label: p, color: getUserColor(p) }));
				}
				
				return noDataOption;
			};
			break;
		default: return { data: [] };
	}
	return {
		data: points.map(pointToValue).reduce((acc: DataEntry[], val) => {
			const v = fn(val?.value, val?.name);
			const f = (val: FnReturn) => {
				const vInAcc = acc.find(c => c.label === val.label);
				if (!vInAcc) {
					acc.push({ ...val, value: 1 });
				} else {
					vInAcc.value += 1;
				}
			};
			if (Array.isArray(v)) {
				for (const item of v) {
					f(item); 
				}
			} else {
				f(v);
			}
			return acc;
		}, []).sort((a, b) => {
			if (a.color == DEFAULT_MAP_PIN_COLOR) return 1;
			if (b.color == DEFAULT_MAP_PIN_COLOR) return -1;
			return b.value - a.value;
		}),
		noToolType
	};
}

export function genAdditionalColumnPinData(
	value: IAdditionalColumnsSubValue | undefined,
	type: AdditionalColumnType | FieldType,
	rangeColor: { firstRange: number, lastRange: number },
	assortiments: AssortmentShort[],
	details: MapCompany,
	minMaxAdditionalColumn: MinMaxAdditionalColumn[],
	field: boolean,
	id: string
): { color?: string, children?: React.ReactNode, child?: React.ReactNode, subTitle?: string } {
	if (value === undefined || value === null || value?.value === undefined || value?.value === null) return { color: DEFAULT_MAP_PIN_COLOR };
	let parsed;
	try {
		parsed = JSON.parse(value.value);
	} catch (e) {
		parsed = value.value;
	}
	switch (type) {
		case 'Company':
			if (value.name === undefined || value.name === null) return { color: DEFAULT_MAP_PIN_COLOR };
			return { color: getUserColor(value.name), subTitle: value.name };
		case 'Boolean':
			if (parsed) return { color: GreenSidely, subTitle: translateToString('true') };
			return { color: RedSidely, subTitle: translateToString('false') };
		case 'Date': {
			const date = moment.utc(parsed);
			const range = moment().diff(date, 'days');
			return { color: getPinColorFromRange(range, rangeColor), subTitle: date.format('L LT') };
		}
		case 'Catalogue': {
			const assortment = assortiments.find(a => a.id === parsed);
			return { color: getUserColor(assortment?.name ?? ''), subTitle: assortment?.name };
		}
		case 'Number':
		case 'Integer': {
			const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
			const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
			const { color } = getColorAndStepBySteps(parsed, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
			return { color, subTitle: parsed };
		}
		case 'String':
		case 'Select':
			return { color: getUserColor(parsed), subTitle: parsed };
		case 'MultiSelect':
		case 'Multiselect': {
			if (!Array.isArray(parsed)) return { color: DEFAULT_MAP_PIN_COLOR };
			const data = parsed.map(d => ({ label: d, color: getUserColor(d), value: 100 / parsed.length }));
			return {
				child: <div style={{
					width: '30px',
					transform: 'translate(-15px, -15px)'
				}}>
					<PieChart
						className='circle'
						lineWidth={70}
						center={[50, 50]}
						viewBoxSize={[100, 100]}
						data={data}
					/>
					<MapToolTip data={data.filter(d => d.value > 0)} instore title={details.name ?? ''}/>
				</div>
			};
		}
		case 'ReportColumn': {
			const value: ReportColumnType = parsed;
			if (typeof value === 'string' || !value) {
				return { color: DEFAULT_MAP_PIN_COLOR };
			}
			if ('percentage' in value) {
				const steps = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
				const { color } = getColorAndStepBySteps(value.percentage, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
				return { color, subTitle: `${value.percentage * 100}%` };
			}
			if ('text' in value) {
				return { subTitle: value.text, color: getUserColor(value.text) };
			}
			if ('key' in value) {
				const translated = translateToString(value.key);
				return { subTitle: translated, color: getUserColor(translated) };
			}
			if ('formattedKey' in value) {
				const translated = translateToString(value.formattedKey.key, value.formattedKey.formats.map((replace, i) => [i.toString(), replace]));
				return { subTitle: translated, color: getUserColor(translated) };
			}
			if ('float' in value) {
				const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
				const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
				const { color } = getColorAndStepBySteps(value.float, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
				return { color, subTitle: value.float.toString() };
			}
			if ('int' in value) {
				const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
				const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
				const { color } = getColorAndStepBySteps(value.int, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
				return { color, subTitle: value.int.toString() };
			}
			if ('colorDot' in value) {
				return { color: value.colorDot };
			}
			if ('pieChart' in value) {
				//TODO pieChart
				return { color: DEFAULT_MAP_PIN_COLOR };
			}
			if ('date' in value) {
				const date = moment.utc(moment.utc(Object.values(value.date.val)[0]));
				const range = moment().diff(date, 'days');
				return { color: getPinColorFromRange(range, rangeColor), subTitle: date.format('L LT') };
			}
			if ('datetime' in value) {
				const date = moment.utc(moment.unix(value.datetime));
				const range = moment().diff(date, 'days');
				return { color: getPinColorFromRange(range, rangeColor), subTitle: date.format('L LT') };
			}
			if ('user' in value) {
				//TODO user
				return { color: DEFAULT_MAP_PIN_COLOR };
			}
			if ('evolution' in value && value.evolution.new.val && 'percentage' in value.evolution.new.val) {
				const { min, max } = minMaxAdditionalColumn.find(minMax => minMax.id === id && field === minMax.field) ?? { min: 0, max: 0 };
				const steps = [min, ...[2, 3, 4].map(n => min + max - ((max - min) / n)) ,max];
				const { color } = getColorAndStepBySteps(value.evolution.new.val.percentage, steps, { r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 });
				return { color, subTitle: value.evolution.toString() };
			}
			if ('product' in value) {
				//TODO product
				return { color: DEFAULT_MAP_PIN_COLOR };
			}
			if ('list' in value) {
				const data = value.list.map(d => ({ label: d, color: getUserColor(d), value: 100 / value.list.length }));
				return {
					child: <div style={{
						width: '30px',
						transform: 'translate(-15px, -15px)'
					}}>
						<PieChart
							className='circle'
							lineWidth={70}
							center={[50, 50]}
							viewBoxSize={[100, 100]}
							data={data}
						/>
						<MapToolTip data={data.filter(d => d.value > 0)} instore title={details.name ?? ''}/>
					</div>
				};
			}
			
			return { color: DEFAULT_MAP_PIN_COLOR };
		}
		default: return { color: DEFAULT_MAP_PIN_COLOR };
	}
}

type ColorRGB = {
    r: number;
    g: number;
    b: number;
};

export function getStepColors(start: ColorRGB, end: ColorRGB, step: number, maxSteps: number): string {
	return getColorForPercentage((step + 1) / maxSteps, 1, [{ pct: 0, color: start }, { pct: 1, color: end }]);
}

export function getStepsColors(start: ColorRGB, end: ColorRGB, steps: number): string[] {
	return Array.from(Array(steps).keys()).map(i => getStepColors(start, end, i, steps));
}

function getColorAndStepBySteps(value: number, steps: number[], start: ColorRGB, end: ColorRGB): { color: string, stepIndex: number } {
	let index = steps.findIndex(s => value < s);
	if (index < 0) {
		index = steps.length - 1;
	}
	const stepValue = index / (steps.length - 1);
	return {
		color: getColorForPercentage(stepValue, 1, [{ pct: 0, color: start }, { pct: 1, color: end }]),
		stepIndex: index
	};
}