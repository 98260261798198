import { selector } from 'recoil';
import axios from 'axios';
import { URL_FOUNDATION } from '../config/keys';
import { FormTemplate } from '../containers_v2/forms/jsonValidator';
import { FieldType } from 'bindings/forms/FieldType';

// -----------------------[ FIELDS ]----------------------- //
export type Field = {
	id: number,
	name: string,
	data?: object | Array<string>,
	type: FieldType
}

export const AFormFields = selector<Field[]>({
	key: 'AFormFields',
	get: async() => getFields()
});

export function getFields(): Promise<Field[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/forms/fields`).then(res => res.data);
}

// -----------------------[ FORMS ]----------------------- //
export const AFormTemplate = selector<FormTemplate[]>({
	key: 'AFormTemplate',
	get: async() => getFormTemplates()
});

export async function getFormTemplates(body?: { limit?: number, offset?: number }): Promise<FormTemplate[]> {
	return await axios.post(
		`${URL_FOUNDATION}/api/v2/forms/templates/query`, body ?? {}
	).then(res => res.data);
}

export async function putFormTemplate(id: number, body: Partial<FormTemplate>): Promise<FormTemplate> {
	return await axios.put(`${URL_FOUNDATION}/api/v2/forms/templates/${id}`, body).then(res => res.data);
}