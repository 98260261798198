/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import { Position } from './modalRight/model';
import LayersImage from 'images/icon/map/layers.svg';
import LassoWhite from 'images/icon/map/lasso_white.svg';
import LassoBlack from 'images/icon/map/lasso_black.svg';
import MapPinWhite from 'images/icon/map/pin_white.svg';
import MapPinBlack from 'images/icon/map/pin_black.svg';
import { MAP_STYLE_LIST, MapStyleDefault } from './style';
import { useRecoilValue } from 'recoil';
import { AProfile, AUsers } from '../../atoms/global/users';
import { BlueSidely } from '../../styles/global/css/Utils';
import useSupercluster from 'use-supercluster';
import { Cluster, ClusterProperties, InitialMapRow, MapCompany, MapData, Point, isCluster } from './model';
import { activateMapListeners, onLassoClick } from './lasso';
import GoogleMapReact from 'google-map-react';
import { MapPinComponent } from './MapPin';
import { AFrequencyEventType } from '../../atoms/global/frequency';
import { FetchKey, MinMax, MinMaxAdditionalColumn } from './MapView';
import { ModalState } from '../products/model';
import ClusterIcon from './cluster';
import { CompanyStatus } from '../client-companies/model/Model';

const GOOGLE_KEY = {
	key: 'AIzaSyCYkQC599NV99WgBGvW2RHABKHXowlisgw'
};

const mapStyleVar = parseInt(localStorage.getItem('mapStyle') ?? '0');
export const Marker = ({ children, lat: _lat, lng: _lng }) => children;
const defaultCenter = { lat: 48.85356482880834, lng: 2.348096962645894 };
const defaultZoom = 6;

interface MapState {
  zoom: number
  bounds: number[]
  center: Position
}

const MAP_BASIC_BUTTON_STYLE = {
	backgroundColor: '#fff',
	border: '2px solid #fff',
	boxShadow: '0 2px 6px rgba(0,0,0,.3)',
	cursor: 'pointer',
	padding: '5px',
	textAlign: 'center',
	width: '40px',
	height: '40px',
};

const MAP_ROUND_BUTTON_STYLE = {
	...MAP_BASIC_BUTTON_STYLE,
	borderRadius: '50%',
	margin: '10px',
};

const MAP_SQUARE_BUTTON_STYLE = {
	...MAP_BASIC_BUTTON_STYLE,
	borderRadius: '3px',
	marginRight: '10px',
};


function CreateMapButton(controlDiv: HTMLDivElement, callBack: (p: { button: HTMLDivElement, image: HTMLImageElement}) => () => void, baseImage: string, style: object, title: string) {
	// Set CSS for the control border.
	const controlUI = document.createElement('div');
	for (const [key, value] of Object.entries(style)) {
		controlUI.style[key] = value;
	}
	controlUI.title = title;
	controlDiv.appendChild(controlUI);

	// Set CSS for the control interior.
	const controlImg = document.createElement('img');
	controlImg.src = baseImage;
	controlImg.style.width = '27px';
	controlImg.style.height = '27px';
	controlUI.appendChild(controlImg);

	// Change Style
	controlUI.addEventListener('click', callBack({ button: controlUI, image: controlImg }));
}

export default function Map(props: {
	points: Point[],
	tabType: FetchKey,
	companiesInLasso: InitialMapRow[],
	statuses: CompanyStatus[],
	setIsModalRightOpen: React.Dispatch<React.SetStateAction<boolean>>,
	setCompaniesInLasso: React.Dispatch<React.SetStateAction<InitialMapRow[]>>,
	rangeColor: { firstRange: number, lastRange: number },
	onMapMarkerCompanyClicked: (companyId: number, noCloseCluster?: true) => void,
	setClusterPopupState: React.Dispatch<React.SetStateAction<ModalState<ClusterProperties[]>>>
	setModalOptions: React.Dispatch<React.SetStateAction<{ isOpen: boolean, idSelected: number }>>
	modalOptions: { isOpen: boolean, idSelected: number },
	apiData: MapData | undefined,
	minMaxAdditionalColumn: MinMaxAdditionalColumn[]
	minMaxCalculatedFields: MinMaxAdditionalColumn[]
	checkoutsMinMax?: MinMax
}) {
	const { points, tabType, companiesInLasso, statuses, setIsModalRightOpen, setCompaniesInLasso, rangeColor, onMapMarkerCompanyClicked, setClusterPopupState, setModalOptions, modalOptions } = props;
	const profile = useRecoilValue(AProfile);
	const [currentPosition, setCurrentPosition] = React.useState<Position | undefined>(profile && profile.latitude && profile.longitude && profile.use_address_as_default_map_center ? { lat: profile.latitude, lng: profile.longitude } : undefined);
	const [mapState, setMapState] = React.useState<MapState>({ zoom: defaultZoom, bounds: [], center: currentPosition ?? defaultCenter });
	const [mapStyle, setMapStyle] = React.useState<number>(MAP_STYLE_LIST[mapStyleVar] ? mapStyleVar : 0);
	const [maps, setMaps] = React.useState(null);
	const [lasso, setLasso] = React.useState(null);
	const [addingToLassoOnClick, setAddingToLassoOnClick] = React.useState<boolean>(false);
	const selectedFrequencyEventType = useRecoilValue(AFrequencyEventType);
	const users = useRecoilValue(AUsers);
	const [key, setKey] = React.useState<number>(0);

	React.useEffect(() => {
		if (currentPosition) {
			setMapState((mapState) => ({ ...mapState, center: currentPosition }));
		}
	}, [currentPosition]);

	React.useEffect(() => {
		if (!profile.latitude && !profile.latitude && !profile.use_address_as_default_map_center)
			navigator.geolocation.getCurrentPosition(
				position => setCurrentPosition({ lat: position.coords.latitude, lng: position.coords.longitude }), 
				err => console.error(err)
			);
	}, []);

	React.useEffect(() => {
		if (lasso && maps) {
			points.forEach(point => {
				if (point && point.geometry.coordinates.every(e => e !== undefined)) {
					// @ts-expect-error maps
					const marker = new maps.Marker({
						position: { lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0] },
						map: null
					});
					// @ts-expect-error maps
					if (maps.geometry.poly.containsLocation(marker.getPosition(), lasso)) {
						const index = companiesInLasso.findIndex(c => c.id == point.properties.detail.id);
						if (index >= 0) {
							companiesInLasso[index] = point.properties.detail as InitialMapRow;
						} else {
							companiesInLasso.push(point.properties.detail as InitialMapRow);
						}
					}
				}
			});
			setIsModalRightOpen(true);
			setCompaniesInLasso([...companiesInLasso]);
		}
	}, [lasso]);

	const { clusters } = useSupercluster({
		points: points.map(p => ({ ...p, geometry: { coordinates: [p.geometry.coordinates[0] ?? 0, p.geometry.coordinates[1] ?? 0] } })),
		bounds: mapState.bounds,
		zoom: mapState.zoom,
		options: {
			radius: 60,
			minZoom: 3,
			maxZoom: 24,
			nodeSize: 64,
			extent: 512,
			log: false,
			generate: false,
			minPoints: 2,
			map: (p: { detail: MapCompany }): { details: ClusterProperties[] } => ({
				details: [{
					id: p.detail.id,
					owner: p.detail.ownerId,
					status: p.detail.clientStatusId,
					statusColor: p.detail.colorCode,
					// events
					range: p.detail.range,
					nextRange: p.detail.nextRange,
					next: p.detail.next,
					// distribution
					distribution: p.detail.distribution,
					// @ts-expect-error orders
					orderRange: p.detail.orderRange,
					// frequency
					frequencies: p.detail.frequencies,
					checkouts: p.detail.checkoutEvolution,
					additionalColumnsValue: p.detail.additionalColumnsValue,
					additionalFieldColumnsValue: p.detail.additionalFieldColumnsValue,
					calculatedFieldColumnsValue: p.detail.calculatedFieldColumnsValue
				}]
			}),
			reduce: (acc: { details: ClusterProperties[] }, p: { details: ClusterProperties[] }) => {
				acc.details = acc.details.concat(p.details);
				return acc;
			}
		}
	});


	const handleApiLoaded = React.useCallback(({ map, maps }) => {
		const nightModeDiv = document.createElement('div');
		CreateMapButton(nightModeDiv, () => () => {
			setMapStyle((n) => {
				const styleIndex = n === 0 ? 1 : 0;
				localStorage.setItem('mapStyle', styleIndex.toString());
				return styleIndex;
			});
		}, LayersImage, MAP_SQUARE_BUTTON_STYLE, 'LayersSwap');
		const LassoDiv = document.createElement('div');
		CreateMapButton(LassoDiv, ({ button, image }) => () => {
			image.src = !map.lassoEnabled ? LassoWhite : LassoBlack;
			button.style.backgroundColor = !map.lassoEnabled ? BlueSidely : '#fff';
			button.style.border = `2px solid ${!map.lassoEnabled ? BlueSidely : '#fff'}`;
			onLassoClick(map, setLasso);
		}, map.lassoEnabled ? LassoWhite : LassoBlack, MAP_ROUND_BUTTON_STYLE, 'LassoSelector');
		const pinDiv = document.createElement('div');
		CreateMapButton(pinDiv, ({ button, image }) => () => {
			image.src = !map.addingToLasso ? MapPinWhite : MapPinBlack;
			button.style.backgroundColor = !map.addingToLasso ? BlueSidely : '#fff';
			button.style.border = `2px solid ${!map.addingToLasso ? BlueSidely : '#fff'}`;
			setAddingToLassoOnClick(!map.addingToLasso);
			map.setOptions({ addingToLasso: !map.addingToLasso });
		}, map.addingToLasso ? MapPinWhite : MapPinBlack, MAP_ROUND_BUTTON_STYLE, 'MapPinSelector');
		map.controls[maps.ControlPosition.RIGHT_TOP].push(nightModeDiv);
		map.controls[maps.ControlPosition.TOP].push(LassoDiv);
		map.controls[maps.ControlPosition.TOP].push(pinDiv);
		activateMapListeners(map, maps, setMaps, setLasso);
	}, []);
	const night = MAP_STYLE_LIST[mapStyle]?.[1] ?? false;

	const onClusterCliked = React.useCallback((data: ClusterProperties[]) => {
		setClusterPopupState({ isOpen: true, data });
		const url = new URL(window.location.href);
		url.searchParams.delete('company');
		window.history.pushState({}, '', url);
		setModalOptions(modalLeftOptions => ({ ...modalLeftOptions, isOpen: false }));
	}, []);

	return <GoogleMapReact
		key={key}
		bootstrapURLKeys={GOOGLE_KEY}
		defaultCenter={defaultCenter}
		center={mapState.center}
		defaultZoom={defaultZoom}
		zoom={mapState.zoom}
		options={React.useCallback((maps) => ({
			zoomControlOptions: {
				position: maps.ControlPosition.RIGHT_TOP,
				style: maps.ZoomControlStyle.SMALL
			},
			styles: MAP_STYLE_LIST[mapStyle][0] ?? MapStyleDefault
		}), [mapStyle])}
		onChange={React.useCallback((val) => {
			if (!val.size || val.size.width === 0) {
				setKey((k) => k + 1);
			}
			setMapState({
				zoom: val.zoom,
				bounds: [
					val.bounds.nw.lng,
					val.bounds.se.lat,
					val.bounds.se.lng,
					val.bounds.nw.lat
				],
				center: val.center
			});
		}, [])}
		yesIWantToUseGoogleMapApiInternals
		onGoogleApiLoaded={handleApiLoaded}
	>
		{clusters.map((cluster: Cluster | Point) => {
			const [longitude, latitude] = cluster.geometry.coordinates;
			if (isCluster(cluster)) {
				return <Marker
					key={`cluster-${cluster.id}`}
					lat={latitude}
					lng={longitude}
				>
					<ClusterIcon
						companiesInLasso={companiesInLasso}
						night={night}
						points={points}
						pointCount={cluster.properties.point_count}
						eventType={selectedFrequencyEventType}
						properties={cluster.properties.details}
						tabType={tabType}
						rangeColor={rangeColor}
						users={users}
						statuses={statuses}
						onClusterClicked={onClusterCliked}
						mapData={props.apiData}
						minMaxAdditionalColumn={props.minMaxAdditionalColumn}
						minMaxCalculatedFields={props.minMaxCalculatedFields}
						checkoutsMinMax={props.checkoutsMinMax}
					/>
				</Marker>;
			} else {
				return <Marker key={`crime-${cluster.properties.id}`} lat={latitude} lng={longitude}>
					<MapPinComponent
						cluster={cluster}
						companiesInLasso={companiesInLasso}
						modalOptions={modalOptions}
						tabType={tabType}
						rangeColor={rangeColor}
						night={night}
						addingToLassoOnClick={addingToLassoOnClick}
						setIsModalRightOpen={setIsModalRightOpen}
						setCompaniesInLasso={setCompaniesInLasso}
						onMapMarkerCompanyClicked={onMapMarkerCompanyClicked}
						mapData={props.apiData}
						statuses={statuses}
						minMaxAdditionalColumn={props.minMaxAdditionalColumn}
						minMaxCalculatedFields={props.minMaxCalculatedFields}
						checkoutsMinMax={props.checkoutsMinMax}
					/>
				</Marker>;
			}
		})}
	</GoogleMapReact>;
}