import * as React from 'react';
// import { getEventStatuses, getEventTypes } from './actions';
import { Collapse } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { CheckGroupFilter } from '../utils';
import { getUsers } from './actions';
import { useStore } from 'react-redux';
import { reportingAction, reportingState } from '../Reducer';
import { ButtonToggle } from '../../../styles/global/css/Open';

type LoadingState = 'loading' | 'loaded' | 'error' | null

function statesInit(filters: Object, key: string) {
	switch (key) {
		case 'lines':
			const translate = getTranslate(storeLang.getState().localize);
			const checked = !(filters.lines && filters.lines.every(e => e != 0));
			return [{ label: translate('shelf_audit.filters.show_companies_to_display'), value: 0, checked }];
	}
	return [];
}

export default function PresencePerProductPerCompanyFilters(props: {
  state: reportingState
  dispatch: (filters: reportingAction) => void
}) {
	const filterName = 'PresencePerProductPerCompany';
	const [activeCollapse, setActiveCollapse] = React.useState<string[]>([]);
	const [showLines, setShowLines] = React.useState<any[]>(statesInit(props.state.filters[filterName], 'lines'));
	const [users, setUsers] = React.useState<any[]>(statesInit(props.state.filters[filterName], 'users'));
	const [loadingState, setLoadingState] = React.useState<LoadingState>(null);
	const store = useStore().getState();

	React.useEffect(() => {
		// wait user or launch apis
		if (store.user.isLoading && !loadingState) {
			setLoadingState('loading');
			setTimeout(() => setLoadingState(null), 1000);
		} else if (!loadingState) {
			setLoadingState('loading');
			const apis = async() => {
				try {
					const usersFilter = props.state.filters[filterName].users;
					const api_users = await getUsers(store.user.current);
					setUsers([{ label: '', value: -1, checked: !(usersFilter && usersFilter.length != api_users.data.data.length) }, ...api_users.data.data.map(e => {
						return {
							label: e.name,
							value: e.id,
							checked: !(usersFilter && usersFilter.every(x => x != e.id))
						};
					})]);
				} catch (e) {
					console.log(e);
					setLoadingState('error');
				}
			};
			apis();
		}
	}, [loadingState]);

	function toggle(type: string) {
		const active = [...activeCollapse];
		if (active.includes(type)) {
			active.splice(active.indexOf(type), 1);
		} else {
			active.push(type);
		}
		setActiveCollapse(active);
	}

	function buttonToggle(title: string, key: string, alreadyTranslated?: boolean) {
		return <ButtonToggle
			onClick={() => toggle(key)}
			alreadyTranslated={alreadyTranslated}
			key={key}
			title={title}
			isOpen={activeCollapse.includes(key)}
		/>;
	}

	return (
		<div style={{ margin: '10px 0px 0px 20px' }}>
			<div className='col'>
				{buttonToggle('shelf_audit.filters.companies_to_display', 'lines')}
				<Collapse isOpen={activeCollapse.includes('lines')}>
					<CheckGroupFilter list={showLines} setList={setShowLines} name="lines" filterName={filterName} {...props} />
				</Collapse>
			</div>
			{/* <div className='col'>
                {buttonToggle('shelf_audit.column.user', 'users')}
                <Collapse isOpen={activeCollapse.includes('users')}>
                    <CheckGroupFilter list={users} setList={setUsers} name="users" filterName={filterName} {...props} />
                </Collapse>
            </div> */}
		</div>
	);
}
