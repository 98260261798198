import * as React from 'react';
import { translateToString } from '../../styles/global/translate';
import { ToolbarState } from '../globals/mainPage/mainPage';
import StatusAndTagsSettings from './subSettings/statusesAndTagsSettings';
import { TagType } from '../../components_v2/toolbarFilter/tags/Model';
import BrandManagement from './subSettings/brandManagement';
import TaxManagement from './subSettings/taxManagement';
import ThirdPartyIntegration from './subSettings/thirdPartyIntegration';
import Import from '../import/Import';
import { Exports } from '../exports/Exports';
import GroupList from '../authorization/groups/GroupList';
import Members from '../authorization/users/Members';
import CategoryManagement from './subSettings/categoryManagement';
import MappingFieldsSettings from './subSettings/mappingSettings';
import SettingsInterpreter, { SystemCategory } from './settingsInterpreter';
import UserSubSettings from './userSettings/userSubSettings';
import MyProfile from './userSettings/myProfile';
import OrderNotificationsSettings from './subSettings/orderNotificationsSettings';
import MetricsV2 from './subSettings/metrics';
import EmailTemplateSettings from './subSettings/emailTemplateSettings';
import FormTemplateList from '../forms/FormTemplateList';
import { CreateForm } from '../forms/create-from-template/FormCreation';
import Checkouts from '../checkouts/Checkouts';
import { FileManager } from '../documents/filemanager';

export default function SystemSettings(props: {
	setToolBarState: (value: ToolbarState) => void
}) {

	const setToolBarState = React.useCallback((state: ToolbarState) => props.setToolBarState({
		bottomLeftToolbarComponent: <></>,
		bottomRightToolbarComponent: <></>,
		...state,
		title: translateToString('settings'),
	}), []);
	const categories: SystemCategory[] = [
		{
			title: translateToString('user_settings'),
			children: [
				{
					name: translateToString('your_preferences'),
					children: [
						{ key: 'my_profile', component: <MyProfile setToolBarState={setToolBarState} />, restricted: { objectAction: 'ViewUserSettings' } },
						{ key: 'my_settings', component: <UserSubSettings setToolBarState={setToolBarState} /> },
						// { key:'security', component: <GroupList setToolBarState={setToolBarState} />},
					]
				}
			]
		},
		{
			title: translateToString('system_settings'),
			children: [
				{
					name: translateToString('account_settings'),
					restricted: { objectAction: 'ViewAccountSettings' },
					owner: true,
					children: [
						// { key:'company_informations', component: <CompanyInformationsSettings />},
						{ key: 'users', component: <Members setToolBarState={setToolBarState} /> },
						{ key: 'groups', component: <GroupList setToolBarState={setToolBarState} /> },
					]
				},
				{
					name: translateToString('field_settings'),
					restricted: { objectAction: 'ViewCompanySettings' },
					children: [
						{ key: 'company_fields', component: <StatusAndTagsSettings setToolBarState={setToolBarState} type={TagType.COMPANY}/> },
						{ key: 'contacts_fields', component: <StatusAndTagsSettings setToolBarState={setToolBarState} type={TagType.CONTACT}/> },
						{ key: 'product_fields', component: <StatusAndTagsSettings setToolBarState={setToolBarState} type={TagType.PRODUCT}/> },
						{ key: 'order_fields', component: <StatusAndTagsSettings setToolBarState={setToolBarState} type={TagType.ORDER}/> },
						{ key: 'opportunity_fields', component: <StatusAndTagsSettings setToolBarState={setToolBarState} type={TagType.OPPORTUNITY}/> },
						{ key: 'mapping_fields', component: <MappingFieldsSettings setToolBarState={setToolBarState} /> },
					]
				},
				{
					name: translateToString('products_settings'),
					restricted: { objectAction: 'ViewCompanySettings' },
					children: [
						{ key: 'brand_management', component: <BrandManagement setToolBarState={setToolBarState} /> },
						{ key: 'categories_management', component: <CategoryManagement setToolBarState={setToolBarState} /> },
						{ key: 'tax_management', component: <TaxManagement setToolBarState={setToolBarState} /> },

					]
				},
				{
					name:translateToString('email_template_settings'),
					children:[
						{ key:'email_template_list', component:<EmailTemplateSettings setToolBarState={setToolBarState} /> }
					]
				},
				{
					name: translateToString('third_party_integrations'),
					children: [
						{ key: 'calendar_integrations', component: <ThirdPartyIntegration type='calendar' setToolBarState={setToolBarState} /> },
						{ key: 'crm_integrations', component: <ThirdPartyIntegration type='crm' setToolBarState={setToolBarState} /> },
						// { key: 'emailing_integrations', component: <ThirdPartyIntegration type='emailing' setToolBarState={setToolBarState} /> },
					]
				},
				{
					name: translateToString('orders_settings'),
					admin: true,
					children: [
						{ key: 'notifications', component: <OrderNotificationsSettings setToolBarState={setToolBarState} />, admin: true }
					]
				},
				{
					restricted: { objectAction: 'ViewForm' },
					name: translateToString('forms_settings'),
					children: [
						{ key: 'form_templates', component: <FormTemplateList setToolBarState={setToolBarState} /> },
						{ key: 'form_library', component: <CreateForm setToolBarState={setToolBarState} />, admin: true },
					]
				},
				{
					restricted: { objectAction: 'ViewCheckout' },
					admin: true,
					name: translateToString('checkouts'),
					children: [
						{ key: 'checkouts', component: <Checkouts setToolBarState={setToolBarState} /> },
					]
				},
				{
					name: translateToString('file_manager.files'),
					admin: true,
					children: [
						{ key: 'file_manager', component: <FileManager setToolBarState={setToolBarState} />, admin: true },
					]
				},
				{
					name: 'Metrics',
					admin: true,
					children: [
						{ key: 'metrics', component: <MetricsV2 setToolBarState={setToolBarState} />, admin: true },
					]
				},
				// KEEP IMPORT IN LAST
				{
					name: translateToString('import_and_exports'),
					children: [
						{ key: 'import', component: <Import setToolBarState={setToolBarState} />, restricted: { objectAction: 'CreateImport' } },
						{ key: 'export', component: <Exports />, restricted: { objectAction: 'ViewExport' } }
					]
				},
			]
		}
	];

	return <SettingsInterpreter setToolBarState={setToolBarState} categories={categories} defaultCategory='my_settings'/>;
}
