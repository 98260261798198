import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';
import { Brand } from '../../containers_v2/globals/Model';

const AglobalBrand: AtomState<Brand[] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_brand', // UNIQUE ID
		default: undefined
	})
};

const AreportBrand: AtomState<Brand[] | undefined> = {
	category: AtomCategory.REPORTS,
	atom: atom({
		key: 'atom_report_brand', // UNIQUE ID
		default: undefined
	})
};

const ABrand: RecoilState<Brand[] | undefined> = selector({
	key: 'atom_brand',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<Brand[] | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalBrand.atom;
		case AtomCategory.REPORTS:
			return AreportBrand.atom;

		default:
			return AglobalBrand.atom;
	}
}

const AglobalAllBrands: AtomState<boolean> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_all_brands', // UNIQUE ID
		default: true,
	})
};

const AAllBrands = selector<boolean>({
	key: 'atom_all_brands',
	get: ({ get }) => get(AglobalAllBrands.atom),
	set: ({ set }, newValue) => set(AglobalAllBrands.atom, newValue)
});

export {
	AglobalBrand,
	AreportBrand,
	ABrand,
	AglobalAllBrands,
	AAllBrands
};
