import boxImage from 'images/icon/systemSettings/box.svg';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilState } from 'recoil';
import { AAllBrands, ABrand } from '../../../atoms/filter/BrandFiltersAtom';
import { Brand } from '../../../containers_v2/globals/Model';
import storeLang from '../../../helpers/storeLang';
import Dropdown from '../../dropdown/Dropdown';
import { DropdownData, DropdownStyle } from '../../dropdown/model/Model';
import { getBrands } from './action';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';
import { MyFilters } from 'bindings/reports/generic/GenericReport';

export const DROPDOWN_FILTER_STYLE: DropdownStyle = {
	width: '150px',
	height: '38px',
	optionWidth: '205px',
	fontSize: 12,
	optionFontColor: '#212529',
	containerBorder: '1px solid rgba(0, 0, 0, 0.125)',
	arrowClassName: 'fas fa-caret-down mr-1',
	arrowColor: 'rgb(204, 204, 204)',
	arrowFontSize: '12px'
};

function BrandFilter(props: GenericToolbarFilterProps): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const [atomBrands, setAtomBrands] = useRecoilState(ABrand);
	const [atomAllBrands, setAtomAllBrands] = useRecoilState(AAllBrands);

	const [brands, setBrands] = React.useState<Brand[]>([]);
	const [list, setList] = React.useState<DropdownData[]>([]);

	const { filters, setFilters } = React.useContext(ReportFiltersContext);

	let filterBrands: { brands: number[], all: boolean };

	if (props.context && filters.my_filters && filters.my_filters.brands) {
		filterBrands = { all: false, brands: filters.my_filters.brands.brands };
	}

	React.useEffect(() => {
		getBrands().then(response => {
			setBrands(response);

			if (!props.context) {
				if (atomBrands != null) {
					if (atomBrands.length <= 0) {
						setAtomBrands(response.length <= 0 ? undefined : response);
					}
				} else {
					setAtomAllBrands(true);
					setAtomBrands([...response]);
				}
				setList(response.map(r => {
					return {
						label: r.name,
						value: r.id,
						checked: (atomBrands != null) && atomBrands.length > 0 ? atomBrands.find(b => b.id === r.id) != undefined : true
					} as DropdownData;
				}));
			}
			else {
				if (filterBrands && filterBrands.brands) {
					if (filterBrands.brands.length <= 0) {
						setFilters(filters => ({ ...filters, my_filters: { ...filters.my_filters, brands: { brands: response.map(r => r.id), all: true } } }));
					}
				}
				setList(response.map(r => {
					return {
						label: r.name,
						value: r.id,
						checked: (filters.my_filters?.brands?.brands) ? filters.my_filters?.brands?.brands.includes(r.id) : true
					} as DropdownData;
				}));
			}
		});
	}, [atomAllBrands]);

	if (props.hidden) return <></>;

	function update(values: DropdownData[]): void {
		const nList = list.map(v => {
			return {
				...v,
				checked: values.find(s => s.value === v.value && s.checked) != undefined
			};
		});
		setList(nList);
		if (props.context) {
			const ids: number[] = brands.filter(b => nList.find(nl => nl.value == b.id && nl.checked) != undefined).map(b => b.id);
			setFilters({ ...filters, my_filters: { ...filters.my_filters, brands: { brands: ids, all: nList.every(nl => nl.checked) } } });
		} else {
			setAtomBrands(brands.filter(b => nList.find(nl => nl.value == b.id && nl.checked) != undefined));
			setAtomAllBrands(nList.every(nl => nl.checked));
		}
	}

	function getText(): string {
		const amount = list.filter(l => l.checked).length;
		return amount > 0 ? (amount === list.length ? translate('tool_bar.brand.all').toString() : `${amount} ${translate('tool_bar.brand.selectedBrand').toString()}`) : translate('tool_bar.brand.selectBrand').toString();
	}
	return (<>
		{
			brands.length > 0 &&
		<Dropdown
			dropdownStyle={{ ...DROPDOWN_FILTER_STYLE, width: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.width, containerWidth: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.containerWidth }}
			datalist={list}
			readOnly
			checkbox
			checkboxAll
			defaultShowLabel={getText()}
			freezeShowLabel
			name='dropdown_scope_filter'
			onChange={(values: DropdownData[]) => {
				update(values);
			}}
			image={boxImage}
		/>
		}
	</>);
}

export default BrandFilter;
