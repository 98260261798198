import axios from 'axios';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { ADatePicker } from '../../../atoms/filter/timeFilterAtom';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { momentToNaiveDateTime, useWindowDimensions } from '../../../components_v2/utils';
import storeLang from '../../../helpers/storeLang';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../../import/model';
import { FlexDiv } from '../../products/style';
import ReportingInterpretor from '../interpretor/ReportInterpretor';
import { reportingAction, reportingState } from '../Reducer';
import { exportPresenceEvolution, getPresenceEvolution } from './action';
import * as wasm from 'validators-web';
import { getCompanyFilters } from '../../client-companies/data/action';
import { FilterParameter } from '../../../components_v2/filter/pages/FilterList';
import { FilterResult } from '../../../components_v2/filter/model/Model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { Report } from '../interpretor/bareReportingDecoder';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { AProductFilter } from '../../../atoms/filter/productsFilterAtom';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';

import optionGrey from 'images/icon/options_grey.png';
import { AShelfAuditTemplatePicker } from '../../../atoms/filter/shelfAuditFilterAtom';
import { ReportInterpretorProviderLinksProps } from '../interpretor/hoverContext';
export default function merchandisingPerCompanyPerProduct(props: {
	state: reportingState
	dispatch: (action: reportingAction) => void
	setToolBarState: (value: ToolbarState) => void
} & ReportInterpretorProviderLinksProps) {
	const [report, setReport] = React.useState<Report>();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();
	const [subComponentToolBarState, setSubComponentToolBarState] = React.useState<ToolbarState>();
	const translate = getTranslate(storeLang.getState().localize);
	const { height, width } = useWindowDimensions();
	const pickedDates = useRecoilValue(ADatePicker);
	const pickedUsers = useRecoilValue(AUserPicker(0));
	const pickedProducts = useRecoilValue(AProductFilter);
	const pickedTemplates = useRecoilValue(AShelfAuditTemplatePicker);
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const [filterResult, setFilterResult] = React.useState<FilterResult>();

	// DO NOT REMOVE THIS OR THE APP WILL CRASH
	() => console.log(wasm);

	React.useEffect(() => {
		getCompanyFilters().then(response => setFilterParams(response.filters));
	}, []);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 200}
					category={AtomCategory.REPORTS}
					elements={[
						{
							kind: ToolbarElement.DATE_PICKER
						},
						{
							kind: ToolbarElement.PRODUCT_PER_CATEGORY,
							max: 50,
						},
						{
							kind: ToolbarElement.USER_PICKER
						},
						ToolbarElement.SHELF_AUDIT_TEMPLATE_PICKER,
					]}
				/>
				{subComponentToolBarState?.bottomLeftToolbarComponent}
				<RefreshButton onFilter={() => setLoadingState(undefined)} isLoading={loadingState == LoadingStateEnum.LOADING}/>
			</FlexDiv>,
			bottomRightToolbarComponent: <>
				{subComponentToolBarState?.bottomRightToolbarComponent}
				<Dropdown
					JSXButton={() => <img src={optionGrey} width={30}/>}
					name='export-merchandising-per-companies-and-per-products'
					datalist={[{ label: 'Export', value: 'Export' }]}
					onChange={(value: DropdownData<'Export'>) => {
						if (value?.value === 'Export' && pickedDates?.[0] && pickedDates[1] && (pickedTemplates != null)) {
							exportPresenceEvolution({
								begin: momentToNaiveDateTime(pickedDates[0]),
								end: momentToNaiveDateTime(pickedDates[1]),
								templates: pickedTemplates.map(t => t.id),
								filters: filterResult?.formatted,
								user_ids: pickedUsers?.[1] ? [] : pickedUsers?.[0].length == 0 ? [-1] : pickedUsers?.[0],
								product_ids: pickedProducts?.all ? [] : pickedProducts?.products,
							});
						}
					}}
				/>
			</>

		});
	}, [loadingState, subComponentToolBarState, pickedDates, filterResult?.formatted, pickedUsers, pickedTemplates, pickedProducts]);

	React.useEffect(() => {
		if (!loadingState && (pickedDates != null) && pickedDates[0] && pickedDates[1] && (pickedTemplates != null)) {
			try {
				setLoadingState(LoadingStateEnum.LOADING);
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });

				getPresenceEvolution({
					begin: momentToNaiveDateTime(pickedDates[0]),
					end: momentToNaiveDateTime(pickedDates[1]),
					filters: filterResult?.formatted,
					user_ids: pickedUsers?.[1] ? [] : pickedUsers?.[0].length == 0 ? [-1] : pickedUsers?.[0],
					product_ids: pickedProducts?.all ? [] : pickedProducts?.products,
					templates: pickedTemplates.map(t => t.id)
				}).then(report => {
					setReport(report);
					if (loadingState != LoadingStateEnum.LOADED) {
						setLoadingState(LoadingStateEnum.LOADED);
					}
					props.dispatch({ type: 'SET_IS_LOADING', value: false });
				})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job in: OrderPerProductPerCompany ', e);
						} else {
							console.error(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState(LoadingStateEnum.ERROR);
					});
			} catch (e) {
				console.error(e);
				setLoadingState(LoadingStateEnum.ERROR);
			}
		}
	}, [loadingState, pickedDates, filterResult, pickedTemplates]);

	if (!report) return <ComponentLoader loadingState={loadingState} allScreen/>;

	return <>
		<ReportingInterpretor
			{...props}
			setToolBarState={setSubComponentToolBarState}
			report={report}
			height={height - 260}
			filterParams={filterParams}
			onFiltersChange={setFilterResult}
		/>
		<ComponentLoader loadingState={loadingState} allScreen/>
	</>;
}