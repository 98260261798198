import { IAdditionalColumnsSubValue, ICheckoutEvolution, IFrequency, Map } from '../../../typings/proto/protobufs';

export interface MapData {
  initial?: boolean
  events?: boolean
  tags?: boolean
  distribution?: boolean
  checkouts?: boolean
  additionalColumns?: boolean
  additionalFields?: boolean
  orders?: boolean
  frequencies?: boolean[]
  list: MapCompany[]
}

export interface MapCompany extends Map.IInitialMapRow, EventsMapRow, Map.ITagsMapRow, Map.IDistributionMapRow, Map.IOrdersMapRow, Map.ICheckoutRow, Map.IAdditionalColumnRow, Map.IAdditionalFieldRow {
  frequencies?: (IFrequency | null | undefined)[];
}

export interface TagsMapRow {
  id: number
  tags: number[]
}

export type FrequencyMapRow = {
  id?: (number | null),
  frequency?: (IFrequency | null)
}

export interface OrdersMapRow {
  id: number
  lastOrderDate: { seconds: number, nanos: number }
  orderStatusId: number
  orderRange: number
}

export interface DistributionMapRow {
  id: number
  distribution: number[]
  last_shelf_audit_date?: Date
}

export type EventsMapRow = Map.IEventsMapRow & {
  events: Array<[number, string | undefined, string | undefined]>
  nextEvent: Array<[number, undefined, string]>
  next?: boolean
  range?: number
  nextRange?: number
}

export interface InitialMapRow {
  id: number
  lat: number
  lon: number
  name: string
  ownerId: number
  clientStatusId: number
  colorCode: string
  city?: string
  postCode?: string
  country?: string
}
export type Cluster = {
	id: number,
	properties: {
		cluster: true,
		cluster_id: number,
		details: ClusterProperties[],
		point_count: number,
		point_count_abbreviated: number
	},
	geometry: {
		coordinates: [number, number]
	}
}

export function isCluster(cluster: Cluster | Point): cluster is Cluster {
	return cluster.properties.cluster;
}

export interface Point {
  properties: {
    cluster: false
    id: number
    color: string
    detail: MapCompany
  }
  geometry: {
    coordinates: [number, number]
  }
}

export interface ClusterProperties {
  // initial
  id: number | null | undefined
  owner: number | null | undefined
  status: number | null | undefined
  statusColor: string | null | undefined
  // events
  next?: boolean
  range?: number
  nextRange?: number
  // distribution
  distribution?: number[] | null
  // orders
  orderRange?: number
  // frequency
  frequencies?: (IFrequency | null | undefined)[]
  checkouts?: (ICheckoutEvolution | null | undefined)
  // additionalColumns
  additionalColumnsValue?: { [k: string]: IAdditionalColumnsSubValue } | null
  additionalFieldColumnsValue?: { [k: string]: IAdditionalColumnsSubValue } | null
  calculatedFieldColumnsValue?: { [k: string]: IAdditionalColumnsSubValue } | null
}

export interface modalLeftState {
  tabType: string
  rangeColor: {
    firstRange: number
    lastRange: number
  }
}
