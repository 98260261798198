import * as React from 'react';
import { BodyCell } from './style/Style';
import { Row } from 'react-table';
import { FreezePosition } from './Table';
import { TableCellToolTipProps } from './TableCellToolTip';

type Options = {
	top?: number,
	left?: number,
	right?: number,
	bottom?: number
}

export function useIsInViewport(ref: React.MutableRefObject<HTMLElement | undefined>, inactive?: boolean, options?: Options, refresh?): [boolean, 'left' | 'right' | 'none'] {
	const [isIntersecting, setIsIntersecting] = React.useState<[boolean, 'left' | 'right' | 'none']>([inactive !== undefined, 'none']);
  
	const observer = React.useMemo(
		() => {
			if (inactive) return undefined;
			return new IntersectionObserver(([entry]) => {
				setIsIntersecting([entry.isIntersecting, entry.isIntersecting ? 'none' : entry.boundingClientRect.left > -(options?.left ?? 0) ? 'right' : 'left']);
			}, { rootMargin: `${options?.top ?? 0}px ${options?.right ?? 0}px ${options?.bottom ?? 0}px ${options?.left ?? 0}px` }
			);
		},
		[inactive],
	);
  
	React.useEffect(() => {
		if (inactive || !observer || !ref.current) return undefined;
		observer.observe(ref.current);
  
		return () => {
			observer.disconnect();
		};
	}, [ref, observer, inactive, refresh]);
  
	return isIntersecting;
}

export default function Cell<T extends object>(props: {
  i: number
  row: any
  isScrollable: boolean
  setSelected
  selected
  showFreezeColumnLeft: boolean
  showFreezeColumnRight: boolean
  onCellClicked
  data
  getFixedBody: (row: Row<object>, id: string, position?: FreezePosition) => number
  default_width
  DEFAULT_FIXED_WIDTH
  noBorder?: boolean
  j: number
  cell
  columnWidths
  setHovered: (props: TableCellToolTipProps | undefined) => void
  isIdSelected?: (row?: T) => boolean,
}) {
	const {
		i,
		row,
		setSelected,
		selected,
		showFreezeColumnLeft,
		showFreezeColumnRight,
		data,
		getFixedBody,
		default_width,
		j,
		cell
	} = props;

	const ref = React.useRef<HTMLElement>();
	const setRef = React.useCallback(node => ref.current = node, []);
	const [isInViewport] = useIsInViewport(ref, !cell.column.visualization);
	const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();
	const [hovered, setHovered] = React.useState<boolean>(false);

	const row_width = cell.column.width;

	const width = React.useMemo(() => getWidth(), [props.columnWidths, default_width]);

	function getWidth(): string {
		if (props.columnWidths[cell.column.id] !== undefined) {
			return `${props.columnWidths[cell.column.id]}px`;
		} else if (typeof row_width === 'number') {
			return row_width + 'px';
		} else {
			return row_width ?? default_width;
		}
	}

	return <BodyCell
		centered={cell.column.centerRow}
		innerRef={setRef}
		padding={cell.column.padding}
		noBorder={props.noBorder}
		key={`bodyCell[${i}][${j}]`}
		onFocus={() => setSelected({ i, j })}
		onBlur={() => setSelected(undefined)}
		edit={selected?.i == i && selected?.j == j && cell.column.editable}
		error={cell.row.original.error === cell.column.id}
		selected={cell.row.original.checked || props.isIdSelected?.(cell.row.original)}
		showFreezeShadow={cell.column.freeze === 'right' ? showFreezeColumnRight : showFreezeColumnLeft}
		freezePadding={cell.column.freeze ? getFixedBody(row, cell.column.id, cell.column.freeze) : undefined}
		freeze={cell.column.freeze}
		onClick={() => {
			props.onCellClicked?.(cell, i, j);
			data.forEach(d => {
				if (d.edit) {
					d.edit = false;
				}
			});
			if (cell.column.editable) {
				data.forEach(d => {
					if (JSON.stringify(d) === JSON.stringify(cell.row.original)) {
						d.edit = cell.column.id;
					}
				});
			}
		}}
		width={width}
		minWidth={cell.column.minWidth}
		maxWidth={cell.column.maxWidth}
		onMouseEnter={() => {
			setHovered(true);
			localTimeout && clearTimeout(localTimeout);
			const content = cell.column?.toolTip?.(cell.row.original);
			if (!ref.current || !content) return;
			setLocalTimeout(setTimeout(bounds => props.setHovered({
				bounds,
				content
			}), 200, ref.current.getBoundingClientRect()));
		}}
		onMouseLeave={() => {
			setHovered(false);
			localTimeout && clearTimeout(localTimeout);
			props.setHovered(undefined);
		}}
	>
		{isInViewport && cell.render('Cell')}
		{hovered && cell.column?.hoverImage?.(cell.row.original)}
	</BodyCell>;
}
