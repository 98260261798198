import { ValueKey } from 'bindings/reports/generic/ValueKey';
import add_blue from 'images/icon/add_blue.png';
import AnimationImage from 'images/icons/events/Animation.svg';
import AnimationImageWhite from 'images/icons/events/Animation_white.svg';
import CallImage from 'images/icons/events/Call.svg';
import CallImageWhite from 'images/icons/events/Call_white.svg';
import DeliveryImage from 'images/icons/events/Delivery.svg';
import DeliveryImageWhite from 'images/icons/events/Delivery_white.svg';
import EmailImage from 'images/icons/events/Email.svg';
import EmailImageWhite from 'images/icons/events/Email_white.svg';
import MeetingImage from 'images/icons/events/Meeting.svg';
import MeetingImageWhite from 'images/icons/events/Meeting_white.svg';
import PromotionImage from 'images/icons/events/Promotion.svg';
import PromotionImageWhite from 'images/icons/events/Promotion_white.svg';
import SMSImage from 'images/icons/events/SMS.svg';
import SMSImageWhite from 'images/icons/events/SMS_white.svg';
import TaskImage from 'images/icons/events/Task.svg';
import TaskImageWhite from 'images/icons/events/Task_white.svg';
import VisitImage from 'images/icons/events/Visit.svg';
import VisitImageWhite from 'images/icons/events/Visit_white.svg';
import close from 'images/icons/orders/close.svg';
import calendar_black from 'images/menu_icon/calendar_black.svg';
import company_map_black from 'images/menu_icon/company_map_black.svg';
import OrderBoxBlack from 'images/menu_icon/order_box_black.svg';
import OrderBoxWhite from 'images/menu_icon/order_box_white.svg';
import trash_red from 'images/ui_icon/trash_red.svg';
import PencilVendorImage from 'images/vendors/pencil.svg';
import * as React from 'react';
import { Cookies } from 'react-cookie';
import { getTranslate, Translate } from 'react-localize-redux';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ITag } from '../../../typings/proto/protobufs';
import { AAdditionalColumns, AdditionalColumn } from '../../atoms/additionalColumns';
import { AAdditionalFieldColumns, AdditionalFieldColumn } from '../../atoms/additionalFieldColumns';
import { AAssortments } from '../../atoms/assortment';
import { ACompanyEdition } from '../../atoms/company/companyEdition';
import { ShelfAuditTemplate } from '../../atoms/filter/shelfAuditFilterAtom';
import { AFormTemplate } from '../../atoms/forms';
import { AEventTypes } from '../../atoms/global/events';
import { AFrequencyEventType } from '../../atoms/global/frequency';
import { AGranularity } from '../../atoms/global/granularity';
import { AExternalMappings, ASelectedExternalMappingId } from '../../atoms/global/mappings';
import { ASelectedFormId } from '../../atoms/global/selectedForm';
import { AUsers } from '../../atoms/global/users';
import { CompanyView, DbView, ViewCompanyColumns } from '../../atoms/global/views';
import AddressModificator from '../../components_v2/address/AddressModificator';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';
import ClientCompanyCreation from '../../components_v2/creation/ClientCompanyCreation';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import {
	FilterId,
	FilterResult,
	isCheckoutEvolutionFilter,
	isExternalIdFilter,
	isFrequencyFilter,
	isLastEventDateFilter,
	isLastFormDateFilter,
	isNextEventDateFilter
} from '../../components_v2/filter/model/Model';
import PageLoader from '../../components_v2/pageLoader/PageLoader';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import Pagination from '../../components_v2/pagination/Pagination';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation, { ButtonStyle } from '../../components_v2/popup/PopupCreation';
import PopupDeletion from '../../components_v2/popup/PopupDeletion';
import PopupInformation from '../../components_v2/popup/PopupInformation';
import { Column, Table, TableSortType } from '../../components_v2/table/Table';
import { getUrlParameterByName, isSuperAdmin, removeAllUrlParameters } from '../../components_v2/utils';
import storeLang from '../../helpers/storeLang';
import { DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { LightBlueSidely } from '../../styles/global/css/Utils';
import { translateToString } from '../../styles/global/translate';
import { AlertContext } from '../alert/AlertProvider';
import PopupEvent from '../calendar/popup/Event';
import { InstancePopup } from '../forms/formInstancePopup';
import { ToolbarBox, ToolbarImage } from '../globals/defaultToolbar/style/Style';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import PermissionContext from '../permissions/PermissionContext';
import Restricted from '../permissions/Restricted';
import { ModalState } from '../products/model';
import { FlexDiv, Link } from '../products/style';
import { mapColumnFilterIds } from './CompaniesAndMapView';
import { deleteCompanies, getCompanies, updateCompany as putCompany } from './data/action';
import CompanyColumns from './data/CompanyColumns';
import { Company, CompanyParams, CompanyStatus, DbCompany, EventType } from './model/Model';
import { enrichCompanies, RejectedValueItem } from './popup/action';
import CompanyBulkEdition from './popup/CompanyBulkEdition';
import CompanyBulkEventCreation from './popup/CompanyBulkEventCreation';
import GeoPopupContent from './popup/GeoPopup';
import { Container, SeclectionImage, SelectAllDiv, SelectionAction, SelectionActionContainer, SelectionText } from './style/Style';
import { ACalculatedFields, CalculatedField } from '../../atoms/calculatedFields';

export function additionalColumnIdFormatter(id: unknown, path?: ValueKey) {
	return JSON.stringify({
		additional_columns: [{ id, path, name: false }]
	});
}

export function calculatedFieldIdFormatter(id: unknown, path?: ValueKey) {
	return JSON.stringify({
		calculated_field_columns: [{ id, path, name: false }]
	});
}

export function additionalFieldColumnIdFormatter(id: unknown) {
	return JSON.stringify({
		additional_field_columns: [id]
	});
}

export const filterCategories = ['most_used_filters', 'company_informations', 'company_activity', 'events', 'forms', 'shelf_audit', 'checkouts'] as const;
export type FilterCategory = typeof filterCategories[number];
export const COMPANY_COLUMN_CATEGORIES_MAP: { [key: string]: number } = [...filterCategories, 'report_fields', 'additional_field_columns', 'additional_columns', 'calculated_field_columns'].reduce((acc, cat, i) => {
	acc[cat] = i;
	return acc;
}, {});

export type ColumnResume = {
	translationKey?: string,
	name: string,
	excluded?: boolean,
	hidded?: boolean,
	admin?: boolean,
	filterCategories?: FilterCategory[],
	noTranslation?: boolean,
	fixedLeft?: boolean,
	categoryIndex?: number
}

export const checkUrlModalParams = (f: (state: { isOpen: boolean, id: string, isFullOpen: boolean }) => void, idName?: string) => () => {
	const id = getUrlParameterByName(idName ?? 'id');
	const mode = getUrlParameterByName('mode');
	let isFullOpen = true;
	if (id) {
		switch (mode) {
			case 'detail':
				isFullOpen = false;
				break;
		}
		f({ isOpen: true, isFullOpen, id });
	}
};

export const getCompaniesFitlersCategories = (filterId: FilterId): FilterCategory[] => {
	if (isCheckoutEvolutionFilter(filterId)) return ['checkouts'];
	if (isFrequencyFilter(filterId)) {
		return ['most_used_filters', 'events'];
	}
	if (isLastEventDateFilter(filterId) || isNextEventDateFilter(filterId)) {
		return ['events'];
	}
	if (isExternalIdFilter(filterId)) {
		return ['company_informations'];
	}
	if (isLastFormDateFilter(filterId)) {
		return ['most_used_filters', 'forms'];
	}
	switch (filterId) {
		case 'name':
		case 'company_id':
		case 'external_id':
		case 'parent_name':
		case 'email':
		case 'phone':
		case 'website':
		case 'company_address':
		case 'company_country':
		case 'latitude':
		case 'longitude':
		case 'parent_id':
			return ['company_informations'];
		case 'company_city':
		case 'company_post_code':
		case 'status_id':
		case 'owner_id':
		case 'tag_id':
			return ['most_used_filters', 'company_informations'];
		case 'created_at':
		case 'created_by':
		case 'updated_at':
		case 'updated_by':
			return ['company_activity'];
		case 'dn':
		case 'last_shelf_audit_date':
			return ['shelf_audit'];
		case 'checkout_evolution':
		case 'checkout_previous_sum':
		case 'checkout_current_sum':
		case 'checkout_current_uvc_sum':
		case 'checkout_previous_uvc_sum':
		case 'checkout_uvc_evolution':
			return ['checkouts'];
		case 'last_event_date':
		case 'next_event_date':
		case 'frequency_event':
		case 'frequency_event_target':
			return ['events'];
		case 'shelf_audit_templates':
			return ['shelf_audit'];
		case 'last_form_date':
			return ['forms'];
	}
	return [];
};

export function getImageFromEventTypeName(name: string, white = false): string {
	switch (name) {
		case 'Task': return white ? TaskImageWhite : TaskImage;
		case 'Call': return white ? CallImageWhite : CallImage;
		case 'Meeting': return white ? MeetingImageWhite : MeetingImage;
		case 'Visit': return white ? VisitImageWhite : VisitImage;
		case 'SMS': return white ? SMSImageWhite : SMSImage;
		case 'Email': return white ? EmailImageWhite : EmailImage;
		case 'Promotion': return white ? PromotionImageWhite : PromotionImage;
		case 'Animation': return white ? AnimationImageWhite : AnimationImage;
		case 'Delivery': return white ? DeliveryImageWhite : DeliveryImage;
		case 'Order': return white ? OrderBoxWhite : OrderBoxBlack;
	}
	throw 'Unknown event type';
}

export const ALL_COMPANIES_COLUMNS: ColumnResume[] = [
	{ name: 'select', excluded: true },
	{ name: 'name', filterCategories: ['company_informations'], excluded: true },
	{ name: 'company_id', filterCategories: ['company_informations'], hidded: true },
	{ name: 'external_id', filterCategories: ['company_informations'], hidded: true },
	{ name: 'parent_name', filterCategories: ['company_informations'] },
	{ name: 'email', filterCategories: ['company_informations'] },
	{ name: 'phone', filterCategories: ['company_informations'] },
	{ name: 'website', filterCategories: ['company_informations'] },
	{ name: 'company_address', filterCategories: ['company_informations'] },
	{ name: 'company_city', filterCategories: ['most_used_filters', 'company_informations'] },
	{ name: 'company_post_code', filterCategories: ['most_used_filters', 'company_informations'] },
	{ name: 'company_country', filterCategories: ['company_informations'] },
	{ name: 'latitude', filterCategories: ['company_informations'] },
	{ name: 'longitude', filterCategories: ['company_informations'] },
	{ name: 'status_id', filterCategories: ['most_used_filters', 'company_informations'] },
	{ name: 'owner_id', filterCategories: ['most_used_filters', 'company_informations'] },
	{ name: 'tag_id', filterCategories: ['most_used_filters', 'company_informations'] },
	{ name: 'created_at', filterCategories: ['company_activity'] },
	{ name: 'created_by', filterCategories: ['company_activity'], hidded: true },
	{ name: 'updated_at', filterCategories: ['company_activity'], hidded: true },
	{ name: 'updated_by', filterCategories: ['company_activity'], hidded: true },
	{ name: 'parent_id', filterCategories: ['company_informations'], hidded: true },
	{ name: 'dn', filterCategories: ['shelf_audit'] },
	{ name: 'last_shelf_audit_date', filterCategories: ['shelf_audit'] },
	{ name: 'last_event_date', filterCategories: ['events'] },
	{ name: 'next_event_date', filterCategories: ['events'] },
	{ name: 'frequency_event', filterCategories: ['events'] },
	{ name: 'frequency_event_target', filterCategories: ['events'] },
	{ name: 'checkout_evolution', filterCategories: ['checkouts'], hidded: true },
	{ name: 'checkout_previous_sum', filterCategories: ['checkouts'], hidded: true },
	{ name: 'checkout_current_sum', filterCategories: ['most_used_filters', 'checkouts'], hidded: true },
	{ name: 'checkout_uvc_evolution', filterCategories: ['checkouts'], hidded: true },
	{ name: 'checkout_previous_uvc_sum', filterCategories: ['checkouts'], hidded: true },
	{ name: 'checkout_current_uvc_sum', filterCategories: ['checkouts'], hidded: true },
	{ name: 'shelf_audit_templates', hidded: true },
	{ name: 'last_form_date', filterCategories: ['shelf_audit'], hidded: true },
	{ name: 'quick_actions', excluded: true }
];

export function allCompaniesAndAdditionalColumns(additionalColumns: AdditionalColumn[], additionalFieldColumns: AdditionalFieldColumn[], calculatedFields: CalculatedField[]) {
	return [
		...ALL_COMPANIES_COLUMNS,
		...additionalColumns.map((ac): ColumnResume => ({ name: additionalColumnIdFormatter(ac.id) })),
		...additionalFieldColumns.map((ac): ColumnResume => ({ name: additionalFieldColumnIdFormatter(ac.field_id) })),
		...calculatedFields.map((ac): ColumnResume => ({ name: calculatedFieldIdFormatter(ac.id) }))
	];
}

const sortItems = (a: RejectedValueItem, b: RejectedValueItem) => (b.scoring?.queryScore ?? 0) - (a.scoring?.queryScore ?? 0);
function Companies(props: {
	allCompaniesColumnsAndAdditionalColumns: ColumnResume[],
	setToolBarState: (value: ToolbarState) => void,
	filterResult: FilterResult,
	selectedView: DbView<CompanyView> | undefined,
	setSelectedFilter: React.Dispatch<React.SetStateAction<FilterId | undefined>>,
	setOpenSummary: React.Dispatch<React.SetStateAction<boolean>>,
	setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>,
	setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>,
	hiddenColumns: string[],
	setSortColumns: React.Dispatch<React.SetStateAction<string[]>>,
	sortColumns: string[],
	allColumns: { id: FilterId, name: string }[],
	statuses: CompanyStatus[]
	tags: ITag[]
	eventTypes: EventType[]
	searchName: string | undefined,
	shelfAuditTemplates: ShelfAuditTemplate[]
}): JSX.Element {
	const DEFAULT_STEP = 25;
	const DEFAULT_OFFSET = 0;
	const { shelfAuditTemplates, searchName, statuses, eventTypes, tags, filterResult, selectedView, setSelectedFilter, setOpenSummary, setFilterOpen, setHiddenColumns, hiddenColumns, setSortColumns, sortColumns, allColumns } = props;

	const translate = getTranslate(storeLang.getState().localize);

	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [isFullOpenMode, setFullOpenMode] = React.useState<boolean>(true);

	const [rawCompanies, setRawCompanies] = React.useState<DbCompany>({ total: 0, companies: [] });
	const [companies_, setCompanies_] = React.useState<Company[]>([]);
	const companies = React.useRef(companies_);
	const setCompanies = (e: Company[]) => {
		companies.current = e;
		setCompanies_(e);
	};

	const [clientCompanyId, setClientCompanyId] = React.useState<number>();
	const [pagination_, setPagination_] = React.useState<PaginationResult>();
	const pagination = React.useRef(pagination_);
	const setPagination = (e: PaginationResult | undefined) => {
		pagination.current = e;
		setPagination_(e);
	};
	const [sort, setSort] = React.useState<TableSortType | undefined>({ id: 'created_at', desc: true });
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();

	const [isCreationOpen, setCreationOpen] = React.useState<boolean>(false);
	const [selectedDuplicateCompany, setSelectedDuplicateCompany] = React.useState<Company>();
	const [isBulkEditOpen, setIsBulkEditOpen] = React.useState<boolean>(false);
	const [isBulkDeleteOpen, setIsBulkDeleteOpen] = React.useState<boolean>(false);

	const [isAllSelected, setIsAllSelected_] = React.useState<boolean>(false);
	const setIsAllSelected = (b: boolean) => {
		setIsAllSelected_(b);
		if (!b) {
			setCompanies(companies.current.map(c => ({ ...c, checked: false })));
			setSelectedCompanies([]);
		} else {
			setCompanies(companies.current.map(c => ({ ...c, checked: true })));
		}
	};
	const [selectedEventType, setSelectedEventType] = React.useState<EventType>();
	const [isParent, setIsParent] = React.useState<boolean>(false);
	const isAdmin = isSuperAdmin();

	const [selectedCompanies, setSelectedCompanies] = React.useState<Array<{ id: number, checked?: boolean }>>([]);
	const formTemplates = useRecoilValue(AFormTemplate);

	const [isModifyAddressOpen, setModifyAddressOpen] = React.useState<boolean>(false);
	const [modifyAddress, setModifyAddress] = React.useState<Company>();
	const atomCompanyEdition = useRecoilValue(ACompanyEdition);
	const atomEventTypes = useRecoilValue(AEventTypes);
	const [selectedColumnEventType, setSelectedColumnEventType] = useRecoilState(AFrequencyEventType);
	const [selectedColumnFormId, setSelectedColumnFormId] = useRecoilState(ASelectedFormId);
	const atomExternalMapping = useRecoilValue(AExternalMappings);
	const [selectedExternalMappingId, setSelectedExternalMappingId] = useRecoilState(ASelectedExternalMappingId);
	const granularity = useRecoilValue(AGranularity);

	const [informationModalState, setInformationModalState] = React.useState<ModalState<{ content: JSX.Element, title: JSX.Element, hideButton?: boolean }>>({ isOpen: false });
	const [eventModalState, setEventModalState] = React.useState<ModalState<number>>({ isOpen: false });
	const [isEnrichOpen, setIsEnrichOpen] = React.useState<boolean>(false);
	const [reload, setReload] = React.useState<boolean>(false);
	const [lastRequest, setLastRequest] = React.useState<string>();

	const [tableResize, setTableResize] = React.useState(JSON.parse(localStorage.getItem('table_company_resize') || '{}'));
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const [formModalState, setFormModalState] = React.useState<ModalState<string>>({ isOpen: false });

	const cookie = new Cookies();
	const history = useHistory();
	const users = useRecoilValue(AUsers);
	const { alertDelete } = React.useContext(AlertContext);
	const { isAllowedTo } = React.useContext(PermissionContext);
	const assortments = useRecoilValue(AAssortments);

	const tableRef = React.useRef<HTMLDivElement>();
	const calculatedFields = useRecoilValue(ACalculatedFields);

	React.useEffect(() => {
		if (atomCompanyEdition != null) {
			if ('deleted' in atomCompanyEdition) {
				const index = companies.current.findIndex(c => c.company_id == atomCompanyEdition.deleted);
				companies.current.splice(index, 1);
				setCompanies([...companies.current]);
				return;
			}
			const index = companies.current.findIndex(c => c.company_id == atomCompanyEdition.client_company_id);
			if (index >= 0) {
				for (const [key, value] of Object.entries(atomCompanyEdition)) {
					switch (key) {
						case 'latitude':
							companies.current[index].latitude = value;
							continue;
						case 'longitude':
							companies.current[index].longitude = value;
							continue;
						case 'email':
							companies.current[index].email = value;
							continue;
						case 'owner_id': {
							const user = users.find(u => u.id == value);
							if (user != null) {
								companies.current[index].owner_id = user.id;
								companies.current[index].owner_name = user.name;
								companies.current[index].owner_photo = user.photoUrl;
							}
							continue;
						}
						case 'phone':
							companies.current[index].phone = value;
							continue;
						case 'website':
							companies.current[index].website = value;
							continue;
						case 'company_name':
							companies.current[index].name = value;
							continue;
						case 'client_status_id': {
							const status = statuses.find(s => s.id === value);
							if (status != null) {
								companies.current[index].status_id = status.id;
								companies.current[index].company_status_color = status.color_code;
								companies.current[index].company_status_name = status.name;
							}
							continue;
						}
						case 'parentCompany':
							if (value.updated?.id) {
								companies.current[index].parent_id = value.updated.id;
								companies.current[index].parent_name = value.updated.name;
							} else if (value === 'deleted') {
								companies.current[index].parent_id = undefined;
								companies.current[index].parent_name = undefined;
							}
							continue;
						// TAGS
						case 'ids_add':
							companies.current[index].tags = companies.current[index].tags.concat(tags.filter(t => value.includes(t.id)));
							continue;
						case 'ids_delete':
							companies.current[index].tags = companies.current[index].tags.filter(t => !value.includes(t.id));
							continue;
					}
				}
				companies.current[index].updated_at = new Date();
				companies.current[index].updated_by = users.find(u => u.isYou)?.id;
				setCompanies([...companies.current]);
			}
		}
	}, [atomCompanyEdition]);

	React.useEffect(() => {
		const f = ({ isOpen, isFullOpen, id }) => {
			const parsedId = parseInt(id);
			if (isNaN(parsedId)) return;
			setOpen(isOpen);
			setFullOpenMode(isFullOpen);
			setClientCompanyId(parsedId);
		};
		checkUrlModalParams(f)();
		history.listen(checkUrlModalParams(f));
	}, [history]);

	React.useEffect(() => {
		updateRawCompanies();
	}, [JSON.stringify(statuses), JSON.stringify(users), JSON.stringify(tags), JSON.stringify(rawCompanies)]);

	React.useEffect(() => {
		let newSelectedCompanies = [...selectedCompanies];

		newSelectedCompanies = newSelectedCompanies
			.map(c => {
				const company = companies.current.find(ac => ac.company_id == c.id);
				if (company != null) {
					return { id: company.company_id, checked: company.checked };
				} else {
					return c;
				}
			});

		companies.current.forEach(company => {
			if (!newSelectedCompanies.some(sc => sc.id == company.company_id)) {
				newSelectedCompanies.push({ id: company.company_id, checked: company.checked });
			}
		});
		setSelectedCompanies([...newSelectedCompanies]);
	}, [JSON.stringify(companies.current)]);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('companies') as string,
			bottomLeftToolbarComponent: <>
				{(selectedCompanies.some(c => c.checked) || isAllSelected) && <FlexDiv gap='10px'>
					<DefaultTextDiv height='30px'>
						<SelectionActionContainer>
							<Restricted to={{ objectAction: 'UpdateCompany' }}>
								<Dropdown
									dropdownStyle={{
										height: '20px',
										optionWidth: '220px',
										optionHeight: '260px',
										containerTop: '30px'
									}}
									JSXButton={() =>
										<SelectionAction>
											<SeclectionImage src={calendar_black} />
											<SelectionText>
												<Translate id='map.modal_right.create_events' />
											</SelectionText>
										</SelectionAction>
									}
									datalist={eventTypes.map(et => ({
										value: et,
										label: translate('map.modal_right.create') + ' ' + translate(`event.${et.name}`),
										image: getImageFromEventTypeName(et.name)
									}))}
									name={'event_type_dropdown'}
									onChange={(value: DropdownData<EventType>) => setSelectedEventType(value.value)}
								/>
								<SelectionAction onClick={() => setIsEnrichOpen(true)}>
									<SeclectionImage src={company_map_black} />
									<SelectionText>
										<Translate id='enrich_geo_positions' />
									</SelectionText>
								</SelectionAction>
								<SelectionAction onClick={() => setIsBulkEditOpen(true)}>

									<SeclectionImage src={PencilVendorImage} />
									<SelectionText>
										<Translate id='edit_selection' />
									</SelectionText>
								</SelectionAction>
							</Restricted>
							<Restricted to={{ objectAction: 'DeleteCompany' }}>
								<SelectionAction onClick={() => setIsBulkDeleteOpen(true)} delete>
									<SeclectionImage src={trash_red} />
									<SelectionText>
										<Translate id='delete_selection' />
									</SelectionText>
								</SelectionAction>
							</Restricted>
						</SelectionActionContainer>
					</DefaultTextDiv>
				</FlexDiv>}
			</>,
			bottomRightToolbarComponent: <ToolbarBox>
				<Restricted to={{ objectAction: 'ReadCompany' }}>
					<Restricted to={{ objectAction: 'CreateCompany' }}>
						<ToolbarImage
							width='25px'
							height='25px'
							hasPointer
							src={add_blue}
							backgroundColor={LightBlueSidely}
							round
							onClick={() => { setSelectedDuplicateCompany(undefined); setCreationOpen(true); }}
						/>
					</Restricted>
				</Restricted>
			</ToolbarBox>
		});
	}, [JSON.stringify(selectedCompanies), isAllSelected, searchName, hiddenColumns, sortColumns]);

	React.useEffect(() => {
		setPagination({ currentPage: 1, offset: DEFAULT_OFFSET, step: pagination?.current?.step ?? DEFAULT_STEP });
		setSelectedCompanies([]);
		setRawCompanies({ companies: [], total: -1 });
		setCompanies([]);
	}, [JSON.stringify({ filterResult, searchName, sort })]);

	React.useEffect(() => {
		updateCompanies();
	}, [pagination.current, selectedColumnEventType, allColumns, hiddenColumns, granularity]);

	const columnsToView = (): ViewCompanyColumns[] => sortColumns.map(c => ({
		column: c,
		hidden: hiddenColumns.includes(c)
	}));

	React.useEffect(() => {
		if (!selectedView)
			localStorage.setItem('company_hidden_columns', JSON.stringify(columnsToView()));
	}, [JSON.stringify(hiddenColumns), JSON.stringify(sortColumns)]);

	React.useEffect(() => {
		localStorage.setItem('table_company_resize', JSON.stringify(tableResize));
	}, [tableResize]);

	function updateCompanies(force?: boolean): void {
		if (allColumns.length > 0 && loadingState !== LoadingStateEnum.LOADING || force) {
			getNewCompanies(force)
				.then(response => {
					if (!response) return;
					if (response.companies.length > 0 && (sortColumns.length <= 0 || sortColumns.length < Object.keys(response.companies[0]).length)) {
						setSortColumns(props.allCompaniesColumnsAndAdditionalColumns
							.filter(c => !c.excluded && (!c.admin || isAdmin))
							.sort((a, b) => {
								let indexA = sortColumns.findIndex(sc => sc == a.name);
								let indexB = sortColumns.findIndex(sc => sc == b.name);
								if (indexA < 0) {
									indexA = props.allCompaniesColumnsAndAdditionalColumns.findIndex(dcs => dcs.name == a.name);
									if (indexA < 0) indexA = 999;
								}
								if (indexB < 0) {
									indexB = props.allCompaniesColumnsAndAdditionalColumns.findIndex(dcs => dcs.name == b.name);
									if (indexB < 0) indexB = 999;
								}
								return indexA - indexB;
							})
							.map(c => c.name)
							.map(k => k === 'tags' ? 'tag_id' : k)
						);
					}
					setRawCompanies(response);
					if (reload) {
						setReload(false);
						updateCompanies();
					} else {
						setReload(false);
					}
				});
		} else if (allColumns.length > 0) {
			setReload(true);
		}
	}

	const createBody = (pagination: PaginationResult | undefined, order_by: string | object | undefined): CompanyParams => ({
		limit: pagination?.step,
		offset: pagination?.offset,
		new_filters: filterResult.formatted,
		search: searchName,
		order_by,
		descending: sort?.desc,
		columns: allColumns.filter(({ name }) => !hiddenColumns.includes(name)).reduce(mapColumnFilterIds(selectedColumnEventType, selectedExternalMappingId, granularity, selectedColumnFormId), [])
	});

	async function getNewCompanies(force?: boolean): Promise<DbCompany | undefined> {
		if (pagination.current != null) {
			let order_by: object | string | undefined = {};
			switch (sort?.id) {
				case 'last_event_date':
				case 'next_event_date':
					order_by[sort.id] = selectedColumnEventType;
					break;
				case 'external_id':
					order_by[sort.id] = selectedExternalMappingId.company;
					break;
				case 'frequency_event':
					order_by['frequencies'] = { event: selectedColumnEventType, element: 'frequency' };
					break;
				case 'frequency_event_target':
					order_by['frequencies'] = { event: selectedColumnEventType, element: 'score' };
					break;
				case 'checkout_evolution':
					order_by['checkout'] = { element: 'evolution', granularity };
					break;
				case 'checkout_current_sum':
					order_by['checkout'] = { element: 'current_sum', granularity };
					break;
				case 'checkout_previous_sum':
					order_by['checkout'] = { element: 'previous_sum', granularity };
					break;
				case 'checkout_uvc_evolution':
					order_by['checkout'] = { element: 'uvc_evolution', granularity };
					break;
				case 'checkout_current_uvc_sum':
					order_by['checkout'] = { element: 'current_uvc_sum', granularity };
					break;
				case 'checkout_previous_uvc_sum':
					order_by['checkout'] = { element: 'previous_uvc_sum', granularity };
					break;
				case 'last_form_date':
					order_by[sort.id] = selectedColumnFormId;
					break;
				default:
					order_by = sort?.id;
			}
			if (sort?.id.startsWith('{"additional_columns":')) {
				order_by = JSON.parse(sort.id);
			} else if (sort?.id.startsWith('{"additional_field_columns":')) {
				order_by = JSON.parse(sort.id);
			} else if (sort?.id.startsWith('{"calculated_field_columns":')) {
				order_by = JSON.parse(sort.id);
			}
			const body = createBody(pagination.current, order_by);
			if (JSON.stringify(body) === lastRequest && !force) {
				return undefined;
			}
			setLoadingState(LoadingStateEnum.LOADING);
			setLastRequest(JSON.stringify(body));
			const response = await getCompanies(body);
			tableRef.current?.scrollTo({ top: 0, behavior: 'auto' });
			setLoadingState(LoadingStateEnum.LOADED);
			return response;
		} else {
			return await new Promise(() => ({ total: 0, companies: [] }));
		}
	}

	function updateRawCompanies(): void {
		setCompanies(rawCompanies.companies.map(c => {
			const newTags = c.tags.map(t => {
				const nt = tags.find(tt => tt.id === (t as unknown as number));
				return { id: (t as unknown as number), name: nt?.name ?? '', color: nt?.color ?? '' };
			});
			const owner = users.find(u => u.id === c.owner_id);
			const status = statuses.find(s => s.id == c.status_id);
			return {
				...c,
				company_status_name: status?.name,
				company_status_color: status?.color_code,
				owner_name: owner?.name ?? '',
				owner_photo: owner?.photoUrl ?? '',
				tags: newTags,
				checked: selectedCompanies.find(sc => sc.id == c.company_id)?.checked ?? isAllSelected
			} as Company;
		}));
	}

	const data: DropdownData[] = [];
	if (isAllowedTo({ objectAction: 'DeleteCompany' })) {
		data.push({ label: translate('company.toolbar.delete_selection').toString(), value: 'delete' });
	}

	const hidableColumns = React.useMemo(() => {
		const columns = ALL_COMPANIES_COLUMNS.filter(c => !c.excluded && (!c.admin || isAdmin)).map(c => {
			let sequence = sortColumns.indexOf(c.name);
			if (sequence === -1) sequence = 999;
			const categoryIndex = COMPANY_COLUMN_CATEGORIES_MAP[getCompaniesFitlersCategories(c.name).find(c => c !== 'most_used_filters') ?? ''];
			return ({
				label: translateToString(`company.columns.${c.name}`),
				value: c.name,
				checked: !hiddenColumns.includes(c.name),
				sequence,
				categoryIndex
			});
		});
		additionalColumns.forEach(ac => {
			const id = additionalColumnIdFormatter(ac.id);
			let sequence = sortColumns.indexOf(id);
			if (sequence === -1) sequence = 999;
			columns.push({
				label: ac.name,
				value: id,
				checked: !hiddenColumns.includes(id),
				sequence,
				categoryIndex: COMPANY_COLUMN_CATEGORIES_MAP[ac.type === 'ReportColumn' ? 'report_fields' : 'additional_columns'] });
		});
		additionalFieldColumns.forEach(ac => {
			const id = additionalFieldColumnIdFormatter(ac.field_id);
			let sequence = sortColumns.indexOf(id);
			if (sequence === -1) sequence = 999;
			columns.push({
				label: ac.field_name,
				value: id,
				checked: !hiddenColumns.includes(id),
				sequence,
				categoryIndex: COMPANY_COLUMN_CATEGORIES_MAP['additional_field_columns'] });
		});
		if (isAdmin)
			calculatedFields.forEach(cf => {
				const id = calculatedFieldIdFormatter(cf.id);
				let sequence = sortColumns.indexOf(id);
				if (sequence === -1) sequence = 999;
				columns.push({
					label: cf.field_name,
					value: id,
					checked: !hiddenColumns.includes(id),
					sequence,
					categoryIndex: COMPANY_COLUMN_CATEGORIES_MAP['calculated_field_columns'] });
			});
		columns.sort((a, b) => a.sequence - b.sequence);
		return columns;
	}, [JSON.stringify(sortColumns), hiddenColumns, isAdmin, additionalColumns, additionalFieldColumns]);

	function updateCompany(company: Company): void {
		company.updated_at = new Date();
		company.updated_by = cookie.get('user_id');
		setCompanies(companies.current.map(c => c.company_id === company.company_id ? company : c));
	}

	function deleteCompany(company: Company): void {
		setCompanies(companies.current.filter(c => c.company_id !== company.company_id));
	}

	function duplicateCompany(company: Company): void {
		setSelectedDuplicateCompany(company);
		setCreationOpen(true);
	}

	const columns: Array<Column<Company>> = React.useMemo(
		() => {
			const cols = CompanyColumns((value, mode) => {
				history.push({
					search: `?id=${value}`
				});
				setFullOpenMode(!mode);
			},
			updateCompany,
			setCompanies,
			duplicateCompany,
			deleteCompany,
			(value) => { setModifyAddress(value); setModifyAddressOpen(true); },
			statuses,
			users,
			tags,
			companies.current.every(c => c.checked),
			hidableColumns,
			setSortColumns,
			setHiddenColumns,
			(checked) => setCompanies(companies.current.map(c => ({ ...c, checked }))),
			atomEventTypes,
			selectedColumnEventType,
			setSelectedColumnEventType,
			() => updateCompanies(true),
			data => setEventModalState({ isOpen: true, data }),
			loadingState,
			atomExternalMapping,
			selectedExternalMappingId,
			setSelectedExternalMappingId,
			alertDelete,
			isAllowedTo,
			shelfAuditTemplates,
			additionalColumns,
			additionalFieldColumns,
			assortments,
			selectedColumnFormId,
			setSelectedColumnFormId,
			formTemplates,
			setFormModalState,
			calculatedFields,
			isAdmin
			);

			if (sortColumns.length > 0) {
				const newC = [cols[0], cols[1]];
				for (const col of cols) {
					if (col.id?.startsWith('ADMIN_TEST') && isAdmin) newC.push(col);
				}
				props.allCompaniesColumnsAndAdditionalColumns
					.filter(c => !c.excluded && (!c.admin || isAdmin))
					.map(column => {
						let sequence = sortColumns.indexOf(column.name);
						if (sequence === -1) sequence = 999;
						return ({
							column,
							sequence
						});
					})
					.sort((a, b) => a.sequence - b.sequence)
					.forEach(({ column }) => {
						const c = cols.find(c => c.id && c.id === column.name);
						if (c) newC.push(c);
					});
				newC.push(cols[cols.length - 1]);
				return newC;
			}

			return cols.filter(c => {
				const col = props.allCompaniesColumnsAndAdditionalColumns.find(c2 => c2.name === c.id);
				return !col || !col.admin || isAdmin;
			});
		},
		[additionalColumns, additionalFieldColumns, JSON.stringify({ companies: companies.current, sortColumns, statuses, users, hidableColumns, selectedColumnEventType, loadingState, selectedExternalMappingId: selectedExternalMappingId.company, atomExternalMapping, shelfAuditTemplates })]
	);

	const selectedLength = selectedCompanies.filter(c => isAllSelected ? !c.checked : c.checked).length;
	const onSort = React.useCallback((sort: TableSortType) => setSort(sort[0]), []);
	const onClickFilter = React.useCallback((id: FilterId) => {
		setSelectedFilter(id);
		setOpenSummary(false);
		setFilterOpen(true);
	}, []);

	const localEnrichCompanies = (replace: boolean) => {
		enrichCompanies({
			companies: selectedCompanies.filter(c => isAllSelected ? !c.checked : c.checked).map(c => c.id),
			all: isAllSelected,
			new_filters: filterResult.formatted,
			search: searchName,
			replace
		})
			.then(({ rejected, changed, rejected_values }) => {
				updateCompanies(true);
				setInformationModalState({
					isOpen: true,
					data: {
						hideButton: true,
						content: <GeoPopupContent
							rejected={rejected}
							changed={changed}
							rejected_values={rejected_values.map(({ company, items }) => ({ company, items: items?.sort(sortItems) }))}
							setTitle={title => setInformationModalState(state => state.data ? ({ ...state, data: { ...state.data, title } }) : ({ ...state }))}/>,
						title: <><Translate id='enrichment' />{rejected_values.length > 0 ? ` (1 / ${(rejected_values.length)})` : ''}</>
					}
				});
			})
			.catch(e => {
				console.error(e);
				setLoadingState(LoadingStateEnum.ERROR);
			});
	};

	function getRecords() {
		return isAllSelected
			? rawCompanies.total - selectedCompanies.filter(c => !c.checked).length
			: selectedCompanies.filter(c => c.checked).length;
	}

	return (
		<Container>
			<ClientCompany
				isOpen={isOpen}
				setOpen={(b: boolean) => {
					if (!b) { removeAllUrlParameters(); }
					setOpen(b);
				}}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				clientCompanyId={clientCompanyId!}
				fullOpenMode={isFullOpenMode}
			/>
			<ClientCompanyCreation
				isOpen={isCreationOpen}
				setOpen={setCreationOpen}
				onCreate={(value) => {
					setClientCompanyId(value.company_id);
					updateCompanies(true);
					setFullOpenMode(true);
					setOpen(true);
				}}
				company={selectedDuplicateCompany}
			/>
			<React.Suspense fallback={<FlexDiv height='calc(100vh - 174px)' width='100%' justify='center'><PageLoader /></FlexDiv>}>
				<Table
					innerRef={tableRef}
					height='calc(100vh - 174px)'
					columns={columns}
					data={companies.current}
					onSort={onSort}
					EnableResize
					initialSortBy={sort}
					hiddenColumns={hiddenColumns}
					onClickFilter={onClickFilter}
					onResize={(value) => Object.keys(value).length > 0 && setTableResize(value)}
					resizeValue={tableResize}
					isInformationHeaderOpen={(selectedCompanies.some(c => c.checked) && selectedCompanies.length != 0) || isAllSelected}
					informationHeader={<SelectAll
						onMaxSelected={setIsAllSelected}
						selected={selectedLength}
						max={rawCompanies.total}
						isAllSelected={isAllSelected}
					/>}
				/>
			</React.Suspense>
			<Pagination
				label={translate('company.beta').toString()}
				amount={rawCompanies.total}
				currentCount={rawCompanies.companies.length}
				steps={[DEFAULT_STEP, 50]}
				onChange={(value) => {
					setPagination(value);
				}}
			/>
			<ComponentLoader loadingState={loadingState} allScreen />
			<Popup
				isOpen={isBulkEditOpen || selectedEventType !== undefined}
				popupMode={PopupMode.Details}
				onClickOut={() => {
					setIsBulkEditOpen(false);
					setSelectedEventType(undefined);
				}}
				disableOutClick={isParent}
				popupStyle={{ animate: true, height: '100%', top: '0%' }}
			>
				<PopupCreation
					title={isBulkEditOpen ? <Translate id='mass_modification' /> : <><Translate id='map.modal_right.plan_your' /><Translate id={`event.${selectedEventType?.name}_s`}/></>}
					hideValidation
					onClose={() => {
						setIsBulkEditOpen(false);
						setSelectedEventType(undefined);
					}}
				>
					{(selectedEventType != null)
						? <CompanyBulkEventCreation
							type={selectedEventType}
							onClose={() => setSelectedEventType(undefined)}
							ids={selectedCompanies.filter(c => isAllSelected ? !c.checked : c.checked).map(c => c.id)}
							isAll={isAllSelected}
							body={{
								filters: filterResult?.formatted,
								search: searchName
							}}
							onChildChange={setIsParent}
						/>
						: <CompanyBulkEdition
							setLoadingState={setLoadingState}
							ids={selectedCompanies.filter(c => isAllSelected ? !c.checked : c.checked).map(c => c.id)}
							users={users?.map(u => ({ label: u.name, value: u, image: u.photoUrl }))}
							statuses={statuses?.map((s, i) => { return { label: s.name, value: s, color: s.color_code, selected: i === 0 }; })}
							tags={tags}
							records={getRecords()}
							refresh={() => {
								setSelectedCompanies([]);
								setIsAllSelected(false);
								updateCompanies(true);
							}}
							onClose={() => setIsBulkEditOpen(false)}
							isAll={isAllSelected}
							new_filters={filterResult?.formatted}
							search={searchName}
						/>
					}
				</PopupCreation>
			</Popup>
			<PopupDeletion
				isOpen={isBulkDeleteOpen}
				onClickOut={() => setIsBulkDeleteOpen(false)}
				records={getRecords()}
				onValidation={() => {
					deleteCompanies(selectedCompanies.filter(c => isAllSelected ? !c.checked : c.checked).map(c => c.id), isAllSelected, filterResult.formatted, searchName)
						.then(response => {
							if (response) {
								updateCompanies(true);
							}
						});
					setSelectedCompanies([]);
					setIsAllSelected(false);
				}}
				translationKey='companies'
			/>
			<AddressModificator
				isOpen={isModifyAddressOpen}
				setOpen={setModifyAddressOpen}
				defaultAddress={(modifyAddress != null) ? {
					country: modifyAddress.company_country,
					postalCode: modifyAddress.company_post_code,
					town: modifyAddress.company_city,
					street: modifyAddress.company_address
				} : undefined}
				onChange={(na) => {
					const company = companies.current.find(c => c.company_id === modifyAddress?.company_id);
					if (company != null) {
						company.company_address = na.street && na.street.length > 0 ? na.street : undefined;
						company.company_post_code = na.postalCode && na.postalCode.length > 0 ? na.postalCode : undefined;
						company.company_city = na.town && na.town.length > 0 ? na.town : undefined;
						company.company_country = na.country && na.country.length > 0 ? na.country : undefined;
						company.latitude = na.latitude ? na.latitude : undefined;
						company.longitude = na.longitude ? na.longitude : undefined;
						company.updated_at = new Date();
						company.updated_by = cookie.get('user_id');

						putCompany({
							client_company_id: company.company_id,
							billing_city: company.company_city,
							billing_country: company.company_country,
							billing_postcode: company.company_post_code,
							billing_address: company.company_address,
							latitude: company.latitude,
							longitude: company.longitude
						}).then(response => {
							if (response) {
								setCompanies(companies.current.map(c => c.company_id === company.company_id ? company : c));
							}
						});
					}
				}}
				title={translate('company.columns.company_address').toString()}
			/>
			<PopupInformation
				height='fit-content'
				gap='20px'
				popupStyle={{ fitContent: true, width: '50vw', minWidth: '1000px' }}
				isOpen={informationModalState.isOpen}
				onClickOut={() => setInformationModalState({ isOpen: false })}
				title={informationModalState.data?.title}
				hideButton={informationModalState.data?.hideButton}
			>
				{informationModalState.data?.content}
			</PopupInformation>
			<PopupEvent
				isOpen={eventModalState.isOpen}
				setIsOpen={isOpen => setEventModalState({ isOpen })}
				defaultValues={{ clientCompanyId: eventModalState.data, eventTypeId: selectedColumnEventType }}
			/>
			<PopupInformation
				isOpen={isEnrichOpen}
				onClickOut={() => setIsEnrichOpen(false)}
				title={<Translate id='enrich_geo_positions_of_companies' />}
				popupStyle={{ width: '750px', height: '300px' }}
				hideButton
				additionalButton={[
					{
						title: <Translate id='companies_without_positions' />,
						onSubmit: () => {
							setLoadingState(LoadingStateEnum.LOADING);
							setIsAllSelected(false);
							localEnrichCompanies(false);
							setIsEnrichOpen(false);
						},
						canValidate: true,
					},
					{
						title: <Translate id='all_selected_companies' />,
						onSubmit: () => {
							setLoadingState(LoadingStateEnum.LOADING);
							setIsAllSelected(false);
							localEnrichCompanies(true);
							setIsEnrichOpen(false);
						},
						canValidate: true
					},
					{
						title: <Translate id='back' />,
						onSubmit: () => setIsEnrichOpen(false),
						canValidate: true,
						style: ButtonStyle.White
					}
				]}
			>
				<DefaultTextDiv fontSize='14px'>
					<Translate id='you_are_about_to_enrich' />
					<br />
					<Translate id='wich_companies_do_you_want_to_enrich'/>
				</DefaultTextDiv>
			</PopupInformation>
			<InstancePopup modalState={formModalState} isOpen={formModalState.isOpen}
				onClickOut={() => setFormModalState({ ...formModalState, isOpen: false, })}/>
		</Container>
	);
}

export function SelectAll(props: {
  selected: number
  max: number
  onMaxSelected: (b: boolean) => void
  isAllSelected: boolean
}): JSX.Element {
	const { selected, max, onMaxSelected, isAllSelected } = props;
	const translate = getTranslate(storeLang.getState().localize);

	return (
		<SelectAllDiv>
			<img
				style={{ cursor: 'pointer', width: '18px' }}
				src={close}
				onClick={() => onMaxSelected(false)}
			/>
			<FlexDiv gap='5px'>
				{isAllSelected
					? <>
						{translate('{{NUMBER}}_records_selected').toString().replace('{{NUMBER}}', (max - selected).toString())}
						<Link onClick={() => onMaxSelected(false)}>
							<Translate id='clear_selection' />
						</Link>
					</>
					: <>
						{translate('{{NUMBER}}_records_selected').toString().replace('{{NUMBER}}', selected.toString())}
						<Link onClick={() => onMaxSelected(true)}>
							{translate('select_all_{{NUMBER}}_records').toString().replace('{{NUMBER}}', max.toString())}
						</Link>
					</>
				}
			</FlexDiv>
		</SelectAllDiv>
	);
}

export default Companies;
