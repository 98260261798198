import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';

export async function getReports(): Promise<Report[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/reports/generic`).then(res => res.data);
}

export function putReport(name: string, value: any, id: number) {
	return axios.put(`${URL_FOUNDATION}/api/v2/reports/generic/${id}`, {
		name,
		value,
	});
}

export function getReportById(id: number): Promise<Report> {
	return axios.get(`${URL_FOUNDATION}/api/v2/reports/generic/${id}`);
}


export interface Report {
  id: number
  name: string
  value: Value
  updated_at: string
}

export interface Value {
  group_by: string
  panels: Panel[]
  range: Range
  variation?: boolean
  evolution?: string
}

export interface Panel {
  columns: Column[]
  name?: string
  products?: string[]
}

export interface Column {
  Field?: Field
  Computed?: Computed
}

export interface Field {
  aggregation: string
  id: number
  name?: string
  operation?: string
}

export interface Computed {
  denominator: string
  name: string
  numerator: Numerator
  percentage: boolean
}

export interface Numerator {
  Field: Field
}

export interface Range {
  begin: string
  end: string
}
