import * as React from 'react';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { currencies } from '../../reports/utils';
import { DROPDOWN_STYLE_SETTINGS, SystemSettingsDropdownWrapper, SystemSettingsWrapper, SystemSettingTitle } from '../subSettings/styles';
import equal from 'deep-equal';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { diff } from '../../../components_v2/utils';

type LanguageCode = 'fr' | 'en'

type State = {
	language?: LanguageCode
	currency?: string
}

const changeLanguage = (value: string): boolean => {
	localStorage.setItem('language', value);
	return true;
};

const changeCurrency = (value: string): boolean => {
	localStorage.setItem('currency', value);
	return true;
};

async function saveChanges(difference: object): Promise<void> {
	const reload = Object.entries(difference).reduce((acc, [key, value]) => {
		switch (key) {
			case 'language': return changeLanguage(value);
			case 'currency': return changeCurrency(value);
		}
	}, false);
	if (reload) location.reload();
	return;
}

export default function UserSubSettings(props: {
	setToolBarState: (value: ToolbarState) => void
}) {
	const [state, setState] = React.useState<State>({});
	const [defaultState, setDefaultState] = React.useState<State>({});

	React.useEffect(() => {
		const defaultState = {
			languague: (localStorage.getItem('language') || navigator.language || this.state.languages[1].code)?.split('-')[0],
			currency: currencies.find(cd => cd[0] === localStorage.getItem('currency'))?.[0]
		};
		setDefaultState({ ...defaultState });
		setState({ ...defaultState });
	}, []);

	React.useEffect(() => {
		props.setToolBarState({
			bottomRightToolbarComponent: <>
				<DefaultButton disabled={equal(defaultState, state)} onClick={() => saveChanges(diff(defaultState, state)).then(_ => setDefaultState({ ...state }))}>
					{translateToNode('save')}
				</DefaultButton>
			</>
		});
	}, [state, defaultState]);

	const currencyData: DropdownData<string>[] = React.useMemo(() => currencies.map(c => ({ label: `${c[0]} ${c[1]}`, value: c[0] })), []);
	const languagesData: DropdownData<LanguageCode>[] = React.useMemo(() => [
		{
			label:  translateToString('english'),
			value: 'en'
		},
		{
			label: translateToString('french'),
			value: 'fr'
		}
	], []);

	return <SystemSettingsWrapper>

		<SystemSettingTitle>{translateToNode('my_settings')}</SystemSettingTitle>

		<SystemSettingsDropdownWrapper>
			{translateToNode('language')}
			<Dropdown<LanguageCode>
				name={''}
				dropdownStyle={DROPDOWN_STYLE_SETTINGS}
				readOnly
				onChange={(value: DropdownData<LanguageCode>) => setState({ ...state, language: value.value })}
				datalist={languagesData}
				selectedValue={languagesData.find(e => e.value === (state.language ?? (localStorage.getItem('language') || navigator.language || this.state.languages[1].code)?.split('-')[0]))}
			/>
		</SystemSettingsDropdownWrapper>

		<SystemSettingsDropdownWrapper>
			{translateToNode('currency')}
			<Dropdown<string>
				name={''}
				dropdownStyle={DROPDOWN_STYLE_SETTINGS}
				readOnly
				onChange={(value: DropdownData<string>) => setState({ ...state, currency: value.value })}
				datalist={currencyData}
				selectedValue={currencyData.find(cd => cd.value === localStorage.getItem('currency'))}
			/>
		</SystemSettingsDropdownWrapper>
	</SystemSettingsWrapper>;
}