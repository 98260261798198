import { Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../../atoms/global/users';
import * as React from 'react';
import { Column, Table } from '../../../../../components_v2/table/Table';
import { SeeMore } from '../style/LinkedElementStyle';
import { FormInstance, getLinkedFormInstance } from '../../../../forms/actions';
import { translateToString } from '../../../../../styles/global/translate';
import { DarkGreySidely } from '../../../../../styles/global/css/Utils';
import { FlexDiv } from '../../../../products/style';
import { DateAccessor } from '../../../data/CompanyColumns';
import { TableRow } from '../../../../orders/templateOrders/style/Style';
import { NoDataText, PartialOpen } from '../../../style/Style';
import { InstancePopup } from '../../../../forms/formInstancePopup';
import { ModalState } from '../../../../products/model';
import eye from 'images/icons/company/eye.svg';


function LinkedForms(props: {
	company_id: number
  }): JSX.Element {
	const DEFAULT_LIMIT = 3;
	const DEFAULT_WIDTH = 65;
	const [linkedInstances, setLinkedInstances] = React.useState<FormInstance[]>();
	const users = useRecoilValue(AUsers);
	const [offset, setOffset] = React.useState<number>(0);
	const [widthOffset, setWidthOffset] = React.useState<number>(0);
	const [modalState, setModalState] = React.useState<ModalState<string>>({ isOpen: false });
  
	React.useEffect(() => {
		getLinkedFormInstance(props.company_id).then(res => {
			setLinkedInstances(res);
			if (res.length >= 3) {
				setWidthOffset((DEFAULT_WIDTH - 53) * res.length);
			}
			else if (res.length == 2) {
				setWidthOffset(DEFAULT_WIDTH - + 25);
			}
		});
	}, []);

  
	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);

		const newWidthOffset = widthOffset + DEFAULT_WIDTH + 42;
		setWidthOffset(newWidthOffset);

	}


	const columns: Array<Column<FormInstance>> = React.useMemo(() => [
		{
			id: 'made_at',
			Header: translateToString('created_at'),
			accessor: row => <TableRow
				fontWeight="500"
				color={DarkGreySidely}
				cursor="pointer" onClick={() => setModalState({ isOpen: true, fullOpenMode: true, data: row.uuid })}>
				<FlexDiv fontWeight={row.progression < 100 ? '200' : '400'}>
					<DateAccessor date={row.made_at} />
					{row.progression < 100 ? translateToString('draft') : translateToString('completed')}
				</FlexDiv>
			</TableRow>,
			width: undefined,
			minWidth: 170,
			type: 'string',
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setModalState({ isOpen: true, fullOpenMode: false, data: row.uuid })}
			/>,
		},
		{
			id: 'created_by',
			Header: translateToString('created_by'),
			accessor: row => <TableRow cursor="pointer" onClick={() => setModalState({ isOpen: true, fullOpenMode: true, data: row.uuid })}>{users.find(a => a.id == row.created_by)?.name}</TableRow>,
			width: undefined,
			type: 'string',
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setModalState({ isOpen: true, fullOpenMode: false, data: row.uuid })}
			/>,
		},
		{
			id: 'form_name',
			Header: translateToString('name'),
			accessor: row => <TableRow cursor="pointer" onClick={() => setModalState({ isOpen: true, fullOpenMode: true, data: row.uuid })}>{row.name}</TableRow>,
			width: undefined,
			minWidth: 150,
			type: 'string',
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setModalState({ isOpen: true, fullOpenMode: false, data: row.uuid })}
			/>,
		},
	], []);


  
	return (<>
		{
			linkedInstances && linkedInstances.length > 0 &&
			<div style={{ height: (DEFAULT_WIDTH + widthOffset) + 'px' }}>
				<Table
					height='474px)'
					columns={columns}
					data={linkedInstances.sort((a, b) => new Date(b.made_at).getTime() - new Date(a.made_at).getTime()).slice(0, DEFAULT_LIMIT + offset)}
				/>
			</div>
		}
		{
			linkedInstances && linkedInstances.length == 0 &&
			<NoDataText><Translate id='no_linked_forms' /></NoDataText>
		}
 
		{linkedInstances && (linkedInstances.length >= offset + DEFAULT_LIMIT) &&
			<SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
		}
		<InstancePopup
			modalState={modalState}
			isOpen={modalState.isOpen}
			onClickOut={() => setModalState({ isOpen: false })}
		/>
	</>);
}
  
export default LinkedForms;
  