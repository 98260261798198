
import { FieldType } from 'bindings/forms/FieldType';

import File from 'images/icons/formTemplate/File.svg';
import Checkbox from 'images/icons/formTemplate/Checkbox.svg';
import Numeric from 'images/icons/formTemplate/Num.svg';
import List from 'images/icons/formTemplate/List.svg';
import Text from 'images/icons/formTemplate/Text.svg';
import Date from 'images/icons/formTemplate/Date.svg';
import Web from 'images/icons/formTemplate/Web.svg';
import Image from 'images/icons/formTemplate/Camera.svg';
import Email from 'images/icons/formTemplate/Mail.svg';
import User from 'images/icons/formTemplate/User.svg';
import Map from 'images/icons/formTemplate/Adress.svg';
import Phone from 'images/icons/formTemplate/Phone.svg';
import { Screen } from '../jsonValidator';

// Relation between type and image
export const ImageTypeMap = [
	{ type: 'Text', icon: Text },
	{ type: 'Number', icon: Numeric },
	{ type: 'Date', icon: Date },
	{ type: 'Boolean', icon: Checkbox },
	{ type: 'Select', icon: List },
	{ type: 'Multiselect', icon: List },
	{ type: 'File', icon: File },
	{ type: 'Image', icon: Image },
	{ type: 'Url', icon: Web },
	{ type: 'Email', icon: Email },
	{ type: 'Phone', icon: Phone },
	{ type: 'Address', icon: Map },
	{ type: 'Location', icon: Map },
	{ type: 'User', icon: User },
	{ type: 'Integer', icon: Numeric },
	{ type: 'TextArea', icon: Text },
];
export type Form = (Screen & {template_title?: string, template_description?: string})[]

export interface Fields {
    // Native information
    field_id: number
    name: string
    display_name: string
    field_data?: string[] | number
    type_: FieldType
    // calculated_value?: string
    // Information for the form builder
    category?: string
    descriptionTitle?: string
    description?: string
    mandatory?: boolean
    validator?: string
    inDraggable?: boolean
    added?: boolean
    dependentOn?: string
    history?: boolean
}

export interface Options {
  name: string
  title: string
  description: string
  value: boolean
  mandatory?: boolean
}