import styled from 'styled-components';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { DefaultText } from './GlobalText';
import { BlueSidely, RedSidely } from './Utils';

interface DefaultButtonProps {
  error?: boolean
  width?: string
  height?: string
  margin?: string
  buttonStyle?: ButtonStyle
}

export const DEFAULT_BUTTON_HEIGHT = 32;

const DefaultButton = styled.button<DefaultButtonProps>`
    transition: all 0.25s ease-in-out;

    background-color: ${p => p.error ? RedSidely : BlueSidely};
    filter: brightness(1);
    cursor: pointer !important;
    border-radius: 4px;
    border-style: none;
    ${p => p.width ? `width: ${p.width};` : ''}
    height: ${p => p.height ? p.height : DEFAULT_BUTTON_HEIGHT + 'px'};
    padding: calc(0.25em + 1px) 1em;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    margin: ${p => p.margin ?? '10px'};
    ${p => {
		if (!p.buttonStyle) return '';
		switch (p.buttonStyle) {
			case 1: return `
		    background-color: ${RedSidely};
		`;
			case 2: return `
            padding: calc(0.25em - 1px) 1em;
            background-color: white;
		    color: ${BlueSidely};
		    border: 2px solid ${BlueSidely};
		`;
			default: return '';
		}
	}}

    &:hover:enabled {
        filter: brightness(1.1);
    }

    &:disabled {

    ${p => {
		switch (p.buttonStyle) {
			case 2: return `
            padding: calc(0.25em - 1px) 1em;
		    color: #D5D5D5;
		    border: 2px solid #D5D5D5;
            cursor: not-allowed !important;
            box-shadow: none;
		`;
			default: return `
            background: #D5D5D5;
            cursor: not-allowed !important;
            box-shadow: none;
        `;
		}
	}}
    }
`;

const DefaultCloseButton = styled.span`
    ${DefaultText}
    transition: color 0.4s ease;
    font-size: 20px;
    color: #808080;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-weight: 700;
    line-height: 1;

    &:after {
        content: 'x';
    }

    &:hover {
        color: #000000;
    }
`;

export {
	DefaultButton,
	DefaultCloseButton
};
