import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector, useRecoilValue } from 'recoil';
import { URL_FOUNDATION } from '../../config/keys';
import { Owner } from '../../containers_v2/orders/model/Model';
import { getMyProfile } from '../../containers_v2/settings/userSettings/actions';
import { UserWithAdditionalFields } from '../../containers_v2/settings/userSettings/myProfile';
import { getUserColor } from '../../components_v2/avatar/Data/Data';
import { sortOwners } from '../../components_v2/dropdown/DropdownOwners';

// -----------------------[ ATOM ]----------------------- //
export const AUsers = selector({
	key: 'CurrentUserName',
	get: async() => await getUsers()
});

export const AProfile = selector({
	key: 'CurrentUserProfile',
	get: async(): Promise<UserWithAdditionalFields & { companyId: number }> => {
		const res = await getMyProfile();
		const cookie = new Cookies();
		let companyId = cookie.get('company_id');
		if (typeof companyId === 'string') companyId = parseInt(companyId);
		if (!companyId || !(typeof companyId === 'number') || isNaN(companyId)) throw 'no company id';
		return { ...res, companyId };
	}
});

export function useMe(): Owner {
	const me = useRecoilValue(AUsers).find(u => u.isYou);
	if (!me) throw 'me is not a user';
	return me;
}

export function isOwner(user: { roleName: string }): boolean {
	return user.roleName === 'Owner';
}

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

async function getUsers(): Promise<Owner[]> {
	try {
		const result = await axios.get(`${URL_FOUNDATION ?? ''}/api/users`)
			.then(response => response.data.map((user): Owner => ({
				id: user.id,
				name: user.name,
				email: user.email,
				phone: user.phone,
				photoUrl: user.photo,
				isYou: user.is_you,
				status: user.status,
				color: user.color ?? getUserColor(user.name),
				roleId: user.role_id,
				roleName: user.role_name
			})));
		result.sort(sortOwners);
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}
