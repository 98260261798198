import * as React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import { SetSelectedReport } from './Reports';
import PresencePerProductPerCompanyFilters from './presencePerProductPerCompany/PresencePerProductPerCompanyFilters';
import EventsPerCompanyFilters from './eventsPerCompany/EventsPerCompanyFilters';
import EventsPerUserFilters from './eventsPerUser/EventsPerUserFilters';
import MerchandisingPerCompanyFilters from './merchandisingPerCompany/MerchandisingPerCompanyFilters';
import CompanyStatusEvolutionFilters from './companyStatusEvolution/CompanyStatusEvolutionFilters';
import { reportingAction, reportingState } from './Reducer';
import PresencePerUsersFilters from './presencePerUsers/PresencePerUsersFilters';
import PresencePerProductsFilters from './presencePerProducts/PresencePerProductsFilters';
import OrdersEvolutionFilters from './ordersEvolution/OrdersEvolutionFilters';
import OrderPerProductPerCompanyFilters from './orderPerProductPerCompany/OrderPerProductPerCompanyFilters';
import { getFieldReportInstallers } from '../../components_v2/sidebar/actions';
import PresencePerBrandOrCategoryPerCompanyFilters from './presencePerBrandOrCategoryPerCompany/presencePerBrandOrCategoryPerCompanyFilters';
import CategorySelector, { Category } from '../../components_v2/Selector/CategorySelector';
import { getLeftOrRightImage } from '../map/modalLeft/ModalLeftFilter';
import PermissionContext from '../permissions/PermissionContext';
import usePermission from '../permissions/usePermission';
import { AlertContext } from '../../containers_v2/alert/AlertProvider';
import { NewReport } from './generic/generic';
import Add from '../../components_v2/add/Add';
import { FlexDiv } from '../products/style';
import styled from 'styled-components';
import { translateToString } from '../../styles/global/translate';
import { isSuperAdmin } from '../../components_v2/utils';
enum ReportTabs {
	ReportingSelection,
	Filter
}

function switchFilters(state: reportingState, dispatch: (filters: reportingAction) => void) {
	switch (state.selectedReport) {
		case 'presence_per_product_per_company':
			return <PresencePerProductPerCompanyFilters state={state} dispatch={dispatch} />;
		case 'merchandising_per_company':
			return <MerchandisingPerCompanyFilters state={state} dispatch={dispatch} />;
		case 'events_per_company':
			return <EventsPerCompanyFilters state={state} dispatch={dispatch} />;
		case 'events_per_user':
			return <EventsPerUserFilters state={state} dispatch={dispatch} />;
		case 'company_status_evolution':
			return <CompanyStatusEvolutionFilters state={state} dispatch={dispatch} />;
		case 'presence_per_users':
			return <PresencePerUsersFilters state={state} dispatch={dispatch} />;
		case 'presence_per_products':
			return <PresencePerProductsFilters state={state} dispatch={dispatch} />;
		case 'orders_evolution':
			return <OrdersEvolutionFilters state={state} dispatch={dispatch} />;
		case 'order_per_product_per_company':
			return <OrderPerProductPerCompanyFilters state={state} dispatch={dispatch} />;
		case 'presence_per_brand_or_category_per_company':
			return <PresencePerBrandOrCategoryPerCompanyFilters state={state} dispatch={dispatch} />;
		case 'presence_evolution':
		default:
			return <></>;
	}
}

const StyledSpan = styled.span`
	font-size: 12px;
	font-weight: 300;
`;

export function ModalLeft(props: SetSelectedReport) {
	const [activeTab, setActiveTab] = React.useState<ReportTabs>(ReportTabs.ReportingSelection);
	const [reportsfields, setReportsFields] = React.useState<string[]>([]);
	const { isAllowedTo } = React.useContext(PermissionContext);
	const isFormV2 = isAllowedTo({ objectAction: 'ViewForm' });
	const { alert } = React.useContext(AlertContext);
	const isAdmin = isSuperAdmin();
	
	const reportKindList: Category[] = [
		{
			name: <Translate id='shelf_audit.sections.my_reports' />,
			restricted: { objectAction: 'ReadForm' },
			hidden: !isFormV2,
			children: [...[...props.reports].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
				.map(r => { 
					if (isAdmin || !r.name.toLowerCase().startsWith('superadmin')) 
						return ({
							beta: false,
							admin: false,
							name: r.name,
							active: r.name === props.state.selectedReport,
							onClick: () => props.dispatch({ type: 'SET_SELECTED_REPORT', value: r.name, data: { ...r } })
						});
					return ({});
				}), { name: <FlexDiv gap='6px'><Add size='20px'/><StyledSpan><Translate id='reports.create_report'></Translate></StyledSpan></FlexDiv>, onClick: () => {
				alert({
					title: translateToString('reports.name_report'),
					content: (onBreak, onOk) => <NewReport onBreak={onBreak} onOk={onOk} />,
					noButtons: true,
				});} }
			]
		}, ...[
			{
				name: <Translate id='shelf_audit.sections.sales_activity' />,
				children: [
					{ kind: 'events_per_company' },
					{ kind: 'event_count_evolution_per_companies' },
					{ kind: 'events_per_user' },
					{ kind: 'event_count_evolution_per_user' },
				]
			},
			{
				name: <Translate id='shelf_audit.sections.companies' />,
				children: [
					{ kind: 'company_status_evolution' }
				]
			},
			{
				name: <Translate id='shelf_audit.sections.orders' />,
				hidden: !reportsfields.some(rf => rf == 'orders'),
				children: [
					{ kind: 'orders_evolution' },
					{ kind: 'order_per_product_per_company' }
				]
			},
			{
				name: <Translate id='shelf_audit.sections.presence' />,
				hidden: !reportsfields.some(rf => rf == 'shelf_audit' || usePermission({ objectAction: 'ReadShelfAudit' })),
				children: [
					{ kind: 'presence_evolution' },
					{ kind: 'presence_evolution_per_product' },
					{ kind: 'presence_per_product_per_company' },
					{ kind: 'presence_per_brand_or_category_per_company' },
					{ kind: 'presence_per_users' },
				]
			},
			{
				name: <Translate id='shelf_audit.sections.merchandising' />,
				hidden: !reportsfields.some(rf => rf == 'shelf_audit' || usePermission({ objectAction: 'ReadShelfAudit' })),
				children: [
				// { kind: 'merchandising_per_company' },
					{ kind: 'merchandising_per_company_per_product' },
					{ kind: 'merchandising_evolution_per_companies' },
					{ kind: 'merchandising_evolution_per_product', beta: false, admin: false },
				]
			}
		].map(cat => ({
			...cat, children: cat.children.map(c => ({
				beta: c.beta,
				admin: c.admin,
				active: c.kind === props.state.selectedReport,
				onClick: () => props.dispatch({ type: 'SET_SELECTED_REPORT', value: c.kind }),
				name: <Translate id={'shelf_audit.sections.' + c.kind} />
			}))
		})) ];

	React.useEffect(() => {
		getFieldReportInstallers().then(res => {
			setReportsFields(res.data.data.map(rep => rep.name));
		});
	}, []);

	return (
		<div className="filter-modal" style={{ marginTop: '52px', height: 'calc(100% - 107px)', left: '0' }}>
			<div className={props.isModalOpen ? 'box-filter-modal' : 'd-none'} style={{ width: '260px' }}>
				<Nav tabs style={{ position: 'sticky', top: 0, zIndex: 2 }}>
					<NavItem>
						<NavLink
							className={activeTab === ReportTabs.ReportingSelection ? 'active' : ''}
							onClick={() => setActiveTab(ReportTabs.ReportingSelection)}
						>
							<Translate id="shelf_audit.sections.fields" />
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={activeTab === ReportTabs.Filter ? 'active' : ''}
							onClick={() => setActiveTab(ReportTabs.Filter)}
						>
							<Translate id="Filters" />
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId={ReportTabs.ReportingSelection}>
						<CategorySelector categories={reportKindList} title={<Translate id="field_of_reporting" />} />
					</TabPane>
					<TabPane tabId={ReportTabs.Filter}>
						{switchFilters(props.state, props.dispatch)}
					</TabPane>
				</TabContent>
			</div>
			<button
				type="button"
				className="btn btn-filter-modal"
				onClick={() => props.setIsModalOpen(!props.isModalOpen)}
			>
				<img
					src={getLeftOrRightImage(props.isModalOpen)}
					alt=""
				/>
			</button>
		</div>
	);
}
