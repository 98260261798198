import trashImage from 'images/formbuilder/trash.svg';
import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import { getTranslate, Translate } from 'react-localize-redux';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownTags } from '../../components_v2/dropdown/DropdownTagsCloud';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import Input from '../../components_v2/input/Input';
import InputImage from '../../components_v2/input/InputImage';
import { ImageThumbnail } from '../../components_v2/input/model/Model';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import storeLang from '../../helpers/storeLang';
import { ITag } from '../../../typings/proto/protobufs';
import { SmallTitle, Spacer, TitleSection } from '../client-companies/style/NewCompanyStyle';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../client-companies/style/Style';
import { normalizeInt, normalizeIntAbove0, normalizeIntAbove0Nullable, normalizeFloat } from '../globals/FieldFunction';
import { Brand } from '../globals/Model';
import { getCurrencySymbol } from '../reports/utils';
import { getTaxes } from '../settings/actions';
import { Tax } from '../settings/models';
import { getBrands, getTags, postProduct, postImages, updateProduct } from './action';
import LegacyCategoryModal from './legacyCategoryModal';
import { ProductsAlternativeList } from './productAlternativesCreation';
import { Content, FlexDiv, HideTrashDiv, Picture, PictureTrash, SymbolContainer, TopRight } from './style';
import { translateToNode, translateToString } from '../../styles/global/translate';
import useAlert from '../alert/UseAlert';
import { parseNumber } from '../../components_v2/utils';

interface ProductCreationType {
  name?: string
  images?: ImageThumbnail[]
  reference?: string
  category?: [number, string]
  stock?: number
  code_upc?: string
  barcode?: string
  price?: string
  recommended_price?: string
  tax?: number
  description?: string
  resume?: string
  brand?: number
  tags?: number[]
  alternatives?: number[]
  outer?: number
  status?: number
  additional_taxes?: Tax[],
  width?: number,
  height?: number,
  depth?: number
}

export default function ProductCreation(props: {
  isOpen: boolean
  setIsOpen: (b: boolean) => void
  refresh?: () => void
}): JSX.Element {
	const { isOpen, setIsOpen, refresh } = props;

	const [brands, setBrands] = React.useState<Brand[]>([]);
	const [tags, setTags] = React.useState<ITag[]>([]);

	const [current, setCurrent] = React.useState<number>(0);
	const [isLightboxOpen, setIsLightboxOpen] = React.useState<boolean>(false);
	const [isCategoryOpen, setIsCategoryOpen] = React.useState<boolean>(false);
	const [isAlternativeOpen, setIsAlternativeOpen] = React.useState<boolean>(false);
	const [creation, setCreation] = React.useState<ProductCreationType>({});
	const translate = getTranslate(storeLang.getState().localize);
	const [taxes, setTaxes] = React.useState<Tax[]>([]);
	const alert = useAlert();

	React.useEffect(() => {
		getBrands()
			.then(res => setBrands(res.data))
			.catch(console.log);
		getTags().then(tags => {
			setTags(tags);
		})
			.catch(console.log);
		getTaxes().then((taxes: Tax[]) => {
			setTaxes(taxes);
			setCreation({ ...creation, additional_taxes: taxes });
		}).catch(console.log);
	}, []);

	function transformAndCreate() {
		const body = {
			...creation,
			price: creation.price ? parseFloat(creation.price) : undefined,
			recommended_price: creation.recommended_price ? parseFloat(creation.recommended_price) : undefined,
			category: creation.category?.[0],
			images: creation.images?.map(image => ({ name: 'thumbs/' + image.thumbnail.name, file: '' })),
			additional_taxes: creation.additional_taxes?.map(t => ({ id: t.id, value: t.tax_value }))
		};
		if (creation.images) {
			creation.images?.map(async(image) => {await postImages([image.thumbnail, image.original], ['thumbs/' + image.thumbnail.name, 'originals/' + image.original.name]);});
		}
		postProduct(body)
			.then((res) => {
				const productId = res.data;
				const dimensions = { width: creation.width, height: creation.height, depth: creation.depth };
				const price = creation.price ? parseFloat(creation.price) : undefined;
				const recommended_price = creation.recommended_price ? parseFloat(creation.recommended_price) : undefined;
				const prices = { price, recommended_price };
				updateProduct(productId, { ...dimensions, ...prices });
				setCreation({});
				setCurrent(0);
				setIsOpen(false);
				refresh?.();
			});
	}

	const isValid: boolean = ((creation.name?.trim() ?? '') !== '') &&
		((creation.reference?.trim() ?? '') !== '') &&
		(creation.category?.[0] !== undefined) &&
		(creation.barcode !== undefined) &&
		(creation.code_upc !== undefined) &&
		(creation.price == undefined || creation.price == '' || !isNaN(parseFloat(creation.price))) &&
		(creation.recommended_price == undefined || creation.recommended_price == '' || !isNaN(parseFloat(creation.recommended_price)));

	return (
		<Popup
			isOpen={isOpen}
			onClickOut={() => setIsOpen(false)}
			popupStyle={{ animate: true, height: '100vh', top: '0' }}
			popupMode={PopupMode.Details}
			disableOutClick={isCategoryOpen || isLightboxOpen || isAlternativeOpen}
			disableEsc={isCategoryOpen || isLightboxOpen || isAlternativeOpen}
		>
			<PopupCreation
				title={<Translate id='create_product' />}
				canValidate={isValid}
				onSubmit={transformAndCreate}
				onClose={() => setIsOpen(false)}
			>
				<CreationPopupContainer>
					<TitleSection><Translate id='Product details' /></TitleSection>
					<Spacer />
					<SmallTitle><Translate id='Picture' /></SmallTitle>
					<Content row gap={10} left wrap>
						{creation.images?.map((v, i) => <>
							<HideTrashDiv>
								<Picture src={v.url} onClick={() => { setCurrent(i); setIsLightboxOpen(true); }} />
								<TopRight>
									<PictureTrash className='trash' src={trashImage} onClick={() => {
										setCreation(prevCreation => ({
											...prevCreation,
											images: prevCreation.images?.filter((_, index) => index !== i)
										}));
									}} />
								</TopRight>
							</HideTrashDiv>
						</>)}
						{(creation.images?.length ?? 0) < 5 &&
							<InputImage
								isProduct={true}
								compress={true}
								limitMo={10}
								disableShowImage
								onChange={(value) => {
									setCreation(prevCreation => ({ ...prevCreation, images: [...(prevCreation.images ?? []), value] }));
								}}
							/>
						}
						{isLightboxOpen &&
							<Lightbox
								mainSrc={creation.images?.[current]?.url ?? ''}
								nextSrc={creation.images?.[current + 1]?.url}
								prevSrc={creation.images?.[current - 1]?.url}
								onCloseRequest={() => setIsLightboxOpen(false)}
								onMovePrevRequest={() => setCurrent(current - 1)}
								onMoveNextRequest={() => setCurrent(current + 1)}
							/>
						}
					</Content>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_name"
						type="text"
						placeholder={translate('name') as string}
						label={<Translate id='name' />}
						required
						value={creation.name}
						onChange={(value) => setCreation({ ...creation, name: value })}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_reference"
						type="text"
						placeholder={translate('reference') as string}
						label={translate('reference') as string}
						required
						value={creation.reference}
						onChange={(value) => setCreation({ ...creation, reference: value })}
					/>
					<Spacer />
					<Input
						onClick={() => setIsCategoryOpen(true)}
						inputStyle={creationPopupInputStyle}
						name="product_category"
						type="text"
						placeholder={translate('category') as string}
						label={translate('category') as string}
						required
						value={creation.category?.[1]}
						disableChange
					/>
					<LegacyCategoryModal
						isOpen={isCategoryOpen}
						toggle={() => setIsCategoryOpen(!isCategoryOpen)}
						onSubmit={(id, name) => id && name && setCreation({ ...creation, category: [id, name] })}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_barcode"
						type="text"
						placeholder={translate('barcode') as string}
						label={translate('barcode') as string}
						value={creation.barcode}
						required
						onChange={(barcode) => setCreation({ ...creation, barcode })}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_code_UPC"
						type="text"
						placeholder={translate('UPC Barcode') as string}
						label={translate('UPC Barcode') as string}
						value={creation.code_upc}
						required
						onChange={(code_upc) => setCreation({ ...creation, code_upc })}
					/>
					<Spacer />
					<Spacer />
					<Dropdown
						dropdownStyle={creationPopupDropdownStyle}
						datalist={brands.map(b => ({ label: b.name, value: b.id }))}
						name='brand_dropdown'
						readOnly
						label={translate('brand') as string}
						onChange={(brand: DropdownData) => {
							if (brand.value == null) {
								setCreation({ ...creation, brand: undefined });
							} else {
								setCreation({ ...creation, brand: brand.value });
							}
						}}
						cancellable
					/>
					<Spacer />
					<DropdownTags
						dropdownStyle={creationPopupDropdownStyle}
						tags={tags}
						selected={tags.filter(t => creation.tags?.includes(t.id!))}
						onChange={(values) => setCreation({ ...creation, tags: values.map(e => e.value.id!) })}
						label={translate('tags') as string}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_stocks"
						type="text"
						placeholder={translate('stock') as string}
						label={translate('stock') as string}
						value={creation.stock}
						normalize={[normalizeInt]}
						onChange={(value) => {
							let stock: number | undefined = parseInt(value);
							if (isNaN(stock)) {
								stock = undefined;
							}
							setCreation({ ...creation, stock });
						}}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_width"
						type="number"
						step={0.1}
						placeholder={translateToString('width') + ' (cm)'}
						label={translateToString('width') + ' (cm)'}
						value={creation.width}
						normalize={[normalizeFloat]}
						onChange={(value) => {
							let width: number | undefined = parseNumber(value);
							if (isNaN(width)) {
								width = undefined;
							}
							setCreation({ ...creation, width });
						}}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_height"
						type="number"
						step={0.1}
						placeholder={translateToString('height') + ' (cm)'}
						label={translateToString('height') + ' (cm)'}
						value={creation.height}
						normalize={[normalizeFloat]}
						onChange={(value) => {
							let height: number | undefined = parseNumber(value);
							if (isNaN(height)) {
								height = undefined;
							}
							setCreation({ ...creation, height });
						}}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_depth"
						type="number"
						step={0.1}
						placeholder={translateToString('depth') + ' (cm)'}
						label={translateToString('depth') + ' (cm)'}
						value={creation.depth}
						normalize={[normalizeFloat]}
						onChange={(value) => {
							let depth: number | undefined = parseNumber(value);
							if (isNaN(depth)) {
								depth = undefined;
							}
							setCreation({ ...creation, depth });
						}}
					/>
					<Spacer />
					<TitleSection><Translate id='price' /></TitleSection>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_price"
						type="text"
						placeholder={translate('price') as string}
						label={translate('price') as string}
						value={creation.price}
						normalize={[normalizeFloat]}
						onChange={(value) => setCreation({ ...creation, price: value })}
					/>
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_recommendedPrice"
						type="text"
						placeholder={translate('recommended price') as string}
						label={translate('recommended price') as string}
						value={creation.recommended_price}
						normalize={[normalizeFloat]}
						onChange={(value) => setCreation({ ...creation, recommended_price: value })}
					/>
					{!taxes.length && <>
						<Spacer/>
						<Input
							inputStyle={creationPopupInputStyle}
							name="product_tax"
							type="text"
							disableChange
							placeholder={translate('tax') as string}
							label={translate('tax') as string}
							onClick={() => {
								if (taxes.length === 0) {
									alert({
										title: translateToNode('taxes'),
										content: <div>{translateToNode('no_data_yet', { onClickA: () => window.open('/settings#tax_management', '_blank'), variables: [['data', translateToString('taxes').toLocaleLowerCase()]] })}</div>,
										noButtons: true
									});
								}
							}}
						/>
					</>}
					{taxes.map((t, i) => {
						let symbol: string;
						switch (t.tax_type_name) {
							case 'percentage':
								symbol = '%';
								break;
							case 'amount':
								symbol = getCurrencySymbol();
								break;
							default:
								symbol = t.tax_type_name;
								break;
						}
						return <div key={`tax[${t.id}]`} style={{ padding: i === 0 ? '10% 0 5% 0' : '5% 0' }}>
							<FlexDiv gap='2px'>
								<Input
									inputStyle={{ ...creationPopupInputStyle, width: '213px' }}
									name={`product_tax[${i}]`}
									type="text"
									placeholder={t.name}
									label={t.name}
									value={creation.additional_taxes?.[i]?.tax_value}
									normalize={[normalizeInt]}
									onChange={(value) => {
										let tax: number | undefined = parseInt(value);
										if (isNaN(tax)) {
											tax = undefined;
										}
										if (creation.additional_taxes != null) {
											creation.additional_taxes[i].tax_value = tax;
										}
										setCreation({ ...creation });
									}}
								/>
								{/* @ts-expect-error style? */}
								<SymbolContainer style={{ ...creationPopupInputStyle, width: 'fit-content' }}>
									{symbol}
								</SymbolContainer>
							</FlexDiv>
						</div>;
					})}
					<Spacer />
					<Input
						inputStyle={creationPopupInputStyle}
						name="product_outer"
						type="text"
						placeholder={translate('outer') as string}
						label={translate('outer') as string}
						value={creation.outer}
						normalize={[normalizeInt]}
						onChange={(value) => {
							let outer: number | undefined = parseInt(value);
							if (isNaN(outer)) {
								outer = undefined;
							}
							setCreation({ ...creation, outer });
						}}
					/>
					<Spacer />
					<TitleSection><Translate id='Caracteristics' /></TitleSection>
					<Spacer />
					<Input
						inputStyle={{ ...creationPopupInputStyle, height: '100px' }}
						name="product_resume"
						type="textarea"
						placeholder={translate('summary') as string}
						label={translate('summary') as string}
						value={creation.resume}
						onChange={(value) => setCreation({ ...creation, resume: value })}
						textArea
					/>
					<Spacer />
					<Input
						textArea
						inputStyle={{ ...creationPopupInputStyle, height: '100px' }}
						name="product_description"
						type="textarea"
						placeholder={translate('description') as string}
						label={translate('description') as string}
						value={creation.description}
						onChange={(value) => setCreation({ ...creation, description: value })}
					/>
					<Spacer />
					<TitleSection><Translate id='Alternative products' /></TitleSection>
					<Spacer />
					<Input
						onClick={() => setIsAlternativeOpen(true)}
						inputStyle={creationPopupInputStyle}
						name="product_alternatives"
						type="text"
						placeholder={translate('Alternative products') as string}
						label={translate('Alternative products') as string}
						value={translate('{{NUMBER}} selected').toString().replace('{{NUMBER}}', `${creation.alternatives?.length ?? 0}`)}
						disableChange
					/>
					<Popup
						popupStyle={{ height: 'calc(40px * 13)' }}
						isOpen={isAlternativeOpen}
						onClickOut={() => setIsAlternativeOpen(false)}
						hasBackground
					>
						<FlexDiv justify='center'>
							<ProductsAlternativeList
								noTitle
								onChange={(alternatives: number[]) => setCreation({ ...creation, alternatives })}
								productId={-1}
								limit={10}
								tableHeight={'calc(100% - 20px)'}
							/>
						</FlexDiv>
					</Popup>
				</CreationPopupContainer>
			</PopupCreation>
		</Popup>
	);
}
