import { Fields, Form, Options } from '../type';

export const chr_presence_final_step = 10;

export const chr_presence_form: Form = [
	{
		'buttons': {
			'children': [
				{
					'action': 'continue',
					'name': 'Commencer',
					'type': 'button',
					'visible_if': 'calcProgression() == 0'
				},
				{
					'action': 'continue',
					'name': 'Continuer',
					'type': 'button',
					'visible_if': 'calcProgression() ~= 0 and calcProgression() ~= 1'
				},
				{
					'action': 'next',
					'name': 'Terminer',
					'type': 'button',
					'visible_if': 'calcProgression() == 1'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'pagination',
					'value': [
						{
							'name': 'Qualification Etablissement',
							'screen': {
								'condition': 'true',
								'screen_name': 'Qualification Etablissement'
							}
						},
						{
							'name': 'Qualification Interlocuteur(s)',
							'screen': {
								'condition': 'true',
								'screen_name': 'Qualification Interlocuteur(s)'
							}
						},
						{
							'name': 'Présence produit',
							'screen': {
								'condition': 'true',
								'screen_name': 'Choix assortiment'
							}
						}
					]
				},
				'name': 'Tâches à mener',
				'type': 'pagination'
			},
			{
				'data': [],
				'type': 'summary'
			}
		],
		'fields': [
			{
				'field_id': 19,
				'metadata': {},
				'name': 'point-de-vente',
				'validator': 'current_screen.fields["point-de-vente"].filled'
			},
			{
				'field_id': 20,
				'metadata': {},
				'name': 'check-in'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'point-de-vente',
					'type': 'field'
				},
				{
					'index': 1,
					'type': 'component'
				},
				{
					'name': 'check-in',
					'type': 'field',
					'visible_if': 'current_screen.fields["point-de-vente"].filled'
				},
				{
					'index': 0,
					'type': 'component',
					'visible_if': 'current_screen.fields["point-de-vente"].filled'
				}
			],
			'type': 'column'
		},
		'name': 'Choix du point de vente',
		'next_screens': [
			{
				'condition': 'true'
			}
		]
	},
	{
		'buttons': {
			'children': [
				{
					'action': 'back',
					'type': 'button'
				},
				{
					'action': 'continue',
					'type': 'button',
					'visible_if': 'calcProgression() == 100'
				}
			],
			'type': 'row'
		},
		'components': [
			{
				'data': {
					'name': 'catalogue',
					'value': 'filter_map_columns(current_company.additional_columns)'
				},
				'name': 'catalogue-dispatcher',
				'screen': {
					'condition': 'true',
					'screen_name': 'Choix du produit'
				},
				'type': 'catalogue_dispatcher'
			}
		],
		'fields': [],
		'layout': {
			'name': 'catalogue-dispatcher',
			'type': 'component'
		},
		'name': 'Choix assortiment',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Choix du point de vente'
			}
		]
	},
	{
		'components': [],
		'fields': [
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null
				},
				'name': 'lineaire-avant-releve'
			}
		],
		'layout': {
			'name': 'lineaire-avant-releve',
			'type': 'field'
		},
		'name': 'Choix du point de vente',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Choix du produit'
			}
		]
	},
	{
		'components': [
			{
				'filters': [
					{
						'name': 'Présence',
						'type': 'checkbox',
						'values': [
							{
								'function': 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(4, {["product"] = _F.item}) == true',
								'label': 'Produits présents'
							},
							{
								'function': 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(4, {["product"] = _F.item}) == false',
								'label': 'Produits absents'
							}
						]
					}
				],
				'data': {
					'callback': [
						{
							'color': 'green',
							'condition': 'getSavedFieldByIdAndCurrentMetadata(4) == true',
							'type': 'border-side'
						},
						{
							'color': 'red',
							'condition': 'getSavedFieldByIdAndCurrentMetadata(4) == true and calcProgressionWithEndScreen(x) ~= 1',
							'svg': 'close',
							'type': 'svg'
						}
					],
					'name': 'product',
					'value': 'catalogue'
				},
				'name': 'liste-produits',
				'screen': {
					'condition': 'true',
					'screen_name': 'Informations du produit'
				},
				'type': 'product_dispatcher'
			}
		],
		'fields': [],
		'layout': {
			'name': 'liste-produits',
			'type': 'component'
		},
		'name': 'Choix du produit',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Choix assortiment'
			}
		]
	},
	{
		'components': [
			{
				'data': 'getProductByUuid(_G.product).images',
				'name': 'product_image',
				'type': 'image'
			},
			{
				'data': 'getProductByUuid(_G.product).name',
				'name': 'product_text',
				'type': 'text'
			}],
		'fields': [
			{
				'default': false,
				'field_id': 22,
				'metadata': {
					'catalogue': null,
					'product': null
				},
				'name': 'produit-present',
				'history': true,
				'validator': 'current_screen.fields["produit-present"].filled'
			},
			{
				'field_id': 0,
				'metadata': {
					'catalogue': null,
					'product': null,
				},
				'name': 'prix-constate',
				'history': true,
			}
		],
		'layout': {
			'children': [
				{
					'children': [
						{
							'child': {
								'name': 'product_image',
								'type': 'component'
							},
							'options': {
								'display': {
									'width': 50
								}
							},
							'type': 'container'
						},
						{
							'name': 'product_text',
							'type': 'component'
						}
					],
					'name': 'Product',
					'type': 'row'
				},
				{
					'name': 'produit-present',
					'type': 'field',
				},
				{
					'name': 'prix-constate',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				},
			],
			'type': 'column'
		},
		'name': 'Informations du produit',
		'template_title': 'Choisissez les éléments que vous souhaitez relever pour chacun de vos produits',
		'template_description': 'Cochez les éléments que vous souhaitez faire remonter par vos équipes lors de leurs relevés de linéaires',
		'next_screens': [
			{
				'condition': 'true',
				'screen_name': 'Choix du produit'
			}
		]
	},
	{
		'components': [],
		'fields': [
			{
				'field_id': 0,
				'metadata': {},
				'name': 'type-point-de-vente',
				'validator': 'current_screen.fields["type-point-de-vente"].value ~= nil'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'grossiste'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'contrat'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'datefincontrat'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'concurrenceprésente'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'concurrentsprésents'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'changementscarte'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'carteété'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'cartehiver'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'photocarte'
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'potentiel'
			}
		],
		'layout': {
			'children': [
				{
					'name': 'type-point-de-vente',
					'type': 'field'
				},
				{
					'name': 'grossiste',
					'type': 'field'
				},
				{
					'name': 'contrat',
					'type': 'field',
					'visible_if': 'current_screen.fields["grossiste"].value == true'
				},
				{
					'name': 'datefincontrat',
					'type': 'field',
					'visible_if': 'current_screen.fields["contrat"].value == true'
				},
				{
					'name': 'potentiel',
					'type': 'field'
				},
				{
					'name': 'concurrenceprésente',
					'type': 'field'
				},
				{
					'name': 'concurrentsprésents',
					'type': 'field',
					'visible_if': 'current_screen.fields["concurrenceprésente"].value == true'
				},
				{
					'name': 'changementscarte',
					'type': 'field'
				},
				{
					'name': 'carteété',
					'type': 'field',
					'visible_if': 'current_screen.fields["changementscarte"].value == true'
				},
				{
					'name': 'cartehiver',
					'type': 'field',
					'visible_if': 'current_screen.fields["changementscarte"].value == true'
				},
				{
					'name': 'photocarte',
					'type': 'field'
				},
			],
			'type': 'column'
		},
		'name': 'Qualification Etablissement',
		'next_screens': [
			{
				'condition': 'true',
				'screen_index': 0
			}
		]
	},
	{
		'components': [
			{
				'name': 'nouveau-contact',
				'type': 'linked_contact'
			},
			{
				'name': 'nouvel-evenement',
				'type': 'event_creator'
			}
		],
		'fields': [
			{
				'field_id': 0,
				'metadata': {},
				'name': 'personnel-rencontre',
				'validator': 'current_screen.fields["personnel-rencontre"].filled',
				history: true,
			},
			{
				field_id: 0,
				'metadata': {},
				'name': 'type-personnel-rencontre',
				history: true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'engagement',
				history: true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'interet',
				history: true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'noninteret',
				history: true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'commentairenoninteret',
				history: true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'gamme',
				history: true,
			},
			{
				'field_id': 0,
				'metadata': {},
				'name': 'commentaireinteret',
				history: true,
			}
		],
		'layout': {
			'children': [
				{
					'name': 'personnel-rencontre',
					'type': 'field'
				},
				{
					'name': 'type-personnel-rencontre',
					'type': 'field',
					'visible_if': 'current_screen.fields["personnel-rencontre"].value == true'
				},
				{
					'name': 'nouveau-contact',
					'type': 'component',
					'visible_if': 'current_screen.fields["personnel-rencontre"].value == true'
				},
				{
					'name': 'engagement',
					'type': 'field'
				},
				{
					'name': 'interet',
					'type': 'field'
				},
				{
					'name': 'noninteret',
					'type': 'field',
					'visible_if': 'current_screen.fields["interet"].value == false'
				},
				{
					'name': 'commentairenoninteret',
					'type': 'field',
					'visible_if': 'current_screen.fields["interet"].value == false'
				},
				{
					'name': 'gamme',
					'type': 'field',
					'visible_if': 'current_screen.fields["interet"].value == true'
				},
				{
					'name': 'commentaireinteret',
					'type': 'field',
					'visible_if': 'current_screen.fields["interet"].value == true'
				},
				{
					'name': 'nouvel-evenement',
					'type': 'component'
				}
			],
			'type': 'column'
		},
		'name': 'Qualification Interlocuteur(s)',
		'next_screens': [
			{
				'condition': 'true',
				'screen_index': 0
			}
		]
	}
];
export const chr_presence_fields: Fields[] = [
	{
		'field_id': 1,
		'name': 'type-point-de-vente',
		display_name: 'Type de point de vente',
		type_: 'Multiselect',
		category: 'Qualification',
		field_data: [],
	},
	{
		'field_id': 2,
		'name': 'grossiste',
		display_name: 'Grossiste',
		type_: 'Boolean',
		category: 'Qualification',
		history: true,
	},
	{
		'field_id': 3,
		'name': 'contrat',
		display_name: 'Contrat',
		type_: 'Boolean',
		category: 'Qualification',
		history: true,
	},
	{
		'field_id': 4,
		'name': 'datefincontrat',
		display_name: 'Date de fin de contrat',
		type_: 'Date',
		category: 'Qualification',
		dependentOn: 'contrat',
		history: true,
	},
	{
		'field_id': 5,
		'name': 'concurrenceprésente',
		display_name: 'Concurrence présente',
		type_: 'Boolean',
		category: 'Qualification',
		history: true,
	},
	{
		'field_id': 6,
		'name': 'concurrentsprésents',
		display_name: 'Concurrents présents',
		type_: 'Multiselect',
		category: 'Qualification',
		history: true,
		field_data: [],
	},
	{
		'field_id': 7,
		'name': 'changementscarte',
		display_name: 'Changements de carte',
		type_: 'Boolean',
		category: 'Qualification',
		history: true,
	},
	{
		'field_id': 8,
		'name': 'carteété',
		display_name: 'Carte été',
		type_: 'Date',
		category: 'Qualification',
		history: true,
	},
	{
		'field_id': 9,
		'name': 'cartehiver',
		display_name: 'Carte hiver',
		type_: 'Date',
		category: 'Qualification',
		history: true,
	},
	{
		'field_id': 10,
		'name': 'photocarte',
		display_name: 'Photo carte',
		type_: 'Image',
		category: 'Qualification',
		history: true,
	},
	{
		'field_id': 11,
		'name': 'potentiel',
		display_name: 'Potentiel',
		type_: 'Select',
		category: 'Qualification',
		history: true,
		field_data: ['⭐️', '⭐️⭐️', '⭐️⭐️⭐️']
	},
	{
		'field_id': 12,
		'name': 'personnel-rencontre',
		display_name: 'Personnel rencontré',
		type_: 'Boolean',
		category: 'Interlocuteur(s)',
		field_data: [],
		history: true,
	},
	{
		'field_id': 13,
		'name': 'engagement',
		display_name: 'Engagement',
		type_: 'Select',
		category: 'Interlocuteur(s)',
		field_data: ['⭐️', '⭐️⭐️', '⭐️⭐️⭐️'],
		history: true,
	},
	{
		'field_id': 14,
		'name': 'interet',
		display_name: 'Intérêt',
		type_: 'Boolean',
		category: 'Interlocuteur(s)',
		history: true,
	},
	{
		'field_id': 15,
		'name': 'noninteret',
		display_name: 'Raison du non intérêt',
		type_: 'Multiselect',
		category: 'Interlocuteur(s)',
		history: true,
		field_data: ['Prix', 'Concurrents', 'Gamme', 'Autre'],
	},
	{
		'field_id': 16,
		'name': 'commentairenoninteret',
		display_name: 'Commentaire non intérêt',
		type_: 'Text',
		category: 'Interlocuteur(s)',
		history: true,
	},
	{
		'field_id': 17,
		'name': 'gamme',
		display_name: 'Gamme',
		type_: 'Multiselect',
		category: 'Interlocuteur(s)',
		field_data: [],
		history: true,
	},
	{
		'field_id': 18,
		'name': 'commentaireinteret',
		display_name: 'Commentaire intérêt',
		type_: 'Text',
		category: 'Interlocuteur(s)',
		history: true,
	},
	{
		'field_id': 19,
		'name': 'point-de-vente',
		display_name: 'Point de vente',
		type_: 'Company',
		category: 'Point de vente',
		mandatory: true,
	},
	{
		field_id: 20,
		name: 'check-in',
		display_name: 'Check-in',
		type_: 'CheckIn',
		descriptionTitle: 'Choisissez vos règles concernant les check-in',
		description: 'Choisissez si vous souhaitez rendre les check in visibles, et si vous souhaitez les rendre obligatoires afin d’accéder à la suite de votre formulaire',
		validator: 'current_screen.fields["check-in"].filled',
		field_data: 100,
	},
	{
		field_id: 21,
		name: 'type-personnel-rencontre',
		display_name: 'Type de personnel rencontré',
		type_: 'Multiselect',
		category: 'Interlocuteur(s)',
		field_data: ['Gerant', 'Barman', 'Cuisinier'],
	},
	{
		field_id: 22,
		name: 'produit-present',
		display_name: 'Produit présent',
		inDraggable: true,
		category: 'Présence produit',
		type_: 'Boolean',
		mandatory: true,
	},
	{
		field_id: 23,
		name: 'prix-constate',
		display_name: 'Prix constaté',
		type_: 'Number',
		category: 'Présence produit',
	}
];


export const chr_presence_form_options: Options[] = [
	
];
