import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';

export type PhotoData = {
    url: string[],
    url_thumb: string[],
    name: string,
}

export type InstancePhotos = {
    form_instance_id: string,
    photos: PhotoData[],
    cc_id: string,
    cc_name: string,
    company_uuid: string,
    owner: number,
    made_at: string
    nb_photos: number,
}

export function getPhotosFromFormCount(form_id: number, body?: any): Promise<number> {
	return axios.post(`${URL_FOUNDATION}/api/v2/gallery-v2/photo-from-form-count/${form_id}`, body).then(res => res.data);
}


export function getPhotosFromForm(form_id: number, body?: any): Promise<InstancePhotos[]> {
	return axios.post(`${URL_FOUNDATION}/api/v2/gallery-v2/photo-from-form/${form_id}`, body).then(res => res.data);
}

export function getPhotoFromHash(body: { hash: string, thumb?: boolean, original?: boolean }): Promise<{ original: string, thumb: string }> {
	return axios.get(`${URL_FOUNDATION}/api/gallery/image-from-hash?hash=${body.hash}&thumb=${body.thumb}&original=${body.original}`).then(res => res.data);
}
