import * as React from 'react';
import { FlexDiv } from '../products/style';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

type SwitchValue<T = unknown> = {
	value: T,
	src: string,
	to?: string,
	onClick?: (value: T) => void
}

const SwapImage = styled.img<{ selected: boolean }>`
	height: 25px;
	${p => p.selected ? '' : `
		opacity: 33%;
		cursor: pointer;
	`}
`;

export default function PageSwitch<T = unknown>(props: { selectedValue?: T, list: SwitchValue<T>[] }) {
	const { list, selectedValue } = props;
	
	return <FlexDiv gap='10px'>
		{list.map((v, i) => {
			const inner = <SwapImage src={v.src} key={`swapImage[${i}]`} onClick={() => v.onClick?.(v.value)} selected={(selectedValue !== undefined && selectedValue === v.value) || (!v.onClick && !v.to)}/>;
			if (v.to) return <NavLink to={v.to}>{inner}</NavLink>;
			return inner;
		})}
	</FlexDiv>;
}