import { Chart } from 'bindings/reporting/Chart';
import { Curve } from 'bindings/reporting/Curve';
import { Link } from 'bindings/reporting/Link';
import { Point } from 'bindings/reporting/Point';
import { SecondaryAxis } from 'bindings/reporting/SecondaryAxis';
import { GenericReport } from 'bindings/reports/generic/GenericReport';
import optionGrey from 'images/icon/options_grey.png';
import arrow from 'images/icons/orders/arrow_left.svg';
import reports_black from 'images/menu_icon/reports_black.svg';
import VisibilityOffIcon from 'images/reports/visibility_off.svg';
import VisibilityOnIcon from 'images/reports/visibility_on.svg';
import VisibilityOnEmptyIcon from 'images/reports/visibility_on_empty.svg';
import setting from 'images/setting_icons/system_setting_icon.svg';
import listView_black from 'images/ui_icon/listView_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { Translate, getTranslate } from 'react-localize-redux';
import { PieChart } from 'react-minimal-pie-chart';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AAdditionalColumns, ReportColumnType } from '../../../atoms/additionalColumns';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { AUsers } from '../../../atoms/global/users';
import { AProducts } from '../../../atoms/product';
import { PanelSelector } from '../../../components_v2/Selector/PanelSelector';
import { Image } from '../../../components_v2/avatar/style/Style';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { OptionBlock } from '../../../components_v2/dropdown/style/Style';
import AdvancedFilters from '../../../components_v2/filter/AdvancedFilters';
import advancedFiltersInterpretor from '../../../components_v2/filter/AdvancedFiltersInterpretor';
import { FilterResult, FilterTree, filterTreeLength } from '../../../components_v2/filter/model/Model';
import { FilterParameter, FilterParameterParent } from '../../../components_v2/filter/pages/FilterList';
import { tooltipPos } from '../../../components_v2/models';
import { InfiniteTable, InfiniteTableStyle } from '../../../components_v2/table/InfiniteTable';
import { Column } from '../../../components_v2/table/Table';
import ToolbarFilterButton from '../../../components_v2/toolbarFilter/ToolbarButton';
import { ColorAndT, createRandomColor, isSuperAdmin } from '../../../components_v2/utils';
import storeLang from '../../../helpers/storeLang';
import { Dot } from '../../../styles/global/css/Dot';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, DarkGreySidely2, FilterBlue, FilterGreen, FilterRed, GreenSidely, GreySidely, RedSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { useFunctionState } from '../../../utils/customHooks';
import { AlertRes } from '../../alert/AlertProvider';
import useAlert from '../../alert/UseAlert';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { FlexDiv } from '../../products/style';
import { LocalFilters, ReportFiltersContext } from '../generic/generic';
import { getColorForPercentage, getStringWidth, stringToColour } from '../utils';
import ChartReportingV2 from './ChartInterpretor';
import { AxisRepresentation, Cell, ComplexCell, EvolutionTuple, Header, Panel, Reference, Report, Date as ReportingDate, Row, TooltipTemplate, Value } from './bareReportingDecoder';
import ReportInterpretorContext, { EvolutionState, ReportInterpretorProvider, ReportInterpretorProviderLinksProps } from './hoverContext';
import Input from '../../../components_v2/input/Input';

const MAX_SELECTED = 10;

const EvolutionOperatorContainer = styled.div`
	${DefaultText};
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${DarkGreySidely2};
	height: 100%;
	gap: 5px;
	margin: 0 5px;
	&:after {
		// content: '';
		margin-left: -1px;
		display: inline-block;
		height: 0;
		width: 0;
		border-right: 4px solid transparent;
		border-top: 4px solid ${DarkGreySidely2};
		border-left: 4px solid transparent;
	}
`;

const PercentageDiv = styled(FlexDiv) <{ color: string, width?: string, minHeight?: string }>`
	background-color: ${p => p.color};
	border-radius: 25px;
	color: ${SidelyBlack};
	text-align: center;
	width: ${({ width }) => width ?? '80px'};
	justify-content: center;
	min-height: ${({ minHeight }) => minHeight ?? '21px'};
`;

const FlatEvolutionDiv = styled.p<{ value: number }>`
	color: ${({ value }) => value === 0 ? GreySidely : value > 0 ? GreenSidely : RedSidely};
	white-space: nowrap;
	margin: 0;
	font-size: 13px;
	&:before {
		${({ value }) => value === 0 ? 'content: \'=\';' : value > 0 ? 'content: \'+\'' : ''}

	}
`;

const EvolutionDiv = styled.p<{ value: number, kind?: 'small', background?: boolean, fontSize?: string }>`
	white-space: nowrap;
	color: ${({ value }) => value === 0 ? GreySidely : value > 0 ? GreenSidely : RedSidely};
	margin: 0;
	${({ kind, fontSize }) => {
		if (fontSize) return `font-size: ${fontSize};`;
		switch (kind) {
			case 'small': return 'font-size: 10px;';
			default: return 'font-size: 13px;';
		}
	}}
	${({ background, value }) => background ? `
		padding: 0.5em 1em;
		background-color: ${value === 0 ? GreySidely : value > 0 ? GreenSidely : RedSidely}30;
		border-radius: 5px;
	` : ''}
	&:before {
		${({ value }) => value === 0 ? 'content: \'=\';' : `
			content: '';
			background: url('${arrow}');
		`}
	${({ kind }) => {
		switch (kind) {
			case 'small': return `
					width: 10px;
					height: 7px;
					font-size: 10px;
				`;
			default: return `
					width: 13px;
					height: 8px;
					font-size: 13px;
				`;
		}
	}}
		margin-right: -1px;
		display: inline-block;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	${({ value }) => {
		if (value === 0) return '';
		return `
				rotate: ${value > 0 ? '' : '-'}90deg;
				filter: ${value > 0 ? FilterGreen : FilterRed};
			`;
	}}
	}
`;

// --------------[VALUE]------------- //
function capitalizeFirstLetter(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function reportingDateToString(value: ReportingDate): string {
	moment.locale();
	const translate = getTranslate(storeLang.getState().localize);
	if ('day' in value.val) {
		return moment(value.val.day).format('L');
	}
	if ('week' in value.val) {
		const eow = moment(value.val.week).endOf('week');
		return `${translate('symbol_week')}${moment(value.val.week).format('w: Do')} - ${eow.format('Do MMM YY')}`;
	}
	if ('month' in value.val) {
		return capitalizeFirstLetter(moment(value.val.month).format('MMMM YYYY'));
	}
	if ('quarter' in value.val) {
		return `${translate('symbol_quarter')}${moment(value.val.quarter).format('Q YYYY')}`;
	}
	if ('year' in value.val) {
		return moment(value.val.year).format('YYYY');
	}
	return '';
}

function styleValue(value: Value): { justify?: 'center' | 'left', className?: string } {
	if (typeof value === 'string' || !value || !value.val) {
		return { justify: 'center' };
	}
	if ('percentage' in value.val) {
		return { justify: 'center' };
	}
	if ('text' in value.val || 'key' in value.val || 'formattedKey' in value.val) {
		return {
			justify: 'center',
			className: 'report-text'
		};
	}
	if ('float' in value.val) {
		return { justify: 'center' };
	}
	if ('int' in value.val) {
		return { justify: 'center' };
	}
	if ('colorDot' in value.val) {
		return { justify: 'center' };
	}
	if ('pieChart' in value.val) {
		return { justify: 'center' };
	}
	if ('date' in value.val) {
		return { justify: 'center' };
	}
	if ('list' in value.val) {
		return { justify: 'center', className: 'tamer' };
	}
	if ('evolution' in value.val) {
		return { justify: 'center' };
	}
	if ('user' in value.val) {
		return {
			justify: 'center',
			className: 'report-text'
		};
	}
	return {};
}

export function Percentage(props: { percentage: number, width?: string, fontSize?: string, minHeight?: string }) {
	const { percentage: value } = props;
	return <PercentageDiv width={props.width} fontSize={props.fontSize} color={getColorForPercentage(value, undefined, undefined, true)} minHeight={props.minHeight}>{(value * 100).toFixed(2)}%</PercentageDiv>;
}

function EvolutionValueDisplayer(props: {value: Extract<ReportColumnType, {'evolution'}>, columnIndex?: number}) {
	const { evolutionState } = React.useContext(ReportInterpretorContext);
	const state = evolutionState[props.columnIndex ?? -1] ?? '%';
	// return <div><ValueToNode value={props.value.evolution.old}/> {'->'} <ValueToNode value={props.value.evolution.new}/> </div>
	const res = calcEvolution(props.value.evolution);
	if (!res) return <>-</>;
	if (state === '+') return <FlatEvolutionDiv value={res?.flat}>{res?.flat.toFixed(2)}</FlatEvolutionDiv>;
	if (isNaN(res.percentage)) {
		if (res.flat === 0) return <>-</>;
		return <Evolution percentage={Infinity * res.flat} />;
	}
	return <Evolution percentage={res.percentage}/>;

}

export function Evolution(props: { percentage: number, kind?: 'small', background?: boolean, fixed?: number, fontSize?: string }) {
	const { percentage, fixed } = props;
	const tamer = isNaN(percentage) ? 0 : percentage;
	return <EvolutionDiv value={tamer} kind={props.kind} background={props.background} fontSize={props.fontSize}>{isFinite(tamer) ? `${(tamer * 100).toFixed(fixed ?? 2)}%` : '%'}</EvolutionDiv>;
}

export function calcEvolution(evolution: EvolutionTuple): { percentage: number, flat: number } | undefined {
	if (evolution.old.tag !== evolution.new.tag) return undefined;
	if (evolution.old.val && 'percentage' in evolution.old.val && evolution.new.val && 'percentage' in evolution.new.val) {
		return { percentage: evolution.new.val.percentage - evolution.old.val.percentage, flat: evolution.new.val.percentage - evolution.old.val.percentage };
	}
	const oldNumber = valueToNumber(evolution.old) ?? 0;
	const newNumber = valueToNumber(evolution.new) ?? 0;
	return { percentage: (newNumber - oldNumber) / oldNumber, flat: newNumber - oldNumber };
}

export function ValueInnerToNode(props: {value: ReportColumnType, emptyTile?: boolean, columnIndex?: number}): JSX.Element {
	const { value } = props;
	const owners = useRecoilValue(AUsers);
	const products = useRecoilValue(AProducts);
	if (typeof value === 'string' || !value) {
		return props.emptyTile ? <></> : <>-</>;
	}
	if ('percentage' in value) {
		return <Percentage percentage={value.percentage} />;
	}
	if ('text' in value) {
		return <>{value.text}</>;
	}
	if ('key' in value) {
		return <Translate id={value.key} />;
	}
	if ('formattedKey' in value) {
		const translate = getTranslate(storeLang.getState().localize);
		let res = translate(value.formattedKey.key).toString();
		value.formattedKey.formats.forEach((replacementKey, i) => res = res?.replaceAll(`{{${i + 1}}}`, replacementKey));
		return <>{res}</>;
	}
	if ('float' in value) {
		return <>{value.float.toFixed(2)}</>;
	}
	if ('int' in value) {
		return <>{value.int.toString()}</>;
	}
	if ('colorDot' in value) {
		return <Dot color={value.colorDot} size='12px' />;
	}
	if ('pieChart' in value) {
		return <PieChart
			className='circle'
			lineWidth={70}
			center={[50, 50]}
			viewBoxSize={[100, 100]}
			data={value.pieChart.map(data => ({ value: data.perc, color: data.color }))}
		/>;
	}
	if ('date' in value) {
		return <>{reportingDateToString(value.date)}</>;
	}
	if ('datetime' in value) {
		return <>{moment.unix(value.datetime).format('L - LT')}</>;
	}
	if ('user' in value) {
		const id = value.user;
		const owner = owners.find(o => o.id === id);
		if (owner) return <>{owner.name}</>;
		return <></>;
	}
	if ('evolution' in value) {
		return <EvolutionValueDisplayer value={value} columnIndex={props.columnIndex}/>;
	}
	if ('product' in value) {
		const id = value.product;
		const product = products.find(p => p.uuid === id);
		if (product) return <>{product.name}</>;
	}
	if ('list' in value) {
		return <FlexDiv overflow='auto'>{value.list.map(e => <MultiSelectElement key={e} value={e}/>)}</FlexDiv>;
	}
	return <></>;

}

export function ValueToNode(props: { value: Omit<Value, 'tag'>, emptyTile?: boolean, columnIndex?: number }): JSX.Element {
	const { value } = props;
	if (typeof value === 'string' || !value || !value.val) {
		return props.emptyTile ? <></> : <>-</>;
	}
	return <ValueInnerToNode value={value.val} emptyTile={props.emptyTile} columnIndex={props.columnIndex}/>;
}


// White pill background for text element white black outline
const MultiSelectDiv = styled.div`
	background-color: white;
	border-radius: 25px;
	border: 0.8px solid ${BorderColor};
	color: ${SidelyBlack};
	text-align: center;
	padding: 0.1em 0.5em;
	margin: 0.1em;
`;

function MultiSelectElement(props: { value: string }) {
	return <MultiSelectDiv>
		{props.value}
	</MultiSelectDiv>;
}

type ReactTableRow = {
	cells: readonly ComplexCell[],
	subRows: ReactTableRow[],
	primaryCell: readonly Cell[],
	id: number,

}

function filterEmptyRow(row: ReactTableRow, tree: FilterTree | undefined): ReactTableRow | undefined {
	if (!tree) return row;
	const newRow = { ...row };
	newRow.subRows = row.subRows.reduce((acc: ReactTableRow[], r) => {
		const res = filterEmptyRow(r, tree);
		if (res) acc.push(res);
		return acc;
	}, []);
	if (newRow.subRows.length > 0) return newRow;
	const filterApproved = advancedFiltersInterpretor(newRow.cells, tree, {
		empty(value, columnId) {
			if (typeof columnId == 'string') {
				const indexedValue = value[Number(columnId)];
				return indexedValue.value.tag === EmptyCell.tag || indexedValue.value.val === undefined;
			}
			return true;
		},
		not_empty(value, columnId) {
			if (typeof columnId == 'string') {
				const indexedValue = value[Number(columnId)];
				return indexedValue.value.tag !== EmptyCell.tag && indexedValue.value.val !== undefined;
			}
			return false;
		},
	});
	return filterApproved ? newRow : undefined;
}

function toReactTableData(row: Row, selectedPanel: number): ReactTableRow {
	return {
		id: row.id,
		cells: row.cells[selectedPanel],
		primaryCell: row.primaryCell,
		subRows: row.rows.map(row => toReactTableData(row, selectedPanel)),
	};
}

export function valueInnerToString(value: ReportColumnType): string | undefined {
	if (typeof value === 'string' || !value) {
		return '';
	} else if ('text' in value) {
		return value.text;
	} else if ('key' in value) {
		return translateToString(value.key);
	} else if ('formattedKey' in value) {
		let res = translateToString(value.formattedKey.key);
		value.formattedKey.formats.forEach((replacementKey, i) => res = res?.replaceAll(`{{${i + 1}}}`, replacementKey));
		return res;
	} else if ('int' in value) {
		return value.int.toString();
	} else if ('float' in value) {
		return value.float.toFixed(2);
	} else if ('date' in value) {
		return reportingDateToString(value.date);
	} else if ('evolution' in value) {
		return value.evolution.toString();
	}

}

export function valueToString(value: Value): string | undefined {
	if (typeof value === 'string' || !value || !value.val) {
		return '';
	}
	return valueInnerToString(value.val);
}

// --------------[CELLS]------------- //

export const EmptyCell: Value = { tag: 14, val: null };

const LinkDiv = styled.div`
	cursor: pointer;
	width: 100%;
`;

const ToolTipTd = styled.td<{ noBorder?: boolean }>`
	vertical-align: middle;
    text-align: center;
	${p => p.noBorder ? 'border: none !important;' : ''}
`;

export function onLinkClick(link: Link | null, links: ReportInterpretorProviderLinksProps): (() => void) | undefined {
	if (!link) return undefined;
	if ('clientCompany' in link) {
		const value = link.clientCompany;
		return () => links.onCompanyClick(value);
	} else if ('contact' in link) {
		const value = link.contact;
		return () => window.open(`contacts/detail/${value}`);
	} else if ('order' in link) {
		const value = link.order;
		return () => window.open(`orders?id=${value}`);
	} else if ('product' in link) {
		const value = link.product;
		return () => window.open(`products-v2?id=${value}`);
	} else if ('assortment' in link) {
		const value = link.assortment;
		return () => window.open(`assortments-v2?id=${value}`);
	} else if ('shelfAudit' in link) {
		const value = link.shelfAudit;
		return () => window.open(`enform/detaildata/${value}`);
	} else if ('user' in link) {
		return () => console.log('TODO');
	} else if ('formInstance' in link) {
		return () => links.onFormInstanceClick(link.formInstance);
	} else {
		return () => console.log('unimplemented link :', link);
	}
}

function ReportLink(props: { link: Link | null, value: React.ReactNode }): JSX.Element {
	const { link, value } = props;
	const { onCompanyClick, onFormInstanceClick } = React.useContext(ReportInterpretorContext);

	return <LinkDiv onClick={onLinkClick(link, { onCompanyClick, onFormInstanceClick })}>{value}</LinkDiv>;
}

function referenceToNode(reference: Reference, primaryCell: Cell | undefined, cell: ComplexCell | Cell): React.ReactNode {
	if (reference.val && 'values' in cell && 'complexValue' in reference.val) {
		// ComplexValue
		return <ValueToNode value={cell.values[reference.val.complexValue]} />;
	} else if (reference.tag === 0 && primaryCell) {
		// PrimaryCell
		return <ValueToNode value={primaryCell.value} />;
	} else if (reference.tag === 1) {
		// PrimaryValue
		return <ValueToNode value={cell.value} />;
	} else if (reference.val && 'primaryValueTupple' in reference.val && cell.value.val && 'evolution' in cell.value.val) {
		switch (reference.val.primaryValueTupple) {
			case 0: return <ValueToNode value={cell.value.val.evolution.old} />;
			case 1: return <ValueToNode value={cell.value.val.evolution.new} />;
		}
	}
	return undefined;
}

function CellToNode(props: {cell: ComplexCell | Cell | undefined, isEmptyTile?: boolean, columnIndex?: number, color?: string}): JSX.Element {
	const { isEmptyTile, columnIndex } = props;
	const cell = props.cell ?? { value: EmptyCell, link: null, tooltip: null };
	const { justify, className } = styleValue(cell.value);
	const value: React.ReactNode = <div className={className}>{<ValueToNode value={cell.value} emptyTile={isEmptyTile} columnIndex={columnIndex}/>}</div>;

	if (!cell.link) return <FlexDiv height='100%' justify={justify} align='center' width='100%' color={props.color}>{value}</FlexDiv>;

	return <FlexDiv height='100%' justify={justify} align='center' width='100%'>
		<ReportLink link={cell.link.val} value={value}/>
	</FlexDiv>;
}

// --------------[TABLE]------------- //

const sort = (primaryLength: number, index: number, evolutionStateRef: React.MutableRefObject<EvolutionState>) => (rowA: { original: ReactTableRow }, rowB: { original: ReactTableRow }, _columnId, desc: boolean) => {
	const translate = getTranslate(storeLang.getState().localize);
	let ra: Value;
	let rb: Value;
	if (index >= primaryLength) {
		ra = rowA.original.cells[index - primaryLength]?.value ?? EmptyCell;
		rb = rowB.original.cells[index - primaryLength]?.value ?? EmptyCell;
	} else {
		ra = rowA.original.primaryCell[index]?.value ?? EmptyCell;
		rb = rowB.original.primaryCell[index]?.value ?? EmptyCell;
	}

	if ((typeof ra === 'string' && typeof rb === 'string') || ((!ra || !ra.val) && (!rb || !rb.val))) return 0;
	if (typeof ra === 'string' || !ra || !ra.val) return desc ? -1 : 1;
	if (typeof rb === 'string' || !rb || !rb.val) return desc ? 1 : -1;

	let fra: number | undefined = undefined;
	let frb: number | undefined = undefined;
	if ('percentage' in ra.val) {
		fra = ra.val.percentage * 100;
	} else if ('float' in ra.val) {
		fra = ra.val.float;
	} else if ('int' in ra.val) {
		fra = Number(ra.val.int);
	} else if ('evolution' in ra.val) {
		if (evolutionStateRef.current[index] === '+') fra = calcEvolution(ra.val.evolution)?.flat;
		else {
			const evo = calcEvolution(ra.val.evolution);
			if (evo === undefined) fra = undefined;
			else {
				if (isNaN(evo.percentage)) {
					if (evo.flat === 0) fra = undefined;
					else fra = Infinity * evo.flat;
				} else fra = evo.percentage;
			}
		}
	}

	if ('percentage' in rb.val) {
		frb = rb.val.percentage * 100;
	} else if ('float' in rb.val) {
		frb = rb.val.float;
	} else if ('int' in rb.val) {
		frb = Number(rb.val.int);
	} else if ('evolution' in rb.val) {
		if (evolutionStateRef.current[index] === '+') frb = calcEvolution(rb.val.evolution)?.flat;
		else {
			const evo = calcEvolution(rb.val.evolution);
			if (evo === undefined) frb = undefined;
			else {
				if (isNaN(evo.percentage)) {
					if (evo.flat === 0) frb = undefined;
					else frb = Infinity * evo.flat;
				} else frb = evo.percentage;
			}
		}
	}
	if (fra && (!isFinite(fra) || isNaN(fra))) return desc ? 1 : -1;
	if (frb && (!isFinite(frb) || isNaN(frb))) return desc ? -1 : 1;

	if (fra !== undefined && frb !== undefined) return fra - frb;
	if (fra === undefined && frb !== undefined) return desc ? -1 : 1;
	if (frb === undefined && fra !== undefined) return desc ? 1 : -1;

	if ('colorDot' in ra.val && 'colorDot' in rb.val) return ra.val.colorDot.localeCompare(rb.val.colorDot);
	let tra: string | undefined = undefined;
	let trb: string | undefined = undefined;
	if ('text' in ra.val) {
		tra = ra.val.text;
	} else if ('key' in ra.val) {
		tra = translate(ra.val.key).toString();
	} else if ('formattedKey' in ra.val) {
		trb = translate(ra.val.formattedKey.key).toString();
		ra.val.formattedKey.formats.forEach((replacementKey, i) => trb = trb?.replaceAll(`{{${i + 1}}}`, replacementKey));
	}
	if ('text' in rb.val) {
		trb = rb.val.text;
	} else if ('key' in rb.val) {
		trb = translate(rb.val.key).toString();
	} else if ('formattedKey' in rb.val) {
		trb = translate(rb.val.formattedKey.key).toString();
		rb.val.formattedKey.formats.forEach((replacementKey, i) => trb = trb?.replaceAll(`{{${i + 1}}}`, replacementKey));
	}
	if (tra !== undefined && trb !== undefined) return tra.localeCompare(trb);
	return JSON.stringify(ra).localeCompare(JSON.stringify(rb));
};

const defaultFilters: FilterResult = {
	values: {
		array: [],
		combinator: 'and'
	},
	formatted: undefined
};

function RenderTableCell(props: {
	data: ReactTableRow | ComplexCell,
	header: Header,
	toolTipTemplate: TooltipTemplate | null,
	index: number,
	primaryLength: number,
	emptyTile?: boolean
}) {
	let element: React.ReactNode = <></>;
	if (!('primaryCell' in props.data)) {
		element = <CellToNode cell={props.data} isEmptyTile={props.emptyTile} columnIndex={props.index} />;
	} else if (props.index < props.primaryLength) {
		element = <CellToNode cell={props.data.primaryCell[props.index]} isEmptyTile={props.emptyTile} columnIndex={props.index} />;
	} else {
		element = props.data.cells?.[props.index - props.primaryLength] ? <CellToNode cell={props.data.cells[props.index - props.primaryLength]} isEmptyTile={props.emptyTile} columnIndex={props.index} /> : undefined;
	}
	return <div style={{ height: '100%', cursor: 'pointer' }}
	>
		{element}
	</div>;
}

export type ToolTipHover = {
	cell: Cell | ComplexCell,
	bounds: DOMRect,
	toolTipTemplate: TooltipTemplate | null,
	primaryCell?: Cell,
	pos: tooltipPos
};

export type ReportInterpretorProps = {
	setToolBarState: (a) => void,
	report: Report,
	height: number,
	filterParams?: FilterParameter[] | FilterParameterParent[],
	visualizationFitlerParams?: FilterParameter[] | FilterParameterParent[],
	filterResult?: FilterResult,
	onFiltersChange?: (filters: FilterResult) => void,
	reprasentation?: GenericReport,
	onStoreColumn?: (columnIndex: number, panelIndex: number, columnName: string) => void,
	onUnstoreColumn?: (columnId: number) => void,
	onCreateCalcField?: (columnIndex: number, panelIndex: number, columnName: string) => void,
	externalActiveFilter?: number,
	context?: boolean
};

export default function ReportInterpretor(props: ReportInterpretorProps & ReportInterpretorProviderLinksProps) {
	return <ReportInterpretorProvider {...props}>
		<ReportInterpretorNoProvider {...props} />
	</ReportInterpretorProvider>;
}

const FuckingSvg = (props: React.HTMLAttributes<HTMLDivElement> & {size: number, fill: string, isHidden}) => <div {...props}>
	<svg width={props.size} height={props.size} viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg" visibility={props.isHidden ? 'hidden' : 'visible'}>
		<path d="M31.1324 5.61967L40.3803 14.8695C40.9708 15.4601 41.4124 16.1825 41.6689 16.9773C41.9254 17.7721 41.9894 18.6164 41.8555 19.4407C41.7217 20.2651 41.3939 21.0458 40.8992 21.7186C40.4044 22.3914 39.7569 22.937 39.0099 23.3105L29.6757 27.9776C29.3392 28.1455 29.0818 28.4386 28.9589 28.7941L26.1989 36.7789C26.0663 37.1627 25.8383 37.5066 25.5363 37.7781C25.2343 38.0496 24.8683 38.2399 24.4726 38.3311C24.0769 38.4223 23.6645 38.4114 23.2741 38.2995C22.8838 38.1876 22.5283 37.9783 22.241 37.6913L16.2917 31.7419L7.78167 40.25H5.75V38.2145L14.26 29.7083L8.30875 23.759C8.02126 23.4718 7.81156 23.1161 7.69937 22.7255C7.58718 22.3349 7.57619 21.9222 7.66743 21.5262C7.75867 21.1302 7.94914 20.7639 8.22093 20.4618C8.49272 20.1597 8.8369 19.9316 9.22108 19.7992L17.2059 17.0411C17.5614 16.9182 17.8545 16.6608 18.0224 16.3243L22.6895 6.99008C23.0629 6.24277 23.6086 5.59491 24.2815 5.09992C24.9545 4.60494 25.7355 4.277 26.5601 4.14316C27.3847 4.00931 28.2293 4.07338 29.0243 4.3301C29.8193 4.58682 30.5418 5.02882 31.1324 5.61967ZM38.3487 16.9012L29.0988 7.65325C28.8305 7.38472 28.5022 7.1838 28.141 7.06703C27.7798 6.95026 27.396 6.92099 27.0213 6.98164C26.6466 7.04229 26.2916 7.19111 25.9857 7.41584C25.6797 7.64058 25.4316 7.9348 25.2617 8.27425L20.5946 17.6103C20.0901 18.6186 19.2108 19.3891 18.1451 19.757L10.8828 22.2678L23.7341 35.1172L26.2411 27.8568C26.6089 26.7911 27.3795 25.9118 28.3877 25.4073L37.7257 20.7383C38.0653 20.5686 38.3597 20.3206 38.5847 20.0147C38.8096 19.7089 38.9586 19.354 39.0195 18.9792C39.0803 18.6045 39.0512 18.2206 38.9346 17.8593C38.8179 17.498 38.6171 17.1696 38.3487 16.9012Z" fill={props.fill} />
	</svg>
</div>;

export function ReportInterpretorNoProvider(props: ReportInterpretorProps) {
	const [selectedPanel, setSelectedPanel] = React.useState(0);
	const [selectedHeader, setSelectedHeader] = React.useState<number>();
	const [selectedView, setSelectedView] = React.useState<'table' | 'chart'>('table');
	const [selectedCheckbox, setSelectedCheckbox] = React.useState<boolean[]>([]);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const { evolutionStateRef, onCompanyClick, onFormInstanceClick } = React.useContext(ReportInterpretorContext);
	const users = useRecoilValue(AUsers);
	const [hidden, setHidden] = React.useState<boolean>(false);
	const [, setTagsFilter] = useRecoilState(ATagFilter);
	const additionalColumns = useRecoilValue(AAdditionalColumns);

	// Filters
	const [isFilterOpen, setFilterOpen] = React.useState<boolean>(false);
	const [isVizualisationFilterOpen, setVizualisationFilterOpen] = React.useState<boolean>(false);
	const [filterResult, setFilterResult] = useFunctionState<FilterResult>(props.filterResult ?? defaultFilters, ({ newValue }) => {
		props.onFiltersChange?.(newValue);
		return newValue;
	});
	const [vizualisationFilterResult, setVizualisationFilterResult] = React.useState<FilterResult>(defaultFilters);
	const [isOpenSummary, setOpenSummary] = React.useState<boolean>(false);

	const { filters, setFilters } = React.useContext(ReportFiltersContext);

	const [nbFilters, setNbFilters] = React.useState<number>(filtersLenght(filters));

	React.useEffect(() => {
		setNbFilters(filtersLenght(filters));
	}, [filters]);

	function filtersLenght(filters: LocalFilters): number {
		let i = 0;
		if (filters) {
			if (filters.form_filter && (filters.form_filter as number[]).length > 0)
				i = i + 1;
			if (filters.created_by_filter && (filters.created_by_filter as number[]).length != users.length)
				i = i + 1;
			if (filters.my_filters) {
				if ((filters.my_filters).products && !(filters.my_filters).products?.all)
					i = i + 1;
				if ((filters.my_filters).brands && !(filters.my_filters).brands?.all)
					i = i + 1;
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				if ((filters.my_filters).tags && (filters.my_filters).tags!.length > 0)
					i = i + 1;
			}
		}
		return i;
	}


	const { report, height } = props;
	const alert = useAlert();
	const colors = React.useMemo(() => {
		const table: number[] = [];
		const flattenRowIds = (row: Row) => {
			table.push(row.id);
			row.rows.forEach(flattenRowIds);
		};
		report.rows.forEach(flattenRowIds);
		return {
			rowColors: createRandomColor(table),
			panelColors: createRandomColor(report.panels)
		};
	}, [report]);

	React.useEffect(() => {
		if (props.filterResult) {
			setFilterResult(props.filterResult);
		}
	}, [props.filterResult]);

	function Visibility(propos: { defaultValue: boolean, onChange: (b: boolean) => void }) {
		const [hidden, setHidden] = React.useState<boolean>(propos.defaultValue);
		let icon = VisibilityOnEmptyIcon;
		if (selectedCheckbox.some(b => b === true)) {
			if (hidden) {
				icon = VisibilityOffIcon;
			}
			else {
				icon = VisibilityOnIcon;
			}
		}
		else {
			if (hidden) {
				setHidden(false);
				alert({
					title: 'Attention',
					content: 'Veuillez sélectionner au moins une entreprise',
					width: '475px',
					noButtons: true,
					svg: 'warning'
				});
				propos.onChange(false);
				icon = VisibilityOffIcon;
			}
		}
		return (<><Image onClick={() => {
			if (selectedCheckbox.some(b => b === true)) {
				setHidden(!hidden); propos.onChange(!hidden);
			}
			else {
				alert({
					title: 'Attention',
					content: 'Veuillez sélectionner au moins une entreprise',
					width: '475px',
					noButtons: true,
					svg: 'warning'
				});
			}
		}} url={icon} width='20px' /></>);
	}

	const isAdmin = isSuperAdmin();
	
	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <>
				{props.filterParams !== undefined && props.filterParams.length > 0 &&
					<ToolbarFilterButton
						activeFilters={
							props.context ? (nbFilters + filterTreeLength(filterResult.formatted) + (props.externalActiveFilter ?? 0)) : filterTreeLength(filterResult.formatted) + (props.externalActiveFilter ?? 0)
						}
						onClick={() => {
							setOpenSummary(true);
							setFilterOpen(true);
						}}
						onDeleteFilter={() => {
							if (props.context) {
								setFilters({ ...filters, form_filter: undefined, created_by_filter: undefined, filter: undefined, my_filters: { ...(filters.my_filters), tags: undefined, products: undefined, brands: undefined } });
							}
							setTagsFilter([]);
							setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
						}}
					/>}
			</>,
			bottomRightToolbarComponent: <>
				<FlexDiv gap='10px'>
					{props.visualizationFitlerParams !== undefined && props.visualizationFitlerParams?.length > 0 &&
						<ToolbarFilterButton
							title={translateToString('report_parameters')}
							activeTitle={translateToString('report_parameters') + ' ({{NUMBER}})'}
							iconSize='20px'
							icon={setting}
							onClick={() => {
								setOpenSummary(true);
								setVizualisationFilterOpen(true);
							}}
							onDeleteFilter={() => {
								setVizualisationFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
							}}
						/>
					}
					<SwitchView src={listView_black} active={selectedView === 'table'} onClick={_ => setSelectedView('table')} />
					<SwitchView src={reports_black} active={selectedView === 'chart'} onClick={_ => {
						if (selectedCheckbox.some(b => b)) {
							setSelectedView('chart');
						} else {
							alert({
								title: 'Attention',
								content: <Translate id='select_at_least_one_company' />,
								width: '475px',
								noButtons: true,
								svg: 'warning'
							});
						}
					}} />
				</FlexDiv>
			</>
		});
	}, [selectedView, selectedCheckbox, filterResult, props.externalActiveFilter, props.visualizationFitlerParams, vizualisationFilterResult, nbFilters]);
	const columns: Column<ReactTableRow>[] = React.useMemo(() => {
		let reprasentationIndex = 0;
		const primaryLength = report.headers.filter(h => h.primary).length;
		return report.headers.reduce((acc: Column<ReactTableRow>[], header, i): Column<ReactTableRow>[] => {
			const headerName = valueToString(header.cell.value);
			let calcFieldName = headerName ?? '';
			const toolTipTemplate = header.tooltip[selectedPanel];
			let options;
			const isEmptyTitle = header.cell.value.val === null;
			if (isEmptyTitle) {
				options = {
					maxWidth: 60,
					minWidth: 60,
					width: 60,
					oldResize: true
				};
			} else {
				options = {
					maxWidth: 500,
					minWidth: 190,
					width: headerName ? getStringWidth(headerName) + 30 : 250,
					noResizer: report.headers[i + 1]?.cell.value.val === null
				};
			}

			const tamer = reprasentationIndex; // Js bad, closures capture by ref so we need a copy
			const reprasentationColumn = props.reprasentation?.panels[selectedPanel]?.columns[reprasentationIndex];
			const isStorable = header.storeable && reprasentationColumn;
			const storedColumnId = reprasentationColumn ? Object.values(reprasentationColumn)[0]?.['additional_column'] : undefined;
			const isStored = isStorable && storedColumnId;
			const isHidden = vizualisationFilterResult.formatted && 'and' in vizualisationFilterResult.formatted && vizualisationFilterResult.formatted.and.some(f => 'val' in f && f.val.column === (i - primaryLength).toString() && f.val.operator === 'not_empty');
			const columnOptionsData: DropdownData[] = [
				{ value: !isHidden ? 'hide_lines' : 'show_lines', label: !isHidden ? translateToString('reports.column_options.hide') : translateToString('reports.column_options.unhide') },
			];
			if (isStorable) {
				columnOptionsData.push({ value: !isStored ? 'pin' : 'unpin', label: !isStored ? translateToString('reports.column_options.pin') : translateToString('reports.column_options.unpin') });
			}
			if (header.storeable && props.reprasentation?.group_by === 'Company' && isAdmin) {
				columnOptionsData.push({ value: 'turn_to_calc_field', label: translateToString('reports.column_options.turn_to_calc_field') });
			}
			const col: Column<ReactTableRow> = {
				id: `column[${i}]`,
				noHeaderEllipsis: true,
				Header: isEmptyTitle ? <EvolutionDropdown headerIndex={i} /> :
					<FlexDiv width='95%'>
						<DefaultImage width='16px' filter={FilterBlue} src={VisibilityOffIcon} isHidden={!isHidden} />
						<CellToNode color={isHidden ? BlueSidely : ''} cell={header.cell} />
						<FuckingSvg size={16} fill={BlueSidely} isHidden={!isStored}/>
						{ !header.primary &&
						<>
							<Dropdown
								stopPropagation
								name='ColumnOptions'
								dropdownStyle={{ optionWidth: '230px', optionLeft: '-205px', height: 'auto' }}
								datalist={columnOptionsData}
								JSXButton={() => <DefaultImage src={optionGrey} width='17px' height='17px' cursor='pointer' rotate='90deg'/>}
								onChange={(value: DropdownData) => {
									switch (value.value) {
										case 'show_lines':
										case 'hide_lines': {
											const newT = vizualisationFilterResult.formatted ?? { and: [] };
											if ('and' in newT) {
												if (isHidden) {
													newT.and = newT.and.filter(f => !('val' in f && f.val.column === (i - primaryLength).toString() && f.val.operator === 'not_empty'));
												} else {
													newT.and.push({
														val: {
															column: (i - primaryLength).toString(),
															operator: 'not_empty'
														}
													});
												}
												setVizualisationFilterResult({ ...vizualisationFilterResult, formatted: { ...newT } });
											}
											break;
										}
										case 'unpin':
										case 'pin': alert({
											title: 'Attention',
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											content: isStored ? translateToString('additional_create', [['headerName', headerName!]]) : translateToString('additional_delete', [['headerName', headerName!]]),
											textAlign: 'center',
											width: '475px'
										}).then(r => {
											if (r == AlertRes.Ok) {
												if (isStored) {
													props.onUnstoreColumn?.(storedColumnId);
												} else {
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
													props.onStoreColumn?.(tamer, selectedPanel, headerName!);
												}
											}
										}); break;
										case 'turn_to_calc_field': alert({
											title: 'Attention',
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											content: <FlexDiv flow='column' align='stretch' justify='center' gap='1.5rem' fontSize='14px' padding='10px 0 0 0'>
												<div>{translateToString('additional_create', [['headerName', headerName!]])}</div>
												<FlexDiv flow='column' align='stretch' gap='0.75rem'>
													<Translate id ='calculated_fields.new_name'/>
													<Input type='text' name='calcFieldName' value={calcFieldName} onChange={e => calcFieldName = e} inputStyle={{
														height: '40px',
														width: '365px',
														padding: '0 10px',
														borderRadius: '5px',
														fontSize: '14px',
													}}/>
												</FlexDiv>
											</FlexDiv>,
											textAlign: 'center',
											width: '475px'
										}).then(r => {
											if (r == AlertRes.Ok) {
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												props.onCreateCalcField?.(tamer, selectedPanel, calcFieldName.trim().length > 0 ? calcFieldName.trim() : headerName!);
											}
										}); break;
									}
								}}
							/>
						</>
						}
					</FlexDiv>,
				accessor: (row: ReactTableRow) => <RenderTableCell primaryLength={primaryLength} data={row} header={header} toolTipTemplate={toolTipTemplate} index={i} emptyTile={isEmptyTitle} />,
				Footer: report.footers?.[selectedPanel]?.[i] ? <RenderTableCell primaryLength={primaryLength} header={header} toolTipTemplate={toolTipTemplate} index={i} data={report.footers[selectedPanel][i]} /> : <></>,
				sortType: sort(primaryLength, i, evolutionStateRef),
				toolTip: (row, type) => {
					const data = type == 'row' ? row : report.footers[selectedPanel][i];
					if (!data) return;
					let cell: ComplexCell | Cell | undefined;
					let primaryCell: Cell | undefined;
					if ('primaryCell' in data && i < primaryLength) {
						cell = data.primaryCell[i];
						primaryCell = data.primaryCell[0];
					} if ('primaryCell' in data) {
						cell = data.cells?.[i - primaryLength] ? data.cells[i - primaryLength] : undefined;
						primaryCell = data.primaryCell[0];
					} else {
						cell = data;
						primaryCell = undefined;
					}
					if (!cell || cell.value.val === null) return undefined;
					return {
						cell,
						toolTipTemplate,
						primaryCell,
						pos: type === 'row' ? 'left' : 'up'
					};
				},
				...options
			};

			if (header.storeable) {
				reprasentationIndex++;
			}

			return [...acc, col];
		}, [
			{
				id: 'expander',
				Header: (props: unknown) => {
					//@ts-expect-error it works
					const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = props;
					return (
						<span {...getToggleAllRowsExpandedProps({ title: undefined })} style={{ fontSize: '20px', cursor: 'pointer' }}>
							{isAllRowsExpanded ? '-' : '+'}
						</span>
					);
				},
				Cell: (p: { row }) => {
					const row = p.row;
					return row.canExpand ? (
						<span
							{...row.getToggleRowExpandedProps({
								title: undefined,
								style: {
									fontSize: '20px'
								}
							})}
						>
							<div style={{ position: 'relative', top: '50%', translate: '0 -50%' }}>
								{row.isExpanded ? '-' : '+'}
							</div>
						</span>
					) : null;
				},
				width: 60,
				Footer: 'Total'
			}
		]);
	}, [report, selectedPanel, selectedCheckbox, vizualisationFilterResult.formatted, additionalColumns]);

	const chart = React.useMemo(() => reportToChart(report, selectedPanel, selectedCheckbox, colors, selectedHeader ?? 0), [selectedView, selectedPanel, report, selectedHeader]);
	const onTableLoad = React.useCallback(() => setLoadingState(LoadingStateEnum.LOADED), []);

	const datas = React.useMemo(() => report.rows.filter(row_to_filter => {
		return selectedCheckbox[row_to_filter.id] || !hidden;
	}).reduce((acc: ReactTableRow[], row) => {
		const r = filterEmptyRow(toReactTableData(row, selectedPanel), vizualisationFilterResult.formatted);
		if (r) acc.push(r);
		return acc;
	}, []), [report, selectedPanel, hidden, vizualisationFilterResult]);

	return <FlexDiv flow='column' align='stretch' height='100%' width='100%'>
		{report.panels.length > 1 && <PanelSelector
			panels={report.panels.map(p => ({ label: <ValueToNode value={p.value} /> }))}
			onClick={(_, i) => {
				if (selectedPanel === i) return;
				selectedView === 'table' && setLoadingState(LoadingStateEnum.LOADING);
				setTimeout(() => setSelectedPanel(i), 10);
			}}
			activeIndex={selectedPanel}
		/>}
		{selectedView === 'chart' && report.mainAxisRepresentation === AxisRepresentation.COMPANIES && <PanelSelector
			panels={report.headers.filter(h => !h.primary && h.visualisable).map(p => ({ label: <ValueToNode value={p.cell.value} /> }))}
			onClick={(_, i) => {
				if (selectedHeader === i) return;
				setTimeout(() => setSelectedHeader(i), 10);
			}}
			activeIndex={selectedHeader ?? 0}
		/>}
		<div style={{ backgroundColor: 'white', flexGrow: 1 }}>
			{selectedView === 'chart' && <ChartReportingV2
				onCompanyClick={onCompanyClick}
				onFormInstanceClick={onFormInstanceClick}
				setToolBarState={() => null}
				chart={chart}
			/>}
			{selectedView === 'table' && <InfiniteTableStyle toolTipNotHidden>
				<InfiniteTable
					onLoad={onTableLoad}
					height={height}
					columns={columns}
					data={datas}
					header_thing={<Visibility defaultValue={hidden} onChange={(b) => { setHidden(b); }} />}
					setSelectedCheckbox={id => {
						if (selectedCheckbox.filter(b => b).length >= MAX_SELECTED && !selectedCheckbox[id]) return false;
						selectedCheckbox[id] = !(selectedCheckbox[id] ?? false);
						setSelectedCheckbox([...selectedCheckbox]);
						return selectedCheckbox[id];
					}}
					selectedCheckbox={selectedCheckbox}
				/>
				<InfiniteTableToolTip/>
			</InfiniteTableStyle>}
		</div>
		{props.filterParams !== undefined &&
			<AdvancedFilters
				isOpen={isFilterOpen}
				setOpen={setFilterOpen}
				isOpenSummary={isOpenSummary}
				filterList={props.filterParams}
				filterValues={filterResult.values}
				onChange={setFilterResult}
			/>
		}
		{props.visualizationFitlerParams !== undefined &&
			<AdvancedFilters
				title={translateToString('report_parameters')}
				isOpen={isVizualisationFilterOpen}
				setOpen={setVizualisationFilterOpen}
				isOpenSummary={isOpenSummary}
				filterList={props.visualizationFitlerParams}
				filterValues={vizualisationFilterResult.values}
				onChange={setVizualisationFilterResult}
				extractFilters
			/>
		}
		<ComponentLoader loadingState={loadingState} allScreen noBackground />
	</FlexDiv>;
}

export function valueToNumber(value: Value): number | null {
	if (typeof value === 'string' || !value || !value.val) return null;
	if ('int' in value.val) return value.val.int;
	if ('float' in value.val) return value.val.float;
	if ('percentage' in value.val) return value.val.percentage * 100;
	return null;
}

// This function is revelant only if data is homogeneous
function getSecondaryAxisOption(cells: [readonly (Cell | Point)[], Cell, number][], headers: [string, number][]): SecondaryAxis | undefined {
	for (const cell of cells) {
		const headerCells = cell[0].filter((_, i) => headers.find(h => h[1] == i));
		for (const cell of headerCells) {
			if (typeof cell.value === 'string' || !cell.value) {
				continue;
			}
			if ('percentage' in cell.value) {
				return { min: 0, max: 100, ticksType: 'percentage', scale: null, title: '' };
			}
		}
	}
}

function reportToChart(report: Report, selectedPanel: number, selectedCheckbox: boolean[], colors: { rowColors: ColorAndT<number>[], panelColors: ColorAndT<Panel>[] }, selectedHeader: number): Chart {
	const multipleCompany = true;
	const primaryIndex = report.headers.reduce((acc, h, i) => h.primary ? i : acc, -1);
	const barChartOption = report.headers.find(h => !h.primary && h.visualisable)?.barChart[selectedPanel];
	const headers: [string, number][] = report.headers.reduce((acc, h, i) => {
		if (h.primary || !h.visualisable) return acc;
		const headerText = valueToString(h.cell.value);
		return headerText ? [...acc, [headerText, i - primaryIndex - 1]] : acc;
	}, []);
	const flattenCells = (acc: [readonly (Cell | ComplexCell)[], Cell, number][], row: Row): [readonly (Cell | ComplexCell)[], Cell, number][] => {
		acc = [...acc, ...row.rows.reduce(flattenCells, [])];
		if (!selectedCheckbox[row.id]) return acc;
		if (multipleCompany) return [...acc, [row.cells[selectedPanel], row.primaryCell[0], row.id]];
		const cells: [readonly (Cell | ComplexCell)[], Cell, number][] = row.cells.map(cell => [cell, row.primaryCell[0], row.id]);
		return [...acc, ...cells];
	};
	const flattenRow = (acc: Row[], row: Row): Row[] => {
		acc = [...acc, ...row.rows.reduce(flattenRow, [])];
		if (!selectedCheckbox[row.id]) return acc;
		return [...acc, row];
	};
	const rows = report.rows.reduce(flattenRow, []);
	const cells: [readonly (Cell | ComplexCell)[], Cell, number][] = report.rows.reduce(flattenCells, []);
	const secondaryAxisOptions = getSecondaryAxisOption(cells, headers);
	const stacked = report.stacked || selectedCheckbox.filter(b => b).length === 1 && cells[0] && cells[0][0] && cells[0][0][0] && 'values' in cells[0][0][0] && cells[0][0][0].values.length > 0;
	return {
		stacked,
		title: valueToString(report.title) ?? '',
		curves: report.mainAxisRepresentation === AxisRepresentation.COMPANIES
			? genCompaniesCurves(report, selectedPanel, rows, selectedHeader)
			: cells.reduce((acc: Curve[], value, i): Curve[] => {
				if (selectedCheckbox.filter(b => b).length === 1 && value[0] && value[0][0] && 'values' in value[0][0] && value[0][0].values.length > 0) {
					acc.push({
						curveValues: value[0].filter((_, i) => headers.find(h => h[1] == i)).map(cell => valueToNumber(cell.value)),
						link: primaryIndex < 0 ? null : value[1].link?.val ?? null,
						color: stringToColour('Total'),
						title: 'Total',
						representation: 'CURVE'
					});
					for (let subPanelIndex = 0; subPanelIndex < value[0][0].values.length; subPanelIndex++) {
						const curveValues = headers.map(([_, headerIndex]) => {
							const complexCell = value[0][headerIndex];
							if (!complexCell || !('values' in complexCell)) return null;
							return valueToNumber(complexCell.values[subPanelIndex]);
						});
						acc.push({
							curveValues,
							link: primaryIndex < 0 ? null : value[1].link?.val ?? null,
							color: barChartOption?.[subPanelIndex].color ?? '',
							title: barChartOption?.[subPanelIndex].name ? valueToString(barChartOption[subPanelIndex].name) ?? '' : '',
							representation: 'BAR_CHART'
						});
					}
				} else {
					const title = !multipleCompany ? valueToString(report.panels[i]?.value) : primaryIndex < 0 ? undefined : (valueToString(value[1].value));
					const v = {
						curveValues: value[0].filter((_, i) => headers.find(h => h[1] == i)).map(cell => {
							return valueToNumber(cell.value);
						}),
						link: primaryIndex < 0 ? null : value[1].link?.val ?? null,
						color: title ? stringToColour(title) : multipleCompany ? (colors.rowColors.find(c => c.value === value[2])?.color ?? '') : colors.panelColors[i]?.color,
						title: title ?? '',
						representation: !multipleCompany ? report.panels[i]?.representation : report.panels[selectedPanel].representation
					};
					acc.push(v);
				}
				return [...acc];
			}, []),
		mainAxis: {
			// TODO
			title: '',
			labels: report.mainAxisRepresentation === AxisRepresentation.COMPANIES ? genCompaniesAxis(rows) : headers.map(h => h[0]),
			key: report.mainAxisKey,
			// TODO
			ticksType: 'string'
		},
		secondaryAxis: secondaryAxisOptions ?? {
			// TODO
			title: '',
			// TODO
			min: null,
			// TODO
			max: null,
			// TODO
			scale: null,
			// TODO
			ticksType: 'string'
		}
	};
}

function genCompaniesAxis(rows: Row[]): string[] {
	return rows.map(row => valueToString(row.primaryCell[0].value) ?? '');
}

function genCompaniesCurves(report: Report, selectedPanel: number, rows: Row[], selectedHeader: number): Curve[] {
	const index = report.headers.filter(h => !h.primary).map((h, i) => ({ keep: h.visualisable && !h.primary, i })).filter(h => h.keep)[selectedHeader]?.i;
	if (index === undefined) return [];
	const firstValue: Value = rows.reduce((acc: Value, r) => {
		if (acc.val !== null) return acc;
		return r.cells[selectedPanel][index]?.value ?? EmptyCell;
	}, EmptyCell);

	if (firstValue.val && 'pieChart' in firstValue.val) {
		const res: { [key: string]: { values: (number | undefined)[], title: string } } = rows.reduce((acc, r) => {
			const val = r.cells[selectedPanel][index];
			if (!val || !val.value.val || !('pieChart' in val.value.val)) return acc;
			val.value.val.pieChart.forEach((v, i) => {
				if (!acc[v.color]) {
					let title = '';
					if (val.tooltip?.content.val && 'table' in val.tooltip.content.val) {
						title = valueToString(val.tooltip.content.val.table[i][0]) ?? '';
					}
					acc[v.color] = { title, values: [] };
				}
				acc[v.color].values[i] = v.perc;
			});
			return acc;
		}, {});
		return Object.entries(res).map(([color, curveValues]) => ({
			curveValues: curveValues.values.map(v => v ?? 0),
			link: null,
			color,
			title: curveValues.title,
			representation: 'BAR_CHART'
		}));
	} else {
		const curveValues = rows.reduce((acc, r) => [...acc, valueToNumber(r.cells[selectedPanel][index]?.value)], []);
		return [{
			curveValues,
			link: null,
			color: BlueSidely,
			title: valueToString(report.headers.filter(h => !h.primary)[index].cell.value) ?? 'values',
			representation: 'BAR_CHART'
		}];
	}
}

export const SwitchView = styled.img<{ active: boolean }>`
	${p => p.active ? '' : `
		cursor: pointer;
		opacity: 0.2;
	`}
	height: 24px;
`;

const ToolTipContainer = styled.div`
	border: none !important;
	height: 0 !important;
	width: 0 !important;
`;

export function InfiniteTableToolTip(): JSX.Element {
	const context = React.useContext(ReportInterpretorContext);
	if (!context.hover) return <></>;
	const { cell, bounds, toolTipTemplate, primaryCell, pos } = context.hover;
	const t = { ...context.hover };
	let tooltipClass = 'tooltip2';
	let tooltipTextClass = 'tooltiptext2';
	if (pos == 'up') {
		tooltipClass = 'tooltipup';
		tooltipTextClass = 'tooltiptextup';
	}

	if (cell.tooltip) {
		let toolTipContent: React.ReactNode = <></>;

		if ('simpleValue' in cell.tooltip.content.val) {
			toolTipContent = <ValueToNode value={cell.tooltip.content.val.simpleValue} />;
		} else if ('table' in cell.tooltip.content.val) {
			toolTipContent = <table>
				{cell.tooltip.content.val.table.map((values, x) => <tr key={`toolTip[${x}]`}>
					{values.map((value, y) => <ToolTipTd key={`toolTip[${x}][${y}]`} noBorder={x === 0}>{<ValueToNode value={value} />}</ToolTipTd>)}
				</tr>)}
			</table>;
		}
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{toolTipContent}
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	} else if (toolTipTemplate) {
		const title = referenceToNode(toolTipTemplate.title, primaryCell, cell);
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{title && <div className='font-weight-bold' style={{ marginBottom: '2px' }}>
						{title}
					</div>}
					<FlexDiv justify='center' align='center'>
						<table>
							{toolTipTemplate.content.map((values, x) => <tr key={`toolTip[${x}]`}>
								{values.map((value, y) => {
									let nodeValue: React.ReactNode;
									if ('value' in value.val) {
										nodeValue = <ValueToNode value={value.val.value} />;
									} else {
										nodeValue = referenceToNode(value.val.reference, primaryCell, cell);
									}
									return <ToolTipTd key={`toolTip[${x}][${y}]`} noBorder={x === 0 && title === undefined}>{nodeValue}</ToolTipTd>;
								})}
							</tr>)}
						</table>
					</FlexDiv>
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}
	return <></>;
}

function EvolutionDropdown(props: {headerIndex: number}) {
	const { evolutionState, setEvolutionState } = React.useContext(ReportInterpretorContext);
	const currentState = evolutionState[props.headerIndex] ?? '%';
	return <Dropdown
		dropdownStyle={{
			height: '20px',
			optionWidth: '60px'
		}}
		datalist={[{ label: '%', value: '%' }, { label: '+', value: '+' }]}
		name={`Operator[${props.headerIndex}]`}
		selectedValue={{ label: currentState, value: currentState }}
		JSXOption={({ options, onOptionClicked }) => {
			return <>{options.map((element, i) => <OptionBlock key={i} onClick={(e) => {
				e.stopPropagation();
				onOptionClicked(element, i);
			}}>{element.label}</OptionBlock>)}</>;
		}}
		JSXButton={({ value, setIsOpen }) => {
			return <EvolutionOperatorContainer onClick={(e) => {
				e.stopPropagation();
				setIsOpen(isOpen => !isOpen);
			}}>{value?.label}</EvolutionOperatorContainer>;
		}}
		onChange={value => setEvolutionState(state => {
			state[props.headerIndex] = value.value;
			return { ...state };
		})}
	/>;
}
